import { convertDateToUnixTimestamp } from './convert-date-to-unix-timestamp';

export function isUnixTimestampInPast(unixTimestamp: number): boolean {
  const currentDate = new Date();
  const currentUnixTimestamp = convertDateToUnixTimestamp(
    currentDate.toDateString()
  );

  return unixTimestamp < currentUnixTimestamp;
}
