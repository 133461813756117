import { EventAdmin } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getArrayDif } from './utils/get-array-dif';

export function getAdminsDif(previous: EventAdmin[], current: EventAdmin[], options?: DifOptions<'admins'>) {

  return getArrayDif(previous, current, 0, 'admins', 'id', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions)

}

// const admins = [] as EventAdmin[];

// getAdminsDif(admins, admins, { keysToIgnore: ['inviteSent'] });
