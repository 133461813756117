import { ShareEventDetails } from 'common-types';
import { formatPhoneWithoutCountryCode } from './format-phone-without-country-code';
import { getFirstNameFromName } from './get-first-name-from-name';
import { getLastNameFromName } from './get-last-name-from-name';
import { User } from '@angular/fire/auth';

export function firebaseUserToShareEventDetails(
  // firebaseUser: firebase.User
  firebaseUser: User
): ShareEventDetails {
  return {
    firstName: getFirstNameFromName(firebaseUser.displayName ?? ''),
    lastName: getLastNameFromName(firebaseUser.displayName ?? ''),
    email: firebaseUser.email ?? '',
    phone: formatPhoneWithoutCountryCode(firebaseUser.phoneNumber ?? ''),
  } as ShareEventDetails;
}
