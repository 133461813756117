export const SendResetPasswordErrorCode = {
  email_missing: 'email-missing',
  site_missing: 'site-missing',
  site_invalid: 'site-invalid',
  invalid_email: 'invalid-email',
  user_not_found: 'user-not-found',
  user_disabled: 'user-disabled',
  too_many_requests: 'too-many-requests',
  unknown_error: 'unknown-error',
} as const;

export type SendResetPasswordErrorCode = typeof SendResetPasswordErrorCode[keyof typeof SendResetPasswordErrorCode];

type SendResetPasswordSuccess = {
  success: true;
  docId: string;
}

type SendResetPasswordFailure = {
  success: false;
  error: SendResetPasswordErrorCode;
}

export type SendResetPasswordResponse = SendResetPasswordSuccess | SendResetPasswordFailure;

