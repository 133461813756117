import { convertUnixTimestampToDate } from './convert-unix-timestamp-to-date';

export function convertUnixTimestampToPrettyDate(
  unixTimestamp: number,
  shortYear: boolean = false
): string {
  const date = convertUnixTimestampToDate(unixTimestamp);
  let year = shortYear ? (date.getFullYear() - 2000).toString() : date.getFullYear().toString();
  return (date.getMonth() + 1).toString() + '-' + date.getDate().toString() + '-' + year;
}
