import { z } from 'zod';
import { EventAdminSchema } from './event-admin.type';

export const EventAdminDetailsSchema = EventAdminSchema.pick({
  firstName: true,
  lastName: true,
  phone: true,
  email: true,
});

export type EventAdminDetails = z.infer<typeof EventAdminDetailsSchema>;
// export interface EventAdminDetails extends z.infer<typeof EventAdminDetailsSchema> { }
