import * as z from 'zod';

export const AltColorSchema = z.object({
  styleCode: z.string(),
  marketingTitle: z.string(),
  colorShade: z.string(),
  colorHexCode: z.string(),
});

export type ZodAltColor = z.infer<typeof AltColorSchema>;


export type AltColor = {
  styleCode: string;
  marketingTitle: string;
  colorShade: string;
  colorHexCode: string;
};
