import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
  Observable,
  Subscription,
  defer,
  distinctUntilChanged,
  map,
  of,
  startWith,
} from 'rxjs';

interface ChipsAutocompleteForm {
  newItem: FormControl<string | null>;
  items: FormControl<string[]>;
}

@Component({
  selector: 'app-chips-autocomplete-form',
  templateUrl: './chips-autocomplete-form.component.html',
  styleUrls: ['./chips-autocomplete-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
})
export class ChipsAutocompleteFormComponent implements OnInit, OnDestroy {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() allItems: string[] = [];
  @Input() required = false;
  @Input() itemsLoaded$: Observable<void> | undefined;
  @Input() clearCtrl$: Observable<void> | undefined;
  @Input() isDisabled$: Observable<boolean> | undefined;

  @Output() focus = new EventEmitter<void>();

  @ViewChild(MatAutocomplete) matAutocomplete!: MatAutocomplete;
  @ViewChild('newItemInput') newItemInput!: ElementRef<HTMLInputElement>;

  public readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public filteredItems$!: Observable<string[]>;
  private subscription = new Subscription();

  public form = new FormGroup<ChipsAutocompleteForm>({
    newItem: new FormControl(''),
    items: new FormControl([], {
      nonNullable: true,
    }),
  });

  public get newItemCtrl(): FormControl<string | null> {
    return this.form.controls.newItem;
  }

  public get itemsCtrl(): FormControl<string[]> {
    return this.form.controls.items;
  }

  public get items(): string[] {
    return this.form.controls.items.value;
  }

  @Input()
  set initialValue(value: string[] | undefined) {
    if (value) {
      this.form.patchValue(
        { items: value },
        { emitEvent: false, onlySelf: true }
      );
      this.cdr.detectChanges();
    }
  }

  @Output() formReady = of(this.form);

  @Output()
  valueChange = defer(() =>
    this.itemsCtrl.valueChanges.pipe(distinctUntilChanged())
  );

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setFilteredItems$();

    if (this.required) {
      this.form.controls.items.addValidators(Validators.required);
    }

    if (this.clearCtrl$) {
      this.subscription.add(
        this.clearCtrl$.subscribe({
          next: () => this.clearCtrl(),
        })
      );
    }

    if (this.itemsLoaded$) {
      this.subscription.add(
        this.itemsLoaded$.subscribe({
          next: () => this.setFilteredItems$(),
        })
      );
    }

    if (this.isDisabled$) {
      this.subscription.add(
        this.isDisabled$.subscribe({
          next: (isDisabled) => {
            if (isDisabled) {
              this.form.disable();
            } else {
              this.form.enable();
            }
          },
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setFilteredItems$(): void {
    this.filteredItems$ = this.newItemCtrl.valueChanges.pipe(
      startWith(null),
      map((item) => (item ? this.filterItems(item) : this.allItems.slice()))
    );
  }

  private filterItems(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allItems.filter((item) =>
      item.toLowerCase().includes(filterValue)
    );
  }

  private clearCtrl(): void {
    this.newItemInput.nativeElement.value = '';
    this.newItemCtrl.setValue(null);
    this.form.controls.items.updateValueAndValidity();
  }

  public onFocus(): void {
    this.focus.emit();
  }

  public onSelected(event: MatAutocompleteSelectedEvent): void {
    const item = event.option.viewValue;
    const alreadyAdded = this.items.includes(item);

    if (!alreadyAdded) {
      this.items.push(item);
    }

    this.clearCtrl();
  }

  public onRemove(item: string): void {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }
}
