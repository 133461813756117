import { Event } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getArrayDif } from './utils/get-array-dif';

export function getAdminsDifFromEvent<T extends Event>(previous: T, current: T, options?: DifOptions<'admins'>) {

  return getArrayDif(previous.admins, current.admins, 0, 'admins', 'id', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions)

}

// const event: Event = {} as Event;

// getAdminsDifFromEvent(event, event, {
//   keysToIgnore: ['isOrganizer'],
//   keysToInclude: ['isOrganizer']
// });

