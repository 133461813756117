import { Event, EventMember } from 'common-types';

export function getEventMemberById(
  event: Event | undefined,
  memberId: string
): EventMember | undefined {
  if (event === undefined) {
    return undefined;
  }

  let memberIndex = event.members.findIndex((member) => {
    return memberId === member.id;
  });

  if (memberIndex > -1) {
    return event.members[memberIndex];
  }

  return undefined;
}
