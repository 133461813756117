import { EventMember } from "common-types";

/**
 * Returns a list of id's from a list of members.
 * Will filter out any members without an id.
 * And will filter out duplicates.
 */
export function getMemberIds(members: EventMember[]): string[] {

  const memberIds = members
    .filter((member): member is EventMember & { id: string } => !!member?.id) // only include members with an email
    .map(member => member.id);

  // filter duplicates
  return [...new Set(memberIds)];

}
