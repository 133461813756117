import { Event, EventMember } from "common-types";
import { getAllStyleCodesInEvent } from "../../utilities";
import { getMemberEmails } from "./get-member-emails";
import { getMemberIds } from "./get-member-ids";


/**
 * Returns a new event object with the member added.  Does not mutate the original event.  Updates the members array, memberIds array, memberEmails array, and styleCodes array.
 * Will throw an error if the member being added has an id that matches a member in the members array or an id in the memberIds array.
 * @param event the event to add the member to
 * @param member the member to add
 * @returns the event with the member added (returns a new object; does not mutate the original event)
 */
export function addMemberToEvent(event: Event, member: EventMember): Event {

  const isDuplicateMemberId = member?.id ? event?.memberIds?.includes(member?.id) : false;
  const isDuplicateMember = member?.id ? event.members.some(m => m.id === member.id) : false;

  if (isDuplicateMemberId || isDuplicateMember) {
    throw new Error(`Member with id ${member.id} already exists in event`);
  }

  const newMembers = [...event.members, member];
  const newMemberIds = getMemberIds(newMembers);
  const newMemberEmails = getMemberEmails(newMembers);
  const newStyleCodes = getAllStyleCodesInEvent({ ...event, members: newMembers });

  const newEvent: Event = {
    ...event,
    members: newMembers,
    memberIds: newMemberIds,
    memberEmails: newMemberEmails,
    styleCodes: newStyleCodes,
  }

  return newEvent;

}
