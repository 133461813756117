import { FirebaseErrorCode } from "../firebase-errors/firebase-error-code.type";

export const UpdatePersonalInfoErrorCode = {
  CLIENT: "CLIENT",
  AUTH: "AUTH",
  FIRESTORE: "FIRESTORE",
  TRANSACTION: "TRANSACTION",
  UNKNOWN: "UNKNOWN"
} as const;

export type UpdatePersonalInfoErrorCode = keyof typeof UpdatePersonalInfoErrorCode;

export interface UpdatePersonalInfoError {
  code: UpdatePersonalInfoErrorCode | FirebaseErrorCode,
  message: string,
  // name: 'UpdatePersonalInfoError',
  stack?: string,
}

// export class UpdatePersonalInfoError {

//   constructor(
//     public code: UpdatePersonalInfoErrorCode,
//     public message: string,
//     public stack?: string,
//   ) {
//     this.message = message;
//     this.code = code;
//     this.name = 'UpdatePersonalInfoError';
//     this.stack = stack;
//   }
// }





