export function getTrackingUrl(trackingNumber: string): string | undefined {
  trackingNumber = trackingNumber.trim();
  if(trackingNumber === '') {
    return undefined;
  }
  if(trackingNumber.substring(0, 2) === '1Z') { // UPS tracking number.
    return 'https://www.ups.com/track?loc=en_US&tracknum=' + trackingNumber;
  } else if( trackingNumber.length === 12 || trackingNumber.length === 15 ) { // FedEx tracking number.
    return 'https://www.fedex.com/fedextrack/?trknbr=' + trackingNumber;
  } else if(trackingNumber.substring(0, 1) === '0') { // SpeeDee
    return 'http://packages.speedeedelivery.com/index.php?barcodes=' + trackingNumber;
  } else { // assume USPS?
    return 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=' + trackingNumber;
  }
}
