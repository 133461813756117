// Returns 5 years into the future from today.
export function getMaxDateForEvent(): Date {
  let maxDate;
  const currentDate = new Date();
  maxDate = new Date(
    currentDate.getFullYear() + 5,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  return maxDate;
}
