import { Cart, MemberCart } from 'common-types';

/**
 * Given a cart, what is the subtotal of the items being purchased.
 *
 * @param cart Cart | undefined
 * @returns number
 */

export function getCartSubtotal(cart: Cart | undefined): number {
  if (cart === undefined) {
    return 0;
  }

  let subTotal: number = 0;

  cart.members?.forEach((member: MemberCart) => {
    subTotal += member.subtotal;
  });

  // Update subtotal for PurchaseItems
  if (cart.cartPurchaseItems !== undefined) {
    cart.cartPurchaseItems.forEach((item) => {
      if (item.style.price !== undefined) {
        let lineTotal = item.style.price * Number(item.qty);
        subTotal += lineTotal;
      }
    });
  }
  return subTotal;
}
