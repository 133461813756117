import { EcomStyle, Look, StyleType } from 'common-types';

export function getEcomStyleFromLook(
  look: Look | undefined,
  styleType: StyleType
): EcomStyle | undefined {
  if (look) {
    return look.styles.find((style) => style.styleType === styleType);
  }
  return undefined;
}
