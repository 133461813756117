import {
  Attachment,
  BaseStyle,
  Button,
  CoatStyle,
  CoatType,
  CollarType,
  Collection,
  Color,
  Designer,
  Fabric,
  Fit,
  HotItem,
  Lapel,
  LegacyStyle,
  MeasurementScale,
  PantStyle,
  Pattern,
  PlatformStyle,
  Pocket,
  PocketSquareStyle,
  PricePoint,
  Program,
  ShirtStyle,
  ShoesStyle,
  SizeScale,
  SockPattern,
  SocksStyle,
  StyleGroup,
  StyleType,
  SuspendersStyle,
  TieStyle,
  TieType,
  Vent,
  VestBack,
  VestStyle,
  VestType,
  Warehouse,
} from 'common-types';
import dayjs from 'dayjs';
import { getStyleCodeFromLegacy } from './getStyleCodeFromLegacy';

export function convertLegacyStyleToPlatformStyle(
  legacyStyle: LegacyStyle
): PlatformStyle {
  let warehouses: Array<Warehouse> = [];
  let legacyWarehouses = JSON.parse(legacyStyle.warehouses);
  legacyWarehouses.forEach((warehouse: any) => {
    warehouses.push((<any>Warehouse)[warehouse]);
  });
  let baseStyle: BaseStyle = {
    styleCode: getStyleCodeFromLegacy(legacyStyle),
    styleType: (<any>StyleType)[legacyStyle.styleType],
    styleGroup: (<any>StyleGroup)[legacyStyle.styleGroup],
    merchandisingTitle: legacyStyle.merchandisingTitle,
    //marketingTitle: legacyStyle.marketingTitle,
    warehouses: warehouses,
    hotItem: (<any>HotItem)[legacyStyle.hotItem],
    collection: (<any>Collection)[legacyStyle.collection.trim()],
    colors: [(<any>Color)[legacyStyle.color.color]],
    pricePoint: (<any>PricePoint)[legacyStyle.pricePoint.trim()],
    barcode: legacyStyle.barcode === 'true',
    activeDate: dayjs(legacyStyle.activeDate).unix(),
    discontinuedDate:
      legacyStyle.discontinuedDate === ''
        ? undefined
        : dayjs(legacyStyle.discontinuedDate).unix(),
    //webReleaseDate: legacyStyle.webReleaseDate === '' ? undefined : dayjs(legacyStyle.webReleaseDate).unix(),
    //webDiscontinuedDate: legacyStyle.webDiscontinuedDate === '' ? undefined : dayjs(legacyStyle.webDiscontinuedDate).unix(),
    webPrice: parseFloat(legacyStyle.webPrice),
    keywords: [],
    categories: [],
    images: [],
    mannequinImages: [],
    legacyStyleCode: legacyStyle.styleCode,
  };
  console.log('legacyStyle', legacyStyle);
  let attributes = JSON.parse(legacyStyle.attributes);
  if (attributes === null) {
    attributes = [];
  }
  console.log('attributes', attributes);
  if (legacyStyle.styleType === 'Coat') {
    let coatStyle: CoatStyle = {
      fabric: (<any>Fabric)[legacyStyle.fabric],
      sizeScale: (<any>SizeScale)[legacyStyle.sizeScale.trim()],
      measurementScale1: (<any>MeasurementScale)[
        legacyStyle.measurementScale1.trim()
      ],
      measurementScale2: (<any>MeasurementScale)[
        legacyStyle.measurementScale2.trim()
      ],
      ...baseStyle,
    };
    attributes.forEach((attribute: any) => {
      switch (attribute.name) {
        case 'designer':
          coatStyle.designer = (<any>Designer)[attribute.value];
          break;
        case 'buttons':
          switch (attribute.value) {
            case '1':
              coatStyle.buttons = Button.One;
              break;
            case '2':
              coatStyle.buttons = Button.Two;
              break;
            case '3':
              coatStyle.buttons = Button.Three;
              break;
            case '4':
              coatStyle.buttons = Button.Four;
              break;
            case '5':
              coatStyle.buttons = Button.Five;
              break;
          }
          break;
        case 'lapel':
          coatStyle.lapel = (<any>Lapel)[attribute.value];
          break;
        case 'fit':
          coatStyle.fit = (<any>Fit)[attribute.value];
          break;
        case 'pocket':
          coatStyle.pocket = (<any>Pocket)[attribute.value];
          break;
        case 'suit':
          coatStyle.coatType =
            attribute.value === 'Y' ? CoatType.Suit : CoatType.Tuxedo;
          break;
        case 'vent':
          coatStyle.vent = (<any>Vent)[attribute.value];
          break;
      }
    });
    return coatStyle;
  }
  if (legacyStyle.styleType === 'Pant') {
    let pantStyle: PantStyle = {
      fabric: (<any>Fabric)[legacyStyle.fabric],
      sizeScale: (<any>SizeScale)[legacyStyle.sizeScale.trim()],
      measurementScale1: (<any>MeasurementScale)[
        legacyStyle.measurementScale1.trim()
      ],
      measurementScale2: (<any>MeasurementScale)[
        legacyStyle.measurementScale2.trim()
      ],
      ...baseStyle,
    };
    attributes.forEach((attribute: any) => {
      switch (attribute.name) {
        case 'fit':
          switch (attribute.value) {
            case 'Flat Front':
              pantStyle.fit = Fit.FlatFront;
              break;
            case 'Modern Slim':
              pantStyle.fit = Fit.Modern;
              break;
            case 'Ultra Slim':
              pantStyle.fit = Fit.UltraSlim;
              break;
          }
          break;
      }
    });
    return pantStyle;
  }
  if (legacyStyle.styleType === 'Shirt') {
    let shirtStyle: ShirtStyle = {
      fabric: (<any>Fabric)[legacyStyle.fabric],
      sizeScale: (<any>SizeScale)[legacyStyle.sizeScale.trim()],
      measurementScale1: (<any>MeasurementScale)[
        legacyStyle.measurementScale1.trim()
      ],
      measurementScale2: (<any>MeasurementScale)[
        legacyStyle.measurementScale2.trim()
      ],
      ...baseStyle,
    };
    attributes.forEach((attribute: any) => {
      switch (attribute.name) {
        case 'fit':
          shirtStyle.fit = (<any>Fit)[attribute.value];
          break;
        case 'collar':
          shirtStyle.collarType = (<any>CollarType)[attribute.value];
          break;
      }
    });
    return shirtStyle;
  }
  if (legacyStyle.styleType === 'Tie') {
    let tieStyle: TieStyle = {
      fabric: (<any>Fabric)[legacyStyle.fabric],
      sizeScale: (<any>SizeScale)[legacyStyle.sizeScale.trim()],
      measurementScale1: (<any>MeasurementScale)[
        legacyStyle.measurementScale1.trim()
      ],
      measurementScale2: (<any>MeasurementScale)[
        legacyStyle.measurementScale2.trim()
      ],
      ...baseStyle,
    };
    attributes.forEach((attribute: any) => {
      switch (attribute.name) {
        case 'collection':
          tieStyle.program = (<any>Program)[attribute.value];
          break;
        case 'type':
          tieStyle.tieType = (<any>TieType)[attribute.value];
          break;
        case 'pattern':
          tieStyle.pattern = (<any>Pattern)[attribute.value];
          break;
      }
    });
    return tieStyle;
  }
  if (legacyStyle.styleType === 'Vest') {
    let vestStyle: VestStyle = {
      fabric: (<any>Fabric)[legacyStyle.fabric],
      sizeScale: (<any>SizeScale)[legacyStyle.sizeScale.trim()],
      measurementScale1: (<any>MeasurementScale)[
        legacyStyle.measurementScale1.trim()
      ],
      measurementScale2: (<any>MeasurementScale)[
        legacyStyle.measurementScale2.trim()
      ],
      ...baseStyle,
    };
    attributes.forEach((attribute: any) => {
      switch (attribute.name) {
        case 'collection':
          vestStyle.program = (<any>Program)[attribute.value];
          break;
        case 'type':
          vestStyle.vestType = (<any>VestType)[attribute.value];
          break;
        case 'buttons':
          switch (attribute.value) {
            case '1':
              vestStyle.buttons = Button.One;
              break;
            case '2':
              vestStyle.buttons = Button.Two;
              break;
            case '3':
              vestStyle.buttons = Button.Three;
              break;
            case '4':
              vestStyle.buttons = Button.Four;
              break;
            case '5':
              vestStyle.buttons = Button.Five;
              break;
          }
          break;
        case 'vestback':
          vestStyle.vestBack = (<any>VestBack)[attribute.value];
          break;
      }
    });
    return vestStyle;
  }
  if (legacyStyle.styleType === 'PocketSquare') {
    let pocketSquareStyle: PocketSquareStyle = {
      ...baseStyle,
    };
    attributes.forEach((attribute: any) => {
      switch (attribute.name) {
        case 'collection':
          pocketSquareStyle.pattern = (<any>Pattern)[attribute.value];
          break;
      }
    });
    return pocketSquareStyle;
  }
  if (legacyStyle.styleType === 'Shoes') {
    let shoeStyle: ShoesStyle = {
      sizeScale: (<any>SizeScale)[legacyStyle.sizeScale.trim()],
      measurementScale1: (<any>MeasurementScale)[
        legacyStyle.measurementScale1.trim()
      ],
      measurementScale2: (<any>MeasurementScale)[
        legacyStyle.measurementScale2.trim()
      ],
      ...baseStyle,
    };
    return shoeStyle;
  }
  if (legacyStyle.styleType === 'Socks') {
    let socksStyle: SocksStyle = {
      sizeScale: (<any>SizeScale)[legacyStyle.sizeScale.trim()],
      measurementScale1: (<any>MeasurementScale)[
        legacyStyle.measurementScale1.trim()
      ],
      measurementScale2: (<any>MeasurementScale)[
        legacyStyle.measurementScale2.trim()
      ],
      ...baseStyle,
    };
    attributes.forEach((attribute: any) => {
      switch (attribute.name) {
        case 'type':
          socksStyle.sockPattern = (<any>SockPattern)[attribute.value];
          break;
      }
    });
    return socksStyle;
  }
  if (legacyStyle.styleType === 'Suspenders') {
    let suspendersStyle: SuspendersStyle = {
      sizeScale: (<any>SizeScale)[legacyStyle.sizeScale.trim()],
      measurementScale1: (<any>MeasurementScale)[
        legacyStyle.measurementScale1.trim()
      ],
      measurementScale2: (<any>MeasurementScale)[
        legacyStyle.measurementScale2.trim()
      ],
      ...baseStyle,
    };
    attributes.forEach((attribute: any) => {
      switch (attribute.name) {
        case 'type':
          switch (attribute.value) {
            case 'Clip-On':
              suspendersStyle.attachment = Attachment.ClipOn;
              break;
            case 'Button-On':
              suspendersStyle.attachment = Attachment.Button;
              break;
          }
          break;
      }
    });
    return suspendersStyle;
  }
  return baseStyle;
}
