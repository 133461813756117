import * as z from 'zod';
import { JFWNewEmailSchema } from '../email';

const NAME_MAX_LENGTH = 30;

export const EventAdminSchema = z.object({
  id: z.string(),
  name: z.string(),
  firstName: z.string()
    .trim()
    .min(1, { message: 'First Name is required' })
    .max(NAME_MAX_LENGTH, {
      message: `Must be less than ${NAME_MAX_LENGTH} characters`
    })
    .optional(),
  lastName: z.string()
    .trim()
    .min(1, { message: 'Last Name is required' })
    .max(NAME_MAX_LENGTH, { message: `Must be less than ${NAME_MAX_LENGTH} characters` }).optional()
    .optional(),
  userId: z.string().optional(),
  confirmedInfo: z.boolean().optional(),
  role: z.string(),
  email: JFWNewEmailSchema,
  phone: z.string(),
  inviteSent: z.boolean().optional(),
  inviteAccepted: z.number().optional(), // Unix timestamp
  isOrganizer: z.boolean(),
});

export type EventAdmin = z.infer<typeof EventAdminSchema>;

export const EventAdminNoIdSchema = EventAdminSchema.omit(
  { id: true }
);

export type EventAdminNoId = z.infer<typeof EventAdminNoIdSchema>;


// export type EventAdmin = {
//   id: string;
//   name: string;
//   firstName?: string;
//   lastName?: string;
//   userId?: string;
//   confirmedInfo?: boolean;
//   role: string;
//   email: string;
//   phone: string;
//   inviteSent?: boolean;
//   isOrganizer: boolean;
// };
