import { EcomEnsemble, FilterCategory, FilterOption } from 'common-types';
import { getFilterCategoriesByEnsemble } from './get-filter-categories-by-ensemble-type';

export function getEnsembleFilterOptions(
  ensembles: Array<EcomEnsemble>
): Array<FilterCategory> {
  let filterCategoryOptions: Array<FilterCategory> = [];
  ensembles.forEach((ensemble: EcomEnsemble) => {
    let filterCategories: Array<FilterCategory> =
      getFilterCategoriesByEnsemble();
    filterCategories.forEach((category: FilterCategory) => {
      if (category.attribute === 'ensembleGroup') {
        // Find coat in ensemble
        const isBothRentAndBuy =
          ensemble.ensembleGroup && ensemble.companionEnsembleCode;
        let i = filterCategoryOptions.findIndex((cat: FilterCategory) => {
          return cat.attribute === category.attribute;
        });
        if (i > -1) {
          // filter category already exists.
          let iOption = filterCategoryOptions[i].options?.findIndex(
            (op: FilterOption) => {
              return (
                op.value ===
                (ensemble[
                  category.attribute as keyof typeof ensemble
                ] as string)
              );
            }
          );
          if (iOption === -1) {
            // FilterOption isn't there. Add it to the Category.
            filterCategoryOptions[i].options?.push({
              value: 'Purchase',
            });
          }
        } else {
          // doesn't exist.
          filterCategoryOptions.push({
            attribute: category.attribute,
            displayName: category.displayName,
            options: [
              {
                value: 'Purchase' as string,
              },
            ],
          });
        }
      }

      if (category.attribute in ensemble) {
        // category exists on ensemble (ie. color)
        let i = filterCategoryOptions.findIndex((cat: FilterCategory) => {
          return cat.attribute === category.attribute;
        });
        if (i > -1) {
          // filter category already exists.
          let iOption = filterCategoryOptions[i].options?.findIndex(
            (op: FilterOption) => {
              return (
                op.value ===
                (ensemble[
                  category.attribute as keyof typeof ensemble
                ] as string)
              );
            }
          );
          if (iOption === -1) {
            // FilterOption isn't there. Add it to the Category.
            filterCategoryOptions[i].options?.push({
              value: ensemble[
                category.attribute as keyof typeof ensemble
              ] as string,
            });
          }
        } else {
          // doesn't exist.
          filterCategoryOptions.push({
            attribute: category.attribute,
            displayName: category.displayName,
            options: [
              {
                value: ensemble[
                  category.attribute as keyof typeof ensemble
                ] as string,
              },
            ],
          });
        }
      }
    });
  });

  const colorSortOrder: String[] = [
    'Black',
    'Grey',
    'Grey/Silver',
    'White',
    'White/Ivory',
    'Yellow/Gold',
    'Tan/Brown',
    'Coral/Orange',
    'Pink',
    'Red',
    'Red/Burgundy',
    'Purple',
    'Blue',
    'Blue/Navy',
    'Turquoise/Teal',
    'Green',
  ];

  filterCategoryOptions.map((filterOption) => {
    if (filterOption.attribute === 'colorFamily') {
      return (filterOption.options = filterOption.options?.sort((a, b) => {
        return (
          colorSortOrder.indexOf(a.value) - colorSortOrder.indexOf(b.value)
        );
      }));
    } else {
      return;
    }
  });
  return filterCategoryOptions;
}
