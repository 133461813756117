import { OrderDetail } from 'common-types';
import { getDefaultProductLine } from './get-default-product-line';

export function getLineNumber(
  legacyProductId: string,
  orderDetails: Array<OrderDetail>
): number {
  const startOverflow: number = 9;
  let defaultProductLine: number | undefined =
    getDefaultProductLine(legacyProductId);
  if (defaultProductLine !== undefined) {
    // Product with a default line.
    let existingIndex = orderDetails.findIndex((orderDetail: OrderDetail) => {
      return orderDetail.line === defaultProductLine;
    });
    if (existingIndex < 0) {
      // the default line isn't currently occupied.
      return defaultProductLine;
    }
  }
  // doesn't have a default product line or is currently occupied by another product.
  for (let line = startOverflow; line <= 25; line++) {
    let existingIndex = orderDetails.findIndex((orderDetail: OrderDetail) => {
      return orderDetail.line === line;
    });
    if (existingIndex < 0) {
      // found a line to place the product.
      return line;
    }
  }
  return -1;
}
