import { DecodedUser, Event } from 'common-types';

export function isDecodedUserOrganizerOrAdminForEvent(
  event: Event,
  decodedUser: DecodedUser
): boolean {

  const isOrganizer = event?.createdByUserId !== undefined && event.createdByUserId === decodedUser.uid;
  const isAdmin = event.admins.some(
    (admin) => admin.email.toLowerCase() === decodedUser?.email?.toLowerCase() ||
      (admin.userId !== undefined && admin.userId === decodedUser?.uid)
  );

  return isOrganizer || isAdmin;

}
