import { EventMember } from 'common-types';

export function hasInvite(member: EventMember): boolean {
  if (member.memberProgress === undefined) {
    return false;
  }
  if (member.memberProgress.inviteSent !== undefined) {
    return true;
  }
  return false;
}
