import { Category, FilterCategory, FilterSelect } from 'common-types';

export function getFilterCategoriesByPlpCategory(
  plpCategory: Category
): Array<FilterCategory> {
  const defaultCategories: Array<FilterCategory> = [
    {
      attribute: 'colorFamily',
      displayName: 'Color Family',
      select: FilterSelect.Single,
    },
    {
      attribute: 'styleGroup',
      displayName: 'Rent or Buy',
      select: FilterSelect.Multi,
    },
  ];

  let extraCategories: Array<FilterCategory>;
  switch (plpCategory) {
    case Category.Accessories:
      extraCategories = [
        {
          attribute: 'styleType',
          displayName: 'Product',
          select: FilterSelect.Multi,
        },
      ];
      break;
    case Category.Shoes:
      extraCategories = [];
      break;
    case Category.ShoesAndSocks:
      extraCategories = [
        {
          attribute: 'styleType',
          displayName: 'Product',
          select: FilterSelect.Multi,
        },
      ];
      break;
    case Category.Ties:
      extraCategories = [
        {
          attribute: 'tieType',
          displayName: 'Tie Style',
          select: FilterSelect.Multi,
        },
        {
          attribute: 'pattern',
          displayName: 'Pattern',
          select: FilterSelect.Multi,
        },
      ];
      break;
    case Category.VestsAndCummerbunds:
      extraCategories = [
        {
          attribute: 'pattern',
          displayName: 'Pattern',
          select: FilterSelect.Multi,
        },
      ];
      break;
    default:
      extraCategories = [];
  }
  return defaultCategories.concat(extraCategories);
}
