import { ChangeType } from "../types/change-type.type";
import { Change } from "../types/change.type";
import { DifOptions } from "../types/dif-options.type";
import { getDif } from "./get-dif";
import { getLevelSpacers } from "./get-level-spacers";


export function getArrayDif<T extends Record<string, any>, U extends Array<Partial<T>>>(previous: U | undefined, current: U | undefined, recursionDepth: number, recursionKey: string, itemIdKey: keyof T | undefined = undefined, options: DifOptions) {
  const { logDifs, logChecks } = options;

  if (logChecks) console.log(getLevelSpacers(recursionDepth) + recursionKey, "in getArrayDif...");


  const dif: Change = {
    previous: {} as Partial<U>,
    current: {} as Partial<U>,
  };

  if (previous === undefined && current === undefined) {
    console.log("Both previous and current are undefined for", recursionKey);
    return null;
  }

  if (previous === undefined && current !== undefined) {
    if (logDifs) console.log(`${recursionKey} -- array added`);
    dif.previous = ChangeType.didNotExist as keyof typeof previous;
    dif.current = current;
    return dif;
  }

  if (current === undefined && previous !== undefined) {
    if (logDifs) console.log(`${recursionKey} -- array removed`);
    dif.previous = previous;
    dif.current = ChangeType.removed;
    return dif;
  }

  if (previous === undefined || current === undefined) { // necessary for typescript to know that previous and current are defined
    console.warn("One of the arrays is undefined for", recursionKey, '.  This should not happen.');
    return null;
  }



  for (let i = 0; i < current.length; i++) {
    const currentItem = current[i];
    const validItemIdKey = itemIdKey && itemIdKey in currentItem;
    const matchingItems = validItemIdKey ? previous.filter(item => item[itemIdKey] === currentItem[itemIdKey]) : null;
    if (matchingItems && validItemIdKey && matchingItems.length > 1) {
      console.warn('Multiple items found with the same', itemIdKey, ":", currentItem[itemIdKey], "in", recursionKey, "at index", i, ". Skipping check for differences.", currentItem);
      // since we can't evaluate if there is a difference, we will just skip this item
      continue;
    }

    const previousItem = validItemIdKey && matchingItems ? matchingItems[0] : previous[i];

    const id = validItemIdKey ? currentItem[itemIdKey] : i;
    if (id === undefined) {
      console.warn("id is undefined");
    }

    /// Check for new items
    if (previousItem === undefined) {
      if (logDifs) console.log(`${recursionKey} -- item added at index ${i}`, currentItem);
      if (typeof dif.current === 'object' && typeof dif.previous === 'object') { // necessary for typescript to know that dif.current and dif.previous are objects
        dif.current[id as keyof typeof current] = currentItem;
        dif.previous[id as keyof typeof previous] = ChangeType.didNotExist
      }
      continue;
    }

    /// We know there is a previousItem so check for changes with current and previous items
    const nestedDif = getDif(previousItem, currentItem, recursionDepth + 1, recursionKey + "." + id, options);
    if (nestedDif !== null) {
      if (typeof dif.current === 'object' && typeof dif.previous === 'object') { // necessary for typescript to know that dif.current and dif.previous are objects
        dif.current[id as keyof typeof current] = nestedDif.current;
        dif.previous[id as keyof typeof previous] = nestedDif.previous;
      }
    }

  }

  /// There could be items that were in the previous array that are not in the current array, so they would not have been checked above
  for (let i = 0; i < previous.length; i++) {
    const previousItem = previous[i];
    const matchingItems = itemIdKey ? current.filter(item => item[itemIdKey] === previousItem[itemIdKey]) : null;
    if (matchingItems && itemIdKey && matchingItems.length > 1) {
      console.warn('Multiple items found with the same', itemIdKey, ":", previousItem[itemIdKey], "in", recursionKey, "at index", i, ":", previousItem);
      // since we can't evaluate if there is a difference, we will just skip this item
      continue;
    }

    const currentItem = itemIdKey && matchingItems ? matchingItems[0] : current[i];
    const id = itemIdKey ? previousItem[itemIdKey] : i;
    if (currentItem === undefined) {
      if (logDifs) console.log(`${recursionKey} -- item removed at index ${i}`, previousItem);
      if (typeof dif.current === 'object' && typeof dif.previous === 'object') { // necessary for typescript to know that dif.current and dif.previous are objects
        dif.previous[id as keyof typeof previous] = previousItem;
        dif.current[id as keyof typeof current] = ChangeType.removed;
      }
    }
  }

  const hasDif = Object.keys(dif.current).length > 0 || Object.keys(dif.previous).length > 0;

  if (!hasDif) {
    return null;
  }

  return dif;
}
