export enum CartTempProcessorStatus {
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  PROCESSING_PAYMENT_SAVED = 'PROCESSING_PAYMENT_SAVED',
  PROCESSING_TAXES_SAVED = 'PROCESSING_TAXES_SAVED',
  PROCESSING_MEMBER_ADDRESSES_SAVED = 'PROCESSING_MEMBER_ADDRESSES_SAVED',
  PROCESSING_USEREVENT_UPDATES_APPLIED = 'PROCESSING_USEREVENT_UPDATES_APPLIED',
  PROCESSING_NOTIFICATIONS_SENT = 'PROCESSING_NOTIFICATIONS_SENT',
}
