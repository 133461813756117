import { AbstractControl, ValidatorFn } from '@angular/forms';

export class JfwPasswordValidator {
  static isValid(
    minLength: number = 10,
    requireUppercase: boolean = true,
    requireLowercase: boolean = true,
    requireNumber: boolean = false,
    requireSpecialChar: boolean = true
  ) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.value;

      // Check if the password length is at least 10 characters.
      if (password === null || password.length < minLength) {
        return { passwordLength: true };
      }

      // Check if the password contains at least one uppercase character.
      if (requireUppercase && !/[A-Z]/.test(password)) {
        return { uppercaseMissing: true };
      }

      // Check if the password contains at least one lowercase character.
      if (requireLowercase && !/[a-z]/.test(password)) {
        return { lowercaseMissing: true };
      }

      // Check if the password contains at least one numeric character
      if (requireNumber && !/[0-9]/.test(password)) {
        return { numberMissing: true };
      }

      // Check if the password contains at least one non-alphanumeric character.
      if (requireSpecialChar && !/[^a-zA-Z0-9]/.test(password)) {
        return { nonAlphanumericMissing: true };
      }

      return null;
    };
  }
}
