export function getLegacyHeight(height: string): string {
  try {
    let legacyHeight = height.replace('"', '').split("'"); // split on feet, remove double quote.
    if (legacyHeight.length !== 2) {
      // should have two pieces left over.
      throw new Error('Error parsing height to legacy height. Height passed was: ' + height);
    }
    let legacyHeightFeet = legacyHeight[0];
    let legacyHeightInches = legacyHeight[1];
    if (legacyHeightInches.length === 1) {
      // 0 should be there. don't need to account for blanks.
      legacyHeightInches = '0' + legacyHeightInches; // pad inches with a 0.
    }
    return legacyHeightFeet + "'" + legacyHeightInches + '"';
  } catch (e) {
    console.log(e);
    return '';
  }
}
