import { EcomStyle, StyleGroup } from 'common-types';

export function canBuyStyle(style: EcomStyle): boolean {
  const { styleGroup, companionStylePrice } = style;
  let hasBuyOption: boolean = false;
  if (styleGroup === StyleGroup.Purchase && style.price) {
    hasBuyOption = true;
  } else if (
    styleGroup === StyleGroup.Rental &&
    companionStylePrice !== null &&
    companionStylePrice !== undefined
  ) {
    hasBuyOption = true;
  }

  return hasBuyOption;
  // if (getStyleBuyPrice(style) !== undefined && hasBuyOption) {
  //   return true;
  // }

  // return false;
}
