import { Event } from 'common-types';
import { getUnlockDate } from './get-unlock-date';
import { convertDateToUnixTimestamp } from '../../date';

export function isEventDateLocked(event: Event): boolean {
  const unlockDate = getUnlockDate(event);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const currentDateToUnix = convertDateToUnixTimestamp(
    currentDate.toDateString()
  );
  return unlockDate < currentDateToUnix;
}
