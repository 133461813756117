import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-string-array-form',
  templateUrl: './string-array-form.component.html',
  styleUrls: ['./string-array-form.component.scss'],
})
export class StringArrayFormComponent implements OnInit, OnDestroy {
  @ViewChild('formDirective') formDirective!: FormGroupDirective;
  @Input() title!: string;
  @Input() inputLabel!: string;
  @Input() values!: string[];
  @Input() reset$!: Subject<void>;
  @Output() valuesEvent = new EventEmitter<string[]>();

  subscription = new Subscription();

  form = new FormGroup({
    input: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor() {}

  ngOnInit(): void {
    this.subscription.add(
      this.reset$.subscribe({ next: () => this.onReset() })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onAdd(): void {
    const value = this.form.controls.input.value;
    if (value) {
      this.values.push(value);
      this.valuesEvent.emit(this.values);
      this.formDirective.resetForm();
      this.form.reset();
    }
  }

  public onDelete(index: number): void {
    this.values.splice(index, 1);
  }

  public onReset(): void {
    this.values = [];
    this.valuesEvent.emit(this.values);
    this.formDirective.resetForm();
    this.form.reset();
  }
}
