import { Db2ResultArray, Store as DealerAccountInterface } from 'common-types';
import { DealerAccount } from './dealer-account';

export class DealerAccountFactory {
  static loadDealerAccountFromFirebase(jsonEventObject: string): DealerAccount {
    let dealerAccount = new DealerAccount();
    dealerAccount.deserialize(
      JSON.parse(jsonEventObject) as Partial<DealerAccountInterface>
    );
    return dealerAccount;
  }

  static loadEventFromDb2(dealerAccountRecord: Db2ResultArray): DealerAccount {
    let dealerAccount = new DealerAccount();

    dealerAccount.lat = parseFloat(dealerAccountRecord['LAT']);
    dealerAccount.lon = parseFloat(dealerAccountRecord['LON']);
    dealerAccount.address1 = dealerAccountRecord['ADR1']?.trim();
    dealerAccount.address2 = dealerAccountRecord['ADR2']?.trim();
    dealerAccount.city = dealerAccountRecord['CITY']?.trim();
    dealerAccount.phone = dealerAccountRecord['PHON']?.trim();
    dealerAccount.aname = dealerAccountRecord['ANAME']?.trim();
    dealerAccount.email = dealerAccountRecord['EMAIL']?.trim();
    dealerAccount.state = dealerAccountRecord['STATE']?.trim();
    dealerAccount.actnum = dealerAccountRecord['ACTNUM']?.trim();
    dealerAccount.isActive =
      dealerAccountRecord['ACTSTS'] === 'A' ? true : false;
    dealerAccount.strtyp = dealerAccountRecord['STRTYP']?.trim();
    dealerAccount.zipCode = dealerAccountRecord['ZIPCOD']?.trim();
    dealerAccount.website = dealerAccountRecord['WEBSITE']?.trim();
    dealerAccount.storeDescription = dealerAccountRecord['STOREDESC']?.trim();
    dealerAccount.warehouse = dealerAccountRecord['WAREHOUSE']?.trim();
    dealerAccount.landingPageUrl =
      dealerAccountRecord['LANDINGPAGEURL']?.trim();
    dealerAccount.sun_type = dealerAccountRecord['SUN_TYPE']?.trim();
    dealerAccount.sun_from = dealerAccountRecord['SUN_FROM']?.trim();
    dealerAccount.sun_to = dealerAccountRecord['SUN_TO']?.trim();
    dealerAccount.mon_type = dealerAccountRecord['MON_TYPE']?.trim();
    dealerAccount.mon_from = dealerAccountRecord['MON_FROM']?.trim();
    dealerAccount.mon_to = dealerAccountRecord['MON_TO']?.trim();
    dealerAccount.tue_type = dealerAccountRecord['TUE_TYPE']?.trim();
    dealerAccount.tue_from = dealerAccountRecord['TUE_FROM']?.trim();
    dealerAccount.tue_to = dealerAccountRecord['TUE_TO']?.trim();
    dealerAccount.wed_type = dealerAccountRecord['WED_TYPE']?.trim();
    dealerAccount.wed_from = dealerAccountRecord['WED_FROM']?.trim();
    dealerAccount.wed_to = dealerAccountRecord['WED_TO']?.trim();
    dealerAccount.thu_type = dealerAccountRecord['THU_TYPE']?.trim();
    dealerAccount.thu_from = dealerAccountRecord['THU_FROM']?.trim();
    dealerAccount.thu_to = dealerAccountRecord['THU_TO']?.trim();
    dealerAccount.fri_type = dealerAccountRecord['FRI_TYPE']?.trim();
    dealerAccount.fri_from = dealerAccountRecord['FRI_FROM']?.trim();
    dealerAccount.fri_to = dealerAccountRecord['FRI_TO']?.trim();
    dealerAccount.sat_type = dealerAccountRecord['SAT_TYPE']?.trim();
    dealerAccount.sat_from = dealerAccountRecord['SAT_FROM']?.trim();
    dealerAccount.sat_to = dealerAccountRecord['SAT_TO']?.trim();
    dealerAccount.contacts = JSON.parse(dealerAccountRecord['CONTACTS']);

    return dealerAccount;
  }
}
