import { z } from "zod";
import { Site } from "../site";

export const AnonTokenRequestDataSchema = z.object({
  refreshKey: z.string(),
  userId: z.string(),
  expiresInMinutes: z.number(),
  site: z.nativeEnum(Site),
});

export type AnonTokenRequestData = z.infer<typeof AnonTokenRequestDataSchema>;
