import { Event, EventAdmin } from "common-types";
import { getAdminEmails } from "./get-admin-emails";

/**
 * Returns a new event object with the admin added.  Does not mutate the original event.  Updates both the admins array and the adminEmails array.
 * @param event the event to add the admin to
 * @param admin the admin to add
 * @returns the event with the admin added (returns a new object; does not mutate the original event)
 */
export function addAdminToEvent(event: Event, admin: EventAdmin): Event {
  const newAdmins = [...event.admins, admin];
  const newAdminEmails = getAdminEmails(newAdmins);

  const newEvent: Event = {
    ...event,
    admins: newAdmins,
    adminEmails: newAdminEmails,
  }

  return newEvent;

}
