import { Event } from 'common-types';

export function isUserOrganizerOrAdminForEvent(
  event: Event | undefined
): boolean {
  let lsUser = JSON.parse(localStorage.getItem('user')!);
  if (event === undefined || lsUser === undefined || lsUser === null) {
    return false;
  }

  let isAdmin = false;

  if (event.createdByUserId === lsUser.uid) {
    return true;
  }

  if (
    event !== undefined &&
    event.admins !== undefined &&
    event.admins.length > 0
  ) {
    event.admins.forEach((admin) => {
      if (admin.email.toLowerCase() === lsUser?.email?.toLowerCase()) {
        isAdmin = true;
      }
    });
  }

  return isAdmin;
}
