import {
  CreditMember,
  CreditMemberLine,
  CreditReturnResponse,
  TransactionLogDetail,
} from 'common-types';

export function convertCreditResponseToTransactionLogDetail(
  creditResponse: CreditReturnResponse
): Array<TransactionLogDetail> {
  let details: Array<TransactionLogDetail> = [];
  let cartId: string = creditResponse.cartId;
  let eventId: string = '';
  let submittedDate: number = creditResponse.createDate;
  if (
    creditResponse.avalaraTaxReturnResponses !== undefined &&
    creditResponse.avalaraTaxReturnResponses.length > 0
  ) {
    creditResponse.avalaraTaxReturnResponses.forEach((response: any) => {
      let uniqueTransactionCode: string = response.avalaraResponse.code;
      let customerCode: string = response.avalaraResponse.customerCode;
      let memberId: string = '';
      try {
        let referenceCode = JSON.parse(response.avalaraResponse.referenceCode);
        eventId = referenceCode.eventId;
        memberId = referenceCode.eventMemberId;
      } catch (e) {
        console.log(
          'invalid json in referenceCode: ',
          response.avalaraResponse.referenceCode
        );
      }
      if (
        response.avalaraResponse.lines !== undefined &&
        response.avalaraResponse.lines.length > 0
      ) {
        response.avalaraResponse.lines.forEach((line: any) => {
          let [source, reason] = extractCreditMemberInfoFromLineNumber(
            creditResponse,
            line.lineNumber
          );
          let detail: TransactionLogDetail = {
            memberId: memberId,
            cartId: cartId,
            eventId: eventId,
            avalaraId: line.id,
            lineNumber: line.lineNumber,
            amount: line.lineAmount,
            quantity: line.quantity,
            tax: line.tax,
            taxCode: line.taxCode,
            submittedDate: submittedDate,
            source: source === 'PROMO' ? 'PROMO' : 'CREDIT',
            reason: reason,
            styleCode: line.itemCode,
            itemCode: line.itemCode,
            uniqueTransactionCode: uniqueTransactionCode,
            customerCode: customerCode,
          };
          details.push(detail);
        });
      }
    });
  }
  return details;
}

function extractCreditMemberInfoFromLineNumber(
  creditResponse: CreditReturnResponse,
  lineNumber: string
): [source: string, reason: string] {
  let info: [string, string] = ['CREDIT', ''];
  if (
    creditResponse.creditMembers !== undefined &&
    creditResponse.creditMembers.length > 0 &&
    creditResponse.creditMembers !== 'ALL'
  ) {
    creditResponse.creditMembers.forEach((member: CreditMember) => {
      if (member.creditMemberLines !== 'ALL') {
        let i = member.creditMemberLines.findIndex((line: CreditMemberLine) => {
          return lineNumber === line.lineNumber.toString();
        });
        if (i >= 0) {
          info = [
            member.creditMemberLines[i].creditReason?.reasonCode === 'Promo'
              ? 'PROMO'
              : 'CREDIT',
            member.creditMemberLines[i].creditReason?.reasonCode ?? '',
          ];
        }
      }
    });
  }
  return info;
}
