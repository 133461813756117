import { AccountMaster, DealerAccountElastic } from "common-types";

export function convertAccountMasterToStore(account: AccountMaster): DealerAccountElastic {

    let store: DealerAccountElastic = {
      0: '',
      actnum: account.accountNumber ?? '',
      aname: account.accountName ?? '',
      email: account.email ?? '',
      phone: account.phone ?? '',
      properName: account.properName ?? '',

      location: {
        lat: account.lat ?? 0,
        lon: account.lon ?? 0,
      },
      address1: account.address?.streetAddress1 ?? '',
      address2: account.address?.streetAddress2 ?? '',
      city: account.address?.city ?? '',
      state: account.address?.state ?? '',
      isActive: account.accountStatus === 'A' ? true : false,
      strtyp: account.storeType ?? '',
      zipCode: account.address?.zip ?? '',
      warehouse: account.serviceCenter ?? '',
      landingPageUrl: account.landingPageUrl ?? '',
      website: account.accountWebSite ?? '',
      storeDescription: account.storeDescription ?? '',

      mon_from: account.mon_from ?? '',
      mon_type: account.mon_type ?? '' ,
      mon_to: account.mon_to ?? '' ,

      tue_from: account.tue_from ?? '' ,
      tue_type: account.tue_type ?? '' ,
      tue_to: account.tue_to ?? '',

      wed_from: account.wed_from  ?? '',
      wed_type: account.wed_type ?? '' ,
      wed_to: account.wed_to  ?? '',
      thu_from: account.thu_from ?? '' ,
      thu_type: account.thu_type ?? '' ,
      thu_to: account.thu_to  ?? '',

      fri_from: account.fri_from  ?? '',
      fri_type: account.fri_type  ?? '',
      fri_to: account.fri_to  ?? '',

      sat_from: account.sat_from  ?? '',
      sat_type: account.sat_type  ?? '',
      sat_to: account.sat_to  ?? '',

      sun_from: account.sat_from  ?? '',
      sun_type: account.sun_type  ?? '',
      sun_to: account.sun_to ?? '',
      store_services: account.offerings ?? []
    };
    return store;
}
