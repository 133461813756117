import {
  Cart,
  CartProcessorStatus,
  MemberCart,
  Order,
  OrderDetail,
  Warehouses,
} from 'common-types';
import {
  getLegacyEventRole,
  getLegacyOrderType,
  getLegacyPickUpDate,
  getLegacyProductionWeek,
  getLegacyUseDate,
} from '../../event/legacy/utilities';
import { getLegacyPantRise } from '../../measurements/legacy';
import { convertLookToOrderDetails } from './convert-look-to-order-details';
import { getSizeFromMeasurements } from './get-size-from-measurements';
import { orderHasValidSizes } from './order-has-valid-sizes';

export function convertCartToOrders(cart: Cart): Array<Order> {
  let orders: Array<Order> = [];
  if (
    // return out of cart processing if its been done or is in process.
    cart.cartProcessorStatus === CartProcessorStatus.COMPLETE ||
    cart.cartProcessorStatus === CartProcessorStatus.PROCESSING
  ) {
    console.log(
      'in convertCartToOrders function. Invalid process status to process orders. Cart processor status is:',
      cart.cartProcessorStatus,
    );
    return orders;
  }
  cart.members?.forEach((memberCart: MemberCart) => {
    let orderDetails: Array<OrderDetail> = [];
    if (memberCart.member.memberLook !== undefined) {
      orderDetails = convertLookToOrderDetails(
        memberCart.member.memberLook,
        memberCart.member.measurements,
      );
    }

    let coatSize = '';
    let coatLength = '';
    let shoeSize = '';
    let shoeWidth = '';
    let pantRise = '';
    if (
      memberCart.member.measurements &&
      memberCart.member.measurements.derivedFrom !== 'skip'
    ) {
      [coatSize, coatLength] = getSizeFromMeasurements(
        'CT',
        memberCart.member.measurements,
      );
      [shoeSize, shoeWidth] = getSizeFromMeasurements(
        'SO',
        memberCart.member.measurements,
      );
      try {
        pantRise = getLegacyPantRise(memberCart.member.measurements);
      } catch (e) {
        console.log(e);
      }
    }
    let warehouseAccount: string | undefined;
    const whse = new Warehouses();
    try {
      warehouseAccount = whse.getAeAccountFromState(
        memberCart.member.shippingAddress?.state,
      );
    } catch (e) {
      console.log(e);
    }

    let order: Order = {
      transactionType: 'NEWORDER',
      storeTicketNumber: '',
      accountNumber: warehouseAccount ?? 'AE370', // default to trenton if state/account number fails?
      //accountNumber: 'TA101', // May need to follow up on this.
      productionWeek: getLegacyProductionWeek(
        cart.eventDate ?? new Date().getTime(),
      ), // Need to ensure event date is set.
      orderNumber: 0, // EZT will handle this on creation. May need to specify when updating
      useDate: getLegacyUseDate(cart.eventDate ?? new Date().getTime()),
      shipToAccount: '',
      shipToName: memberCart.member.shippingAddress?.name ?? '', // require name?
      shipToAddress1: memberCart.member.shippingAddress?.streetAddress1 ?? '',
      shipToAddress2: memberCart.member.shippingAddress?.streetAddress2 ?? '',
      shipToCity: memberCart.member.shippingAddress?.city ?? '',
      shipToState: memberCart.member.shippingAddress?.state ?? '', // may need to ensure 2 character code.
      shipToZipcode: memberCart.member.shippingAddress?.zip ?? '',
      weeksOut: 1,
      orderType: getLegacyOrderType(
        cart.eventType ?? 'Wedding',
        memberCart.member.memberRole,
      ),
      callerFirstName: 'FIREBASE',
      callerLastName: 'USER',
      orderText: '',
      internalOrderNumber: 0, // Need to check on update.
      memberId: memberCart.member.id ?? '',
      pickupDate: getLegacyPickUpDate(cart.eventDate ?? new Date().getTime()), // need event date required.
      hotShot: 'N',
      nationalEventId: '',
      eventDescription: cart.eventName ?? '',
      potentialOrder: '',
      commissionAccountNumber: cart.commissionAccount ?? '',
      commissionType: cart.commissionType,
      webEventId: 0,
      webMemberId: 0,
      warehouse: '',
      changeUser: 'FIREBASE',
      eventId: cart.eventId ?? '', // make eventId required.
      transferredByDealer: cart.transferredByDealer ?? '',
      cartId: cart.id,
      orderStatus: 'pending',
      documentId: '',
      errors: [],
      customer: {
        role: getLegacyEventRole(memberCart.member.memberRole),
        firstName: memberCart.member.firstName ?? '',
        lastName: memberCart.member.lastName ?? '',
        address1: memberCart.member.shippingAddress?.streetAddress1
          ? memberCart.member.shippingAddress?.streetAddress1
          : '',
        address2: memberCart.member.shippingAddress?.streetAddress2
          ? memberCart.member.shippingAddress?.streetAddress2
          : '',
        city: memberCart.member.shippingAddress?.city
          ? memberCart.member.shippingAddress?.city
          : '',
        state: memberCart.member.shippingAddress?.state
          ? memberCart.member.shippingAddress?.state
          : '', // may need 2 character code.
        zipCode: memberCart.member.shippingAddress?.zip
          ? memberCart.member.shippingAddress?.zip
          : '',
        cellPhone: memberCart.member.phone ?? '',
        homePhone: memberCart.member.phone ?? '',
        age: 0,
        sex: '',
        email: memberCart.member.email ?? '',
        measurements:
          memberCart.member.measurements === undefined ||
          memberCart.member.measurements.derivedFrom === 'skip'
            ? { derivedFrom: 'skip' }
            : {
                chest: memberCart.member.measurements?.chest,
                overArm: memberCart.member.measurements?.overarm,
                height: memberCart.member.measurements?.height ?? '',
                weight: memberCart.member.measurements?.weight,
                sleeve: memberCart.member.measurements?.sleeve,
                neck: memberCart.member.measurements?.neck,
                inseam: memberCart.member.measurements?.inseam,
                outseam: memberCart.member.measurements?.outseam,
                shoeSize: parseInt(shoeSize),
                shoeWidth: shoeWidth,
                stomach: memberCart.member.measurements?.stomach,
                waist: memberCart.member.measurements?.waist,
                coatSize: coatSize + coatLength,
                vestSize: memberCart.member.measurements?.vestSize,
                pantRise: pantRise,
                hip: memberCart.member.measurements?.hip,
                derivedFrom: memberCart.member.measurements?.derivedFrom ?? '',
              },
      },
      details: orderDetails,
      paymentChargeResponse: cart.paymentChargeResponse,
      paymentRefResponse: cart.paymentRefResponse,
    };
    if (!orderHasValidSizes(order)) {
      if (!orderHasValidSizes(order, false)) {
        order.orderStatus = 'missingMeasurements';
      } else {
        order.orderStatus = 'missingCoatSize';
      }
    }

    /// - Add damage waiver and shipping avalara line ids to order.
    order.damageWaiverAvalaraLineId = memberCart.damageWaiverAvalaraLineId;
    order.shippingAvalaraLineId = memberCart.shippingAvalaraLineId;

    orders.push(order);
  });

  console.log('after order conversion from cart:', orders);

  return orders;
}
