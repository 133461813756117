import { BaseUser } from 'common-types';
import { formatPhoneWithoutCountryCode } from './format-phone-without-country-code';
import { getFirstNameFromName } from './get-first-name-from-name';
import { getLastNameFromName } from './get-last-name-from-name';
import { User } from '@angular/fire/auth';

// export function firebaseUserToBaseUser(firebaseUser: firebase.User): BaseUser {

/**
 * Converts a Firebase User to a Base User
 * @param firebaseUser The User returned from Firebase Auth
 * @param phone Optional: phone number to use instead of the one in Firebase Auth (necessary because we are no longer storing phone number in Firebase Auth (only in Firestore docs))
 * @returns
 */
export function firebaseUserToBaseUser(
  firebaseUser: User,
  phone?: string
): BaseUser {
  return {
    firstName: getFirstNameFromName(firebaseUser.displayName ?? ''),
    lastName: getLastNameFromName(firebaseUser.displayName ?? ''),
    displayName: firebaseUser.displayName ?? '',
    email: firebaseUser.email ?? '',
    phone:
      phone ?? formatPhoneWithoutCountryCode(firebaseUser.phoneNumber ?? ''), // Necessary because we are no longer storing phone number in Firebase Auth (only in Firestore docs)
    userId: firebaseUser.uid,
  } as BaseUser;
}
