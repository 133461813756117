import { MatCheckbox } from '@angular/material/checkbox';
import { Colors } from 'common-types';

// Sets the color chip colors on filter component.
export function setColorClass(
  colorFamily: string,
  checkbox?: MatCheckbox
): string {
  const colors = new Colors();
  const background = checkbox?._elementRef.nativeElement.getElementsByClassName(
    'mdc-checkbox__background'
  );
  const checkMark = checkbox?._elementRef.nativeElement.getElementsByClassName(
    'mdc-checkbox__checkmark'
  );

  const backgroundItem = background?.item(0);
  const checkMarkItem = checkMark?.item(0);
  if (!backgroundItem || !checkMarkItem) {
    return '';
  }

  if (colorFamily === 'White/Ivory') {
    backgroundItem.setAttribute(
      'style',
      `
          background-color: ${colors.getHexCodeByFamily(colorFamily)};
          border: solid 1px #DBDCDD;
        `
    );
    checkMarkItem.setAttribute('style', `color: black`);
  } else {
    backgroundItem.setAttribute(
      'style',
      `
          background-color: ${colors.getHexCodeByFamily(colorFamily)};
          border: none;
        `
    );
  }
  return ``;
}
