import { Event } from "common-types";
import { getAdminEmails } from "./get-admin-emails";

/**
 * Returns a new event object with the admin removed.  Does not mutate the original event.  Updates both the admins array and the adminEmails array.
 * @param event the event to remove the admin from
 * @param adminId the id of the admin to remove
 * @returns the event with the admin removed (returns a new object; does not mutate the original event)
 */
export function deleteAdminFromEvent(event: Event, adminId: string): Event {
  const newAdmins = event.admins.filter(admin => admin.id !== adminId);
  const newAdminEmails = getAdminEmails(newAdmins);

  const newEvent: Event = {
    ...event,
    admins: newAdmins,
    adminEmails: newAdminEmails,
  }

  return newEvent;

}
