export enum CommunicationQueueV1Message {
  POST_QUEUE_DOCUMENT = 'Unable to form queue object',
  ADD_DOCUMENT_ERROR = 'Unable to create communication queue document',
  GET_PROCESS_EVENTS = 'Unable to process events',
  GET_COMMUNICATION_QUEUE = 'Error while querying for communication',

  POST_EMAIL_TO_SENDGRID = 'Failed to send email to sendgrid',
  POST_TWILIO_MESSAGE = 'Failed to create twilio message',
  UPDATE_RETRY_COUNT = 'Failed to update retryCount in communication queue',
}
