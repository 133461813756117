import { Component, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { defer, map, of, startWith } from 'rxjs';
import { JfwEmailValidator } from '../validators/jfwEmail.validator';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent {
  constructor(private formBuilder: FormBuilder) {}

  form = this.formBuilder.nonNullable.group({
    email: ['', JfwEmailValidator.isValid()],
  });

  @Input() required = true;
  @Input() label = 'Email';
  @Input() hideRequiredMarker = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Input()
  set initialValue(value: string) {
    this.form.patchValue(
      {
        email: value,
      },
      {
        emitEvent: false,
      }
    );
  }

  @Output() formReady = of(this.form);

  @Output()
  valueChange = defer(() =>
    this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((formValue) => formValue.email)
    )
  );
}
