import { PlatformStyle } from 'common-types';
import { convertUnixTimestampToDate } from '../../../date';

export function isStyleActive(style: PlatformStyle): boolean {
  if (!style.activeDate) {
    return false;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const activeDate = convertUnixTimestampToDate(style.activeDate);
  activeDate.setHours(0, 0, 0, 0);

  if (activeDate > today) {
    return false;
  }

  if (!style.discontinuedDate) {
    return true;
  }

  const discontinuedDate = convertUnixTimestampToDate(style.discontinuedDate);
  discontinuedDate.setHours(0, 0, 0, 0);

  if (discontinuedDate <= today) {
    return false;
  }

  if (activeDate < discontinuedDate) {
    return true;
  }

  return false;
}
