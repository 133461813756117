// export enum CommissionTypeEnum {
//   Organic = 'ORGANIC',
//   CustomLink = 'CUSTOM_LINK',
//   Referral = 'REFERRAL',
// }

export const CommissionTypeEnum = {
  Organic: 'ORGANIC',
  CustomLink: 'CUSTOM_LINK',
  Referral: 'REFERRAL',
} as const;

export type CommissionTypeEnum = typeof CommissionTypeEnum[keyof typeof CommissionTypeEnum];
