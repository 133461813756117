export enum EventTaskV1Message {
  PROCESS_EVENTS = 'Failed to process events',
  PROCESS_EVENT = 'Unable to process notifications event',

  QUEUE_FEED_BACK_SMS = 'Failed to queue feedback sms',
  QUEUE_FEED_BACK_EMAIL = 'Failed to queue feedback email',

  QUEUE_TIME_TO_GET_MEASURED_EMAIL = 'Failed to queue time to get measured email',
  QUEUE_TIME_TO_GET_MEASURED_SMS = 'Failed to queue time to get measured sms',

  QUEUE_ORDER_SHIPPED_EMAIL = 'Failed to queue order shipped email',
  QUEUE_ORDER_SHIPPED_SMS = 'Failed to queue order shipped sms',

  QUEUE_REPLACEMENT_SHIPPED_EMAIL = 'Failed to queue replacement shipped email',
  QUEUE_REPLACEMENT_SHIPPED_SMS = 'Failed to queue replacement shipped sms',

  QUEUE_TIME_TO_RETURN_ORDER_EMAIL = 'Failed to queue time to return order email',
  QUEUE_TIME_TO_RETURN_ORDER_SMS = 'Failed to queue time to return order sms',
}
