import * as z from 'zod';

export const MeasurementsSchema = z.object({
  derivedFrom: z.string().optional(),
  digitalMeasurements: z.boolean().optional(),
  chest: z.number().optional(),
  overarm: z.number().optional(),
  waist: z.number().optional(),
  hip: z.number().optional(),
  stomach: z.number().optional(),
  neck: z.number().optional(),
  sleeve: z.number().optional(),
  insleeve: z.number().optional(),
  outseam: z.number().optional(),
  inseam: z.number().optional(),
  height: z.string().optional(),
  weight: z.number().optional(),
  coatSize: z.string().optional(),
  vestSize: z.string().optional(),
  shoeSize: z.string().optional(),
  submitted: z.boolean().optional(),
});

export type ZodMeasurements = z.infer<typeof MeasurementsSchema>;

export type Measurements = {
  derivedFrom?: string;
  digitalMeasurements?: boolean;
  chest?: number;
  overarm?: number;
  waist?: number;
  hip?: number;
  stomach?: number;
  neck?: number;
  sleeve?: number;
  insleeve?: number;
  outseam?: number;
  inseam?: number;
  height?: string;
  weight?: number;
  coatSize?: string;
  vestSize?: string;
  shoeSize?: string;
  submitted?: boolean;
};
