import * as z from 'zod';

export const TransferredBySchema = z.object({
  userId: z.string(),
  email: z.string(),
  name: z.string(),
  transferDate: z.number().optional(),
});

export type ZodTransferredBy = z.infer<typeof TransferredBySchema>;

export type TransferredBy = {
  userId: string;
  email: string;
  name: string;
  transferDate?: number;
};
