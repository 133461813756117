import { EcomStyle, Look } from 'common-types';

export function getStyleFromLook(look: Look, styleCode: string): EcomStyle {
  let returnedStyle: EcomStyle | undefined;
  look.styles.forEach((style) => {
    if (style.styleCode === styleCode) {
      returnedStyle = style;
    }
  });
  if (!returnedStyle) {
    throw new Error('No styles with style code ' + styleCode);
  }
  return returnedStyle;
}
