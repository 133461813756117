import { Event } from "common-types";

type NestedKeys<T> = T extends undefined ? never : {
  [K in keyof T]: T[K] extends object ? (T[K] extends any[] ? K : K | NestedKeys<T[K]>) : K
}[keyof T];

type MembersKeys = keyof Event['members'][0] // | NestedKeys<Event['members'][0]>
  | keyof Event['members'][0]['measurements'] // | NestedKeys<Event['members'][0]['measurements']>
  | keyof Event['members'][0]['memberLook'] // | NestedKeys<Event['members'][0]['memberLook']>
  // | keyof (keyof Event['members'][0]['memberLook'])['styles'][0] | NestedKeys<(keyof Event['members'][0]['memberLook'])['styles'][0]>
  | keyof Event['members'][0]['memberProgress'] // | NestedKeys<Event['members'][0]['memberProgress']>
  | keyof Event['members'][0]['orderDetails'] // | NestedKeys<Event['members'][0]['orderDetails']>
  | keyof Event['members'][0]['shippingAddress'] // | NestedKeys<Event['members'][0]['shippingAddress']>

type AdminsKeys = keyof Event['admins'][0] // | NestedKeys<Event['admins'][0]>

type LooksKeys = | keyof Event['looks'][0] //| NestedKeys<Event['looks'][0]>
  | keyof Event['looks'][0]['styles'][0] // | NestedKeys<Event['looks'][0]['styles'][0]>
  | keyof Event['looks'][0]['styles'][0]['mannequinImages'] // | NestedKeys<Event['looks'][0]['styles'][0]['mannequinImages']>


type EventKeys = keyof Event
  | MembersKeys
  | AdminsKeys
  | LooksKeys

type KeysToIgnore<T = typeof undefined> = T extends 'members' ? MembersKeys
  : T extends 'admins' ? AdminsKeys
  : T extends 'looks' ? LooksKeys
  : T extends 'event' ? EventKeys
  : T extends typeof undefined ? EventKeys
  : never

type KeysToInclude<T = typeof undefined> = T extends 'members' ? MembersKeys
  : T extends 'admins' ? AdminsKeys
  : T extends 'looks' ? LooksKeys
  : T extends 'event' ? keyof Event
  : T extends typeof undefined ? keyof Event
  : never

// const aKeyFromEvent: NestedEventKeys = 'mannequinImage'



export type DifOptions<T extends 'looks' | 'members' | 'admins' | 'event' | typeof undefined = typeof undefined> = {
  ignoreMeta?: boolean, // does not check meta fields like lastUpdated, update_time, lastUpdatedBy
  logNoDif?: boolean, // logs when no differences are found
  logDifs?: boolean, // logs differences in objects and arrays
  logSimpleDifs?: boolean, // logs differences in simple values
  logChecks?: boolean, // logs the property being checked when a check is being made

  /**only checks these keys (if they exist) in the event.  If not provided, checks all keys.
   * Only top-level Event keys. */
  keysToInclude?: Array<KeysToInclude<T>>,
  /**ignores these keys (if they exist) in the event.  If not provided, ignores no keys.
     * These can be keys at any level in the Event object. */
  keysToIgnore?: Array<KeysToIgnore<T>>,

}

// export type DifOptionsForEvent = DifOptionsBase & {
//   keysToIgnore?: Array<KeysToIgnoreForEvent>
// }

// export type DifOptionsForMembers = DifOptionsBase & {
//   keysToIgnore?: Array<KeysToIgnoreForMembers>
// }

// export type DifOptionsForAdmins = DifOptionsBase & {
//   keysToIgnore?: Array<KeysToIgnoreForAdmins>
// }

// export type DifOptionsForLooks = DifOptionsBase & {
//   keysToIgnore?: Array<KeysToIgnoreForLooks>
// }

// export type DifOptions = DifOptionsForEvent | DifOptionsForMembers | DifOptionsForAdmins | DifOptionsForLooks


export const DEFAULT_DIF_OPTIONS: DifOptions = {
  ignoreMeta: false,
  logNoDif: false,
  logDifs: true,
  logSimpleDifs: false,
  logChecks: false,
}
