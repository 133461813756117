import { Event } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getDif } from "./utils/get-dif";

// type EventChange = {
//   previous: Partial<Event>,
//   current: Partial<Event>,
// }


export function getEventDif(previous: Event, current: Event, options: DifOptions = DEFAULT_DIF_OPTIONS) {
  console.log("********** BEGIN EVENT DIF **********");
  if (options?.keysToInclude) {
    console.warn("Only checking the following keys: ", options.keysToInclude);
  }
  if (options?.keysToIgnore) {
    console.warn("Ignoring the following keys: ", options.keysToIgnore);
  }
  console.time('getEventDif elapsed time');
  const dif = getDif(previous, current, 0, 'event', options);
  if (dif === null) {
    console.log("No differences found between the two events");
  } else {
    console.log("Differences found between the two events");
  }
  console.log("********** END EVENT DIF **********");
  console.timeEnd('getEventDif elapsed time');
  return dif;

}

// const event: Event = {} as Event;

// getEventDif(event, event, { keysToIgnore: ['name'], keysToInclude: ['looks'] });



