import { EventTimeline } from 'common-types';
import { getDaysDiffFormatted } from './get-days-diff-formatted';

export function getEventTimeline(eventDate: number): EventTimeline | null {
  try {
    const eventTimeline: EventTimeline = {
      getMeasuredBy: getDaysDiffFormatted(eventDate, -21),
      placeOrdersBy: getDaysDiffFormatted(eventDate, -21),
      orderShippedFrom: getDaysDiffFormatted(eventDate, -10),
      orderShippedTo: getDaysDiffFormatted(eventDate, -5),
      returnRentalsBy: getDaysDiffFormatted(eventDate, 1, true),
    };

    return eventTimeline;
  } catch {
    return null;
  }
}
