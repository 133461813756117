import { z, ZodSchema } from 'zod';
import { IdValidator, ShippingAddressValidator } from '../../../common';
import { LookValidator } from '../../../ensemble/validation/look-validator';
import { MeasurementsValidator } from '../../../measurements/validation/measurements-validator';
import { UserIdValidator } from '../../../user/validation/user-id-validator';

export const EventMemberValidator: ZodSchema = z.object({
  id: IdValidator,
  firstName: z.string().max(50),
  lastName: z.string().max(50),
  memberRole: z.string().max(30),
  memberLook: LookValidator.optional(),
  phone: z.string().max(12).optional(),
  email: z.string().email(),
  shippingAddress: ShippingAddressValidator,
  measurements: MeasurementsValidator.optional(),
  surrogateUser: z.boolean().optional(),
  acceptsTexts: z.boolean().optional(),
  userPaying: UserIdValidator.optional(),
});
