import { EventAdmin } from "common-types";

/**
 * Returns a list of emails from a list of admins.
 * Will filter out any admins without an email.
 * And will filter out duplicates.
 */
export function getAdminEmails(admins: EventAdmin[]): string[] {
  const emails = admins
    .filter((admin): admin is EventAdmin & { email: string } => !!admin.email) // only include admins with an email
    .map(admin => admin.email);

  // filter duplicates
  return [...new Set(emails)];
}
