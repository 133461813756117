
import { convertDateToUnixTimestamp } from '../../date/utilities';
import { getMaxDateForEvent } from "./get-max-event-date";


/**
 * Checks if an event can be created in the store based on the event date.
 * The event date must not be in the past.
 * The event date must not be more than {@link getMaxDateForEvent} in the future (from today's date).
 * @param eventDate The date of the event in unix timestamp
 */

export function canEventBeCreatedInStore(eventDate: number) {

  const minDate: Date = new Date();
  minDate.setHours(0, 0, 0, 0);
  // minDate.setDate(minDate.getDate());
  const minUnixTimestamp = convertDateToUnixTimestamp(minDate.toDateString());

  const isEventInPast = eventDate < minUnixTimestamp;
  if (isEventInPast) {
    console.log('Event is in the past', { eventDate, minUnixTimestamp });
    return false;
  }

  const maxDate = getMaxDateForEvent();
  const maxDateUnixTimestamp = convertDateToUnixTimestamp(maxDate.toDateString());
  const isEventTooFar = eventDate > maxDateUnixTimestamp;
  if (isEventTooFar) {
    console.log('Event is too far in the future', { eventDate, maxDateUnixTimestamp });
    return false;
  }


  return true;

}
