import { AddOnResponseType, TransactionLogDetail } from 'common-types';

export function convertAddonResponseToTransactionLogDetail(
  addonResponse: AddOnResponseType
): Array<TransactionLogDetail> {
  let details: Array<TransactionLogDetail> = [];
  let memberId: string = addonResponse.memberId;
  let eventId: string = addonResponse.eventId;
  let cartId: string = addonResponse.cartId;
  if (addonResponse.avalaraTaxResponse !== undefined) {
    let submittedDate: number = addonResponse.avalaraTaxResponse.submittedDate;
    if (
      addonResponse.avalaraTaxResponse.avalaraResponse !== undefined &&
      addonResponse.avalaraTaxResponse.avalaraResponse.lines !== undefined &&
      addonResponse.avalaraTaxResponse.avalaraResponse.lines.length > 0
    ) {
      addonResponse.avalaraTaxResponse.avalaraResponse.lines.forEach(
        (line: any) => {
          let detail: TransactionLogDetail = {
            memberId: memberId,
            eventId: eventId,
            cartId: cartId,
            source: 'ADDON',
            avalaraId: line.id,
            lineNumber: line.lineNumber,
            itemCode: line.description,
            amount: line.lineAmount,
            tax: line.tax,
            taxCode: line.taxCode,
            quantity: line.quantity,
            submittedDate: submittedDate,
            styleCode: line.itemCode,
            customerCode:
              addonResponse.avalaraTaxResponse.avalaraResponse.customerCode,
            uniqueTransactionCode:
              addonResponse.avalaraTaxResponse.avalaraResponse.code,
          };
          details.push(detail);
          console.log(detail);
        }
      );
    }
  }
  return details;
}
