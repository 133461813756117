import { DealerAccount, Store } from 'common-types';

export function dealerAccountToStore(dealerAccount: DealerAccount): Store {
  let store = {} as Store;
  store.distance = dealerAccount.distance;
  store.lat = dealerAccount.lat;
  store.lon = dealerAccount.lon;
  store.adr1 = dealerAccount.address1;
  store.adr2 = dealerAccount.address2;
  store.city = dealerAccount.city;
  store.phon = dealerAccount.phone;
  store.aname = dealerAccount.aname;
  store.email = dealerAccount.email;
  store.state = dealerAccount.state;
  store.actnum = dealerAccount.actnum;
  store.actsts = dealerAccount.isActive === true ? 'A' : 'D';
  store.strtyp = dealerAccount.strtyp;
  store.zipcod = dealerAccount.zipCode;
  store.website = dealerAccount.website;
  store.storedesc = dealerAccount.storeDescription;
  store.store_services = dealerAccount.store_services;
  store.warehouse = dealerAccount.warehouse;
  store.landingpageurl = dealerAccount.landingPageUrl;
  store.properName = dealerAccount.properName;
  store.sun_type = dealerAccount.sun_type;
  store.sun_from = dealerAccount.sun_from;
  store.sun_to = dealerAccount.sun_to;

  store.mon_type = dealerAccount.mon_type;
  store.mon_from = dealerAccount.mon_from;
  store.mon_to = dealerAccount.mon_to;

  store.tue_type = dealerAccount.tue_type;
  store.tue_from = dealerAccount.tue_from;
  store.tue_to = dealerAccount.tue_to;

  store.wed_type = dealerAccount.wed_type;
  store.wed_from = dealerAccount.wed_from;
  store.wed_to = dealerAccount.wed_to;

  store.thu_type = dealerAccount.thu_type;
  store.thu_from = dealerAccount.thu_from;
  store.thu_to = dealerAccount.thu_to;

  store.fri_type = dealerAccount.fri_type;
  store.fri_from = dealerAccount.fri_from;
  store.fri_to = dealerAccount.fri_to;

  store.sat_type = dealerAccount.sat_type;
  store.sat_from = dealerAccount.sat_from;
  store.sat_to = dealerAccount.sat_to;

  return store;
}
