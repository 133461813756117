import { MannequinAccessoryImageType } from './mannequin-accessory-image-type.enum';
import { MannequinImageType } from './mannequin-image-type.enum';

import * as z from 'zod';

export const ImageSchema = z.object({
  src: z.string(),
  alt: z.string(),
  mannequinImage: z.union([z.nativeEnum(MannequinImageType), z.nativeEnum(MannequinAccessoryImageType)]).optional(),
});

export type ZodImage = z.infer<typeof ImageSchema>;

export type Image = {
  src: string;
  alt: string;
  mannequinImage?: MannequinImageType | MannequinAccessoryImageType;
};
