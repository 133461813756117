import { DealerUser } from '../../../dealer/dealer-user.type';


// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export const EventNotFoundResponse = "Event not found" as const;
export type EventNotFoundResponseType = typeof EventNotFoundResponse;
export const InvalidUserOrEventResponse = "invalid user/event" as const;
export type InvalidUserOrEventResponseType = typeof InvalidUserOrEventResponse;
export const InvalidDataResponse = "Invalid event data" as const;
export type InvalidDataResponseType = typeof InvalidDataResponse;
export const MissingMemberIdsResponse = "Missing memberIds" as const;
export type MissingMemberIdsResponseType = typeof MissingMemberIdsResponse;
export type EmptyErrorResponse = void;
export type NonSpecificErrorResponse = any;


// ********************************************************************************************************************
// *                                             CREATE                                                               *
// ********************************************************************************************************************

export type CreateDealerUserSuccessResponse = DealerUser;
export const EmailAlreadyInUse = "Email is already in use." as const;
export type EmailAlreadyInUseType = typeof EmailAlreadyInUse;
export type CreateDealerUserResponse = CreateDealerUserSuccessResponse | EmailAlreadyInUseType | EmptyErrorResponse;




// ********************************************************************************************************************
// *                                             READ                                                                 *
// ********************************************************************************************************************

export type GetDealerIdsByUserIdSuccessResponse = { dealerIds: string[] };
export type GetDealerIdsByUserIdResponse = GetDealerIdsByUserIdSuccessResponse | EmptyErrorResponse;

export type GetDealerUserByUserIdAndEmailSuccessResponse = DealerUser;
export type GetDealerUserByUserIdAndEmailResponse = GetDealerUserByUserIdAndEmailSuccessResponse | EmptyErrorResponse | NonSpecificErrorResponse;

export type GetUsersByDealerIdSuccessResponse = DealerUser[];
export type GetUsersByDealerIdResponse = GetUsersByDealerIdSuccessResponse // returns an empty array if no users are found



// ********************************************************************************************************************
// *                                             UPDATE                                                               *
// ********************************************************************************************************************

export type DisableDealerUserSuccessResponse = void;
export type DisableDealerUserResponse = DisableDealerUserSuccessResponse | EmptyErrorResponse;

export type EnableDealerUserSuccessResponse = void;
export type EnableDealerUserResponse = EnableDealerUserSuccessResponse | EmptyErrorResponse;

export type UpdateDealerUserRoleSuccessResponse = void;
export type UpdateDealerUserRoleResponse = UpdateDealerUserRoleSuccessResponse | EmptyErrorResponse;





// ********************************************************************************************************************
// *                                             DELETE                                                               *
// ********************************************************************************************************************

export type DeleteDealerUserSuccessResponse = void;
export type DeleteDealerUserResponse = DeleteDealerUserSuccessResponse | EmptyErrorResponse;
