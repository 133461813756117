export const StaticHolidays: Array<Date> = [
  new Date(2022, 10, 25),
  new Date(2022, 11, 25),
  new Date(2023, 0, 1),
  new Date(2023, 4, 29),
  new Date(2023, 6, 4),
  new Date(2023, 8, 4),
  new Date(2023, 10, 23),
  new Date(2023, 11, 25),
  new Date(2024, 0, 1),
  new Date(2024, 4, 27),
  new Date(2024, 6, 4),
  new Date(2024, 8, 2),
  new Date(2024, 10, 28),
  new Date(2024, 11, 25),
  new Date(2025, 0, 1),
  new Date(2025, 4, 26),
  new Date(2025, 6, 4),
  new Date(2025, 8, 1),
  new Date(2025, 10, 27),
  new Date(2025, 11, 25),
  new Date(2026, 0, 1),
  new Date(2026, 4, 25),
  new Date(2026, 6, 4),
  new Date(2026, 8, 7),
  new Date(2026, 10, 26),
  new Date(2026, 11, 25),
  new Date(2027, 0, 1),
  new Date(2027, 4, 31),
  new Date(2027, 6, 4),
  new Date(2027, 8, 6),
  new Date(2027, 10, 25),
  new Date(2027, 11, 25),
];
