import {
  CanBeValidated,
  EventAdmin,
  Event as EventFromCommonTypes,
  EventMember,
  EventType,
  InStoreInfo,
  Look,
  Serializable,
} from 'common-types';
import { ZodError, ZodSchema } from 'zod';
import { EventValidator } from './validation/event-validator';

export class Event
  implements Partial<EventFromCommonTypes>, CanBeValidated, Serializable<Event>
{
  id?: string | undefined;
  userId?: string | undefined;
  createDate?: number | undefined;
  lastUpdated?: string | undefined;
  isOrganizer?: boolean | undefined;
  isSingleUser?: boolean | undefined;
  visible?: boolean | undefined;
  eventType?: EventType | undefined;
  eventName?: string | undefined;
  eventDate?: number | undefined;
  looks?: Look[] | undefined;
  members?: EventMember[] | undefined;
  admins?: EventAdmin[] | undefined;
  inStoreInfo?: InStoreInfo | undefined;
  saved?: boolean | undefined;

  toObject() {
    return {
      id: this.id,
      userId: this.userId,
      createDate: this.createDate,
      lastUpdated: this.lastUpdated,
      isOrganizer: this.isOrganizer,
      isSingleUser: this.isSingleUser,
      visible: this.visible,
      eventType: this.eventType,
      eventName: this.eventName,
      eventDate: this.eventDate,
      looks: this.looks,
      members: this.members,
      admins: this.admins,
      inStoreInfo: this.inStoreInfo,
      saved: this.saved,
    };
  }

  serialize(): string {
    return JSON.stringify(this.toObject());
  }

  deserialize(input: Partial<EventFromCommonTypes>): Event {
    this.id = input.id;
    this.userId = input.createdByUserId;
    this.createDate = input.createDate;
    this.lastUpdated = input.lastUpdated;
    this.isOrganizer = input.isOrganizer;
    this.isSingleUser = input.isSingleUser;
    this.visible = input.visible;
    this.eventType = input.eventType;
    this.eventName = input.eventName;
    this.eventDate = input.eventDate;
    this.looks = input.looks;
    this.members = input.members;
    this.admins = input.admins;
    this.inStoreInfo = input.inStoreInfo;
    this.saved = input.saved;
    return this;
  }

  isValid(validationSchema?: ZodSchema): boolean {
    if (validationSchema === undefined) {
      validationSchema = EventValidator;
    }

    try {
      validationSchema.parse(this);
      return true;
    } catch (err) {
      if (err instanceof ZodError) {
        return false;
      } else {
        throw err;
      }
    }
  }

  getErrors(validationSchema?: ZodSchema): ZodError<any> | undefined {
    if (validationSchema === undefined) {
      validationSchema = EventValidator;
    }

    try {
      validationSchema.parse(this);
    } catch (err) {
      if (err instanceof ZodError) {
        return err;
      } else {
        throw err;
      }
    }
    return undefined;
  }
}
