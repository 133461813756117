import { z, ZodSchema } from 'zod';
import { InvoiceRecipientsValidator } from './invoice-recipients-validator';

export const DealerAccountValidator: ZodSchema = z.object({
  lat: z.number().min(-90.0).max(90.0),
  lon: z.number().min(-180.0).max(180.0),
  address1: z.string().max(150),
  address2: z.string().max(150),
  city: z.string().max(35),
  phone: z.string().max(12),
  aname: z.string().max(150),
  email: z.string().email(),
  state: z.string().length(2),
  actnum: z.string().length(5),
  isActive: z.boolean(),
  fri_to: z.string().max(15),
  mon_to: z.string().max(15),
  sat_to: z.string().max(15),
  strtyp: z.string().max(2),
  sun_to: z.string().max(15),
  thu_to: z.string().max(15),
  tue_to: z.string().max(15),
  wed_to: z.string().max(15),
  zipCode: z.string().max(10),
  //url isn't good yet.
  website: z.string().max(150),
  fri_from: z.string().max(15),
  fri_type: z.string().length(1),
  mon_from: z.string().max(15),
  mon_type: z.string().length(1),
  sat_from: z.string().max(15),
  sat_type: z.string().length(1),
  sun_from: z.string().max(15),
  sun_type: z.string().length(1),
  thu_from: z.string().max(15),
  thu_type: z.string().length(1),
  tue_from: z.string().max(15),
  tue_type: z.string().length(1),
  wed_from: z.string().max(15),
  wed_type: z.string().length(1),
  storeDescription: z.string().max(500),
  warehouse: z.string().length(2),
  //relative path and won't pass url check.
  landingPageUrl: z.string().max(150),
  invoiceRecipients: InvoiceRecipientsValidator.array().optional(),
});
