export function findClosestMeasurement(
  numbers: number[],
  sample: number
): number {
  let closestNumber = numbers[0];
  let smallestDifference = Math.abs(numbers[0] - sample);

  for (let i = 1; i < numbers.length; i++) {
    const difference = Math.abs(numbers[i] - sample);
    if (difference < smallestDifference) {
      smallestDifference = difference;
      closestNumber = numbers[i];
    }
  }

  return closestNumber;
}
