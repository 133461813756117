import { Event } from "common-types";
import { getEventDif } from "../get-event-dif";
import { getMembersDifFromEvent } from "../get-members-dif-from-event";
import { DifOptions } from "../types/dif-options.type";

const previousEventTest: Event = {
  "saved": true,
  "dealerId": "TA102",
  "eventType": "Wedding",
  "commissionType": "REFERRAL",
  "lastUpdated": "1719415154963",
  "id": "DSlbsnPCeHvvlP31Guyf",
  "eventDate": 1727409600,
  "createDate": 1719415155,
  "dealerInfo": {
    "transferredByDealer": {
      "name": "Nick S",
      "userId": "sl1lWXrPkXW8NmD8DfgIIQB0Lvu2",
      "email": "nstefanic@gmail.com"
    },
    "createdByDealer": {
      "name": "Nick S",
      "userId": "sl1lWXrPkXW8NmD8DfgIIQB0Lvu2",
      "email": "nstefanic@gmail.com"
    },
    "dealerId": "not TA102"
  },
  "createdByUserId": "KuicqhN13Ker3xiYAZ9mQIS0uty2",
  "looks": [
    {
      "ensembleCode": "421",
      "price": 190,
      "styles": [
        {
          "keywords": [],
          "webReleaseDate": 1688014800,
          "styleGroup": "Rental",
          "companionStyleCode": "PPT921",
          "marketingTitle": "Black Performance Legacy Pants",
          "colors": [
            "Black"
          ],
          "fit": "Ultra Slim",
          "styleType": "Pant",
          "price": 48,
          "activeDate": 1682571600,
          "colorHexCode": "#000000",
          "categories": [],
          "companionStylePrice": 150,
          "images": [
            {
              "src": "black-performance-legacy-pants-921.jpg",
              "alt": "Black Performance Legacy Pants"
            }
          ],
          "sizeScale": "FLAT2",
          "styleRank": 1,
          "styleFirestoreDocumentId": "CeTKEZjFCizIms0q7gr5",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                  "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT421",
              "marketingTitle": "Black Performance Wedding Suit Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                  "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT921",
              "marketingTitle": "Black Legacy Tuxedo Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                  "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT981",
              "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
            }
          ],
          "designer": "Michael Kors",
          "styleCode": "RPT921",
          "legacyStyleCode": "921",
          "matchingStyles": [
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "PVS921"
            },
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "RCT421"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RCT981"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RVS921"
            },
            {
              "docId": "zg6xNgr4zbkkvXXAZIFO",
              "styleCode": "PVS921"
            },
            {
              "docId": "zg6xNgr4zbkkvXXAZIFO",
              "styleCode": "RCT921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Available to rent or buy\n- Flat front with adjustable waist\n- Belt loops and buttons to accommodate suspenders\n- Coordinates with Black Performance Stretch Suits and Tuxedo\n\n### SIZE SCALE:\n921 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
          "fabric": "CoolMax/Sorona/Wool",
          "suspenderButtons": true,
          "mannequinImages": [
            {
              "mannequinImage": "Pant",
              "src": "PT_921_1280x1920.png",
              "alt": "Black Performance Legacy Pants"
            }
          ],
          "colorShade": "Black",
          "waist": "Adjustable",
          "beltLoops": true,
          "publishedDate": 1698107750
        },
        {
          "keywords": [
            "belt",
            "reversible",
            "black",
            "brown"
          ],
          "webReleaseDate": 1683522000,
          "styleGroup": "Purchase",
          "marketingTitle": "Black/Brown Reversible Belt",
          "colors": [
            "Black/Brown"
          ],
          "styleType": "Miscellaneous",
          "price": 24,
          "activeDate": 1683522000,
          "colorHexCode": "#000000",
          "categories": [
            "Accessories"
          ],
          "images": [
            {
              "src": "belt-black-tan-reversible-NBLT.jpg",
              "alt": "Black/Brown Reversible Belt"
            }
          ],
          "styleRank": 1,
          "styleFirestoreDocumentId": "jMgaN4ySnwR4M7G8I1MQ",
          "colorShadeId": "BlackBrown",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Ottawa",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "relatedStyles": [],
          "colorFamily": "Black",
          "collection": "Resale",
          "styleCode": "PMSBLT",
          "miscellaneousType": "Belt",
          "legacyStyleCode": "NBLT",
          "marketingDescription": "### FEATURES:\n- Synthetic leather\n- Reversible black & brown\n- Platinum burnished buckle, stitched edges, 3.5 cm wide\n\n### SIZE SCALE:\n- XS: 21-24\n- S: 25-28\n- M: 29-32\n- L: 33-36\n- XL: 37-40\n- 2X: 41-44\n- 3X: 45-28",
          "fabric": "Leather",
          "mannequinImages": [
            {
              "mannequinImage": "Miscellaneous",
              "src": "belt-black-tan-reversible-NBLT.jpg",
              "alt": "Black/Brown Reversible Belt"
            }
          ],
          "colorShade": "Black/Brown",
          "publishedDate": 1698108942
        },
        {
          "vestType": "Fullback",
          "keywords": [],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "vestBack": "Matching",
          "companionStyleCode": "PVS921",
          "program": "Matching",
          "marketingTitle": "Black Performance Vest",
          "colors": [
            "Black"
          ],
          "styleType": "Vest",
          "price": 32,
          "activeDate": 1684731600,
          "colorHexCode": "#000000",
          "categories": [
            "VestsAndCummerbunds"
          ],
          "companionStylePrice": 79,
          "images": [
            {
              "src": "fullback-vest-black-performance-stretch-F921.jpg",
              "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
            }
          ],
          "sizeScale": "VST16",
          "buttons": 4,
          "styleRank": 1,
          "styleFirestoreDocumentId": "2Zg4MDqr3PPnsAOSaAKJ",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                  "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT921",
              "marketingTitle": "Black Legacy Tuxedo Coat"
            },
            {
              "styleType": "Pant",
              "images": [
                {
                  "src": "black-performance-legacy-pants-921.jpg",
                  "alt": "Black Performance Legacy Pants"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RPT921",
              "marketingTitle": "Black Performance Legacy Pants"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                  "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT421",
              "marketingTitle": "Black Performance Wedding Suit Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                  "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT981",
              "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
            }
          ],
          "designer": "Michael Kors",
          "styleCode": "RVS921",
          "legacyStyleCode": "F921",
          "matchingStyles": [
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RCT981"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RPT921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- 4-button\n- Adjustable back\n- Darted pockets\n- Matching colored back\n\n### SIZE SCALE:\n- Boys' Small - Men's 5XA\n\n### FABRIC:\n- Polyester",
          "fabric": "CoolMax/Sorona/Wool",
          "mannequinImages": [
            {
              "mannequinImage": "Vest",
              "src": "VS_F921_1280x1920.png",
              "alt": "Michael Kors Black Performance Stretch Wedding Vest"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "VST18",
          "publishedDate": 1698428370,
          "pocket": "Darted"
        },
        {
          "keywords": [
            "cufflinks",
            "studs",
            "jewlry",
            "silver"
          ],
          "webReleaseDate": 1694667600,
          "styleGroup": "Rental",
          "marketingTitle": "Black/Silver Basic Jewelry",
          "colors": [
            "Black"
          ],
          "styleType": "Jewelry",
          "price": 2,
          "activeDate": 1194242400,
          "colorHexCode": "#000000",
          "finish": "Silver",
          "categories": [
            "CufflinksAndStuds"
          ],
          "images": [
            {
              "src": "JW_BL_1280x1920.jpg",
              "alt": "Black/Silver Basic Jewelry"
            }
          ],
          "styleRank": 1,
          "styleFirestoreDocumentId": "JZaae4iAZVssS3k3E0nE",
          "colorShadeId": "Black",
          "jewelryType": "Full Set",
          "warehouses": [
            "Trenton",
            "Anderson",
            "Atlanta",
            "Seguin",
            "Visalia",
            "Salina",
            "Richmond"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Shirt",
              "images": [
                {
                  "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
                  "alt": "White Fitted Microfiber Shirt"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RSR96W",
              "marketingTitle": "White Fitted Microfiber Shirt"
            }
          ],
          "collection": "Basic",
          "styleCode": "RJWBL",
          "legacyStyleCode": "BL",
          "marketingDescription": "### FEATURES:\n- Set includes 2 cufflinks and 4 shirt studs\n- Toggle back\n- Faux onyx stone framed in silver-toned metal",
          "mannequinImages": [
            {
              "mannequinImage": "Jewelry",
              "src": "JW_BL_1280x1920.jpg",
              "alt": "Black/Silver Basic Jewelry"
            }
          ],
          "colorShade": "Black",
          "publishedDate": 1699627147
        },
        {
          "coatType": "Suit",
          "vent": "Side",
          "keywords": [],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "companionStyleCode": "PCT421",
          "marketingTitle": "Black Performance Wedding Suit Coat",
          "colors": [
            "Black"
          ],
          "coatLength": 29.5,
          "fit": "Ultra Slim",
          "styleType": "Coat",
          "price": 190,
          "activeDate": 1684731600,
          "colorHexCode": "#000000",
          "categories": [],
          "companionStylePrice": 330,
          "lapel": "Notch",
          "images": [
            {
              "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
              "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
            }
          ],
          "sizeScale": "B",
          "buttons": 2,
          "styleRank": 2,
          "styleFirestoreDocumentId": "Fa6MNKeF1oTFv4A5gzD0",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "https://www.jimsformalwear.com/images/1280x1920/fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "Black Performance Vest"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            }
          ],
          "collection": "Diamond",
          "designer": "Michael Kors",
          "styleCode": "RCT421",
          "legacyStyleCode": "421",
          "matchingStyles": [
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "RPT921"
            },
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "PVS921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 29 1/2\" length. \n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
          "fabric": "CoolMax/Sorona/Wool",
          "recommendedStyles": [
            "RPT921"
          ],
          "mannequinImages": [
            {
              "mannequinImage": "Coat",
              "src": "CT_421_1280x1920.png",
              "alt": "Black Performance Wedding Suit Coat"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "CSTZ",
          "publishedDate": 1697577801,
          "pocket": "Chest"
        },
        {
          "keywords": [
            "white",
            "ivory",
            "black",
            "shirt",
            "fitted",
            "formal shirt"
          ],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "companionStyleCode": "PSR96W",
          "marketingTitle": "White Fitted Microfiber Shirt",
          "colors": [
            "White"
          ],
          "collarType": "Laydown",
          "fit": "Fitted",
          "styleType": "Shirt",
          "price": 28,
          "activeDate": 1683522000,
          "colorHexCode": "#FFFFFF",
          "categories": [
            "Shirts"
          ],
          "companionStylePrice": 54,
          "images": [
            {
              "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
              "alt": "White Fitted Microfiber Shirt"
            }
          ],
          "sizeScale": "FIT",
          "styleRank": 1,
          "styleFirestoreDocumentId": "cP3bWqMFOifb38CvszqT",
          "colorShadeId": "White",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "White/Ivory",
          "relatedStyles": [
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_BG_1280x1920.jpg",
                  "alt": "Black/Gold Premium Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBG",
              "marketingTitle": "Black/Gold Premium Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_BL_1280x1920.jpg",
                  "alt": "Black/Silver Basic Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBL",
              "marketingTitle": "Black/Silver Basic Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "cufflinks-studs-tuxedo-jewelry-BS.jpg",
                  "alt": "Black/Silver Premium Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBS",
              "marketingTitle": "Black/Silver Premium Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_WH_1280x1920.jpg",
                  "alt": "White/Silver Basic Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWWH",
              "marketingTitle": "White/Silver Basic Jewelry"
            }
          ],
          "collection": "Premium (PRM)",
          "styleCode": "RSR96W",
          "legacyStyleCode": "96W",
          "marketingDescription": "### FEATURES:\n- Non-pleated front with tapered sides\n- Laydown collar, barrel cuff\n- Available in White, Ivory, and Black\n\n### SIZE SCALE:\nBoys' Large - Men's 3XL\n\n### FABRIC:\nPremium Microfiber\n\n### FIT:\nSlim Fit",
          "fabric": "Microfiber",
          "mannequinImages": [
            {
              "mannequinImage": "Shirt",
              "src": "SR_96W_1280x1920.png",
              "alt": "Shirt"
            },
            {
              "mannequinImage": "ShirtExtra",
              "src": "SR_96W_C_1280x1920_extra.png",
              "alt": "Shirt Collar"
            }
          ],
          "colorShade": "White",
          "publishedDate": 1701098610,
          "shirtFront": "Flat Front"
        },
        {
          "keywords": [],
          "webReleaseDate": 1697778000,
          "pattern": "Solid",
          "styleGroup": "Purchase",
          "program": "Expressions",
          "marketingTitle": "Rosewood Expressions Pocket Square",
          "colors": [
            "Rosewood"
          ],
          "styleType": "PocketSquare",
          "price": 10,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Apple Red",
              "colorHexCode": "#730000",
              "styleCode": "PPKPWAR",
              "marketingTitle": "Apple Red Expressions Pocket Square"
            },
            {
              "colorShade": "Aqua",
              "colorHexCode": "#70BCA5",
              "styleCode": "PPKPWAQ",
              "marketingTitle": "Aqua Expressions Pocket Square"
            },
            {
              "colorShade": "Ballet",
              "colorHexCode": "#D2A29E",
              "styleCode": "PPKPWBA",
              "marketingTitle": "Ballet Expressions Pocket Square"
            },
            {
              "colorShade": "Bellini",
              "colorHexCode": "#DAB7A6",
              "styleCode": "PPKPWBE",
              "marketingTitle": "Bellini Expressions Pocket Square"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "PPKPWBL",
              "marketingTitle": "Black Expressions Pocket Square"
            },
            {
              "colorShade": "Blue Box",
              "colorHexCode": "#75C7CE",
              "styleCode": "PPKPWBB",
              "marketingTitle": "Blue Box Expressions Pocket Square"
            },
            {
              "colorShade": "Blush",
              "colorHexCode": "#B58B88",
              "styleCode": "PPKPWBH",
              "marketingTitle": "Blush Expressions Pocket Square"
            },
            {
              "colorShade": "Brite Pink",
              "colorHexCode": "#DF588A",
              "styleCode": "PPKPWBP",
              "marketingTitle": "Brite Pink Expressions Pocket Square"
            },
            {
              "colorShade": "Buttercup",
              "colorHexCode": "#EDD66A",
              "styleCode": "PPKPWBC",
              "marketingTitle": "Buttercup Expressions Pocket Square"
            },
            {
              "colorShade": "Champagne",
              "colorHexCode": "#A29581",
              "styleCode": "PPKPWCP",
              "marketingTitle": "Champagne Expressions Pocket Square"
            },
            {
              "colorShade": "Charcoal",
              "colorHexCode": "#3E3E3E",
              "styleCode": "PPKPWCA",
              "marketingTitle": "Charcoal Expressions Pocket Square"
            },
            {
              "colorShade": "Cherry",
              "colorHexCode": "#7F212E",
              "styleCode": "PPKPWCY",
              "marketingTitle": "Cherry Expressions Pocket Square"
            },
            {
              "colorShade": "Chocolate",
              "colorHexCode": "#4E3D30",
              "styleCode": "PPKPWCH",
              "marketingTitle": "Chocolate Expressions Pocket Square"
            },
            {
              "colorShade": "Cinnamon",
              "colorHexCode": "#79342A",
              "styleCode": "PPKPWCI",
              "marketingTitle": "Cinnamon Expressions Pocket Square"
            },
            {
              "colorShade": "Cobalt",
              "colorHexCode": "#2d406f",
              "styleCode": "PPKPWCB",
              "marketingTitle": "Cobalt Expressions Pocket Square"
            },
            {
              "colorShade": "Coral",
              "colorHexCode": "#F6A4A3",
              "styleCode": "PPKPWCO",
              "marketingTitle": "Coral Expressions Pocket Square"
            },
            {
              "colorShade": "Cornflower",
              "colorHexCode": "#647EB0",
              "styleCode": "PPKPWCF",
              "marketingTitle": "Cornflower Expressions Pocket Square"
            },
            {
              "colorShade": "Cranberry",
              "colorHexCode": "#490B14",
              "styleCode": "PPKPWCE",
              "marketingTitle": "Cranberry Expressions Pocket Square"
            },
            {
              "colorShade": "Dark Coral",
              "colorHexCode": "#E17A74",
              "styleCode": "PPKPWDC",
              "marketingTitle": "Dark Coral Expressions Pocket Square"
            },
            {
              "colorShade": "Dusty Blue",
              "colorHexCode": "#8B98A7",
              "styleCode": "PPKPWDB",
              "marketingTitle": "Dusty Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Dusty Rose",
              "colorHexCode": "#E3D0D7",
              "styleCode": "PPKPWDR",
              "marketingTitle": "Dusty Rose Expressions Pocket Square"
            },
            {
              "colorShade": "Emerald",
              "colorHexCode": "#05493B",
              "styleCode": "PPKPWEM",
              "marketingTitle": "Emerald Expressions Pocket Square"
            },
            {
              "colorShade": "Evergreen/Moss",
              "colorHexCode": "#757B63",
              "styleCode": "PPKPWEV",
              "marketingTitle": "Evergreen/Moss Expressions Pocket Square"
            },
            {
              "colorShade": "Ferrari Red",
              "colorHexCode": "#A60A20",
              "styleCode": "PPKPWFR",
              "marketingTitle": "Ferrari Red Expressions Pocket Square"
            },
            {
              "colorShade": "First Blush",
              "colorHexCode": "#B69797",
              "styleCode": "PPKPWFB",
              "marketingTitle": "First Blush Expressions Pocket Square"
            },
            {
              "colorShade": "Forest",
              "colorHexCode": "#2F4437",
              "styleCode": "PPKPWFO",
              "marketingTitle": "Forest Expressions Pocket Square"
            },
            {
              "colorShade": "Gold",
              "colorHexCode": "#8b7256",
              "styleCode": "PPKPWGO",
              "marketingTitle": "Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Gold Metallic",
              "colorHexCode": "#C0B065",
              "styleCode": "PPKPWMG",
              "marketingTitle": "Gold Metallic Expressions Pocket Square"
            },
            {
              "colorShade": "Golden",
              "colorHexCode": "#A7956D",
              "styleCode": "PPKPWGL",
              "marketingTitle": "Golden Expressions Pocket Square"
            },
            {
              "colorShade": "Hunter Green",
              "colorHexCode": "#133B2F",
              "styleCode": "PPKPWHG",
              "marketingTitle": "Hunter Green Expressions Pocket Square"
            },
            {
              "colorShade": "Ivory",
              "colorHexCode": "#EEE9DF",
              "styleCode": "PPKPWIV",
              "marketingTitle": "Ivory Expressions Pocket Square"
            },
            {
              "colorShade": "Lavender",
              "colorHexCode": "#938BA8",
              "styleCode": "PPKPWLA",
              "marketingTitle": "Lavender Expressions Pocket Square"
            },
            {
              "colorShade": "Lite Blue",
              "colorHexCode": "#9FBBD7",
              "styleCode": "PPKPWLB",
              "marketingTitle": "Lite Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Lite Watermelon",
              "colorHexCode": "#D5487D",
              "styleCode": "PPKPWLW",
              "marketingTitle": "Lite Watermelon Expressions Pocket Square"
            },
            {
              "colorShade": "Malibu",
              "colorHexCode": "#369FC4",
              "styleCode": "PPKPWML",
              "marketingTitle": "Malibu Expressions Pocket Square"
            },
            {
              "colorShade": "Mint",
              "colorHexCode": "#AAD6BA",
              "styleCode": "PPKPWMN",
              "marketingTitle": "Mint Expressions Pocket Square"
            },
            {
              "colorShade": "Navy",
              "colorHexCode": "#25334D",
              "styleCode": "PPKPWNA",
              "marketingTitle": "Navy Expressions Pocket Square"
            },
            {
              "colorShade": "Nude",
              "colorHexCode": "#C7B097",
              "styleCode": "PPKPWNU",
              "marketingTitle": "Nude Expressions Pocket Square"
            },
            {
              "colorShade": "Pink Rose Gold",
              "colorHexCode": "#D4B1AA",
              "styleCode": "PPKPWPR",
              "marketingTitle": "Pink Rose Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Platinum",
              "colorHexCode": "#A4A7AD",
              "styleCode": "PPKPWPT",
              "marketingTitle": "Platinum Expressions Pocket Square"
            },
            {
              "colorShade": "Plum",
              "colorHexCode": "#381d38",
              "styleCode": "PPKPWPL",
              "marketingTitle": "Plum Expressions Pocket Square"
            },
            {
              "colorShade": "Quartz",
              "colorHexCode": "#B69797",
              "styleCode": "PPKPWQZ",
              "marketingTitle": "Quartz Expressions Pocket Square"
            },
            {
              "colorShade": "Raspberry",
              "colorHexCode": "#772E59",
              "styleCode": "PPKPWRA",
              "marketingTitle": "Raspberry Expressions Pocket Square"
            },
            {
              "colorShade": "Regency",
              "colorHexCode": "#33215C",
              "styleCode": "PPKPWRG",
              "marketingTitle": "Regency Expressions Pocket Square"
            },
            {
              "colorShade": "Rose Gold",
              "colorHexCode": "#99837F",
              "styleCode": "PPKPWRS",
              "marketingTitle": "Rose Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Rose Petal",
              "colorHexCode": "#E8B4C1",
              "styleCode": "PPKPWRP",
              "marketingTitle": "Rose Petal Expressions Pocket Square"
            },
            {
              "colorShade": "Rosewood",
              "colorHexCode": "#5f3237",
              "styleCode": "PPKPWRW",
              "marketingTitle": "Rosewood Expressions Pocket Square"
            },
            {
              "colorShade": "Royal Blue",
              "colorHexCode": "#21508F",
              "styleCode": "PPKPWRO",
              "marketingTitle": "Royal Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Sage",
              "colorHexCode": "#B3BBA2",
              "styleCode": "PPKPWSE",
              "marketingTitle": "Sage Expressions Pocket Square"
            },
            {
              "colorShade": "Silver",
              "colorHexCode": "#A7AEB8",
              "styleCode": "PPKPWSI",
              "marketingTitle": "Silver Expressions Pocket Square"
            },
            {
              "colorShade": "Silver Metallic",
              "colorHexCode": "#C8C6C6",
              "styleCode": "PPKPWMS",
              "marketingTitle": "Silver Metallic Expressions Pocket Square"
            },
            {
              "colorShade": "Slate Blue",
              "colorHexCode": "#3F5167",
              "styleCode": "PPKPWSL",
              "marketingTitle": "Slate Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Spice",
              "colorHexCode": "#9B4D3E",
              "styleCode": "PPKPWSC",
              "marketingTitle": "Spice Expressions Pocket Square"
            },
            {
              "colorShade": "Taupe",
              "colorHexCode": "#CFC5C3",
              "styleCode": "PPKPWTP",
              "marketingTitle": "Taupe Expressions Pocket Square"
            },
            {
              "colorShade": "Teal",
              "colorHexCode": "#057085",
              "styleCode": "PPKPWTE",
              "marketingTitle": "Teal Expressions Pocket Square"
            },
            {
              "colorShade": "Tiffany Blue",
              "colorHexCode": "#90D2D5",
              "styleCode": "PPKPWTB",
              "marketingTitle": "Tiffany Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Turquoise",
              "colorHexCode": "#5BACB3",
              "styleCode": "PPKPWTU",
              "marketingTitle": "Turquoise Expressions Pocket Square"
            },
            {
              "colorShade": "Viola",
              "colorHexCode": "#5C0A5A",
              "styleCode": "PPKPWVA",
              "marketingTitle": "Viola Expressions Pocket Square"
            },
            {
              "colorShade": "Watermelon",
              "colorHexCode": "#AC1848",
              "styleCode": "PPKPWWA",
              "marketingTitle": "Watermelon Expressions Pocket Square"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "PPKPWWH",
              "marketingTitle": "White Expressions Pocket Square"
            },
            {
              "colorShade": "Wine",
              "colorHexCode": "#54101B",
              "styleCode": "PPKPWWI",
              "marketingTitle": "Wine Expressions Pocket Square"
            }
          ],
          "colorHexCode": "#5f3237",
          "categories": [
            "Accessories"
          ],
          "images": [
            {
              "src": "PK_PWRW_1280x1920.jpg",
              "alt": "Rosewood Expressions Solid Pocket Square"
            }
          ],
          "styleRank": 4,
          "styleFirestoreDocumentId": "7udSrg2Iv9KxWMIAE5VX",
          "colorShadeId": "Rosewood",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Pink",
          "relatedStyles": [
            {
              "styleType": "Tie",
              "images": [
                {
                  "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
                  "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RTIWXRW",
              "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
            }
          ],
          "styleCode": "PPKPWRW",
          "legacyStyleCode": "PWRW",
          "matchingStyles": [
            {
              "docId": "i393vAhanT6qXMCMWJDn",
              "styleCode": "RTIWXRW"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Hand-sewn edges\n- 9\" x 9\"\n- Polyester\n- Hand wash",
          "mannequinImages": [
            {
              "mannequinImage": "PocketSquare",
              "src": "PK_PWRW_1280x1920.jpg",
              "alt": "Rosewood Expressions Solid Pocket Square"
            }
          ],
          "colorShade": "Rosewood",
          "publishedDate": 1697835035
        },
        {
          "keywords": [],
          "webReleaseDate": 1688533200,
          "styleGroup": "Rental",
          "companionStyleCode": "PSOBXS",
          "marketingTitle": "Black Oxford Shoe",
          "colors": [
            "Black"
          ],
          "styleType": "Shoes",
          "shoeType": "Oxford",
          "price": 30,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RSOBDS",
              "marketingTitle": "Black Dunbar Shoe"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RSOBXS",
              "marketingTitle": "Black Oxford Shoe"
            },
            {
              "colorShade": "Chestnut",
              "colorHexCode": "#91533d",
              "styleCode": "RSOCOP",
              "marketingTitle": "Chestnut Oxford Plain Toe Shoe"
            },
            {
              "colorShade": "Cognac",
              "colorHexCode": "#6C423D",
              "styleCode": "RSOCXS",
              "marketingTitle": "Cognac Oxford Shoe"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "RSOWAS",
              "marketingTitle": "White Allegro Shoe"
            }
          ],
          "colorHexCode": "#000000",
          "categories": [
            "Shoes"
          ],
          "companionStylePrice": 79,
          "images": [
            {
              "src": "SO-BXS-1280x1920.jpg",
              "alt": "Black Oxford Shoe"
            }
          ],
          "sizeScale": "C",
          "styleRank": 3,
          "styleFirestoreDocumentId": "O0Xb8rmylCTlFstqKJCu",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "colored-socks-royal-blue-XSRO.jpg",
                  "alt": "Royal Blue Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXSRO",
              "marketingTitle": "Royal Blue Socks"
            },
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "patterned-socks-wine-argyle-XPWG.jpg",
                  "alt": "Wine Grey Argyle Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXPWG",
              "marketingTitle": "Wine Grey Argyle Socks"
            },
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "colored-socks-black-XBLK.jpg",
                  "alt": "Black Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXBLK",
              "marketingTitle": "Black Socks"
            }
          ],
          "styleCode": "RSOBXS",
          "legacyStyleCode": "BXS",
          "material": "Leather",
          "marketingDescription": "### FEATURES:\n- Synthetic Leather\n- Cap toe, lace up closure\n- Premium cushioned footbed and flex construction \n\n### SIZE SCALE:\n- Men's Medium Width (C/D): 6 - 18 (half sizes up to 11.5)\n- Men's Wide Width (E/EE): 7-18 (half sizes up to 11.5)\n- Boys' Medium Width: 7 - 5.5",
          "mannequinImages": [
            {
              "mannequinImage": "Shoes",
              "src": "SO-BXS-1280x1920.jpg",
              "alt": "Black Oxford Shoe"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "BASIC",
          "publishedDate": 1701715960,
          "measurementScale2": "BASIC"
        },
        {
          "keywords": [],
          "webReleaseDate": 1697432400,
          "pattern": "Striped",
          "styleGroup": "Rental",
          "companionStyleCode": "PTIWXRW",
          "program": "Expressions",
          "marketingTitle": "Rosewood Striped Expressions Windsor Tie",
          "colors": [
            "Rosewood"
          ],
          "styleType": "Tie",
          "price": 17,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Apple Red",
              "colorHexCode": "#730000",
              "styleCode": "RTIWXAR",
              "marketingTitle": "Apple Red Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Aqua",
              "colorHexCode": "#70BCA5",
              "styleCode": "RTIWXAQ",
              "marketingTitle": "Aqua Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ballet",
              "colorHexCode": "#D2A29E",
              "styleCode": "RTIWXBA",
              "marketingTitle": "Ballet Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Bellini",
              "colorHexCode": "#DAB7A6",
              "styleCode": "RTIWXBE",
              "marketingTitle": "Bellini Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RTIWXBL",
              "marketingTitle": "Black Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Blue Box",
              "colorHexCode": "#75C7CE",
              "styleCode": "RTIWXBB",
              "marketingTitle": "Blue Box Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Blush",
              "colorHexCode": "#B58B88",
              "styleCode": "RTIWXBH",
              "marketingTitle": "Blush Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Brite Pink",
              "colorHexCode": "#DF588A",
              "styleCode": "RTIWXBP",
              "marketingTitle": "Brite Pink Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Buttercup",
              "colorHexCode": "#EDD66A",
              "styleCode": "RTIWXBC",
              "marketingTitle": "Buttercup Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Champagne",
              "colorHexCode": "#A29581",
              "styleCode": "RTIWXCP",
              "marketingTitle": "Champagne Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Charcoal",
              "colorHexCode": "#3E3E3E",
              "styleCode": "RTIWXCA",
              "marketingTitle": "Charcoal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cherry",
              "colorHexCode": "#7F212E",
              "styleCode": "RTIWXCY",
              "marketingTitle": "Cherry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Chocolate",
              "colorHexCode": "#4E3D30",
              "styleCode": "RTIWXCH",
              "marketingTitle": "Chocolate Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cinnamon",
              "colorHexCode": "#79342A",
              "styleCode": "RTIWXCI",
              "marketingTitle": "Cinnamon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cobalt",
              "colorHexCode": "#2d406f",
              "styleCode": "RTIWXCB",
              "marketingTitle": "Cobalt Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Coral",
              "colorHexCode": "#F6A4A3",
              "styleCode": "RTIWXCO",
              "marketingTitle": "Coral Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cornflower",
              "colorHexCode": "#647EB0",
              "styleCode": "RTIWXCF",
              "marketingTitle": "Cornflower Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cranberry",
              "colorHexCode": "#490B14",
              "styleCode": "RTIWXCE",
              "marketingTitle": "Cranberry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dark Coral",
              "colorHexCode": "#E17A74",
              "styleCode": "RTIWXDC",
              "marketingTitle": "Dark Coral Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dusty Blue",
              "colorHexCode": "#8B98A7",
              "styleCode": "RTIWXDB",
              "marketingTitle": "Dusty Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dusty Rose",
              "colorHexCode": "#E3D0D7",
              "styleCode": "RTIWXDR",
              "marketingTitle": "Dusty Rose Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Emerald",
              "colorHexCode": "#05493B",
              "styleCode": "RTIWXEM",
              "marketingTitle": "Emerald Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Evergreen/Moss",
              "colorHexCode": "#757B63",
              "styleCode": "RTIWXEV",
              "marketingTitle": "Evergreen/Moss Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ferrari Red",
              "colorHexCode": "#A60A20",
              "styleCode": "RTIWXFR",
              "marketingTitle": "Ferrari Red Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "First Blush",
              "colorHexCode": "#B69797",
              "styleCode": "RTIWXFB",
              "marketingTitle": "First Blush Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Forest",
              "colorHexCode": "#2F4437",
              "styleCode": "RTIWXFO",
              "marketingTitle": "Forest Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Gold",
              "colorHexCode": "#8b7256",
              "styleCode": "RTIWXGO",
              "marketingTitle": "Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Gold Metallic",
              "colorHexCode": "#C0B065",
              "styleCode": "RTIWXMG",
              "marketingTitle": "Gold Metallic Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Golden",
              "colorHexCode": "#A7956D",
              "styleCode": "RTIWXGL",
              "marketingTitle": "Golden Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Hunter Green",
              "colorHexCode": "#133B2F",
              "styleCode": "RTIWXHG",
              "marketingTitle": "Hunter Green Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ivory",
              "colorHexCode": "#EEE9DF",
              "styleCode": "RTIWXIV",
              "marketingTitle": "Ivory Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lavender",
              "colorHexCode": "#938BA8",
              "styleCode": "RTIWXLA",
              "marketingTitle": "Lavender Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lite Blue",
              "colorHexCode": "#9FBBD7",
              "styleCode": "RTIWXLB",
              "marketingTitle": "Lite Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lite Watermelon",
              "colorHexCode": "#D5487D",
              "styleCode": "RTIWXLW",
              "marketingTitle": "Lite Watermelon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Malibu",
              "colorHexCode": "#369FC4",
              "styleCode": "RTIWXML",
              "marketingTitle": "Malibu Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Mint",
              "colorHexCode": "#AAD6BA",
              "styleCode": "RTIWXMN",
              "marketingTitle": "Mint Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Navy",
              "colorHexCode": "#25334D",
              "styleCode": "RTIWXNA",
              "marketingTitle": "Navy Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Nude",
              "colorHexCode": "#C7B097",
              "styleCode": "RTIWXNU",
              "marketingTitle": "Nude Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Pink Rose Gold",
              "colorHexCode": "#D4B1AA",
              "styleCode": "RTIWXPR",
              "marketingTitle": "Pink Rose Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Platinum",
              "colorHexCode": "#A4A7AD",
              "styleCode": "RTIWXPT",
              "marketingTitle": "Platinum Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Plum",
              "colorHexCode": "#381d38",
              "styleCode": "RTIWXPL",
              "marketingTitle": "Plum Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Quartz",
              "colorHexCode": "#B69797",
              "styleCode": "RTIWXQZ",
              "marketingTitle": "Quartz Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Raspberry",
              "colorHexCode": "#772E59",
              "styleCode": "RTIWXRA",
              "marketingTitle": "Raspberry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Regency",
              "colorHexCode": "#33215C",
              "styleCode": "RTIWXRG",
              "marketingTitle": "Regency Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rose Gold",
              "colorHexCode": "#99837F",
              "styleCode": "RTIWXRS",
              "marketingTitle": "Rose Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rose Petal",
              "colorHexCode": "#E8B4C1",
              "styleCode": "RTIWXRP",
              "marketingTitle": "Rose Petal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rosewood",
              "colorHexCode": "#5f3237",
              "styleCode": "RTIWXRW",
              "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Royal Blue",
              "colorHexCode": "#21508F",
              "styleCode": "RTIWXRO",
              "marketingTitle": "Royal Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Sage",
              "colorHexCode": "#B3BBA2",
              "styleCode": "RTIWXSE",
              "marketingTitle": "Sage Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Silver",
              "colorHexCode": "#A7AEB8",
              "styleCode": "RTIWXSI",
              "marketingTitle": "Silver Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Silver Metallic",
              "colorHexCode": "#C8C6C6",
              "styleCode": "RTIWXMS",
              "marketingTitle": "Silver Metallic Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Slate Blue",
              "colorHexCode": "#3F5167",
              "styleCode": "RTIWXSL",
              "marketingTitle": "Slate Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Spice",
              "colorHexCode": "#9B4D3E",
              "styleCode": "RTIWXSC",
              "marketingTitle": "Spice Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Taupe",
              "colorHexCode": "#CFC5C3",
              "styleCode": "RTIWXTP",
              "marketingTitle": "Taupe Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Teal",
              "colorHexCode": "#057085",
              "styleCode": "RTIWXTE",
              "marketingTitle": "Teal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Tiffany Blue",
              "colorHexCode": "#90D2D5",
              "styleCode": "RTIWXTB",
              "marketingTitle": "Tiffany Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Turquoise",
              "colorHexCode": "#5BACB3",
              "styleCode": "RTIWXTU",
              "marketingTitle": "Turquoise Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Viola",
              "colorHexCode": "#5C0A5A",
              "styleCode": "RTIWXVA",
              "marketingTitle": "Viola Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Watermelon",
              "colorHexCode": "#AC1848",
              "styleCode": "RTIWXWA",
              "marketingTitle": "Watermelon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "RTIWXWH",
              "marketingTitle": "White Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Wine",
              "colorHexCode": "#54101B",
              "styleCode": "RTIWXWI",
              "marketingTitle": "Wine Striped Expressions Windsor Tie"
            }
          ],
          "colorHexCode": "#5f3237",
          "categories": [
            "Ties"
          ],
          "tieType": "Windsor",
          "companionStylePrice": 39,
          "images": [
            {
              "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
              "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
            },
            {
              "src": "windsor-tie-rose-petal-stripe-WXRP.jpg",
              "alt": "this is a test"
            },
            {
              "src": "windsor-tie-black-stripe-WXBL.jpg",
              "alt": "test 2"
            }
          ],
          "sizeScale": "A",
          "styleRank": 157,
          "styleFirestoreDocumentId": "b1EKl2zr3h3ByJqe5Rp4",
          "colorShadeId": "Rosewood",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Pink",
          "relatedStyles": [
            {
              "styleType": "PocketSquare",
              "images": [
                {
                  "src": "PK_PWRW_1280x1920.jpg",
                  "alt": "Rosewood Expressions Solid Pocket Square"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PPKPWRW",
              "marketingTitle": "Rosewood Expressions Pocket Square"
            },
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            }
          ],
          "collection": "Premium (PRM)",
          "styleCode": "RTIWXRW",
          "legacyStyleCode": "WXRW",
          "matchingStyles": [
            {
              "docId": "i393vAhanT6qXMCMWJDn",
              "styleCode": "PPKPWRW"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Striped colored windsor tie\n- Pre-tied, expandable neck\n- Coordinates with any Matching Wool Vest\n- Matching solid pocket square is available\n\n### SIZE SCALE:\n - Men's and Boys' Sizes\n\n### FABRIC:\n - Polyester",
          "fabric": "Polyester",
          "mannequinImages": [
            {
              "mannequinImage": "WindsorTie",
              "src": "TI_WXRW_1280x1920.png",
              "alt": "Rosewood Expressions Striped Windsor Tie"
            }
          ],
          "colorShade": "Rosewood",
          "measurementScale1": "A",
          "publishedDate": 1711135542
        }
      ],
      "id": "24cb9fc8-a211-4be6-b2e9-f6529f4942e4",
      "title": "Look 1"
    }
  ],
  "memberIds": [
    "a508f5ab-2bb2-476c-b982-2be9a9cab6cb"
  ],
  "members": [
    {
      "surrogateUser": false,
      "firstName": "First",
      "lastName": "Member",
      "phone": "0000000000",
      "memberProgress": {
        "inviteAccepted": 1719415453
      },
      "shippingAddress": {
        "zip": "",
        "city": "",
        "phone": "",
        "shippingMethod": "",
        "streetAddress1": "",
        "name": "",
        "streetAddress2": "",
        "state": ""
      },
      "id": "a508f5ab-2bb2-476c-b982-2be9a9cab6cb",
      "memberRole": "Groom",
      "email": "nstefanicjfw@gmail.com",
      "measurements": {},
      "memberLook": {
        "ensembleCode": "421",
        "price": 192,
        "styles": [
          {
            "keywords": [],
            "webReleaseDate": 1688014802,
            "styleGroup": "Rental",
            "companionStyleCode": "PPT921",
            "marketingTitle": "Black Performance Legacy Pants",
            "colors": [
              "Black"
            ],
            "fit": "Ultra Slim",
            "styleType": "Pant",
            "price": 48,
            "activeDate": 1682571600,
            "colorHexCode": "#000000",
            "categories": [],
            "companionStylePrice": 152,
            "images": [
              {
                "src": "black-performance-legacy-pants-921.jpg",
                "alt": "Black Performance Legacy Pants"
              }
            ],
            "sizeScale": "FLAT2",
            "styleRank": 1,
            "styleFirestoreDocumentId": "CeTKEZjFCizIms0q7gr5",
            "colorShadeId": "Black",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Vest",
                "images": [
                  {
                    "src": "fullback-vest-black-performance-stretch-F921.jpg",
                    "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RVS921",
                "marketingTitle": "Black Performance Vest"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                    "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT421",
                "marketingTitle": "Black Performance Wedding Suit Coat"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                    "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT921",
                "marketingTitle": "Black Legacy Tuxedo Coat"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                    "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT981",
                "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
              }
            ],
            "designer": "Michael Kors",
            "styleCode": "RPT921",
            "legacyStyleCode": "921",
            "matchingStyles": [
              {
                "docId": "DrSpbZEVmCJdWrhva9cl",
                "styleCode": "PVS921"
              },
              {
                "docId": "DrSpbZEVmCJdWrhva9cl",
                "styleCode": "RCT421"
              },
              {
                "docId": "dEHuyoH7F7yVXSgabD64",
                "styleCode": "RCT981"
              },
              {
                "docId": "dEHuyoH7F7yVXSgabD64",
                "styleCode": "RVS921"
              },
              {
                "docId": "zg6xNgr4zbkkvXXAZIFO",
                "styleCode": "PVS921"
              },
              {
                "docId": "zg6xNgr4zbkkvXXAZIFO",
                "styleCode": "RCT921"
              }
            ],
            "marketingDescription": "### FEATURES:\n- Available to rent or buy\n- Flat front with adjustable waist\n- Belt loops and buttons to accommodate suspenders\n- Coordinates with Black Performance Stretch Suits and Tuxedo\n\n### SIZE SCALE:\n921 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
            "fabric": "CoolMax/Sorona/Wool",
            "suspenderButtons": true,
            "mannequinImages": [
              {
                "mannequinImage": "Pant",
                "src": "PT_921_1280x1920.png",
                "alt": "Black Performance Legacy Pants"
              }
            ],
            "colorShade": "Black",
            "waist": "Adjustable",
            "beltLoops": true,
            "publishedDate": 1698107750
          },
          {
            "keywords": [
              "belt",
              "reversible",
              "black",
              "brown"
            ],
            "webReleaseDate": 1683522000,
            "styleGroup": "Purchase",
            "marketingTitle": "Black/Brown Reversible Belt",
            "colors": [
              "Black/Brown"
            ],
            "styleType": "Miscellaneous",
            "price": 24,
            "activeDate": 1683522000,
            "colorHexCode": "#000000",
            "categories": [
              "Accessories"
            ],
            "images": [
              {
                "src": "belt-black-tan-reversible-NBLT.jpg",
                "alt": "Black/Brown Reversible Belt"
              }
            ],
            "styleRank": 1,
            "styleFirestoreDocumentId": "jMgaN4ySnwR4M7G8I1MQ",
            "colorShadeId": "BlackBrown",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Ottawa",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "relatedStyles": [],
            "colorFamily": "Black",
            "collection": "Resale",
            "styleCode": "PMSBLT",
            "miscellaneousType": "Belt",
            "legacyStyleCode": "NBLT",
            "marketingDescription": "### FEATURES:\n- Synthetic leather\n- Reversible black & brown\n- Platinum burnished buckle, stitched edges, 3.5 cm wide\n\n### SIZE SCALE:\n- XS: 21-24\n- S: 25-28\n- M: 29-32\n- L: 33-36\n- XL: 37-40\n- 2X: 41-44\n- 3X: 45-28",
            "fabric": "Leather",
            "mannequinImages": [
              {
                "mannequinImage": "Miscellaneous",
                "src": "belt-RED-tan-reversible-NBLT.jpg",
                "alt": "Black/Brown Reversible Belt"
              }
            ],
            "colorShade": "Black/Brown",
            "publishedDate": 1698108942
          },
          {
            "vestType": "Fullback",
            "keywords": [],
            "webReleaseDate": 1688101200,
            "styleGroup": "Rental",
            "vestBack": "Matching",
            "companionStyleCode": "PVS921",
            "program": "Matching",
            "marketingTitle": "Black Performance Vest",
            "colors": [
              "Black"
            ],
            "styleType": "Vest",
            "price": 32,
            "activeDate": 1684731600,
            "colorHexCode": "#000000",
            "categories": [
              "VestsAndCummerbunds"
            ],
            "companionStylePrice": 79,
            "images": [
              {
                "src": "fullback-vest-black-performance-stretch-F921.jpg",
                "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
              }
            ],
            "sizeScale": "VST16",
            "buttons": 4,
            "styleRank": 1,
            "styleFirestoreDocumentId": "2Zg4MDqr3PPnsAOSaAKJ",
            "colorShadeId": "Black",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                    "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT921",
                "marketingTitle": "Black Legacy Tuxedo Coat"
              },
              {
                "styleType": "Pant",
                "images": [
                  {
                    "src": "black-performance-legacy-pants-921.jpg",
                    "alt": "Black Performance Legacy Pants"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RPT921",
                "marketingTitle": "Black Performance Legacy Pants"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                    "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT421",
                "marketingTitle": "Black Performance Wedding Suit Coat"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                    "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT981",
                "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
              }
            ],
            "designer": "Michael Kors",
            "styleCode": "RVS921",
            "legacyStyleCode": "F921",
            "matchingStyles": [
              {
                "docId": "dEHuyoH7F7yVXSgabD64",
                "styleCode": "RCT981"
              },
              {
                "docId": "dEHuyoH7F7yVXSgabD64",
                "styleCode": "RPT921"
              }
            ],
            "marketingDescription": "### FEATURES:\n- 4-button\n- Adjustable back\n- Darted pockets\n- Matching colored back\n\n### SIZE SCALE:\n- Boys' Small - Men's 5XA\n\n### FABRIC:\n- Polyester",
            "fabric": "CoolMax/Sorona/Wool",
            "mannequinImages": [
              {
                "mannequinImage": "Vest",
                "src": "VS_F921_1280x1920.png",
                "alt": "Michael Kors Black Performance Stretch Wedding Vest"
              }
            ],
            "colorShade": "Black",
            "measurementScale1": "VST18",
            "publishedDate": 1698428370,
            "pocket": "Darted"
          },
          {
            "keywords": [
              "cufflinks",
              "studs",
              "jewlry",
              "silver"
            ],
            "webReleaseDate": 1694667600,
            "styleGroup": "Rental",
            "marketingTitle": "Black/Silver Basic Jewelry",
            "colors": [
              "Black"
            ],
            "styleType": "Jewelry",
            "price": 2,
            "activeDate": 1194242400,
            "colorHexCode": "#000000",
            "finish": "Silver",
            "categories": [
              "CufflinksAndStuds"
            ],
            "images": [
              {
                "src": "JW_BL_1280x1920.jpg",
                "alt": "Black/Silver Basic Jewelry"
              }
            ],
            "styleRank": 1,
            "styleFirestoreDocumentId": "JZaae4iAZVssS3k3E0nE",
            "colorShadeId": "Black",
            "jewelryType": "Full Set",
            "warehouses": [
              "Trenton",
              "Anderson",
              "Atlanta",
              "Seguin",
              "Visalia",
              "Salina",
              "Richmond"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Shirt",
                "images": [
                  {
                    "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
                    "alt": "White Fitted Microfiber Shirt"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RSR96W",
                "marketingTitle": "White Fitted Microfiber Shirt"
              }
            ],
            "collection": "Basic",
            "styleCode": "RJWBL",
            "legacyStyleCode": "BL",
            "marketingDescription": "### FEATURES:\n- Set includes 2 cufflinks and 4 shirt studs\n- Toggle back\n- Faux onyx stone framed in silver-toned metal",
            "mannequinImages": [
              {
                "mannequinImage": "Jewelry",
                "src": "JW_BL_1280x1920.jpg",
                "alt": "Black/Silver Basic Jewelry"
              }
            ],
            "colorShade": "Black",
            "publishedDate": 1699627147
          },
          {
            "coatType": "Suit",
            "vent": "Side",
            "keywords": [],
            "webReleaseDate": 1688101200,
            "styleGroup": "Rental",
            "companionStyleCode": "PCT421",
            "marketingTitle": "Black Performance Wedding Suit Coat",
            "colors": [
              "Black"
            ],
            "coatLength": 29.5,
            "fit": "Ultra Slim",
            "styleType": "Coat",
            "price": 190,
            "activeDate": 1684731600,
            "colorHexCode": "#000000",
            "categories": [],
            "companionStylePrice": 330,
            "lapel": "Notch",
            "images": [
              {
                "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
              }
            ],
            "sizeScale": "B",
            "buttons": 2,
            "styleRank": 2,
            "styleFirestoreDocumentId": "Fa6MNKeF1oTFv4A5gzD0",
            "colorShadeId": "Black",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Vest",
                "images": [
                  {
                    "src": "https://www.jimsformalwear.com/images/1280x1920/fullback-vest-black-performance-stretch-F921.jpg",
                    "alt": "Black Performance Vest"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RVS921",
                "marketingTitle": "Black Performance Vest"
              }
            ],
            "collection": "Diamond",
            "designer": "Michael Kors",
            "styleCode": "RCT421",
            "legacyStyleCode": "421",
            "matchingStyles": [
              {
                "docId": "DrSpbZEVmCJdWrhva9cl",
                "styleCode": "RPT921"
              },
              {
                "docId": "DrSpbZEVmCJdWrhva9cl",
                "styleCode": "PVS921"
              }
            ],
            "marketingDescription": "### FEATURES:\n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 29 1/2\" length. \n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
            "fabric": "CoolMax/Sorona/Wool",
            "recommendedStyles": [
              "RPT921"
            ],
            "mannequinImages": [
              {
                "mannequinImage": "Coat",
                "src": "CT_421_1280x1920.png",
                "alt": "Black Performance Wedding Suit Coat"
              }
            ],
            "colorShade": "Black",
            "measurementScale1": "CSTZ",
            "publishedDate": 1697577801,
            "pocket": "Chest"
          },
          {
            "keywords": [
              "white",
              "ivory",
              "black",
              "shirt",
              "fitted",
              "formal shirt"
            ],
            "webReleaseDate": 1688101200,
            "styleGroup": "Rental",
            "companionStyleCode": "PSR96W",
            "marketingTitle": "White Fitted Microfiber Shirt",
            "colors": [
              "White"
            ],
            "collarType": "Laydown",
            "fit": "Fitted",
            "styleType": "Shirt",
            "price": 28,
            "activeDate": 1683522000,
            "colorHexCode": "#FFFFFF",
            "categories": [
              "Shirts"
            ],
            "companionStylePrice": 54,
            "images": [
              {
                "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
                "alt": "White Fitted Microfiber Shirt"
              }
            ],
            "sizeScale": "FIT",
            "styleRank": 1,
            "styleFirestoreDocumentId": "cP3bWqMFOifb38CvszqT",
            "colorShadeId": "White",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "White/Ivory",
            "relatedStyles": [
              {
                "styleType": "Jewelry",
                "images": [
                  {
                    "src": "JW_BG_1280x1920.jpg",
                    "alt": "Black/Gold Premium Jewelry"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RJWBG",
                "marketingTitle": "Black/Gold Premium Jewelry"
              },
              {
                "styleType": "Jewelry",
                "images": [
                  {
                    "src": "JW_BL_1280x1920.jpg",
                    "alt": "Black/Silver Basic Jewelry"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RJWBL",
                "marketingTitle": "Black/Silver Basic Jewelry"
              },
              {
                "styleType": "Jewelry",
                "images": [
                  {
                    "src": "cufflinks-studs-tuxedo-jewelry-BS.jpg",
                    "alt": "Black/Silver Premium Jewelry"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RJWBS",
                "marketingTitle": "Black/Silver Premium Jewelry"
              },
              {
                "styleType": "Jewelry",
                "images": [
                  {
                    "src": "JW_WH_1280x1920.jpg",
                    "alt": "White/Silver Basic Jewelry"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RJWWH",
                "marketingTitle": "White/Silver Basic Jewelry"
              }
            ],
            "collection": "Premium (PRM)",
            "styleCode": "RSR96W",
            "legacyStyleCode": "96W",
            "marketingDescription": "### FEATURES:\n- Non-pleated front with tapered sides\n- Laydown collar, barrel cuff\n- Available in White, Ivory, and Black\n\n### SIZE SCALE:\nBoys' Large - Men's 3XL\n\n### FABRIC:\nPremium Microfiber\n\n### FIT:\nSlim Fit",
            "fabric": "Microfiber",
            "mannequinImages": [
              {
                "mannequinImage": "Shirt",
                "src": "SR_96W_1280x1920.png",
                "alt": "Shirt"
              },
              {
                "mannequinImage": "ShirtExtra",
                "src": "SR_96W_C_1280x1920_extra.png",
                "alt": "Shirt Collar"
              }
            ],
            "colorShade": "White",
            "publishedDate": 1701098610,
            "shirtFront": "Flat Front"
          },
          {
            "keywords": [],
            "webReleaseDate": 1697778000,
            "pattern": "Solid",
            "styleGroup": "Purchase",
            "program": "Expressions",
            "marketingTitle": "Rosewood Expressions Pocket Square",
            "colors": [
              "Rosewood"
            ],
            "styleType": "PocketSquare",
            "price": 10,
            "activeDate": 1684731600,
            "altColors": [
              {
                "colorShade": "Green",
                "colorHexCode": "#730000",
                "styleCode": "PPKPWAR",
                "marketingTitle": "Green Expressions Pocket Square"
              },
              {
                "colorShade": "Aqua",
                "colorHexCode": "#70BCA5",
                "styleCode": "PPKPWAQ",
                "marketingTitle": "Aqua Expressions Pocket Square"
              },
              {
                "colorShade": "Ballet",
                "colorHexCode": "#D2A29E",
                "styleCode": "PPKPWBA",
                "marketingTitle": "Ballet Expressions Pocket Square"
              },
              {
                "colorShade": "Bellini",
                "colorHexCode": "#DAB7A6",
                "styleCode": "PPKPWBE",
                "marketingTitle": "Bellini Expressions Pocket Square"
              },
              {
                "colorShade": "Black",
                "colorHexCode": "#000000",
                "styleCode": "PPKPWBL",
                "marketingTitle": "Black Expressions Pocket Square"
              },
              {
                "colorShade": "Blue Box",
                "colorHexCode": "#75C7CE",
                "styleCode": "PPKPWBB",
                "marketingTitle": "Blue Box Expressions Pocket Square"
              },
              {
                "colorShade": "Blush",
                "colorHexCode": "#B58B88",
                "styleCode": "PPKPWBH",
                "marketingTitle": "Blush Expressions Pocket Square"
              },
              {
                "colorShade": "Brite Pink",
                "colorHexCode": "#DF588A",
                "styleCode": "PPKPWBP",
                "marketingTitle": "Brite Pink Expressions Pocket Square"
              },
              {
                "colorShade": "Buttercup",
                "colorHexCode": "#EDD66A",
                "styleCode": "PPKPWBC",
                "marketingTitle": "Buttercup Expressions Pocket Square"
              },
              {
                "colorShade": "Champagne",
                "colorHexCode": "#A29581",
                "styleCode": "PPKPWCP",
                "marketingTitle": "Champagne Expressions Pocket Square"
              },
              {
                "colorShade": "Charcoal",
                "colorHexCode": "#3E3E3E",
                "styleCode": "PPKPWCA",
                "marketingTitle": "Charcoal Expressions Pocket Square"
              },
              {
                "colorShade": "Cherry",
                "colorHexCode": "#7F212E",
                "styleCode": "PPKPWCY",
                "marketingTitle": "Cherry Expressions Pocket Square"
              },
              {
                "colorShade": "Chocolate",
                "colorHexCode": "#4E3D30",
                "styleCode": "PPKPWCH",
                "marketingTitle": "Chocolate Expressions Pocket Square"
              },
              {
                "colorShade": "Cinnamon",
                "colorHexCode": "#79342A",
                "styleCode": "PPKPWCI",
                "marketingTitle": "Cinnamon Expressions Pocket Square"
              },
              {
                "colorShade": "Cobalt",
                "colorHexCode": "#2d406f",
                "styleCode": "PPKPWCB",
                "marketingTitle": "Cobalt Expressions Pocket Square"
              },
              {
                "colorShade": "Coral",
                "colorHexCode": "#F6A4A3",
                "styleCode": "PPKPWCO",
                "marketingTitle": "Coral Expressions Pocket Square"
              },
              {
                "colorShade": "Cornflower",
                "colorHexCode": "#647EB0",
                "styleCode": "PPKPWCF",
                "marketingTitle": "Cornflower Expressions Pocket Square"
              },
              {
                "colorShade": "Cranberry",
                "colorHexCode": "#490B14",
                "styleCode": "PPKPWCE",
                "marketingTitle": "Cranberry Expressions Pocket Square"
              },
              {
                "colorShade": "Dark Coral",
                "colorHexCode": "#E17A74",
                "styleCode": "PPKPWDC",
                "marketingTitle": "Dark Coral Expressions Pocket Square"
              },
              {
                "colorShade": "Dusty Blue",
                "colorHexCode": "#8B98A7",
                "styleCode": "PPKPWDB",
                "marketingTitle": "Dusty Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Dusty Rose",
                "colorHexCode": "#E3D0D7",
                "styleCode": "PPKPWDR",
                "marketingTitle": "Dusty Rose Expressions Pocket Square"
              },
              {
                "colorShade": "Emerald",
                "colorHexCode": "#05493B",
                "styleCode": "PPKPWEM",
                "marketingTitle": "Emerald Expressions Pocket Square"
              },
              {
                "colorShade": "Evergreen/Moss",
                "colorHexCode": "#757B63",
                "styleCode": "PPKPWEV",
                "marketingTitle": "Evergreen/Moss Expressions Pocket Square"
              },
              {
                "colorShade": "Ferrari Red",
                "colorHexCode": "#A60A20",
                "styleCode": "PPKPWFR",
                "marketingTitle": "Ferrari Red Expressions Pocket Square"
              },
              {
                "colorShade": "First Blush",
                "colorHexCode": "#B69797",
                "styleCode": "PPKPWFB",
                "marketingTitle": "First Blush Expressions Pocket Square"
              },
              {
                "colorShade": "Forest",
                "colorHexCode": "#2F4437",
                "styleCode": "PPKPWFO",
                "marketingTitle": "Forest Expressions Pocket Square"
              },
              {
                "colorShade": "Gold",
                "colorHexCode": "#8b7256",
                "styleCode": "PPKPWGO",
                "marketingTitle": "Gold Expressions Pocket Square"
              },
              {
                "colorShade": "Gold Metallic",
                "colorHexCode": "#C0B065",
                "styleCode": "PPKPWMG",
                "marketingTitle": "Gold Metallic Expressions Pocket Square"
              },
              {
                "colorShade": "Golden",
                "colorHexCode": "#A7956D",
                "styleCode": "PPKPWGL",
                "marketingTitle": "Golden Expressions Pocket Square"
              },
              {
                "colorShade": "Hunter Green",
                "colorHexCode": "#133B2F",
                "styleCode": "PPKPWHG",
                "marketingTitle": "Hunter Green Expressions Pocket Square"
              },
              {
                "colorShade": "Ivory",
                "colorHexCode": "#EEE9DF",
                "styleCode": "PPKPWIV",
                "marketingTitle": "Ivory Expressions Pocket Square"
              },
              {
                "colorShade": "Lavender",
                "colorHexCode": "#938BA8",
                "styleCode": "PPKPWLA",
                "marketingTitle": "Lavender Expressions Pocket Square"
              },
              {
                "colorShade": "Lite Blue",
                "colorHexCode": "#9FBBD7",
                "styleCode": "PPKPWLB",
                "marketingTitle": "Lite Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Lite Watermelon",
                "colorHexCode": "#D5487D",
                "styleCode": "PPKPWLW",
                "marketingTitle": "Lite Watermelon Expressions Pocket Square"
              },
              {
                "colorShade": "Malibu",
                "colorHexCode": "#369FC4",
                "styleCode": "PPKPWML",
                "marketingTitle": "Malibu Expressions Pocket Square"
              },
              {
                "colorShade": "Mint",
                "colorHexCode": "#AAD6BA",
                "styleCode": "PPKPWMN",
                "marketingTitle": "Mint Expressions Pocket Square"
              },
              {
                "colorShade": "Navy",
                "colorHexCode": "#25334D",
                "styleCode": "PPKPWNA",
                "marketingTitle": "Navy Expressions Pocket Square"
              },
              {
                "colorShade": "Nude",
                "colorHexCode": "#C7B097",
                "styleCode": "PPKPWNU",
                "marketingTitle": "Nude Expressions Pocket Square"
              },
              {
                "colorShade": "Pink Rose Gold",
                "colorHexCode": "#D4B1AA",
                "styleCode": "PPKPWPR",
                "marketingTitle": "Pink Rose Gold Expressions Pocket Square"
              },
              {
                "colorShade": "Platinum",
                "colorHexCode": "#A4A7AD",
                "styleCode": "PPKPWPT",
                "marketingTitle": "Platinum Expressions Pocket Square"
              },
              {
                "colorShade": "Plum",
                "colorHexCode": "#381d38",
                "styleCode": "PPKPWPL",
                "marketingTitle": "Plum Expressions Pocket Square"
              },
              {
                "colorShade": "Quartz",
                "colorHexCode": "#B69797",
                "styleCode": "PPKPWQZ",
                "marketingTitle": "Quartz Expressions Pocket Square"
              },
              {
                "colorShade": "Raspberry",
                "colorHexCode": "#772E59",
                "styleCode": "PPKPWRA",
                "marketingTitle": "Raspberry Expressions Pocket Square"
              },
              {
                "colorShade": "Regency",
                "colorHexCode": "#33215C",
                "styleCode": "PPKPWRG",
                "marketingTitle": "Regency Expressions Pocket Square"
              },
              {
                "colorShade": "Rose Gold",
                "colorHexCode": "#99837F",
                "styleCode": "PPKPWRS",
                "marketingTitle": "Rose Gold Expressions Pocket Square"
              },
              {
                "colorShade": "Rose Petal",
                "colorHexCode": "#E8B4C1",
                "styleCode": "PPKPWRP",
                "marketingTitle": "Rose Petal Expressions Pocket Square"
              },
              {
                "colorShade": "Rosewood",
                "colorHexCode": "#5f3237",
                "styleCode": "PPKPWRW",
                "marketingTitle": "Rosewood Expressions Pocket Square"
              },
              {
                "colorShade": "Royal Blue",
                "colorHexCode": "#21508F",
                "styleCode": "PPKPWRO",
                "marketingTitle": "Royal Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Sage",
                "colorHexCode": "#B3BBA2",
                "styleCode": "PPKPWSE",
                "marketingTitle": "Sage Expressions Pocket Square"
              },
              {
                "colorShade": "Silver",
                "colorHexCode": "#A7AEB8",
                "styleCode": "PPKPWSI",
                "marketingTitle": "Silver Expressions Pocket Square"
              },
              {
                "colorShade": "Silver Metallic",
                "colorHexCode": "#C8C6C6",
                "styleCode": "PPKPWMS",
                "marketingTitle": "Silver Metallic Expressions Pocket Square"
              },
              {
                "colorShade": "Slate Blue",
                "colorHexCode": "#3F5167",
                "styleCode": "PPKPWSL",
                "marketingTitle": "Slate Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Spice",
                "colorHexCode": "#9B4D3E",
                "styleCode": "PPKPWSC",
                "marketingTitle": "Spice Expressions Pocket Square"
              },
              {
                "colorShade": "Taupe",
                "colorHexCode": "#CFC5C3",
                "styleCode": "PPKPWTP",
                "marketingTitle": "Taupe Expressions Pocket Square"
              },
              {
                "colorShade": "Teal",
                "colorHexCode": "#057085",
                "styleCode": "PPKPWTE",
                "marketingTitle": "Teal Expressions Pocket Square"
              },
              {
                "colorShade": "Tiffany Blue",
                "colorHexCode": "#90D2D5",
                "styleCode": "PPKPWTB",
                "marketingTitle": "Tiffany Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Turquoise",
                "colorHexCode": "#5BACB3",
                "styleCode": "PPKPWTU",
                "marketingTitle": "Turquoise Expressions Pocket Square"
              },
              {
                "colorShade": "Viola",
                "colorHexCode": "#5C0A5A",
                "styleCode": "PPKPWVA",
                "marketingTitle": "Viola Expressions Pocket Square"
              },
              {
                "colorShade": "Watermelon",
                "colorHexCode": "#AC1848",
                "styleCode": "PPKPWWA",
                "marketingTitle": "Watermelon Expressions Pocket Square"
              },
              {
                "colorShade": "White",
                "colorHexCode": "#FFFFFF",
                "styleCode": "PPKPWWH",
                "marketingTitle": "White Expressions Pocket Square"
              },
              {
                "colorShade": "Wine",
                "colorHexCode": "#54101B",
                "styleCode": "PPKPWWI",
                "marketingTitle": "Wine Expressions Pocket Square"
              }
            ],
            "colorHexCode": "#5f3237",
            "categories": [
              "Accessories"
            ],
            "images": [
              {
                "src": "PK_PWRW_1280x1920.jpg",
                "alt": "Rosewood Expressions Solid Pocket Square"
              }
            ],
            "styleRank": 4,
            "styleFirestoreDocumentId": "7udSrg2Iv9KxWMIAE5VX",
            "colorShadeId": "Rosewood",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Pink",
            "relatedStyles": [
              {
                "styleType": "Tie",
                "images": [
                  {
                    "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
                    "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RTIWXRW",
                "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
              }
            ],
            "styleCode": "PPKPWRW",
            "legacyStyleCode": "PWRW",
            "matchingStyles": [
              {
                "docId": "i393vAhanT6qXMCMWJDn",
                "styleCode": "RTIWXRW"
              }
            ],
            "marketingDescription": "### FEATURES:\n- Hand-sewn edges\n- 9\" x 9\"\n- Polyester\n- Hand wash",
            "mannequinImages": [
              {
                "mannequinImage": "PocketSquare",
                "src": "PK_PWRW_1280x1920.jpg",
                "alt": "Rosewood Expressions Solid Pocket Square"
              }
            ],
            "colorShade": "Rosewood",
            "publishedDate": 1697835035
          },
          {
            "keywords": [],
            "webReleaseDate": 1688533200,
            "styleGroup": "Rental",
            "companionStyleCode": "PSOBXS",
            "marketingTitle": "Black Oxford Shoe",
            "colors": [
              "Black"
            ],
            "styleType": "Shoes",
            "shoeType": "Oxford",
            "price": 30,
            "activeDate": 1684731600,
            "altColors": [
              {
                "colorShade": "Black",
                "colorHexCode": "#000000",
                "styleCode": "RSOBDS",
                "marketingTitle": "Black Dunbar Shoe"
              },
              {
                "colorShade": "Black",
                "colorHexCode": "#000000",
                "styleCode": "RSOBXS",
                "marketingTitle": "Black Oxford Shoe"
              },
              {
                "colorShade": "Chestnut",
                "colorHexCode": "#91533d",
                "styleCode": "RSOCOP",
                "marketingTitle": "Chestnut Oxford Plain Toe Shoe"
              },
              {
                "colorShade": "Cognac",
                "colorHexCode": "#6C423D",
                "styleCode": "RSOCXS",
                "marketingTitle": "Cognac Oxford Shoe"
              },
              {
                "colorShade": "White",
                "colorHexCode": "#FFFFFF",
                "styleCode": "RSOWAS",
                "marketingTitle": "White Allegro Shoe"
              }
            ],
            "colorHexCode": "#000000",
            "categories": [
              "Shoes"
            ],
            "companionStylePrice": 79,
            "images": [
              {
                "src": "SO-BXS-1280x1920.jpg",
                "alt": "Black Oxford Shoe"
              }
            ],
            "sizeScale": "C",
            "styleRank": 3,
            "styleFirestoreDocumentId": "O0Xb8rmylCTlFstqKJCu",
            "colorShadeId": "Black",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Socks",
                "images": [
                  {
                    "src": "colored-socks-royal-blue-XSRO.jpg",
                    "alt": "Royal Blue Socks"
                  }
                ],
                "styleGroup": "Purchase",
                "styleCode": "PHSXSRO",
                "marketingTitle": "Royal Blue Socks"
              },
              {
                "styleType": "Socks",
                "images": [
                  {
                    "src": "patterned-socks-wine-argyle-XPWG.jpg",
                    "alt": "Wine Grey Argyle Socks"
                  }
                ],
                "styleGroup": "Purchase",
                "styleCode": "PHSXPWG",
                "marketingTitle": "Wine Grey Argyle Socks"
              },
              {
                "styleType": "Socks",
                "images": [
                  {
                    "src": "colored-socks-black-XBLK.jpg",
                    "alt": "Black Socks"
                  }
                ],
                "styleGroup": "Purchase",
                "styleCode": "PHSXBLK",
                "marketingTitle": "Black Socks"
              }
            ],
            "styleCode": "RSOBXS",
            "legacyStyleCode": "BXS",
            "material": "Leather",
            "marketingDescription": "### FEATURES:\n- Synthetic Leather\n- Cap toe, lace up closure\n- Premium cushioned footbed and flex construction \n\n### SIZE SCALE:\n- Men's Medium Width (C/D): 6 - 18 (half sizes up to 11.5)\n- Men's Wide Width (E/EE): 7-18 (half sizes up to 11.5)\n- Boys' Medium Width: 7 - 5.5",
            "mannequinImages": [
              {
                "mannequinImage": "Shoes",
                "src": "SO-BXS-1280x1920.jpg",
                "alt": "Black Oxford Shoe"
              }
            ],
            "colorShade": "Black",
            "measurementScale1": "BASIC",
            "publishedDate": 1701715960,
            "measurementScale2": "BASIC"
          },
          {
            "keywords": [],
            "webReleaseDate": 1697432400,
            "pattern": "Striped",
            "styleGroup": "Rental",
            "companionStyleCode": "PTIWXRW",
            "program": "Expressions",
            "marketingTitle": "Rosewood Striped Expressions Windsor Tie",
            "colors": [
              "Rosewood"
            ],
            "styleType": "Tie",
            "price": 17,
            "activeDate": 1684731600,
            "altColors": [
              {
                "colorShade": "Apple Red",
                "colorHexCode": "#730000",
                "styleCode": "RTIWXAR",
                "marketingTitle": "Apple Red Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Aqua",
                "colorHexCode": "#70BCA5",
                "styleCode": "RTIWXAQ",
                "marketingTitle": "Aqua Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Ballet",
                "colorHexCode": "#D2A29E",
                "styleCode": "RTIWXBA",
                "marketingTitle": "Ballet Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Bellini",
                "colorHexCode": "#DAB7A6",
                "styleCode": "RTIWXBE",
                "marketingTitle": "Bellini Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Black",
                "colorHexCode": "#000000",
                "styleCode": "RTIWXBL",
                "marketingTitle": "Black Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Blue Box",
                "colorHexCode": "#75C7CE",
                "styleCode": "RTIWXBB",
                "marketingTitle": "Blue Box Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Blush",
                "colorHexCode": "#B58B88",
                "styleCode": "RTIWXBH",
                "marketingTitle": "Blush Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Brite Pink",
                "colorHexCode": "#DF588A",
                "styleCode": "RTIWXBP",
                "marketingTitle": "Brite Pink Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Buttercup",
                "colorHexCode": "#EDD66A",
                "styleCode": "RTIWXBC",
                "marketingTitle": "Buttercup Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Champagne",
                "colorHexCode": "#A29581",
                "styleCode": "RTIWXCP",
                "marketingTitle": "Champagne Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Charcoal",
                "colorHexCode": "#3E3E3E",
                "styleCode": "RTIWXCA",
                "marketingTitle": "Charcoal Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cherry",
                "colorHexCode": "#7F212E",
                "styleCode": "RTIWXCY",
                "marketingTitle": "Cherry Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Chocolate",
                "colorHexCode": "#4E3D30",
                "styleCode": "RTIWXCH",
                "marketingTitle": "Chocolate Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cinnamon",
                "colorHexCode": "#79342A",
                "styleCode": "RTIWXCI",
                "marketingTitle": "Cinnamon Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cobalt",
                "colorHexCode": "#2d406f",
                "styleCode": "RTIWXCB",
                "marketingTitle": "Cobalt Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Coral",
                "colorHexCode": "#F6A4A3",
                "styleCode": "RTIWXCO",
                "marketingTitle": "Coral Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cornflower",
                "colorHexCode": "#647EB0",
                "styleCode": "RTIWXCF",
                "marketingTitle": "Cornflower Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cranberry",
                "colorHexCode": "#490B14",
                "styleCode": "RTIWXCE",
                "marketingTitle": "Cranberry Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Dark Coral",
                "colorHexCode": "#E17A74",
                "styleCode": "RTIWXDC",
                "marketingTitle": "Dark Coral Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Dusty Blue",
                "colorHexCode": "#8B98A7",
                "styleCode": "RTIWXDB",
                "marketingTitle": "Dusty Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Dusty Rose",
                "colorHexCode": "#E3D0D7",
                "styleCode": "RTIWXDR",
                "marketingTitle": "Dusty Rose Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Emerald",
                "colorHexCode": "#05493B",
                "styleCode": "RTIWXEM",
                "marketingTitle": "Emerald Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Evergreen/Moss",
                "colorHexCode": "#757B63",
                "styleCode": "RTIWXEV",
                "marketingTitle": "Evergreen/Moss Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Ferrari Red",
                "colorHexCode": "#A60A20",
                "styleCode": "RTIWXFR",
                "marketingTitle": "Ferrari Red Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "First Blush",
                "colorHexCode": "#B69797",
                "styleCode": "RTIWXFB",
                "marketingTitle": "First Blush Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Forest",
                "colorHexCode": "#2F4437",
                "styleCode": "RTIWXFO",
                "marketingTitle": "Forest Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Gold",
                "colorHexCode": "#8b7256",
                "styleCode": "RTIWXGO",
                "marketingTitle": "Gold Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Gold Metallic",
                "colorHexCode": "#C0B065",
                "styleCode": "RTIWXMG",
                "marketingTitle": "Gold Metallic Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Golden",
                "colorHexCode": "#A7956D",
                "styleCode": "RTIWXGL",
                "marketingTitle": "Golden Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Hunter Green",
                "colorHexCode": "#133B2F",
                "styleCode": "RTIWXHG",
                "marketingTitle": "Hunter Green Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Ivory",
                "colorHexCode": "#EEE9DF",
                "styleCode": "RTIWXIV",
                "marketingTitle": "Ivory Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Lavender",
                "colorHexCode": "#938BA8",
                "styleCode": "RTIWXLA",
                "marketingTitle": "Lavender Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Lite Blue",
                "colorHexCode": "#9FBBD7",
                "styleCode": "RTIWXLB",
                "marketingTitle": "Lite Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Lite Watermelon",
                "colorHexCode": "#D5487D",
                "styleCode": "RTIWXLW",
                "marketingTitle": "Lite Watermelon Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Malibu",
                "colorHexCode": "#369FC4",
                "styleCode": "RTIWXML",
                "marketingTitle": "Malibu Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Mint",
                "colorHexCode": "#AAD6BA",
                "styleCode": "RTIWXMN",
                "marketingTitle": "Mint Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Navy",
                "colorHexCode": "#25334D",
                "styleCode": "RTIWXNA",
                "marketingTitle": "Navy Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Nude",
                "colorHexCode": "#C7B097",
                "styleCode": "RTIWXNU",
                "marketingTitle": "Nude Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Pink Rose Gold",
                "colorHexCode": "#D4B1AA",
                "styleCode": "RTIWXPR",
                "marketingTitle": "Pink Rose Gold Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Platinum",
                "colorHexCode": "#A4A7AD",
                "styleCode": "RTIWXPT",
                "marketingTitle": "Platinum Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Plum",
                "colorHexCode": "#381d38",
                "styleCode": "RTIWXPL",
                "marketingTitle": "Plum Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Quartz",
                "colorHexCode": "#B69797",
                "styleCode": "RTIWXQZ",
                "marketingTitle": "Quartz Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Raspberry",
                "colorHexCode": "#772E59",
                "styleCode": "RTIWXRA",
                "marketingTitle": "Raspberry Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Regency",
                "colorHexCode": "#33215C",
                "styleCode": "RTIWXRG",
                "marketingTitle": "Regency Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Rose Gold",
                "colorHexCode": "#99837F",
                "styleCode": "RTIWXRS",
                "marketingTitle": "Rose Gold Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Rose Petal",
                "colorHexCode": "#E8B4C1",
                "styleCode": "RTIWXRP",
                "marketingTitle": "Rose Petal Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Rosewood",
                "colorHexCode": "#5f3237",
                "styleCode": "RTIWXRW",
                "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Royal Blue",
                "colorHexCode": "#21508F",
                "styleCode": "RTIWXRO",
                "marketingTitle": "Royal Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Sage",
                "colorHexCode": "#B3BBA2",
                "styleCode": "RTIWXSE",
                "marketingTitle": "Sage Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Silver",
                "colorHexCode": "#A7AEB8",
                "styleCode": "RTIWXSI",
                "marketingTitle": "Silver Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Silver Metallic",
                "colorHexCode": "#C8C6C6",
                "styleCode": "RTIWXMS",
                "marketingTitle": "Silver Metallic Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Slate Blue",
                "colorHexCode": "#3F5167",
                "styleCode": "RTIWXSL",
                "marketingTitle": "Slate Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Spice",
                "colorHexCode": "#9B4D3E",
                "styleCode": "RTIWXSC",
                "marketingTitle": "Spice Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Taupe",
                "colorHexCode": "#CFC5C3",
                "styleCode": "RTIWXTP",
                "marketingTitle": "Taupe Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Teal",
                "colorHexCode": "#057085",
                "styleCode": "RTIWXTE",
                "marketingTitle": "Teal Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Tiffany Blue",
                "colorHexCode": "#90D2D5",
                "styleCode": "RTIWXTB",
                "marketingTitle": "Tiffany Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Turquoise",
                "colorHexCode": "#5BACB3",
                "styleCode": "RTIWXTU",
                "marketingTitle": "Turquoise Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Viola",
                "colorHexCode": "#5C0A5A",
                "styleCode": "RTIWXVA",
                "marketingTitle": "Viola Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Watermelon",
                "colorHexCode": "#AC1848",
                "styleCode": "RTIWXWA",
                "marketingTitle": "Watermelon Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "White",
                "colorHexCode": "#FFFFFF",
                "styleCode": "RTIWXWH",
                "marketingTitle": "White Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Wine",
                "colorHexCode": "#54101B",
                "styleCode": "RTIWXWI",
                "marketingTitle": "Wine Striped Expressions Windsor Tie"
              }
            ],
            "colorHexCode": "#5f3237",
            "categories": [
              "Ties"
            ],
            "tieType": "Windsor",
            "companionStylePrice": 39,
            "images": [
              {
                "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
                "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
              },
              {
                "src": "windsor-tie-rose-petal-stripe-WXRP.jpg",
                "alt": "this is a test"
              },
              {
                "src": "windsor-tie-black-stripe-WXBL.jpg",
                "alt": "test 2"
              }
            ],
            "sizeScale": "A",
            "styleRank": 157,
            "styleFirestoreDocumentId": "b1EKl2zr3h3ByJqe5Rp4",
            "colorShadeId": "Rosewood",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Pink",
            "relatedStyles": [
              {
                "styleType": "PocketSquare",
                "images": [
                  {
                    "src": "PK_PWRW_1280x1920.jpg",
                    "alt": "Rosewood Expressions Solid Pocket Square"
                  }
                ],
                "styleGroup": "Purchase",
                "styleCode": "PPKPWRW",
                "marketingTitle": "Rosewood Expressions Pocket Square"
              },
              {
                "styleType": "Vest",
                "images": [
                  {
                    "src": "fullback-vest-black-performance-stretch-F921.jpg",
                    "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RVS921",
                "marketingTitle": "Black Performance Vest"
              }
            ],
            "collection": "Premium (PRM)",
            "styleCode": "RTIWXRW",
            "legacyStyleCode": "WXRW",
            "matchingStyles": [
              {
                "docId": "i393vAhanT6qXMCMWJDn",
                "styleCode": "PPKPWRW"
              }
            ],
            "marketingDescription": "### FEATURES:\n- Striped colored windsor tie\n- Pre-tied, expandable neck\n- Coordinates with any Matching Wool Vest\n- Matching solid pocket square is available\n\n### SIZE SCALE:\n - Men's and Boys' Sizes\n\n### FABRIC:\n - Polyester",
            "fabric": "Polyester",
            "mannequinImages": [
              {
                "mannequinImage": "WindsorTie",
                "src": "TI_WXRW_1280x1920.png",
                "alt": "Rosewood Expressions Striped Windsor Tie"
              }
            ],
            "colorShade": "Rosewood",
            "measurementScale1": "A",
            "publishedDate": 1711135542
          }
        ],
        "id": "24cb9fc8-a211-4be6-b2e9-f6529f4942e4",
        "title": "Look 2"
      },
    }
  ],
  "admins": [
    {
      "firstName": "The",
      "lastName": "Organizer",
      "role": "",
      "isOrganizer": true,
      "inviteAccepted": 1719415303,
      "phone": "0000000000",
      "name": "The Organizer",
      "id": "960c03fb-5ff7-4461-acb1-a1f0b74e98da",
      "email": "nickstefanic@jimsfw.com"
    },
    {
      "firstName": "First",
      "lastName": "Member",
      "role": "",
      "isOrganizer": false,
      "inviteAccepted": 1719415453,
      "phone": "0000000000",
      "name": "",
      "id": "a508f5ab-2bb2-476c-b982-2be9a9cab6cb",
      "email": "nstefanicjfw@gmail.com"
    }
  ],
  "update_time": {
    "_seconds": 1719497497,
    "_nanoseconds": 192000000
  },
  "eventName": "Nick EXT-2595 (4)a",
  "adminEmails": [
    "nickstefanic@jimsfw.com",
    "nstefanicjfw@gmail.com"
  ],
  "memberEmails": [
    "nstefanicjfw@gmail.com"
  ]
}

const currentEventTest: Event = {
  "saved": true,
  "dealerId": "TA102",
  "eventType": "Wedding",
  // "commissionType": "REFERRAL",
  "lastUpdated": "1719415154963",
  "id": "DSlbsnPCeHvvlP31Guyf",
  "eventDate": 1727409622,
  "createDate": 1719415155,
  "dealerInfo": {
    "transferredByDealer": {
      "name": "Nick S",
      "userId": "sl1lWXrPkXW8NmD8DfgIIQB0Lvu2",
      "email": "nstefanic@gmail.com"
    },
    "createdByDealer": {
      "name": "Nick S",
      "userId": "sl1lWXrPkXW8NmD8DfgIIQB0Lvu2",
      "email": "nstefanic@gmail.com"
    },
    "dealerId": "TA102"
  },
  "createdByUserId": "KuicqhN13Ker3xiYAZ9mQIS0uty2",
  "looks": [
    {
      "ensembleCode": "421",
      "price": 192,
      "styles": [
        {
          "keywords": [],
          "webReleaseDate": 1688014802,
          "styleGroup": "Rental",
          "companionStyleCode": "PPT921",
          "marketingTitle": "Black Performance Legacy Pants",
          "colors": [
            "Black"
          ],
          "fit": "Ultra Slim",
          "styleType": "Pant",
          "price": 48,
          "activeDate": 1682571600,
          "colorHexCode": "#000000",
          "categories": [],
          "companionStylePrice": 152,
          "images": [
            {
              "src": "black-performance-legacy-pants-921.jpg",
              "alt": "Black Performance Legacy Pants"
            }
          ],
          "sizeScale": "FLAT2",
          "styleRank": 1,
          "styleFirestoreDocumentId": "CeTKEZjFCizIms0q7gr5",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                  "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT421",
              "marketingTitle": "Black Performance Wedding Suit Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                  "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT921",
              "marketingTitle": "Black Legacy Tuxedo Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                  "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT981",
              "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
            }
          ],
          "designer": "Michael Kors",
          "styleCode": "RPT921",
          "legacyStyleCode": "921",
          "matchingStyles": [
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "PVS921"
            },
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "RCT421"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RCT981"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RVS921"
            },
            {
              "docId": "zg6xNgr4zbkkvXXAZIFO",
              "styleCode": "PVS921"
            },
            {
              "docId": "zg6xNgr4zbkkvXXAZIFO",
              "styleCode": "RCT921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Available to rent or buy\n- Flat front with adjustable waist\n- Belt loops and buttons to accommodate suspenders\n- Coordinates with Black Performance Stretch Suits and Tuxedo\n\n### SIZE SCALE:\n921 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
          "fabric": "CoolMax/Sorona/Wool",
          "suspenderButtons": true,
          "mannequinImages": [
            {
              "mannequinImage": "Pant",
              "src": "PT_921_1280x1920.png",
              "alt": "Black Performance Legacy Pants"
            }
          ],
          "colorShade": "Black",
          "waist": "Adjustable",
          "beltLoops": true,
          "publishedDate": 1698107750
        },
        {
          "keywords": [
            "belt",
            "reversible",
            "black",
            "brown"
          ],
          "webReleaseDate": 1683522000,
          "styleGroup": "Purchase",
          "marketingTitle": "Black/Brown Reversible Belt",
          "colors": [
            "Black/Brown"
          ],
          "styleType": "Miscellaneous",
          "price": 24,
          "activeDate": 1683522000,
          "colorHexCode": "#000000",
          "categories": [
            "Accessories"
          ],
          "images": [
            {
              "src": "belt-black-tan-reversible-NBLT.jpg",
              "alt": "Black/Brown Reversible Belt"
            }
          ],
          "styleRank": 1,
          "styleFirestoreDocumentId": "jMgaN4ySnwR4M7G8I1MQ",
          "colorShadeId": "BlackBrown",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Ottawa",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "relatedStyles": [],
          "colorFamily": "Black",
          "collection": "Resale",
          "styleCode": "PMSBLT",
          "miscellaneousType": "Belt",
          "legacyStyleCode": "NBLT",
          "marketingDescription": "### FEATURES:\n- Synthetic leather\n- Reversible black & brown\n- Platinum burnished buckle, stitched edges, 3.5 cm wide\n\n### SIZE SCALE:\n- XS: 21-24\n- S: 25-28\n- M: 29-32\n- L: 33-36\n- XL: 37-40\n- 2X: 41-44\n- 3X: 45-28",
          "fabric": "Leather",
          "mannequinImages": [
            {
              "mannequinImage": "Miscellaneous",
              "src": "belt-RED-tan-reversible-NBLT.jpg",
              "alt": "Black/Brown Reversible Belt"
            }
          ],
          "colorShade": "Black/Brown",
          "publishedDate": 1698108942
        },
        {
          "vestType": "Fullback",
          "keywords": [],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "vestBack": "Matching",
          "companionStyleCode": "PVS921",
          "program": "Matching",
          "marketingTitle": "Black Performance Vest",
          "colors": [
            "Black"
          ],
          "styleType": "Vest",
          "price": 32,
          "activeDate": 1684731600,
          "colorHexCode": "#000000",
          "categories": [
            "VestsAndCummerbunds"
          ],
          "companionStylePrice": 79,
          "images": [
            {
              "src": "fullback-vest-black-performance-stretch-F921.jpg",
              "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
            }
          ],
          "sizeScale": "VST16",
          "buttons": 4,
          "styleRank": 1,
          "styleFirestoreDocumentId": "2Zg4MDqr3PPnsAOSaAKJ",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                  "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT921",
              "marketingTitle": "Black Legacy Tuxedo Coat"
            },
            {
              "styleType": "Pant",
              "images": [
                {
                  "src": "black-performance-legacy-pants-921.jpg",
                  "alt": "Black Performance Legacy Pants"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RPT921",
              "marketingTitle": "Black Performance Legacy Pants"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                  "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT421",
              "marketingTitle": "Black Performance Wedding Suit Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                  "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT981",
              "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
            }
          ],
          "designer": "Michael Kors",
          "styleCode": "RVS921",
          "legacyStyleCode": "F921",
          "matchingStyles": [
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RCT981"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RPT921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- 4-button\n- Adjustable back\n- Darted pockets\n- Matching colored back\n\n### SIZE SCALE:\n- Boys' Small - Men's 5XA\n\n### FABRIC:\n- Polyester",
          "fabric": "CoolMax/Sorona/Wool",
          "mannequinImages": [
            {
              "mannequinImage": "Vest",
              "src": "VS_F921_1280x1920.png",
              "alt": "Michael Kors Black Performance Stretch Wedding Vest"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "VST18",
          "publishedDate": 1698428370,
          "pocket": "Darted"
        },
        {
          "keywords": [
            "cufflinks",
            "studs",
            "jewlry",
            "silver"
          ],
          "webReleaseDate": 1694667600,
          "styleGroup": "Rental",
          "marketingTitle": "Black/Silver Basic Jewelry",
          "colors": [
            "Black"
          ],
          "styleType": "Jewelry",
          "price": 2,
          "activeDate": 1194242400,
          "colorHexCode": "#000000",
          "finish": "Silver",
          "categories": [
            "CufflinksAndStuds"
          ],
          "images": [
            {
              "src": "JW_BL_1280x1920.jpg",
              "alt": "Black/Silver Basic Jewelry"
            }
          ],
          "styleRank": 1,
          "styleFirestoreDocumentId": "JZaae4iAZVssS3k3E0nE",
          "colorShadeId": "Black",
          "jewelryType": "Full Set",
          "warehouses": [
            "Trenton",
            "Anderson",
            "Atlanta",
            "Seguin",
            "Visalia",
            "Salina",
            "Richmond"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Shirt",
              "images": [
                {
                  "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
                  "alt": "White Fitted Microfiber Shirt"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RSR96W",
              "marketingTitle": "White Fitted Microfiber Shirt"
            }
          ],
          "collection": "Basic",
          "styleCode": "RJWBL",
          "legacyStyleCode": "BL",
          "marketingDescription": "### FEATURES:\n- Set includes 2 cufflinks and 4 shirt studs\n- Toggle back\n- Faux onyx stone framed in silver-toned metal",
          "mannequinImages": [
            {
              "mannequinImage": "Jewelry",
              "src": "JW_BL_1280x1920.jpg",
              "alt": "Black/Silver Basic Jewelry"
            }
          ],
          "colorShade": "Black",
          "publishedDate": 1699627147
        },
        {
          "coatType": "Suit",
          "vent": "Side",
          "keywords": [],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "companionStyleCode": "PCT421",
          "marketingTitle": "Black Performance Wedding Suit Coat",
          "colors": [
            "Black"
          ],
          "coatLength": 29.5,
          "fit": "Ultra Slim",
          "styleType": "Coat",
          "price": 190,
          "activeDate": 1684731600,
          "colorHexCode": "#000000",
          "categories": [],
          "companionStylePrice": 330,
          "lapel": "Notch",
          "images": [
            {
              "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
              "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
            }
          ],
          "sizeScale": "B",
          "buttons": 2,
          "styleRank": 2,
          "styleFirestoreDocumentId": "Fa6MNKeF1oTFv4A5gzD0",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "https://www.jimsformalwear.com/images/1280x1920/fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "Black Performance Vest"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            }
          ],
          "collection": "Diamond",
          "designer": "Michael Kors",
          "styleCode": "RCT421",
          "legacyStyleCode": "421",
          "matchingStyles": [
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "RPT921"
            },
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "PVS921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 29 1/2\" length. \n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
          "fabric": "CoolMax/Sorona/Wool",
          "recommendedStyles": [
            "RPT921"
          ],
          "mannequinImages": [
            {
              "mannequinImage": "Coat",
              "src": "CT_421_1280x1920.png",
              "alt": "Black Performance Wedding Suit Coat"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "CSTZ",
          "publishedDate": 1697577801,
          "pocket": "Chest"
        },
        {
          "keywords": [
            "white",
            "ivory",
            "black",
            "shirt",
            "fitted",
            "formal shirt"
          ],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "companionStyleCode": "PSR96W",
          "marketingTitle": "White Fitted Microfiber Shirt",
          "colors": [
            "White"
          ],
          "collarType": "Laydown",
          "fit": "Fitted",
          "styleType": "Shirt",
          "price": 28,
          "activeDate": 1683522000,
          "colorHexCode": "#FFFFFF",
          "categories": [
            "Shirts"
          ],
          "companionStylePrice": 54,
          "images": [
            {
              "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
              "alt": "White Fitted Microfiber Shirt"
            }
          ],
          "sizeScale": "FIT",
          "styleRank": 1,
          "styleFirestoreDocumentId": "cP3bWqMFOifb38CvszqT",
          "colorShadeId": "White",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "White/Ivory",
          "relatedStyles": [
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_BG_1280x1920.jpg",
                  "alt": "Black/Gold Premium Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBG",
              "marketingTitle": "Black/Gold Premium Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_BL_1280x1920.jpg",
                  "alt": "Black/Silver Basic Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBL",
              "marketingTitle": "Black/Silver Basic Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "cufflinks-studs-tuxedo-jewelry-BS.jpg",
                  "alt": "Black/Silver Premium Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBS",
              "marketingTitle": "Black/Silver Premium Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_WH_1280x1920.jpg",
                  "alt": "White/Silver Basic Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWWH",
              "marketingTitle": "White/Silver Basic Jewelry"
            }
          ],
          "collection": "Premium (PRM)",
          "styleCode": "RSR96W",
          "legacyStyleCode": "96W",
          "marketingDescription": "### FEATURES:\n- Non-pleated front with tapered sides\n- Laydown collar, barrel cuff\n- Available in White, Ivory, and Black\n\n### SIZE SCALE:\nBoys' Large - Men's 3XL\n\n### FABRIC:\nPremium Microfiber\n\n### FIT:\nSlim Fit",
          "fabric": "Microfiber",
          "mannequinImages": [
            {
              "mannequinImage": "Shirt",
              "src": "SR_96W_1280x1920.png",
              "alt": "Shirt"
            },
            {
              "mannequinImage": "ShirtExtra",
              "src": "SR_96W_C_1280x1920_extra.png",
              "alt": "Shirt Collar"
            }
          ],
          "colorShade": "White",
          "publishedDate": 1701098610,
          "shirtFront": "Flat Front"
        },
        {
          "keywords": [],
          "webReleaseDate": 1697778000,
          "pattern": "Solid",
          "styleGroup": "Purchase",
          "program": "Expressions",
          "marketingTitle": "Rosewood Expressions Pocket Square",
          "colors": [
            "Rosewood"
          ],
          "styleType": "PocketSquare",
          "price": 10,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Green",
              "colorHexCode": "#730000",
              "styleCode": "PPKPWAR",
              "marketingTitle": "Green Expressions Pocket Square"
            },
            {
              "colorShade": "Aqua",
              "colorHexCode": "#70BCA5",
              "styleCode": "PPKPWAQ",
              "marketingTitle": "Aqua Expressions Pocket Square"
            },
            {
              "colorShade": "Ballet",
              "colorHexCode": "#D2A29E",
              "styleCode": "PPKPWBA",
              "marketingTitle": "Ballet Expressions Pocket Square"
            },
            {
              "colorShade": "Bellini",
              "colorHexCode": "#DAB7A6",
              "styleCode": "PPKPWBE",
              "marketingTitle": "Bellini Expressions Pocket Square"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "PPKPWBL",
              "marketingTitle": "Black Expressions Pocket Square"
            },
            {
              "colorShade": "Blue Box",
              "colorHexCode": "#75C7CE",
              "styleCode": "PPKPWBB",
              "marketingTitle": "Blue Box Expressions Pocket Square"
            },
            {
              "colorShade": "Blush",
              "colorHexCode": "#B58B88",
              "styleCode": "PPKPWBH",
              "marketingTitle": "Blush Expressions Pocket Square"
            },
            {
              "colorShade": "Brite Pink",
              "colorHexCode": "#DF588A",
              "styleCode": "PPKPWBP",
              "marketingTitle": "Brite Pink Expressions Pocket Square"
            },
            {
              "colorShade": "Buttercup",
              "colorHexCode": "#EDD66A",
              "styleCode": "PPKPWBC",
              "marketingTitle": "Buttercup Expressions Pocket Square"
            },
            {
              "colorShade": "Champagne",
              "colorHexCode": "#A29581",
              "styleCode": "PPKPWCP",
              "marketingTitle": "Champagne Expressions Pocket Square"
            },
            {
              "colorShade": "Charcoal",
              "colorHexCode": "#3E3E3E",
              "styleCode": "PPKPWCA",
              "marketingTitle": "Charcoal Expressions Pocket Square"
            },
            {
              "colorShade": "Cherry",
              "colorHexCode": "#7F212E",
              "styleCode": "PPKPWCY",
              "marketingTitle": "Cherry Expressions Pocket Square"
            },
            {
              "colorShade": "Chocolate",
              "colorHexCode": "#4E3D30",
              "styleCode": "PPKPWCH",
              "marketingTitle": "Chocolate Expressions Pocket Square"
            },
            {
              "colorShade": "Cinnamon",
              "colorHexCode": "#79342A",
              "styleCode": "PPKPWCI",
              "marketingTitle": "Cinnamon Expressions Pocket Square"
            },
            {
              "colorShade": "Cobalt",
              "colorHexCode": "#2d406f",
              "styleCode": "PPKPWCB",
              "marketingTitle": "Cobalt Expressions Pocket Square"
            },
            {
              "colorShade": "Coral",
              "colorHexCode": "#F6A4A3",
              "styleCode": "PPKPWCO",
              "marketingTitle": "Coral Expressions Pocket Square"
            },
            {
              "colorShade": "Cornflower",
              "colorHexCode": "#647EB0",
              "styleCode": "PPKPWCF",
              "marketingTitle": "Cornflower Expressions Pocket Square"
            },
            {
              "colorShade": "Cranberry",
              "colorHexCode": "#490B14",
              "styleCode": "PPKPWCE",
              "marketingTitle": "Cranberry Expressions Pocket Square"
            },
            {
              "colorShade": "Dark Coral",
              "colorHexCode": "#E17A74",
              "styleCode": "PPKPWDC",
              "marketingTitle": "Dark Coral Expressions Pocket Square"
            },
            {
              "colorShade": "Dusty Blue",
              "colorHexCode": "#8B98A7",
              "styleCode": "PPKPWDB",
              "marketingTitle": "Dusty Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Dusty Rose",
              "colorHexCode": "#E3D0D7",
              "styleCode": "PPKPWDR",
              "marketingTitle": "Dusty Rose Expressions Pocket Square"
            },
            {
              "colorShade": "Emerald",
              "colorHexCode": "#05493B",
              "styleCode": "PPKPWEM",
              "marketingTitle": "Emerald Expressions Pocket Square"
            },
            {
              "colorShade": "Evergreen/Moss",
              "colorHexCode": "#757B63",
              "styleCode": "PPKPWEV",
              "marketingTitle": "Evergreen/Moss Expressions Pocket Square"
            },
            {
              "colorShade": "Ferrari Red",
              "colorHexCode": "#A60A20",
              "styleCode": "PPKPWFR",
              "marketingTitle": "Ferrari Red Expressions Pocket Square"
            },
            {
              "colorShade": "First Blush",
              "colorHexCode": "#B69797",
              "styleCode": "PPKPWFB",
              "marketingTitle": "First Blush Expressions Pocket Square"
            },
            {
              "colorShade": "Forest",
              "colorHexCode": "#2F4437",
              "styleCode": "PPKPWFO",
              "marketingTitle": "Forest Expressions Pocket Square"
            },
            {
              "colorShade": "Gold",
              "colorHexCode": "#8b7256",
              "styleCode": "PPKPWGO",
              "marketingTitle": "Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Gold Metallic",
              "colorHexCode": "#C0B065",
              "styleCode": "PPKPWMG",
              "marketingTitle": "Gold Metallic Expressions Pocket Square"
            },
            {
              "colorShade": "Golden",
              "colorHexCode": "#A7956D",
              "styleCode": "PPKPWGL",
              "marketingTitle": "Golden Expressions Pocket Square"
            },
            {
              "colorShade": "Hunter Green",
              "colorHexCode": "#133B2F",
              "styleCode": "PPKPWHG",
              "marketingTitle": "Hunter Green Expressions Pocket Square"
            },
            {
              "colorShade": "Ivory",
              "colorHexCode": "#EEE9DF",
              "styleCode": "PPKPWIV",
              "marketingTitle": "Ivory Expressions Pocket Square"
            },
            {
              "colorShade": "Lavender",
              "colorHexCode": "#938BA8",
              "styleCode": "PPKPWLA",
              "marketingTitle": "Lavender Expressions Pocket Square"
            },
            {
              "colorShade": "Lite Blue",
              "colorHexCode": "#9FBBD7",
              "styleCode": "PPKPWLB",
              "marketingTitle": "Lite Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Lite Watermelon",
              "colorHexCode": "#D5487D",
              "styleCode": "PPKPWLW",
              "marketingTitle": "Lite Watermelon Expressions Pocket Square"
            },
            {
              "colorShade": "Malibu",
              "colorHexCode": "#369FC4",
              "styleCode": "PPKPWML",
              "marketingTitle": "Malibu Expressions Pocket Square"
            },
            {
              "colorShade": "Mint",
              "colorHexCode": "#AAD6BA",
              "styleCode": "PPKPWMN",
              "marketingTitle": "Mint Expressions Pocket Square"
            },
            {
              "colorShade": "Navy",
              "colorHexCode": "#25334D",
              "styleCode": "PPKPWNA",
              "marketingTitle": "Navy Expressions Pocket Square"
            },
            {
              "colorShade": "Nude",
              "colorHexCode": "#C7B097",
              "styleCode": "PPKPWNU",
              "marketingTitle": "Nude Expressions Pocket Square"
            },
            {
              "colorShade": "Pink Rose Gold",
              "colorHexCode": "#D4B1AA",
              "styleCode": "PPKPWPR",
              "marketingTitle": "Pink Rose Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Platinum",
              "colorHexCode": "#A4A7AD",
              "styleCode": "PPKPWPT",
              "marketingTitle": "Platinum Expressions Pocket Square"
            },
            {
              "colorShade": "Plum",
              "colorHexCode": "#381d38",
              "styleCode": "PPKPWPL",
              "marketingTitle": "Plum Expressions Pocket Square"
            },
            {
              "colorShade": "Quartz",
              "colorHexCode": "#B69797",
              "styleCode": "PPKPWQZ",
              "marketingTitle": "Quartz Expressions Pocket Square"
            },
            {
              "colorShade": "Raspberry",
              "colorHexCode": "#772E59",
              "styleCode": "PPKPWRA",
              "marketingTitle": "Raspberry Expressions Pocket Square"
            },
            {
              "colorShade": "Regency",
              "colorHexCode": "#33215C",
              "styleCode": "PPKPWRG",
              "marketingTitle": "Regency Expressions Pocket Square"
            },
            {
              "colorShade": "Rose Gold",
              "colorHexCode": "#99837F",
              "styleCode": "PPKPWRS",
              "marketingTitle": "Rose Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Rose Petal",
              "colorHexCode": "#E8B4C1",
              "styleCode": "PPKPWRP",
              "marketingTitle": "Rose Petal Expressions Pocket Square"
            },
            {
              "colorShade": "Rosewood",
              "colorHexCode": "#5f3237",
              "styleCode": "PPKPWRW",
              "marketingTitle": "Rosewood Expressions Pocket Square"
            },
            {
              "colorShade": "Royal Blue",
              "colorHexCode": "#21508F",
              "styleCode": "PPKPWRO",
              "marketingTitle": "Royal Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Sage",
              "colorHexCode": "#B3BBA2",
              "styleCode": "PPKPWSE",
              "marketingTitle": "Sage Expressions Pocket Square"
            },
            {
              "colorShade": "Silver",
              "colorHexCode": "#A7AEB8",
              "styleCode": "PPKPWSI",
              "marketingTitle": "Silver Expressions Pocket Square"
            },
            {
              "colorShade": "Silver Metallic",
              "colorHexCode": "#C8C6C6",
              "styleCode": "PPKPWMS",
              "marketingTitle": "Silver Metallic Expressions Pocket Square"
            },
            {
              "colorShade": "Slate Blue",
              "colorHexCode": "#3F5167",
              "styleCode": "PPKPWSL",
              "marketingTitle": "Slate Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Spice",
              "colorHexCode": "#9B4D3E",
              "styleCode": "PPKPWSC",
              "marketingTitle": "Spice Expressions Pocket Square"
            },
            {
              "colorShade": "Taupe",
              "colorHexCode": "#CFC5C3",
              "styleCode": "PPKPWTP",
              "marketingTitle": "Taupe Expressions Pocket Square"
            },
            {
              "colorShade": "Teal",
              "colorHexCode": "#057085",
              "styleCode": "PPKPWTE",
              "marketingTitle": "Teal Expressions Pocket Square"
            },
            {
              "colorShade": "Tiffany Blue",
              "colorHexCode": "#90D2D5",
              "styleCode": "PPKPWTB",
              "marketingTitle": "Tiffany Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Turquoise",
              "colorHexCode": "#5BACB3",
              "styleCode": "PPKPWTU",
              "marketingTitle": "Turquoise Expressions Pocket Square"
            },
            {
              "colorShade": "Viola",
              "colorHexCode": "#5C0A5A",
              "styleCode": "PPKPWVA",
              "marketingTitle": "Viola Expressions Pocket Square"
            },
            {
              "colorShade": "Watermelon",
              "colorHexCode": "#AC1848",
              "styleCode": "PPKPWWA",
              "marketingTitle": "Watermelon Expressions Pocket Square"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "PPKPWWH",
              "marketingTitle": "White Expressions Pocket Square"
            },
            {
              "colorShade": "Wine",
              "colorHexCode": "#54101B",
              "styleCode": "PPKPWWI",
              "marketingTitle": "Wine Expressions Pocket Square"
            }
          ],
          "colorHexCode": "#5f3237",
          "categories": [
            "Accessories"
          ],
          "images": [
            {
              "src": "PK_PWRW_1280x1920.jpg",
              "alt": "Rosewood Expressions Solid Pocket Square"
            }
          ],
          "styleRank": 4,
          "styleFirestoreDocumentId": "7udSrg2Iv9KxWMIAE5VX",
          "colorShadeId": "Rosewood",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Pink",
          "relatedStyles": [
            {
              "styleType": "Tie",
              "images": [
                {
                  "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
                  "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RTIWXRW",
              "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
            }
          ],
          "styleCode": "PPKPWRW",
          "legacyStyleCode": "PWRW",
          "matchingStyles": [
            {
              "docId": "i393vAhanT6qXMCMWJDn",
              "styleCode": "RTIWXRW"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Hand-sewn edges\n- 9\" x 9\"\n- Polyester\n- Hand wash",
          "mannequinImages": [
            {
              "mannequinImage": "PocketSquare",
              "src": "PK_PWRW_1280x1920.jpg",
              "alt": "Rosewood Expressions Solid Pocket Square"
            }
          ],
          "colorShade": "Rosewood",
          "publishedDate": 1697835035
        },
        {
          "keywords": [],
          "webReleaseDate": 1688533200,
          "styleGroup": "Rental",
          "companionStyleCode": "PSOBXS",
          "marketingTitle": "Black Oxford Shoe",
          "colors": [
            "Black"
          ],
          "styleType": "Shoes",
          "shoeType": "Oxford",
          "price": 30,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RSOBDS",
              "marketingTitle": "Black Dunbar Shoe"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RSOBXS",
              "marketingTitle": "Black Oxford Shoe"
            },
            {
              "colorShade": "Chestnut",
              "colorHexCode": "#91533d",
              "styleCode": "RSOCOP",
              "marketingTitle": "Chestnut Oxford Plain Toe Shoe"
            },
            {
              "colorShade": "Cognac",
              "colorHexCode": "#6C423D",
              "styleCode": "RSOCXS",
              "marketingTitle": "Cognac Oxford Shoe"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "RSOWAS",
              "marketingTitle": "White Allegro Shoe"
            }
          ],
          "colorHexCode": "#000000",
          "categories": [
            "Shoes"
          ],
          "companionStylePrice": 79,
          "images": [
            {
              "src": "SO-BXS-1280x1920.jpg",
              "alt": "Black Oxford Shoe"
            }
          ],
          "sizeScale": "C",
          "styleRank": 3,
          "styleFirestoreDocumentId": "O0Xb8rmylCTlFstqKJCu",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "colored-socks-royal-blue-XSRO.jpg",
                  "alt": "Royal Blue Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXSRO",
              "marketingTitle": "Royal Blue Socks"
            },
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "patterned-socks-wine-argyle-XPWG.jpg",
                  "alt": "Wine Grey Argyle Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXPWG",
              "marketingTitle": "Wine Grey Argyle Socks"
            },
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "colored-socks-black-XBLK.jpg",
                  "alt": "Black Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXBLK",
              "marketingTitle": "Black Socks"
            }
          ],
          "styleCode": "RSOBXS",
          "legacyStyleCode": "BXS",
          "material": "Leather",
          "marketingDescription": "### FEATURES:\n- Synthetic Leather\n- Cap toe, lace up closure\n- Premium cushioned footbed and flex construction \n\n### SIZE SCALE:\n- Men's Medium Width (C/D): 6 - 18 (half sizes up to 11.5)\n- Men's Wide Width (E/EE): 7-18 (half sizes up to 11.5)\n- Boys' Medium Width: 7 - 5.5",
          "mannequinImages": [
            {
              "mannequinImage": "Shoes",
              "src": "SO-BXS-1280x1920.jpg",
              "alt": "Black Oxford Shoe"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "BASIC",
          "publishedDate": 1701715960,
          "measurementScale2": "BASIC"
        },
        {
          "keywords": [],
          "webReleaseDate": 1697432400,
          "pattern": "Striped",
          "styleGroup": "Rental",
          "companionStyleCode": "PTIWXRW",
          "program": "Expressions",
          "marketingTitle": "Rosewood Striped Expressions Windsor Tie",
          "colors": [
            "Rosewood"
          ],
          "styleType": "Tie",
          "price": 17,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Apple Red",
              "colorHexCode": "#730000",
              "styleCode": "RTIWXAR",
              "marketingTitle": "Apple Red Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Aqua",
              "colorHexCode": "#70BCA5",
              "styleCode": "RTIWXAQ",
              "marketingTitle": "Aqua Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ballet",
              "colorHexCode": "#D2A29E",
              "styleCode": "RTIWXBA",
              "marketingTitle": "Ballet Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Bellini",
              "colorHexCode": "#DAB7A6",
              "styleCode": "RTIWXBE",
              "marketingTitle": "Bellini Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RTIWXBL",
              "marketingTitle": "Black Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Blue Box",
              "colorHexCode": "#75C7CE",
              "styleCode": "RTIWXBB",
              "marketingTitle": "Blue Box Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Blush",
              "colorHexCode": "#B58B88",
              "styleCode": "RTIWXBH",
              "marketingTitle": "Blush Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Brite Pink",
              "colorHexCode": "#DF588A",
              "styleCode": "RTIWXBP",
              "marketingTitle": "Brite Pink Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Buttercup",
              "colorHexCode": "#EDD66A",
              "styleCode": "RTIWXBC",
              "marketingTitle": "Buttercup Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Champagne",
              "colorHexCode": "#A29581",
              "styleCode": "RTIWXCP",
              "marketingTitle": "Champagne Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Charcoal",
              "colorHexCode": "#3E3E3E",
              "styleCode": "RTIWXCA",
              "marketingTitle": "Charcoal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cherry",
              "colorHexCode": "#7F212E",
              "styleCode": "RTIWXCY",
              "marketingTitle": "Cherry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Chocolate",
              "colorHexCode": "#4E3D30",
              "styleCode": "RTIWXCH",
              "marketingTitle": "Chocolate Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cinnamon",
              "colorHexCode": "#79342A",
              "styleCode": "RTIWXCI",
              "marketingTitle": "Cinnamon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cobalt",
              "colorHexCode": "#2d406f",
              "styleCode": "RTIWXCB",
              "marketingTitle": "Cobalt Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Coral",
              "colorHexCode": "#F6A4A3",
              "styleCode": "RTIWXCO",
              "marketingTitle": "Coral Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cornflower",
              "colorHexCode": "#647EB0",
              "styleCode": "RTIWXCF",
              "marketingTitle": "Cornflower Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cranberry",
              "colorHexCode": "#490B14",
              "styleCode": "RTIWXCE",
              "marketingTitle": "Cranberry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dark Coral",
              "colorHexCode": "#E17A74",
              "styleCode": "RTIWXDC",
              "marketingTitle": "Dark Coral Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dusty Blue",
              "colorHexCode": "#8B98A7",
              "styleCode": "RTIWXDB",
              "marketingTitle": "Dusty Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dusty Rose",
              "colorHexCode": "#E3D0D7",
              "styleCode": "RTIWXDR",
              "marketingTitle": "Dusty Rose Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Emerald",
              "colorHexCode": "#05493B",
              "styleCode": "RTIWXEM",
              "marketingTitle": "Emerald Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Evergreen/Moss",
              "colorHexCode": "#757B63",
              "styleCode": "RTIWXEV",
              "marketingTitle": "Evergreen/Moss Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ferrari Red",
              "colorHexCode": "#A60A20",
              "styleCode": "RTIWXFR",
              "marketingTitle": "Ferrari Red Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "First Blush",
              "colorHexCode": "#B69797",
              "styleCode": "RTIWXFB",
              "marketingTitle": "First Blush Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Forest",
              "colorHexCode": "#2F4437",
              "styleCode": "RTIWXFO",
              "marketingTitle": "Forest Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Gold",
              "colorHexCode": "#8b7256",
              "styleCode": "RTIWXGO",
              "marketingTitle": "Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Gold Metallic",
              "colorHexCode": "#C0B065",
              "styleCode": "RTIWXMG",
              "marketingTitle": "Gold Metallic Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Golden",
              "colorHexCode": "#A7956D",
              "styleCode": "RTIWXGL",
              "marketingTitle": "Golden Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Hunter Green",
              "colorHexCode": "#133B2F",
              "styleCode": "RTIWXHG",
              "marketingTitle": "Hunter Green Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ivory",
              "colorHexCode": "#EEE9DF",
              "styleCode": "RTIWXIV",
              "marketingTitle": "Ivory Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lavender",
              "colorHexCode": "#938BA8",
              "styleCode": "RTIWXLA",
              "marketingTitle": "Lavender Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lite Blue",
              "colorHexCode": "#9FBBD7",
              "styleCode": "RTIWXLB",
              "marketingTitle": "Lite Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lite Watermelon",
              "colorHexCode": "#D5487D",
              "styleCode": "RTIWXLW",
              "marketingTitle": "Lite Watermelon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Malibu",
              "colorHexCode": "#369FC4",
              "styleCode": "RTIWXML",
              "marketingTitle": "Malibu Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Mint",
              "colorHexCode": "#AAD6BA",
              "styleCode": "RTIWXMN",
              "marketingTitle": "Mint Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Navy",
              "colorHexCode": "#25334D",
              "styleCode": "RTIWXNA",
              "marketingTitle": "Navy Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Nude",
              "colorHexCode": "#C7B097",
              "styleCode": "RTIWXNU",
              "marketingTitle": "Nude Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Pink Rose Gold",
              "colorHexCode": "#D4B1AA",
              "styleCode": "RTIWXPR",
              "marketingTitle": "Pink Rose Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Platinum",
              "colorHexCode": "#A4A7AD",
              "styleCode": "RTIWXPT",
              "marketingTitle": "Platinum Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Plum",
              "colorHexCode": "#381d38",
              "styleCode": "RTIWXPL",
              "marketingTitle": "Plum Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Quartz",
              "colorHexCode": "#B69797",
              "styleCode": "RTIWXQZ",
              "marketingTitle": "Quartz Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Raspberry",
              "colorHexCode": "#772E59",
              "styleCode": "RTIWXRA",
              "marketingTitle": "Raspberry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Regency",
              "colorHexCode": "#33215C",
              "styleCode": "RTIWXRG",
              "marketingTitle": "Regency Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rose Gold",
              "colorHexCode": "#99837F",
              "styleCode": "RTIWXRS",
              "marketingTitle": "Rose Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rose Petal",
              "colorHexCode": "#E8B4C1",
              "styleCode": "RTIWXRP",
              "marketingTitle": "Rose Petal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rosewood",
              "colorHexCode": "#5f3237",
              "styleCode": "RTIWXRW",
              "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Royal Blue",
              "colorHexCode": "#21508F",
              "styleCode": "RTIWXRO",
              "marketingTitle": "Royal Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Sage",
              "colorHexCode": "#B3BBA2",
              "styleCode": "RTIWXSE",
              "marketingTitle": "Sage Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Silver",
              "colorHexCode": "#A7AEB8",
              "styleCode": "RTIWXSI",
              "marketingTitle": "Silver Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Silver Metallic",
              "colorHexCode": "#C8C6C6",
              "styleCode": "RTIWXMS",
              "marketingTitle": "Silver Metallic Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Slate Blue",
              "colorHexCode": "#3F5167",
              "styleCode": "RTIWXSL",
              "marketingTitle": "Slate Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Spice",
              "colorHexCode": "#9B4D3E",
              "styleCode": "RTIWXSC",
              "marketingTitle": "Spice Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Taupe",
              "colorHexCode": "#CFC5C3",
              "styleCode": "RTIWXTP",
              "marketingTitle": "Taupe Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Teal",
              "colorHexCode": "#057085",
              "styleCode": "RTIWXTE",
              "marketingTitle": "Teal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Tiffany Blue",
              "colorHexCode": "#90D2D5",
              "styleCode": "RTIWXTB",
              "marketingTitle": "Tiffany Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Turquoise",
              "colorHexCode": "#5BACB3",
              "styleCode": "RTIWXTU",
              "marketingTitle": "Turquoise Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Viola",
              "colorHexCode": "#5C0A5A",
              "styleCode": "RTIWXVA",
              "marketingTitle": "Viola Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Watermelon",
              "colorHexCode": "#AC1848",
              "styleCode": "RTIWXWA",
              "marketingTitle": "Watermelon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "RTIWXWH",
              "marketingTitle": "White Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Wine",
              "colorHexCode": "#54101B",
              "styleCode": "RTIWXWI",
              "marketingTitle": "Wine Striped Expressions Windsor Tie"
            }
          ],
          "colorHexCode": "#5f3237",
          "categories": [
            "Ties"
          ],
          "tieType": "Windsor",
          "companionStylePrice": 39,
          "images": [
            {
              "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
              "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
            },
            {
              "src": "windsor-tie-rose-petal-stripe-WXRP.jpg",
              "alt": "this is a test"
            },
            {
              "src": "windsor-tie-black-stripe-WXBL.jpg",
              "alt": "test 2"
            }
          ],
          "sizeScale": "A",
          "styleRank": 157,
          "styleFirestoreDocumentId": "b1EKl2zr3h3ByJqe5Rp4",
          "colorShadeId": "Rosewood",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Pink",
          "relatedStyles": [
            {
              "styleType": "PocketSquare",
              "images": [
                {
                  "src": "PK_PWRW_1280x1920.jpg",
                  "alt": "Rosewood Expressions Solid Pocket Square"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PPKPWRW",
              "marketingTitle": "Rosewood Expressions Pocket Square"
            },
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            }
          ],
          "collection": "Premium (PRM)",
          "styleCode": "RTIWXRW",
          "legacyStyleCode": "WXRW",
          "matchingStyles": [
            {
              "docId": "i393vAhanT6qXMCMWJDn",
              "styleCode": "PPKPWRW"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Striped colored windsor tie\n- Pre-tied, expandable neck\n- Coordinates with any Matching Wool Vest\n- Matching solid pocket square is available\n\n### SIZE SCALE:\n - Men's and Boys' Sizes\n\n### FABRIC:\n - Polyester",
          "fabric": "Polyester",
          "mannequinImages": [
            {
              "mannequinImage": "WindsorTie",
              "src": "TI_WXRW_1280x1920.png",
              "alt": "Rosewood Expressions Striped Windsor Tie"
            }
          ],
          "colorShade": "Rosewood",
          "measurementScale1": "A",
          "publishedDate": 1711135542
        }
      ],
      "id": "24cb9fc8-a211-4be6-b2e9-f6529f4942e4",
      "title": "Look 1a"
    },
    {
      "ensembleCode": "421",
      "price": 192,
      "styles": [
        {
          "keywords": [],
          "webReleaseDate": 1688014802,
          "styleGroup": "Rental",
          "companionStyleCode": "PPT921",
          "marketingTitle": "Black Performance Legacy Pants",
          "colors": [
            "Black"
          ],
          "fit": "Ultra Slim",
          "styleType": "Pant",
          "price": 48,
          "activeDate": 1682571600,
          "colorHexCode": "#000000",
          "categories": [],
          "companionStylePrice": 152,
          "images": [
            {
              "src": "black-performance-legacy-pants-921.jpg",
              "alt": "Black Performance Legacy Pants"
            }
          ],
          "sizeScale": "FLAT2",
          "styleRank": 1,
          "styleFirestoreDocumentId": "CeTKEZjFCizIms0q7gr5",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                  "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT421",
              "marketingTitle": "Black Performance Wedding Suit Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                  "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT921",
              "marketingTitle": "Black Legacy Tuxedo Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                  "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT981",
              "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
            }
          ],
          "designer": "Michael Kors",
          "styleCode": "RPT921",
          "legacyStyleCode": "921",
          "matchingStyles": [
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "PVS921"
            },
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "RCT421"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RCT981"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RVS921"
            },
            {
              "docId": "zg6xNgr4zbkkvXXAZIFO",
              "styleCode": "PVS921"
            },
            {
              "docId": "zg6xNgr4zbkkvXXAZIFO",
              "styleCode": "RCT921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Available to rent or buy\n- Flat front with adjustable waist\n- Belt loops and buttons to accommodate suspenders\n- Coordinates with Black Performance Stretch Suits and Tuxedo\n\n### SIZE SCALE:\n921 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
          "fabric": "CoolMax/Sorona/Wool",
          "suspenderButtons": true,
          "mannequinImages": [
            {
              "mannequinImage": "Pant",
              "src": "PT_921_1280x1920.png",
              "alt": "Black Performance Legacy Pants"
            }
          ],
          "colorShade": "Black",
          "waist": "Adjustable",
          "beltLoops": true,
          "publishedDate": 1698107750
        },
        {
          "keywords": [
            "belt",
            "reversible",
            "black",
            "brown"
          ],
          "webReleaseDate": 1683522000,
          "styleGroup": "Purchase",
          "marketingTitle": "Black/Brown Reversible Belt",
          "colors": [
            "Black/Brown"
          ],
          "styleType": "Miscellaneous",
          "price": 24,
          "activeDate": 1683522000,
          "colorHexCode": "#000000",
          "categories": [
            "Accessories"
          ],
          "images": [
            {
              "src": "belt-black-tan-reversible-NBLT.jpg",
              "alt": "Black/Brown Reversible Belt"
            }
          ],
          "styleRank": 1,
          "styleFirestoreDocumentId": "jMgaN4ySnwR4M7G8I1MQ",
          "colorShadeId": "BlackBrown",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Ottawa",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "relatedStyles": [],
          "colorFamily": "Black",
          "collection": "Resale",
          "styleCode": "PMSBLT",
          "miscellaneousType": "Belt",
          "legacyStyleCode": "NBLT",
          "marketingDescription": "### FEATURES:\n- Synthetic leather\n- Reversible black & brown\n- Platinum burnished buckle, stitched edges, 3.5 cm wide\n\n### SIZE SCALE:\n- XS: 21-24\n- S: 25-28\n- M: 29-32\n- L: 33-36\n- XL: 37-40\n- 2X: 41-44\n- 3X: 45-28",
          "fabric": "Leather",
          "mannequinImages": [
            {
              "mannequinImage": "Miscellaneous",
              "src": "belt-black-tan-reversible-NBLT.jpg",
              "alt": "Black/Brown Reversible Belt"
            }
          ],
          "colorShade": "Black/Brown",
          "publishedDate": 1698108942
        },
        {
          "vestType": "Fullback",
          "keywords": [],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "vestBack": "Matching",
          "companionStyleCode": "PVS921",
          "program": "Matching",
          "marketingTitle": "Black Performance Vest",
          "colors": [
            "Black"
          ],
          "styleType": "Vest",
          "price": 32,
          "activeDate": 1684731600,
          "colorHexCode": "#000000",
          "categories": [
            "VestsAndCummerbunds"
          ],
          "companionStylePrice": 79,
          "images": [
            {
              "src": "fullback-vest-black-performance-stretch-F921.jpg",
              "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
            }
          ],
          "sizeScale": "VST16",
          "buttons": 4,
          "styleRank": 1,
          "styleFirestoreDocumentId": "2Zg4MDqr3PPnsAOSaAKJ",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                  "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT921",
              "marketingTitle": "Black Legacy Tuxedo Coat"
            },
            {
              "styleType": "Pant",
              "images": [
                {
                  "src": "black-performance-legacy-pants-921.jpg",
                  "alt": "Black Performance Legacy Pants"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RPT921",
              "marketingTitle": "Black Performance Legacy Pants"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                  "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT421",
              "marketingTitle": "Black Performance Wedding Suit Coat"
            },
            {
              "styleType": "Coat",
              "images": [
                {
                  "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                  "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RCT981",
              "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
            }
          ],
          "designer": "Michael Kors",
          "styleCode": "RVS921",
          "legacyStyleCode": "F921",
          "matchingStyles": [
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RCT981"
            },
            {
              "docId": "dEHuyoH7F7yVXSgabD64",
              "styleCode": "RPT921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- 4-button\n- Adjustable back\n- Darted pockets\n- Matching colored back\n\n### SIZE SCALE:\n- Boys' Small - Men's 5XA\n\n### FABRIC:\n- Polyester",
          "fabric": "CoolMax/Sorona/Wool",
          "mannequinImages": [
            {
              "mannequinImage": "Vest",
              "src": "VS_F921_1280x1920.png",
              "alt": "Michael Kors Black Performance Stretch Wedding Vest"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "VST18",
          "publishedDate": 1698428370,
          "pocket": "Darted"
        },
        {
          "keywords": [
            "cufflinks",
            "studs",
            "jewlry",
            "silver"
          ],
          "webReleaseDate": 1694667600,
          "styleGroup": "Rental",
          "marketingTitle": "Black/Silver Basic Jewelry",
          "colors": [
            "Black"
          ],
          "styleType": "Jewelry",
          "price": 2,
          "activeDate": 1194242400,
          "colorHexCode": "#000000",
          "finish": "Silver",
          "categories": [
            "CufflinksAndStuds"
          ],
          "images": [
            {
              "src": "JW_BL_1280x1920.jpg",
              "alt": "Black/Silver Basic Jewelry"
            }
          ],
          "styleRank": 1,
          "styleFirestoreDocumentId": "JZaae4iAZVssS3k3E0nE",
          "colorShadeId": "Black",
          "jewelryType": "Full Set",
          "warehouses": [
            "Trenton",
            "Anderson",
            "Atlanta",
            "Seguin",
            "Visalia",
            "Salina",
            "Richmond"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Shirt",
              "images": [
                {
                  "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
                  "alt": "White Fitted Microfiber Shirt"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RSR96W",
              "marketingTitle": "White Fitted Microfiber Shirt"
            }
          ],
          "collection": "Basic",
          "styleCode": "RJWBL",
          "legacyStyleCode": "BL",
          "marketingDescription": "### FEATURES:\n- Set includes 2 cufflinks and 4 shirt studs\n- Toggle back\n- Faux onyx stone framed in silver-toned metal",
          "mannequinImages": [
            {
              "mannequinImage": "Jewelry",
              "src": "JW_BL_1280x1920.jpg",
              "alt": "Black/Silver Basic Jewelry"
            }
          ],
          "colorShade": "Black",
          "publishedDate": 1699627147
        },
        {
          "coatType": "Suit",
          "vent": "Side",
          "keywords": [],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "companionStyleCode": "PCT421",
          "marketingTitle": "Black Performance Wedding Suit Coat",
          "colors": [
            "Black"
          ],
          "coatLength": 29.5,
          "fit": "Ultra Slim",
          "styleType": "Coat",
          "price": 190,
          "activeDate": 1684731600,
          "colorHexCode": "#000000",
          "categories": [],
          "companionStylePrice": 330,
          "lapel": "Notch",
          "images": [
            {
              "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
              "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
            }
          ],
          "sizeScale": "B",
          "buttons": 2,
          "styleRank": 2,
          "styleFirestoreDocumentId": "Fa6MNKeF1oTFv4A5gzD0",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "https://www.jimsformalwear.com/images/1280x1920/fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "Black Performance Vest"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            }
          ],
          "collection": "Diamond",
          "designer": "Michael Kors",
          "styleCode": "RCT421",
          "legacyStyleCode": "421",
          "matchingStyles": [
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "RPT921"
            },
            {
              "docId": "DrSpbZEVmCJdWrhva9cl",
              "styleCode": "PVS921"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 29 1/2\" length. \n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
          "fabric": "CoolMax/Sorona/Wool",
          "recommendedStyles": [
            "RPT921"
          ],
          "mannequinImages": [
            {
              "mannequinImage": "Coat",
              "src": "CT_421_1280x1920.png",
              "alt": "Black Performance Wedding Suit Coat"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "CSTZ",
          "publishedDate": 1697577801,
          "pocket": "Chest"
        },
        {
          "keywords": [
            "white",
            "ivory",
            "black",
            "shirt",
            "fitted",
            "formal shirt"
          ],
          "webReleaseDate": 1688101200,
          "styleGroup": "Rental",
          "companionStyleCode": "PSR96W",
          "marketingTitle": "White Fitted Microfiber Shirt",
          "colors": [
            "White"
          ],
          "collarType": "Laydown",
          "fit": "Fitted",
          "styleType": "Shirt",
          "price": 28,
          "activeDate": 1683522000,
          "colorHexCode": "#FFFFFF",
          "categories": [
            "Shirts"
          ],
          "companionStylePrice": 54,
          "images": [
            {
              "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
              "alt": "White Fitted Microfiber Shirt"
            }
          ],
          "sizeScale": "FIT",
          "styleRank": 1,
          "styleFirestoreDocumentId": "cP3bWqMFOifb38CvszqT",
          "colorShadeId": "White",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "White/Ivory",
          "relatedStyles": [
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_BG_1280x1920.jpg",
                  "alt": "Black/Gold Premium Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBG",
              "marketingTitle": "Black/Gold Premium Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_BL_1280x1920.jpg",
                  "alt": "Black/Silver Basic Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBL",
              "marketingTitle": "Black/Silver Basic Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "cufflinks-studs-tuxedo-jewelry-BS.jpg",
                  "alt": "Black/Silver Premium Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWBS",
              "marketingTitle": "Black/Silver Premium Jewelry"
            },
            {
              "styleType": "Jewelry",
              "images": [
                {
                  "src": "JW_WH_1280x1920.jpg",
                  "alt": "White/Silver Basic Jewelry"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RJWWH",
              "marketingTitle": "White/Silver Basic Jewelry"
            }
          ],
          "collection": "Premium (PRM)",
          "styleCode": "RSR96W",
          "legacyStyleCode": "96W",
          "marketingDescription": "### FEATURES:\n- Non-pleated front with tapered sides\n- Laydown collar, barrel cuff\n- Available in White, Ivory, and Black\n\n### SIZE SCALE:\nBoys' Large - Men's 3XL\n\n### FABRIC:\nPremium Microfiber\n\n### FIT:\nSlim Fit",
          "fabric": "Microfiber",
          "mannequinImages": [
            {
              "mannequinImage": "Shirt",
              "src": "SR_96W_1280x1920.png",
              "alt": "Shirt"
            },
            {
              "mannequinImage": "ShirtExtra",
              "src": "SR_96W_C_1280x1920_extra.png",
              "alt": "Shirt Collar"
            }
          ],
          "colorShade": "White",
          "publishedDate": 1701098610,
          "shirtFront": "Flat Front"
        },
        {
          "keywords": [],
          "webReleaseDate": 1697778000,
          "pattern": "Solid",
          "styleGroup": "Purchase",
          "program": "Expressions",
          "marketingTitle": "Rosewood Expressions Pocket Square",
          "colors": [
            "Rosewood"
          ],
          "styleType": "PocketSquare",
          "price": 10,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Apple Red",
              "colorHexCode": "#730000",
              "styleCode": "PPKPWAR",
              "marketingTitle": "Apple Red Expressions Pocket Square"
            },
            {
              "colorShade": "Aqua",
              "colorHexCode": "#70BCA5",
              "styleCode": "PPKPWAQ",
              "marketingTitle": "Aqua Expressions Pocket Square"
            },
            {
              "colorShade": "Ballet",
              "colorHexCode": "#D2A29E",
              "styleCode": "PPKPWBA",
              "marketingTitle": "Ballet Expressions Pocket Square"
            },
            {
              "colorShade": "Bellini",
              "colorHexCode": "#DAB7A6",
              "styleCode": "PPKPWBE",
              "marketingTitle": "Bellini Expressions Pocket Square"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "PPKPWBL",
              "marketingTitle": "Black Expressions Pocket Square"
            },
            {
              "colorShade": "Blue Box",
              "colorHexCode": "#75C7CE",
              "styleCode": "PPKPWBB",
              "marketingTitle": "Blue Box Expressions Pocket Square"
            },
            {
              "colorShade": "Blush",
              "colorHexCode": "#B58B88",
              "styleCode": "PPKPWBH",
              "marketingTitle": "Blush Expressions Pocket Square"
            },
            {
              "colorShade": "Brite Pink",
              "colorHexCode": "#DF588A",
              "styleCode": "PPKPWBP",
              "marketingTitle": "Brite Pink Expressions Pocket Square"
            },
            {
              "colorShade": "Buttercup",
              "colorHexCode": "#EDD66A",
              "styleCode": "PPKPWBC",
              "marketingTitle": "Buttercup Expressions Pocket Square"
            },
            {
              "colorShade": "Champagne",
              "colorHexCode": "#A29581",
              "styleCode": "PPKPWCP",
              "marketingTitle": "Champagne Expressions Pocket Square"
            },
            {
              "colorShade": "Charcoal",
              "colorHexCode": "#3E3E3E",
              "styleCode": "PPKPWCA",
              "marketingTitle": "Charcoal Expressions Pocket Square"
            },
            {
              "colorShade": "Cherry",
              "colorHexCode": "#7F212E",
              "styleCode": "PPKPWCY",
              "marketingTitle": "Cherry Expressions Pocket Square"
            },
            {
              "colorShade": "Chocolate",
              "colorHexCode": "#4E3D30",
              "styleCode": "PPKPWCH",
              "marketingTitle": "Chocolate Expressions Pocket Square"
            },
            {
              "colorShade": "Cinnamon",
              "colorHexCode": "#79342A",
              "styleCode": "PPKPWCI",
              "marketingTitle": "Cinnamon Expressions Pocket Square"
            },
            {
              "colorShade": "Cobalt",
              "colorHexCode": "#2d406f",
              "styleCode": "PPKPWCB",
              "marketingTitle": "Cobalt Expressions Pocket Square"
            },
            {
              "colorShade": "Coral",
              "colorHexCode": "#F6A4A3",
              "styleCode": "PPKPWCO",
              "marketingTitle": "Coral Expressions Pocket Square"
            },
            {
              "colorShade": "Cornflower",
              "colorHexCode": "#647EB0",
              "styleCode": "PPKPWCF",
              "marketingTitle": "Cornflower Expressions Pocket Square"
            },
            {
              "colorShade": "Cranberry",
              "colorHexCode": "#490B14",
              "styleCode": "PPKPWCE",
              "marketingTitle": "Cranberry Expressions Pocket Square"
            },
            {
              "colorShade": "Dark Coral",
              "colorHexCode": "#E17A74",
              "styleCode": "PPKPWDC",
              "marketingTitle": "Dark Coral Expressions Pocket Square"
            },
            {
              "colorShade": "Dusty Blue",
              "colorHexCode": "#8B98A7",
              "styleCode": "PPKPWDB",
              "marketingTitle": "Dusty Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Dusty Rose",
              "colorHexCode": "#E3D0D7",
              "styleCode": "PPKPWDR",
              "marketingTitle": "Dusty Rose Expressions Pocket Square"
            },
            {
              "colorShade": "Emerald",
              "colorHexCode": "#05493B",
              "styleCode": "PPKPWEM",
              "marketingTitle": "Emerald Expressions Pocket Square"
            },
            {
              "colorShade": "Evergreen/Moss",
              "colorHexCode": "#757B63",
              "styleCode": "PPKPWEV",
              "marketingTitle": "Evergreen/Moss Expressions Pocket Square"
            },
            {
              "colorShade": "Ferrari Red",
              "colorHexCode": "#A60A20",
              "styleCode": "PPKPWFR",
              "marketingTitle": "Ferrari Red Expressions Pocket Square"
            },
            {
              "colorShade": "First Blush",
              "colorHexCode": "#B69797",
              "styleCode": "PPKPWFB",
              "marketingTitle": "First Blush Expressions Pocket Square"
            },
            {
              "colorShade": "Forest",
              "colorHexCode": "#2F4437",
              "styleCode": "PPKPWFO",
              "marketingTitle": "Forest Expressions Pocket Square"
            },
            {
              "colorShade": "Gold",
              "colorHexCode": "#8b7256",
              "styleCode": "PPKPWGO",
              "marketingTitle": "Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Gold Metallic",
              "colorHexCode": "#C0B065",
              "styleCode": "PPKPWMG",
              "marketingTitle": "Gold Metallic Expressions Pocket Square"
            },
            {
              "colorShade": "Golden",
              "colorHexCode": "#A7956D",
              "styleCode": "PPKPWGL",
              "marketingTitle": "Golden Expressions Pocket Square"
            },
            {
              "colorShade": "Hunter Green",
              "colorHexCode": "#133B2F",
              "styleCode": "PPKPWHG",
              "marketingTitle": "Hunter Green Expressions Pocket Square"
            },
            {
              "colorShade": "Ivory",
              "colorHexCode": "#EEE9DF",
              "styleCode": "PPKPWIV",
              "marketingTitle": "Ivory Expressions Pocket Square"
            },
            {
              "colorShade": "Lavender",
              "colorHexCode": "#938BA8",
              "styleCode": "PPKPWLA",
              "marketingTitle": "Lavender Expressions Pocket Square"
            },
            {
              "colorShade": "Lite Blue",
              "colorHexCode": "#9FBBD7",
              "styleCode": "PPKPWLB",
              "marketingTitle": "Lite Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Lite Watermelon",
              "colorHexCode": "#D5487D",
              "styleCode": "PPKPWLW",
              "marketingTitle": "Lite Watermelon Expressions Pocket Square"
            },
            {
              "colorShade": "Malibu",
              "colorHexCode": "#369FC4",
              "styleCode": "PPKPWML",
              "marketingTitle": "Malibu Expressions Pocket Square"
            },
            {
              "colorShade": "Mint",
              "colorHexCode": "#AAD6BA",
              "styleCode": "PPKPWMN",
              "marketingTitle": "Mint Expressions Pocket Square"
            },
            {
              "colorShade": "Navy",
              "colorHexCode": "#25334D",
              "styleCode": "PPKPWNA",
              "marketingTitle": "Navy Expressions Pocket Square"
            },
            {
              "colorShade": "Nude",
              "colorHexCode": "#C7B097",
              "styleCode": "PPKPWNU",
              "marketingTitle": "Nude Expressions Pocket Square"
            },
            {
              "colorShade": "Pink Rose Gold",
              "colorHexCode": "#D4B1AA",
              "styleCode": "PPKPWPR",
              "marketingTitle": "Pink Rose Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Platinum",
              "colorHexCode": "#A4A7AD",
              "styleCode": "PPKPWPT",
              "marketingTitle": "Platinum Expressions Pocket Square"
            },
            {
              "colorShade": "Plum",
              "colorHexCode": "#381d38",
              "styleCode": "PPKPWPL",
              "marketingTitle": "Plum Expressions Pocket Square"
            },
            {
              "colorShade": "Quartz",
              "colorHexCode": "#B69797",
              "styleCode": "PPKPWQZ",
              "marketingTitle": "Quartz Expressions Pocket Square"
            },
            {
              "colorShade": "Raspberry",
              "colorHexCode": "#772E59",
              "styleCode": "PPKPWRA",
              "marketingTitle": "Raspberry Expressions Pocket Square"
            },
            {
              "colorShade": "Regency",
              "colorHexCode": "#33215C",
              "styleCode": "PPKPWRG",
              "marketingTitle": "Regency Expressions Pocket Square"
            },
            {
              "colorShade": "Rose Gold",
              "colorHexCode": "#99837F",
              "styleCode": "PPKPWRS",
              "marketingTitle": "Rose Gold Expressions Pocket Square"
            },
            {
              "colorShade": "Rose Petal",
              "colorHexCode": "#E8B4C1",
              "styleCode": "PPKPWRP",
              "marketingTitle": "Rose Petal Expressions Pocket Square"
            },
            {
              "colorShade": "Rosewood",
              "colorHexCode": "#5f3237",
              "styleCode": "PPKPWRW",
              "marketingTitle": "Rosewood Expressions Pocket Square"
            },
            {
              "colorShade": "Royal Blue",
              "colorHexCode": "#21508F",
              "styleCode": "PPKPWRO",
              "marketingTitle": "Royal Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Sage",
              "colorHexCode": "#B3BBA2",
              "styleCode": "PPKPWSE",
              "marketingTitle": "Sage Expressions Pocket Square"
            },
            {
              "colorShade": "Silver",
              "colorHexCode": "#A7AEB8",
              "styleCode": "PPKPWSI",
              "marketingTitle": "Silver Expressions Pocket Square"
            },
            {
              "colorShade": "Silver Metallic",
              "colorHexCode": "#C8C6C6",
              "styleCode": "PPKPWMS",
              "marketingTitle": "Silver Metallic Expressions Pocket Square"
            },
            {
              "colorShade": "Slate Blue",
              "colorHexCode": "#3F5167",
              "styleCode": "PPKPWSL",
              "marketingTitle": "Slate Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Spice",
              "colorHexCode": "#9B4D3E",
              "styleCode": "PPKPWSC",
              "marketingTitle": "Spice Expressions Pocket Square"
            },
            {
              "colorShade": "Taupe",
              "colorHexCode": "#CFC5C3",
              "styleCode": "PPKPWTP",
              "marketingTitle": "Taupe Expressions Pocket Square"
            },
            {
              "colorShade": "Teal",
              "colorHexCode": "#057085",
              "styleCode": "PPKPWTE",
              "marketingTitle": "Teal Expressions Pocket Square"
            },
            {
              "colorShade": "Tiffany Blue",
              "colorHexCode": "#90D2D5",
              "styleCode": "PPKPWTB",
              "marketingTitle": "Tiffany Blue Expressions Pocket Square"
            },
            {
              "colorShade": "Turquoise",
              "colorHexCode": "#5BACB3",
              "styleCode": "PPKPWTU",
              "marketingTitle": "Turquoise Expressions Pocket Square"
            },
            {
              "colorShade": "Viola",
              "colorHexCode": "#5C0A5A",
              "styleCode": "PPKPWVA",
              "marketingTitle": "Viola Expressions Pocket Square"
            },
            {
              "colorShade": "Watermelon",
              "colorHexCode": "#AC1848",
              "styleCode": "PPKPWWA",
              "marketingTitle": "Watermelon Expressions Pocket Square"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "PPKPWWH",
              "marketingTitle": "White Expressions Pocket Square"
            },
            {
              "colorShade": "Wine",
              "colorHexCode": "#54101B",
              "styleCode": "PPKPWWI",
              "marketingTitle": "Wine Expressions Pocket Square"
            }
          ],
          "colorHexCode": "#5f3237",
          "categories": [
            "Accessories"
          ],
          "images": [
            {
              "src": "PK_PWRW_1280x1920.jpg",
              "alt": "Rosewood Expressions Solid Pocket Square"
            }
          ],
          "styleRank": 4,
          "styleFirestoreDocumentId": "7udSrg2Iv9KxWMIAE5VX",
          "colorShadeId": "Rosewood",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Pink",
          "relatedStyles": [
            {
              "styleType": "Tie",
              "images": [
                {
                  "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
                  "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RTIWXRW",
              "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
            }
          ],
          "styleCode": "PPKPWRW",
          "legacyStyleCode": "PWRW",
          "matchingStyles": [
            {
              "docId": "i393vAhanT6qXMCMWJDn",
              "styleCode": "RTIWXRW"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Hand-sewn edges\n- 9\" x 9\"\n- Polyester\n- Hand wash",
          "mannequinImages": [
            {
              "mannequinImage": "PocketSquare",
              "src": "PK_PWRW_1280x1920.jpg",
              "alt": "Rosewood Expressions Solid Pocket Square"
            }
          ],
          "colorShade": "Rosewood",
          "publishedDate": 1697835035
        },
        {
          "keywords": [],
          "webReleaseDate": 1688533200,
          "styleGroup": "Rental",
          "companionStyleCode": "PSOBXS",
          "marketingTitle": "Black Oxford Shoe",
          "colors": [
            "Black"
          ],
          "styleType": "Shoes",
          "shoeType": "Oxford",
          "price": 30,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RSOBDS",
              "marketingTitle": "Black Dunbar Shoe"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RSOBXS",
              "marketingTitle": "Black Oxford Shoe"
            },
            {
              "colorShade": "Chestnut",
              "colorHexCode": "#91533d",
              "styleCode": "RSOCOP",
              "marketingTitle": "Chestnut Oxford Plain Toe Shoe"
            },
            {
              "colorShade": "Cognac",
              "colorHexCode": "#6C423D",
              "styleCode": "RSOCXS",
              "marketingTitle": "Cognac Oxford Shoe"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "RSOWAS",
              "marketingTitle": "White Allegro Shoe"
            }
          ],
          "colorHexCode": "#000000",
          "categories": [
            "Shoes"
          ],
          "companionStylePrice": 79,
          "images": [
            {
              "src": "SO-BXS-1280x1920.jpg",
              "alt": "Black Oxford Shoe"
            }
          ],
          "sizeScale": "C",
          "styleRank": 3,
          "styleFirestoreDocumentId": "O0Xb8rmylCTlFstqKJCu",
          "colorShadeId": "Black",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Black",
          "relatedStyles": [
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "colored-socks-royal-blue-XSRO.jpg",
                  "alt": "Royal Blue Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXSRO",
              "marketingTitle": "Royal Blue Socks"
            },
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "patterned-socks-wine-argyle-XPWG.jpg",
                  "alt": "Wine Grey Argyle Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXPWG",
              "marketingTitle": "Wine Grey Argyle Socks"
            },
            {
              "styleType": "Socks",
              "images": [
                {
                  "src": "colored-socks-black-XBLK.jpg",
                  "alt": "Black Socks"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PHSXBLK",
              "marketingTitle": "Black Socks"
            }
          ],
          "styleCode": "RSOBXS",
          "legacyStyleCode": "BXS",
          "material": "Leather",
          "marketingDescription": "### FEATURES:\n- Synthetic Leather\n- Cap toe, lace up closure\n- Premium cushioned footbed and flex construction \n\n### SIZE SCALE:\n- Men's Medium Width (C/D): 6 - 18 (half sizes up to 11.5)\n- Men's Wide Width (E/EE): 7-18 (half sizes up to 11.5)\n- Boys' Medium Width: 7 - 5.5",
          "mannequinImages": [
            {
              "mannequinImage": "Shoes",
              "src": "SO-BXS-1280x1920.jpg",
              "alt": "Black Oxford Shoe"
            }
          ],
          "colorShade": "Black",
          "measurementScale1": "BASIC",
          "publishedDate": 1701715960,
          "measurementScale2": "BASIC"
        },
        {
          "keywords": [],
          "webReleaseDate": 1697432400,
          "pattern": "Striped",
          "styleGroup": "Rental",
          "companionStyleCode": "PTIWXRW",
          "program": "Expressions",
          "marketingTitle": "Rosewood Striped Expressions Windsor Tie",
          "colors": [
            "Rosewood"
          ],
          "styleType": "Tie",
          "price": 17,
          "activeDate": 1684731600,
          "altColors": [
            {
              "colorShade": "Apple Red",
              "colorHexCode": "#730000",
              "styleCode": "RTIWXAR",
              "marketingTitle": "Apple Red Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Aqua",
              "colorHexCode": "#70BCA5",
              "styleCode": "RTIWXAQ",
              "marketingTitle": "Aqua Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ballet",
              "colorHexCode": "#D2A29E",
              "styleCode": "RTIWXBA",
              "marketingTitle": "Ballet Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Bellini",
              "colorHexCode": "#DAB7A6",
              "styleCode": "RTIWXBE",
              "marketingTitle": "Bellini Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Black",
              "colorHexCode": "#000000",
              "styleCode": "RTIWXBL",
              "marketingTitle": "Black Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Blue Box",
              "colorHexCode": "#75C7CE",
              "styleCode": "RTIWXBB",
              "marketingTitle": "Blue Box Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Blush",
              "colorHexCode": "#B58B88",
              "styleCode": "RTIWXBH",
              "marketingTitle": "Blush Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Brite Pink",
              "colorHexCode": "#DF588A",
              "styleCode": "RTIWXBP",
              "marketingTitle": "Brite Pink Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Buttercup",
              "colorHexCode": "#EDD66A",
              "styleCode": "RTIWXBC",
              "marketingTitle": "Buttercup Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Champagne",
              "colorHexCode": "#A29581",
              "styleCode": "RTIWXCP",
              "marketingTitle": "Champagne Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Charcoal",
              "colorHexCode": "#3E3E3E",
              "styleCode": "RTIWXCA",
              "marketingTitle": "Charcoal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cherry",
              "colorHexCode": "#7F212E",
              "styleCode": "RTIWXCY",
              "marketingTitle": "Cherry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Chocolate",
              "colorHexCode": "#4E3D30",
              "styleCode": "RTIWXCH",
              "marketingTitle": "Chocolate Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cinnamon",
              "colorHexCode": "#79342A",
              "styleCode": "RTIWXCI",
              "marketingTitle": "Cinnamon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cobalt",
              "colorHexCode": "#2d406f",
              "styleCode": "RTIWXCB",
              "marketingTitle": "Cobalt Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Coral",
              "colorHexCode": "#F6A4A3",
              "styleCode": "RTIWXCO",
              "marketingTitle": "Coral Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cornflower",
              "colorHexCode": "#647EB0",
              "styleCode": "RTIWXCF",
              "marketingTitle": "Cornflower Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Cranberry",
              "colorHexCode": "#490B14",
              "styleCode": "RTIWXCE",
              "marketingTitle": "Cranberry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dark Coral",
              "colorHexCode": "#E17A74",
              "styleCode": "RTIWXDC",
              "marketingTitle": "Dark Coral Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dusty Blue",
              "colorHexCode": "#8B98A7",
              "styleCode": "RTIWXDB",
              "marketingTitle": "Dusty Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Dusty Rose",
              "colorHexCode": "#E3D0D7",
              "styleCode": "RTIWXDR",
              "marketingTitle": "Dusty Rose Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Emerald",
              "colorHexCode": "#05493B",
              "styleCode": "RTIWXEM",
              "marketingTitle": "Emerald Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Evergreen/Moss",
              "colorHexCode": "#757B63",
              "styleCode": "RTIWXEV",
              "marketingTitle": "Evergreen/Moss Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ferrari Red",
              "colorHexCode": "#A60A20",
              "styleCode": "RTIWXFR",
              "marketingTitle": "Ferrari Red Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "First Blush",
              "colorHexCode": "#B69797",
              "styleCode": "RTIWXFB",
              "marketingTitle": "First Blush Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Forest",
              "colorHexCode": "#2F4437",
              "styleCode": "RTIWXFO",
              "marketingTitle": "Forest Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Gold",
              "colorHexCode": "#8b7256",
              "styleCode": "RTIWXGO",
              "marketingTitle": "Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Gold Metallic",
              "colorHexCode": "#C0B065",
              "styleCode": "RTIWXMG",
              "marketingTitle": "Gold Metallic Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Golden",
              "colorHexCode": "#A7956D",
              "styleCode": "RTIWXGL",
              "marketingTitle": "Golden Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Hunter Green",
              "colorHexCode": "#133B2F",
              "styleCode": "RTIWXHG",
              "marketingTitle": "Hunter Green Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Ivory",
              "colorHexCode": "#EEE9DF",
              "styleCode": "RTIWXIV",
              "marketingTitle": "Ivory Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lavender",
              "colorHexCode": "#938BA8",
              "styleCode": "RTIWXLA",
              "marketingTitle": "Lavender Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lite Blue",
              "colorHexCode": "#9FBBD7",
              "styleCode": "RTIWXLB",
              "marketingTitle": "Lite Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Lite Watermelon",
              "colorHexCode": "#D5487D",
              "styleCode": "RTIWXLW",
              "marketingTitle": "Lite Watermelon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Malibu",
              "colorHexCode": "#369FC4",
              "styleCode": "RTIWXML",
              "marketingTitle": "Malibu Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Mint",
              "colorHexCode": "#AAD6BA",
              "styleCode": "RTIWXMN",
              "marketingTitle": "Mint Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Navy",
              "colorHexCode": "#25334D",
              "styleCode": "RTIWXNA",
              "marketingTitle": "Navy Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Nude",
              "colorHexCode": "#C7B097",
              "styleCode": "RTIWXNU",
              "marketingTitle": "Nude Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Pink Rose Gold",
              "colorHexCode": "#D4B1AA",
              "styleCode": "RTIWXPR",
              "marketingTitle": "Pink Rose Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Platinum",
              "colorHexCode": "#A4A7AD",
              "styleCode": "RTIWXPT",
              "marketingTitle": "Platinum Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Plum",
              "colorHexCode": "#381d38",
              "styleCode": "RTIWXPL",
              "marketingTitle": "Plum Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Quartz",
              "colorHexCode": "#B69797",
              "styleCode": "RTIWXQZ",
              "marketingTitle": "Quartz Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Raspberry",
              "colorHexCode": "#772E59",
              "styleCode": "RTIWXRA",
              "marketingTitle": "Raspberry Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Regency",
              "colorHexCode": "#33215C",
              "styleCode": "RTIWXRG",
              "marketingTitle": "Regency Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rose Gold",
              "colorHexCode": "#99837F",
              "styleCode": "RTIWXRS",
              "marketingTitle": "Rose Gold Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rose Petal",
              "colorHexCode": "#E8B4C1",
              "styleCode": "RTIWXRP",
              "marketingTitle": "Rose Petal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Rosewood",
              "colorHexCode": "#5f3237",
              "styleCode": "RTIWXRW",
              "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Royal Blue",
              "colorHexCode": "#21508F",
              "styleCode": "RTIWXRO",
              "marketingTitle": "Royal Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Sage",
              "colorHexCode": "#B3BBA2",
              "styleCode": "RTIWXSE",
              "marketingTitle": "Sage Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Silver",
              "colorHexCode": "#A7AEB8",
              "styleCode": "RTIWXSI",
              "marketingTitle": "Silver Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Silver Metallic",
              "colorHexCode": "#C8C6C6",
              "styleCode": "RTIWXMS",
              "marketingTitle": "Silver Metallic Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Slate Blue",
              "colorHexCode": "#3F5167",
              "styleCode": "RTIWXSL",
              "marketingTitle": "Slate Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Spice",
              "colorHexCode": "#9B4D3E",
              "styleCode": "RTIWXSC",
              "marketingTitle": "Spice Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Taupe",
              "colorHexCode": "#CFC5C3",
              "styleCode": "RTIWXTP",
              "marketingTitle": "Taupe Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Teal",
              "colorHexCode": "#057085",
              "styleCode": "RTIWXTE",
              "marketingTitle": "Teal Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Tiffany Blue",
              "colorHexCode": "#90D2D5",
              "styleCode": "RTIWXTB",
              "marketingTitle": "Tiffany Blue Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Turquoise",
              "colorHexCode": "#5BACB3",
              "styleCode": "RTIWXTU",
              "marketingTitle": "Turquoise Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Viola",
              "colorHexCode": "#5C0A5A",
              "styleCode": "RTIWXVA",
              "marketingTitle": "Viola Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Watermelon",
              "colorHexCode": "#AC1848",
              "styleCode": "RTIWXWA",
              "marketingTitle": "Watermelon Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "White",
              "colorHexCode": "#FFFFFF",
              "styleCode": "RTIWXWH",
              "marketingTitle": "White Striped Expressions Windsor Tie"
            },
            {
              "colorShade": "Wine",
              "colorHexCode": "#54101B",
              "styleCode": "RTIWXWI",
              "marketingTitle": "Wine Striped Expressions Windsor Tie"
            }
          ],
          "colorHexCode": "#5f3237",
          "categories": [
            "Ties"
          ],
          "tieType": "Windsor",
          "companionStylePrice": 39,
          "images": [
            {
              "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
              "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
            },
            {
              "src": "windsor-tie-rose-petal-stripe-WXRP.jpg",
              "alt": "this is a test"
            },
            {
              "src": "windsor-tie-black-stripe-WXBL.jpg",
              "alt": "test 2"
            }
          ],
          "sizeScale": "A",
          "styleRank": 157,
          "styleFirestoreDocumentId": "b1EKl2zr3h3ByJqe5Rp4",
          "colorShadeId": "Rosewood",
          "warehouses": [
            "Anderson",
            "Atlanta",
            "Richmond",
            "Salina",
            "Seguin",
            "Trenton",
            "Visalia"
          ],
          "colorFamily": "Pink",
          "relatedStyles": [
            {
              "styleType": "PocketSquare",
              "images": [
                {
                  "src": "PK_PWRW_1280x1920.jpg",
                  "alt": "Rosewood Expressions Solid Pocket Square"
                }
              ],
              "styleGroup": "Purchase",
              "styleCode": "PPKPWRW",
              "marketingTitle": "Rosewood Expressions Pocket Square"
            },
            {
              "styleType": "Vest",
              "images": [
                {
                  "src": "fullback-vest-black-performance-stretch-F921.jpg",
                  "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                }
              ],
              "styleGroup": "Rental",
              "styleCode": "RVS921",
              "marketingTitle": "Black Performance Vest"
            }
          ],
          "collection": "Premium (PRM)",
          "styleCode": "RTIWXRW",
          "legacyStyleCode": "WXRW",
          "matchingStyles": [
            {
              "docId": "i393vAhanT6qXMCMWJDn",
              "styleCode": "PPKPWRW"
            }
          ],
          "marketingDescription": "### FEATURES:\n- Striped colored windsor tie\n- Pre-tied, expandable neck\n- Coordinates with any Matching Wool Vest\n- Matching solid pocket square is available\n\n### SIZE SCALE:\n - Men's and Boys' Sizes\n\n### FABRIC:\n - Polyester",
          "fabric": "Polyester",
          "mannequinImages": [
            {
              "mannequinImage": "WindsorTie",
              "src": "TI_WXRW_1280x1920.png",
              "alt": "Rosewood Expressions Striped Windsor Tie"
            }
          ],
          "colorShade": "Rosewood",
          "measurementScale1": "A",
          "publishedDate": 1711135542
        }
      ],
      "id": "12345",
      "title": "Look 2"
    }
  ],
  "memberIds": [
    "a508f5ab-2bb2-476c-b982-2be9a9cab6cb",
    "12345"
  ],
  "members": [
    {
      "surrogateUser": false,
      "firstName": "First",
      "lastName": "Member1",
      "phone": "0000000000",
      "memberProgress": {
        "inviteAccepted": 1719415453
      },
      "shippingAddress": {
        "zip": "",
        "city": "",
        "phone": "",
        "shippingMethod": "",
        "streetAddress1": "",
        "name": "",
        "streetAddress2": "",
        "state": ""
      },
      "id": "a508f5ab-2bb2-476c-b982-2be9a9cab6cb",
      "memberRole": "Groom",
      "email": "nstefanicjfw@gmail.com",
      "measurements": {},
      "memberLook": {
        "ensembleCode": "421",
        "price": 192,
        "styles": [
          {
            "keywords": [],
            "webReleaseDate": 1688014802,
            "styleGroup": "Rental",
            "companionStyleCode": "PPT921",
            "marketingTitle": "Black Performance Legacy Pants",
            "colors": [
              "Black"
            ],
            "fit": "Ultra Slim",
            "styleType": "Pant",
            "price": 48,
            "activeDate": 1682571600,
            "colorHexCode": "#000000",
            "categories": [],
            "companionStylePrice": 152,
            "images": [
              {
                "src": "black-performance-legacy-pants-921.jpg",
                "alt": "Black Performance Legacy Pants"
              }
            ],
            "sizeScale": "FLAT2",
            "styleRank": 1,
            "styleFirestoreDocumentId": "CeTKEZjFCizIms0q7gr5",
            "colorShadeId": "Black",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Vest",
                "images": [
                  {
                    "src": "fullback-vest-black-performance-stretch-F921.jpg",
                    "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RVS921",
                "marketingTitle": "Black Performance Vest"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                    "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT421",
                "marketingTitle": "Black Performance Wedding Suit Coat"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                    "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT921",
                "marketingTitle": "Black Legacy Tuxedo Coat"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                    "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT981",
                "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
              }
            ],
            "designer": "Michael Kors",
            "styleCode": "RPT921",
            "legacyStyleCode": "921",
            "matchingStyles": [
              {
                "docId": "DrSpbZEVmCJdWrhva9cl",
                "styleCode": "PVS921"
              },
              {
                "docId": "DrSpbZEVmCJdWrhva9cl",
                "styleCode": "RCT421"
              },
              {
                "docId": "dEHuyoH7F7yVXSgabD64",
                "styleCode": "RCT981"
              },
              {
                "docId": "dEHuyoH7F7yVXSgabD64",
                "styleCode": "RVS921"
              },
              {
                "docId": "zg6xNgr4zbkkvXXAZIFO",
                "styleCode": "PVS921"
              },
              {
                "docId": "zg6xNgr4zbkkvXXAZIFO",
                "styleCode": "RCT921"
              }
            ],
            "marketingDescription": "### FEATURES:\n- Available to rent or buy\n- Flat front with adjustable waist\n- Belt loops and buttons to accommodate suspenders\n- Coordinates with Black Performance Stretch Suits and Tuxedo\n\n### SIZE SCALE:\n921 pant sizes: 18-71\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
            "fabric": "CoolMax/Sorona/Wool",
            "suspenderButtons": true,
            "mannequinImages": [
              {
                "mannequinImage": "Pant",
                "src": "PT_921_1280x1920.png",
                "alt": "Black Performance Legacy Pants"
              }
            ],
            "colorShade": "Black",
            "waist": "Adjustable",
            "beltLoops": true,
            "publishedDate": 1698107750
          },
          {
            "keywords": [
              "belt",
              "reversible",
              "black",
              "brown"
            ],
            "webReleaseDate": 1683522000,
            "styleGroup": "Purchase",
            "marketingTitle": "Black/Brown Reversible Belt",
            "colors": [
              "Black/Brown"
            ],
            "styleType": "Miscellaneous",
            "price": 24,
            "activeDate": 1683522000,
            "colorHexCode": "#000000",
            "categories": [
              "Accessories"
            ],
            "images": [
              {
                "src": "belt-black-tan-reversible-NBLT.jpg",
                "alt": "Black/Brown Reversible Belt"
              }
            ],
            "styleRank": 1,
            "styleFirestoreDocumentId": "jMgaN4ySnwR4M7G8I1MQ",
            "colorShadeId": "BlackBrown",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Ottawa",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "relatedStyles": [],
            "colorFamily": "Black",
            "collection": "Resale",
            "styleCode": "PMSBLT",
            "miscellaneousType": "Belt",
            "legacyStyleCode": "NBLT",
            "marketingDescription": "### FEATURES:\n- Synthetic leather\n- Reversible black & brown\n- Platinum burnished buckle, stitched edges, 3.5 cm wide\n\n### SIZE SCALE:\n- XS: 21-24\n- S: 25-28\n- M: 29-32\n- L: 33-36\n- XL: 37-40\n- 2X: 41-44\n- 3X: 45-28",
            "fabric": "Leather",
            "mannequinImages": [
              {
                "mannequinImage": "Miscellaneous",
                "src": "belt-RED-tan-reversible-NBLT.jpg",
                "alt": "Black/Brown Reversible Belt"
              }
            ],
            "colorShade": "Black/Brown",
            "publishedDate": 1698108942
          },
          {
            "vestType": "Fullback",
            "keywords": [],
            "webReleaseDate": 1688101200,
            "styleGroup": "Rental",
            "vestBack": "Matching",
            "companionStyleCode": "PVS921",
            "program": "Matching",
            "marketingTitle": "Black Performance Vest",
            "colors": [
              "Black"
            ],
            "styleType": "Vest",
            "price": 32,
            "activeDate": 1684731600,
            "colorHexCode": "#000000",
            "categories": [
              "VestsAndCummerbunds"
            ],
            "companionStylePrice": 79,
            "images": [
              {
                "src": "fullback-vest-black-performance-stretch-F921.jpg",
                "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
              }
            ],
            "sizeScale": "VST16",
            "buttons": 4,
            "styleRank": 1,
            "styleFirestoreDocumentId": "2Zg4MDqr3PPnsAOSaAKJ",
            "colorShadeId": "Black",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-tuxedo-black-performance-michael-kors-legacy-921-1.jpg",
                    "alt": "Wedding Tuxedo Black Performance Michael Kors Legacy with Matching Vest and Black Satin Bow Tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT921",
                "marketingTitle": "Black Legacy Tuxedo Coat"
              },
              {
                "styleType": "Pant",
                "images": [
                  {
                    "src": "black-performance-legacy-pants-921.jpg",
                    "alt": "Black Performance Legacy Pants"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RPT921",
                "marketingTitle": "Black Performance Legacy Pants"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                    "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT421",
                "marketingTitle": "Black Performance Wedding Suit Coat"
              },
              {
                "styleType": "Coat",
                "images": [
                  {
                    "src": "wedding-tuxedo-force-performance-stretch-michael-kors-981.jpg",
                    "alt": "man wearing Michael Kors Force Black Performance Stretch Tuxedo and yellow tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RCT981",
                "marketingTitle": "Black Force Performance Stretch Tuxedo Coat"
              }
            ],
            "designer": "Michael Kors",
            "styleCode": "RVS921",
            "legacyStyleCode": "F921",
            "matchingStyles": [
              {
                "docId": "dEHuyoH7F7yVXSgabD64",
                "styleCode": "RCT981"
              },
              {
                "docId": "dEHuyoH7F7yVXSgabD64",
                "styleCode": "RPT921"
              }
            ],
            "marketingDescription": "### FEATURES:\n- 4-button\n- Adjustable back\n- Darted pockets\n- Matching colored back\n\n### SIZE SCALE:\n- Boys' Small - Men's 5XA\n\n### FABRIC:\n- Polyester",
            "fabric": "CoolMax/Sorona/Wool",
            "mannequinImages": [
              {
                "mannequinImage": "Vest",
                "src": "VS_F921_1280x1920.png",
                "alt": "Michael Kors Black Performance Stretch Wedding Vest"
              }
            ],
            "colorShade": "Black",
            "measurementScale1": "VST18",
            "publishedDate": 1698428370,
            "pocket": "Darted"
          },
          {
            "keywords": [
              "cufflinks",
              "studs",
              "jewlry",
              "silver"
            ],
            "webReleaseDate": 1694667600,
            "styleGroup": "Rental",
            "marketingTitle": "Black/Silver Basic Jewelry",
            "colors": [
              "Black"
            ],
            "styleType": "Jewelry",
            "price": 2,
            "activeDate": 1194242400,
            "colorHexCode": "#000000",
            "finish": "Silver",
            "categories": [
              "CufflinksAndStuds"
            ],
            "images": [
              {
                "src": "JW_BL_1280x1920.jpg",
                "alt": "Black/Silver Basic Jewelry"
              }
            ],
            "styleRank": 1,
            "styleFirestoreDocumentId": "JZaae4iAZVssS3k3E0nE",
            "colorShadeId": "Black",
            "jewelryType": "Full Set",
            "warehouses": [
              "Trenton",
              "Anderson",
              "Atlanta",
              "Seguin",
              "Visalia",
              "Salina",
              "Richmond"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Shirt",
                "images": [
                  {
                    "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
                    "alt": "White Fitted Microfiber Shirt"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RSR96W",
                "marketingTitle": "White Fitted Microfiber Shirt"
              }
            ],
            "collection": "Basic",
            "styleCode": "RJWBL",
            "legacyStyleCode": "BL",
            "marketingDescription": "### FEATURES:\n- Set includes 2 cufflinks and 4 shirt studs\n- Toggle back\n- Faux onyx stone framed in silver-toned metal",
            "mannequinImages": [
              {
                "mannequinImage": "Jewelry",
                "src": "JW_BL_1280x1920.jpg",
                "alt": "Black/Silver Basic Jewelry"
              }
            ],
            "colorShade": "Black",
            "publishedDate": 1699627147
          },
          {
            "coatType": "Suit",
            "vent": "Side",
            "keywords": [],
            "webReleaseDate": 1688101200,
            "styleGroup": "Rental",
            "companionStyleCode": "PCT421",
            "marketingTitle": "Black Performance Wedding Suit Coat",
            "colors": [
              "Black"
            ],
            "coatLength": 29.5,
            "fit": "Ultra Slim",
            "styleType": "Coat",
            "price": 190,
            "activeDate": 1684731600,
            "colorHexCode": "#000000",
            "categories": [],
            "companionStylePrice": 330,
            "lapel": "Notch",
            "images": [
              {
                "src": "wedding-suit-black-performance-stretch-michael-kors-421.jpg\t",
                "alt": "man wearing Michael Kors black performance stretch suit with matching vest and rosewood striped windsor tie"
              }
            ],
            "sizeScale": "B",
            "buttons": 2,
            "styleRank": 2,
            "styleFirestoreDocumentId": "Fa6MNKeF1oTFv4A5gzD0",
            "colorShadeId": "Black",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Vest",
                "images": [
                  {
                    "src": "https://www.jimsformalwear.com/images/1280x1920/fullback-vest-black-performance-stretch-F921.jpg",
                    "alt": "Black Performance Vest"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RVS921",
                "marketingTitle": "Black Performance Vest"
              }
            ],
            "collection": "Diamond",
            "designer": "Michael Kors",
            "styleCode": "RCT421",
            "legacyStyleCode": "421",
            "matchingStyles": [
              {
                "docId": "DrSpbZEVmCJdWrhva9cl",
                "styleCode": "RPT921"
              },
              {
                "docId": "DrSpbZEVmCJdWrhva9cl",
                "styleCode": "PVS921"
              }
            ],
            "marketingDescription": "### FEATURES:\n- Available to Rent or Buy\n- Tailored to fit all body types\n- Self-framed notch lapel, side-vented, 29 1/2\" length. \n\n### SIZE SCALE:\n- Men's Coat Sizes: 34-74\n- Boys' Coat Sizes: 3-18\n\n### FABRIC:\nCoolMax / Sorona / Wool\n\n### FIT:\nUltra Slim Fit",
            "fabric": "CoolMax/Sorona/Wool",
            "recommendedStyles": [
              "RPT921"
            ],
            "mannequinImages": [
              {
                "mannequinImage": "Coat",
                "src": "CT_421_1280x1920.png",
                "alt": "Black Performance Wedding Suit Coat"
              }
            ],
            "colorShade": "Black",
            "measurementScale1": "CSTZ",
            "publishedDate": 1697577801,
            "pocket": "Chest"
          },
          {
            "keywords": [
              "white",
              "ivory",
              "black",
              "shirt",
              "fitted",
              "formal shirt"
            ],
            "webReleaseDate": 1688101200,
            "styleGroup": "Rental",
            "companionStyleCode": "PSR96W",
            "marketingTitle": "White Fitted Microfiber Shirt",
            "colors": [
              "White"
            ],
            "collarType": "Laydown",
            "fit": "Fitted",
            "styleType": "Shirt",
            "price": 28,
            "activeDate": 1683522000,
            "colorHexCode": "#FFFFFF",
            "categories": [
              "Shirts"
            ],
            "companionStylePrice": 54,
            "images": [
              {
                "src": "microfiber-fitted-formal-shirt-white-96W.jpg",
                "alt": "White Fitted Microfiber Shirt"
              }
            ],
            "sizeScale": "FIT",
            "styleRank": 1,
            "styleFirestoreDocumentId": "cP3bWqMFOifb38CvszqT",
            "colorShadeId": "White",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "White/Ivory",
            "relatedStyles": [
              {
                "styleType": "Jewelry",
                "images": [
                  {
                    "src": "JW_BG_1280x1920.jpg",
                    "alt": "Black/Gold Premium Jewelry"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RJWBG",
                "marketingTitle": "Black/Gold Premium Jewelry"
              },
              {
                "styleType": "Jewelry",
                "images": [
                  {
                    "src": "JW_BL_1280x1920.jpg",
                    "alt": "Black/Silver Basic Jewelry"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RJWBL",
                "marketingTitle": "Black/Silver Basic Jewelry"
              },
              {
                "styleType": "Jewelry",
                "images": [
                  {
                    "src": "cufflinks-studs-tuxedo-jewelry-BS.jpg",
                    "alt": "Black/Silver Premium Jewelry"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RJWBS",
                "marketingTitle": "Black/Silver Premium Jewelry"
              },
              {
                "styleType": "Jewelry",
                "images": [
                  {
                    "src": "JW_WH_1280x1920.jpg",
                    "alt": "White/Silver Basic Jewelry"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RJWWH",
                "marketingTitle": "White/Silver Basic Jewelry"
              }
            ],
            "collection": "Premium (PRM)",
            "styleCode": "RSR96W",
            "legacyStyleCode": "96W",
            "marketingDescription": "### FEATURES:\n- Non-pleated front with tapered sides\n- Laydown collar, barrel cuff\n- Available in White, Ivory, and Black\n\n### SIZE SCALE:\nBoys' Large - Men's 3XL\n\n### FABRIC:\nPremium Microfiber\n\n### FIT:\nSlim Fit",
            "fabric": "Microfiber",
            "mannequinImages": [
              {
                "mannequinImage": "Shirt",
                "src": "SR_96W_1280x1920.png",
                "alt": "Shirt"
              },
              {
                "mannequinImage": "ShirtExtra",
                "src": "SR_96W_C_1280x1920_extra.png",
                "alt": "Shirt Collar"
              }
            ],
            "colorShade": "White",
            "publishedDate": 1701098610,
            "shirtFront": "Flat Front"
          },
          {
            "keywords": [],
            "webReleaseDate": 1697778000,
            "pattern": "Solid",
            "styleGroup": "Purchase",
            "program": "Expressions",
            "marketingTitle": "Rosewood Expressions Pocket Square",
            "colors": [
              "Rosewood"
            ],
            "styleType": "PocketSquare",
            "price": 10,
            "activeDate": 1684731600,
            "altColors": [
              {
                "colorShade": "Green",
                "colorHexCode": "#730000",
                "styleCode": "PPKPWAR",
                "marketingTitle": "Green Expressions Pocket Square"
              },
              {
                "colorShade": "Aqua",
                "colorHexCode": "#70BCA5",
                "styleCode": "PPKPWAQ",
                "marketingTitle": "Aqua Expressions Pocket Square"
              },
              {
                "colorShade": "Ballet",
                "colorHexCode": "#D2A29E",
                "styleCode": "PPKPWBA",
                "marketingTitle": "Ballet Expressions Pocket Square"
              },
              {
                "colorShade": "Bellini",
                "colorHexCode": "#DAB7A6",
                "styleCode": "PPKPWBE",
                "marketingTitle": "Bellini Expressions Pocket Square"
              },
              {
                "colorShade": "Black",
                "colorHexCode": "#000000",
                "styleCode": "PPKPWBL",
                "marketingTitle": "Black Expressions Pocket Square"
              },
              {
                "colorShade": "Blue Box",
                "colorHexCode": "#75C7CE",
                "styleCode": "PPKPWBB",
                "marketingTitle": "Blue Box Expressions Pocket Square"
              },
              {
                "colorShade": "Blush",
                "colorHexCode": "#B58B88",
                "styleCode": "PPKPWBH",
                "marketingTitle": "Blush Expressions Pocket Square"
              },
              {
                "colorShade": "Brite Pink",
                "colorHexCode": "#DF588A",
                "styleCode": "PPKPWBP",
                "marketingTitle": "Brite Pink Expressions Pocket Square"
              },
              {
                "colorShade": "Buttercup",
                "colorHexCode": "#EDD66A",
                "styleCode": "PPKPWBC",
                "marketingTitle": "Buttercup Expressions Pocket Square"
              },
              {
                "colorShade": "Champagne",
                "colorHexCode": "#A29581",
                "styleCode": "PPKPWCP",
                "marketingTitle": "Champagne Expressions Pocket Square"
              },
              {
                "colorShade": "Charcoal",
                "colorHexCode": "#3E3E3E",
                "styleCode": "PPKPWCA",
                "marketingTitle": "Charcoal Expressions Pocket Square"
              },
              {
                "colorShade": "Cherry",
                "colorHexCode": "#7F212E",
                "styleCode": "PPKPWCY",
                "marketingTitle": "Cherry Expressions Pocket Square"
              },
              {
                "colorShade": "Chocolate",
                "colorHexCode": "#4E3D30",
                "styleCode": "PPKPWCH",
                "marketingTitle": "Chocolate Expressions Pocket Square"
              },
              {
                "colorShade": "Cinnamon",
                "colorHexCode": "#79342A",
                "styleCode": "PPKPWCI",
                "marketingTitle": "Cinnamon Expressions Pocket Square"
              },
              {
                "colorShade": "Cobalt",
                "colorHexCode": "#2d406f",
                "styleCode": "PPKPWCB",
                "marketingTitle": "Cobalt Expressions Pocket Square"
              },
              {
                "colorShade": "Coral",
                "colorHexCode": "#F6A4A3",
                "styleCode": "PPKPWCO",
                "marketingTitle": "Coral Expressions Pocket Square"
              },
              {
                "colorShade": "Cornflower",
                "colorHexCode": "#647EB0",
                "styleCode": "PPKPWCF",
                "marketingTitle": "Cornflower Expressions Pocket Square"
              },
              {
                "colorShade": "Cranberry",
                "colorHexCode": "#490B14",
                "styleCode": "PPKPWCE",
                "marketingTitle": "Cranberry Expressions Pocket Square"
              },
              {
                "colorShade": "Dark Coral",
                "colorHexCode": "#E17A74",
                "styleCode": "PPKPWDC",
                "marketingTitle": "Dark Coral Expressions Pocket Square"
              },
              {
                "colorShade": "Dusty Blue",
                "colorHexCode": "#8B98A7",
                "styleCode": "PPKPWDB",
                "marketingTitle": "Dusty Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Dusty Rose",
                "colorHexCode": "#E3D0D7",
                "styleCode": "PPKPWDR",
                "marketingTitle": "Dusty Rose Expressions Pocket Square"
              },
              {
                "colorShade": "Emerald",
                "colorHexCode": "#05493B",
                "styleCode": "PPKPWEM",
                "marketingTitle": "Emerald Expressions Pocket Square"
              },
              {
                "colorShade": "Evergreen/Moss",
                "colorHexCode": "#757B63",
                "styleCode": "PPKPWEV",
                "marketingTitle": "Evergreen/Moss Expressions Pocket Square"
              },
              {
                "colorShade": "Ferrari Red",
                "colorHexCode": "#A60A20",
                "styleCode": "PPKPWFR",
                "marketingTitle": "Ferrari Red Expressions Pocket Square"
              },
              {
                "colorShade": "First Blush",
                "colorHexCode": "#B69797",
                "styleCode": "PPKPWFB",
                "marketingTitle": "First Blush Expressions Pocket Square"
              },
              {
                "colorShade": "Forest",
                "colorHexCode": "#2F4437",
                "styleCode": "PPKPWFO",
                "marketingTitle": "Forest Expressions Pocket Square"
              },
              {
                "colorShade": "Gold",
                "colorHexCode": "#8b7256",
                "styleCode": "PPKPWGO",
                "marketingTitle": "Gold Expressions Pocket Square"
              },
              {
                "colorShade": "Gold Metallic",
                "colorHexCode": "#C0B065",
                "styleCode": "PPKPWMG",
                "marketingTitle": "Gold Metallic Expressions Pocket Square"
              },
              {
                "colorShade": "Golden",
                "colorHexCode": "#A7956D",
                "styleCode": "PPKPWGL",
                "marketingTitle": "Golden Expressions Pocket Square"
              },
              {
                "colorShade": "Hunter Green",
                "colorHexCode": "#133B2F",
                "styleCode": "PPKPWHG",
                "marketingTitle": "Hunter Green Expressions Pocket Square"
              },
              {
                "colorShade": "Ivory",
                "colorHexCode": "#EEE9DF",
                "styleCode": "PPKPWIV",
                "marketingTitle": "Ivory Expressions Pocket Square"
              },
              {
                "colorShade": "Lavender",
                "colorHexCode": "#938BA8",
                "styleCode": "PPKPWLA",
                "marketingTitle": "Lavender Expressions Pocket Square"
              },
              {
                "colorShade": "Lite Blue",
                "colorHexCode": "#9FBBD7",
                "styleCode": "PPKPWLB",
                "marketingTitle": "Lite Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Lite Watermelon",
                "colorHexCode": "#D5487D",
                "styleCode": "PPKPWLW",
                "marketingTitle": "Lite Watermelon Expressions Pocket Square"
              },
              {
                "colorShade": "Malibu",
                "colorHexCode": "#369FC4",
                "styleCode": "PPKPWML",
                "marketingTitle": "Malibu Expressions Pocket Square"
              },
              {
                "colorShade": "Mint",
                "colorHexCode": "#AAD6BA",
                "styleCode": "PPKPWMN",
                "marketingTitle": "Mint Expressions Pocket Square"
              },
              {
                "colorShade": "Navy",
                "colorHexCode": "#25334D",
                "styleCode": "PPKPWNA",
                "marketingTitle": "Navy Expressions Pocket Square"
              },
              {
                "colorShade": "Nude",
                "colorHexCode": "#C7B097",
                "styleCode": "PPKPWNU",
                "marketingTitle": "Nude Expressions Pocket Square"
              },
              {
                "colorShade": "Pink Rose Gold",
                "colorHexCode": "#D4B1AA",
                "styleCode": "PPKPWPR",
                "marketingTitle": "Pink Rose Gold Expressions Pocket Square"
              },
              {
                "colorShade": "Platinum",
                "colorHexCode": "#A4A7AD",
                "styleCode": "PPKPWPT",
                "marketingTitle": "Platinum Expressions Pocket Square"
              },
              {
                "colorShade": "Plum",
                "colorHexCode": "#381d38",
                "styleCode": "PPKPWPL",
                "marketingTitle": "Plum Expressions Pocket Square"
              },
              {
                "colorShade": "Quartz",
                "colorHexCode": "#B69797",
                "styleCode": "PPKPWQZ",
                "marketingTitle": "Quartz Expressions Pocket Square"
              },
              {
                "colorShade": "Raspberry",
                "colorHexCode": "#772E59",
                "styleCode": "PPKPWRA",
                "marketingTitle": "Raspberry Expressions Pocket Square"
              },
              {
                "colorShade": "Regency",
                "colorHexCode": "#33215C",
                "styleCode": "PPKPWRG",
                "marketingTitle": "Regency Expressions Pocket Square"
              },
              {
                "colorShade": "Rose Gold",
                "colorHexCode": "#99837F",
                "styleCode": "PPKPWRS",
                "marketingTitle": "Rose Gold Expressions Pocket Square"
              },
              {
                "colorShade": "Rose Petal",
                "colorHexCode": "#E8B4C1",
                "styleCode": "PPKPWRP",
                "marketingTitle": "Rose Petal Expressions Pocket Square"
              },
              {
                "colorShade": "Rosewood",
                "colorHexCode": "#5f3237",
                "styleCode": "PPKPWRW",
                "marketingTitle": "Rosewood Expressions Pocket Square"
              },
              {
                "colorShade": "Royal Blue",
                "colorHexCode": "#21508F",
                "styleCode": "PPKPWRO",
                "marketingTitle": "Royal Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Sage",
                "colorHexCode": "#B3BBA2",
                "styleCode": "PPKPWSE",
                "marketingTitle": "Sage Expressions Pocket Square"
              },
              {
                "colorShade": "Silver",
                "colorHexCode": "#A7AEB8",
                "styleCode": "PPKPWSI",
                "marketingTitle": "Silver Expressions Pocket Square"
              },
              {
                "colorShade": "Silver Metallic",
                "colorHexCode": "#C8C6C6",
                "styleCode": "PPKPWMS",
                "marketingTitle": "Silver Metallic Expressions Pocket Square"
              },
              {
                "colorShade": "Slate Blue",
                "colorHexCode": "#3F5167",
                "styleCode": "PPKPWSL",
                "marketingTitle": "Slate Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Spice",
                "colorHexCode": "#9B4D3E",
                "styleCode": "PPKPWSC",
                "marketingTitle": "Spice Expressions Pocket Square"
              },
              {
                "colorShade": "Taupe",
                "colorHexCode": "#CFC5C3",
                "styleCode": "PPKPWTP",
                "marketingTitle": "Taupe Expressions Pocket Square"
              },
              {
                "colorShade": "Teal",
                "colorHexCode": "#057085",
                "styleCode": "PPKPWTE",
                "marketingTitle": "Teal Expressions Pocket Square"
              },
              {
                "colorShade": "Tiffany Blue",
                "colorHexCode": "#90D2D5",
                "styleCode": "PPKPWTB",
                "marketingTitle": "Tiffany Blue Expressions Pocket Square"
              },
              {
                "colorShade": "Turquoise",
                "colorHexCode": "#5BACB3",
                "styleCode": "PPKPWTU",
                "marketingTitle": "Turquoise Expressions Pocket Square"
              },
              {
                "colorShade": "Viola",
                "colorHexCode": "#5C0A5A",
                "styleCode": "PPKPWVA",
                "marketingTitle": "Viola Expressions Pocket Square"
              },
              {
                "colorShade": "Watermelon",
                "colorHexCode": "#AC1848",
                "styleCode": "PPKPWWA",
                "marketingTitle": "Watermelon Expressions Pocket Square"
              },
              {
                "colorShade": "White",
                "colorHexCode": "#FFFFFF",
                "styleCode": "PPKPWWH",
                "marketingTitle": "White Expressions Pocket Square"
              },
              {
                "colorShade": "Wine",
                "colorHexCode": "#54101B",
                "styleCode": "PPKPWWI",
                "marketingTitle": "Wine Expressions Pocket Square"
              }
            ],
            "colorHexCode": "#5f3237",
            "categories": [
              "Accessories"
            ],
            "images": [
              {
                "src": "PK_PWRW_1280x1920.jpg",
                "alt": "Rosewood Expressions Solid Pocket Square"
              }
            ],
            "styleRank": 4,
            "styleFirestoreDocumentId": "7udSrg2Iv9KxWMIAE5VX",
            "colorShadeId": "Rosewood",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Pink",
            "relatedStyles": [
              {
                "styleType": "Tie",
                "images": [
                  {
                    "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
                    "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RTIWXRW",
                "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
              }
            ],
            "styleCode": "PPKPWRW",
            "legacyStyleCode": "PWRW",
            "matchingStyles": [
              {
                "docId": "i393vAhanT6qXMCMWJDn",
                "styleCode": "RTIWXRW"
              }
            ],
            "marketingDescription": "### FEATURES:\n- Hand-sewn edges\n- 9\" x 9\"\n- Polyester\n- Hand wash",
            "mannequinImages": [
              {
                "mannequinImage": "PocketSquare",
                "src": "PK_PWRW_1280x1920.jpg",
                "alt": "Rosewood Expressions Solid Pocket Square"
              }
            ],
            "colorShade": "Rosewood",
            "publishedDate": 1697835035
          },
          {
            "keywords": [],
            "webReleaseDate": 1688533200,
            "styleGroup": "Rental",
            "companionStyleCode": "PSOBXS",
            "marketingTitle": "Black Oxford Shoe",
            "colors": [
              "Black"
            ],
            "styleType": "Shoes",
            "shoeType": "Oxford",
            "price": 30,
            "activeDate": 1684731600,
            "altColors": [
              {
                "colorShade": "Black",
                "colorHexCode": "#000000",
                "styleCode": "RSOBDS",
                "marketingTitle": "Black Dunbar Shoe"
              },
              {
                "colorShade": "Black",
                "colorHexCode": "#000000",
                "styleCode": "RSOBXS",
                "marketingTitle": "Black Oxford Shoe"
              },
              {
                "colorShade": "Chestnut",
                "colorHexCode": "#91533d",
                "styleCode": "RSOCOP",
                "marketingTitle": "Chestnut Oxford Plain Toe Shoe"
              },
              {
                "colorShade": "Cognac",
                "colorHexCode": "#6C423D",
                "styleCode": "RSOCXS",
                "marketingTitle": "Cognac Oxford Shoe"
              },
              {
                "colorShade": "White",
                "colorHexCode": "#FFFFFF",
                "styleCode": "RSOWAS",
                "marketingTitle": "White Allegro Shoe"
              }
            ],
            "colorHexCode": "#000000",
            "categories": [
              "Shoes"
            ],
            "companionStylePrice": 79,
            "images": [
              {
                "src": "SO-BXS-1280x1920.jpg",
                "alt": "Black Oxford Shoe"
              }
            ],
            "sizeScale": "C",
            "styleRank": 3,
            "styleFirestoreDocumentId": "O0Xb8rmylCTlFstqKJCu",
            "colorShadeId": "Black",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Black",
            "relatedStyles": [
              {
                "styleType": "Socks",
                "images": [
                  {
                    "src": "colored-socks-royal-blue-XSRO.jpg",
                    "alt": "Royal Blue Socks"
                  }
                ],
                "styleGroup": "Purchase",
                "styleCode": "PHSXSRO",
                "marketingTitle": "Royal Blue Socks"
              },
              {
                "styleType": "Socks",
                "images": [
                  {
                    "src": "patterned-socks-wine-argyle-XPWG.jpg",
                    "alt": "Wine Grey Argyle Socks"
                  }
                ],
                "styleGroup": "Purchase",
                "styleCode": "PHSXPWG",
                "marketingTitle": "Wine Grey Argyle Socks"
              },
              {
                "styleType": "Socks",
                "images": [
                  {
                    "src": "colored-socks-black-XBLK.jpg",
                    "alt": "Black Socks"
                  }
                ],
                "styleGroup": "Purchase",
                "styleCode": "PHSXBLK",
                "marketingTitle": "Black Socks"
              }
            ],
            "styleCode": "RSOBXS",
            "legacyStyleCode": "BXS",
            "material": "Leather",
            "marketingDescription": "### FEATURES:\n- Synthetic Leather\n- Cap toe, lace up closure\n- Premium cushioned footbed and flex construction \n\n### SIZE SCALE:\n- Men's Medium Width (C/D): 6 - 18 (half sizes up to 11.5)\n- Men's Wide Width (E/EE): 7-18 (half sizes up to 11.5)\n- Boys' Medium Width: 7 - 5.5",
            "mannequinImages": [
              {
                "mannequinImage": "Shoes",
                "src": "SO-BXS-1280x1920.jpg",
                "alt": "Black Oxford Shoe"
              }
            ],
            "colorShade": "Black",
            "measurementScale1": "BASIC",
            "publishedDate": 1701715960,
            "measurementScale2": "BASIC"
          },
          {
            "keywords": [],
            "webReleaseDate": 1697432400,
            "pattern": "Striped",
            "styleGroup": "Rental",
            "companionStyleCode": "PTIWXRW",
            "program": "Expressions",
            "marketingTitle": "Rosewood Striped Expressions Windsor Tie",
            "colors": [
              "Rosewood"
            ],
            "styleType": "Tie",
            "price": 17,
            "activeDate": 1684731600,
            "altColors": [
              {
                "colorShade": "Apple Red",
                "colorHexCode": "#730000",
                "styleCode": "RTIWXAR",
                "marketingTitle": "Apple Red Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Aqua",
                "colorHexCode": "#70BCA5",
                "styleCode": "RTIWXAQ",
                "marketingTitle": "Aqua Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Ballet",
                "colorHexCode": "#D2A29E",
                "styleCode": "RTIWXBA",
                "marketingTitle": "Ballet Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Bellini",
                "colorHexCode": "#DAB7A6",
                "styleCode": "RTIWXBE",
                "marketingTitle": "Bellini Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Black",
                "colorHexCode": "#000000",
                "styleCode": "RTIWXBL",
                "marketingTitle": "Black Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Blue Box",
                "colorHexCode": "#75C7CE",
                "styleCode": "RTIWXBB",
                "marketingTitle": "Blue Box Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Blush",
                "colorHexCode": "#B58B88",
                "styleCode": "RTIWXBH",
                "marketingTitle": "Blush Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Brite Pink",
                "colorHexCode": "#DF588A",
                "styleCode": "RTIWXBP",
                "marketingTitle": "Brite Pink Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Buttercup",
                "colorHexCode": "#EDD66A",
                "styleCode": "RTIWXBC",
                "marketingTitle": "Buttercup Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Champagne",
                "colorHexCode": "#A29581",
                "styleCode": "RTIWXCP",
                "marketingTitle": "Champagne Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Charcoal",
                "colorHexCode": "#3E3E3E",
                "styleCode": "RTIWXCA",
                "marketingTitle": "Charcoal Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cherry",
                "colorHexCode": "#7F212E",
                "styleCode": "RTIWXCY",
                "marketingTitle": "Cherry Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Chocolate",
                "colorHexCode": "#4E3D30",
                "styleCode": "RTIWXCH",
                "marketingTitle": "Chocolate Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cinnamon",
                "colorHexCode": "#79342A",
                "styleCode": "RTIWXCI",
                "marketingTitle": "Cinnamon Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cobalt",
                "colorHexCode": "#2d406f",
                "styleCode": "RTIWXCB",
                "marketingTitle": "Cobalt Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Coral",
                "colorHexCode": "#F6A4A3",
                "styleCode": "RTIWXCO",
                "marketingTitle": "Coral Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cornflower",
                "colorHexCode": "#647EB0",
                "styleCode": "RTIWXCF",
                "marketingTitle": "Cornflower Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Cranberry",
                "colorHexCode": "#490B14",
                "styleCode": "RTIWXCE",
                "marketingTitle": "Cranberry Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Dark Coral",
                "colorHexCode": "#E17A74",
                "styleCode": "RTIWXDC",
                "marketingTitle": "Dark Coral Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Dusty Blue",
                "colorHexCode": "#8B98A7",
                "styleCode": "RTIWXDB",
                "marketingTitle": "Dusty Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Dusty Rose",
                "colorHexCode": "#E3D0D7",
                "styleCode": "RTIWXDR",
                "marketingTitle": "Dusty Rose Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Emerald",
                "colorHexCode": "#05493B",
                "styleCode": "RTIWXEM",
                "marketingTitle": "Emerald Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Evergreen/Moss",
                "colorHexCode": "#757B63",
                "styleCode": "RTIWXEV",
                "marketingTitle": "Evergreen/Moss Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Ferrari Red",
                "colorHexCode": "#A60A20",
                "styleCode": "RTIWXFR",
                "marketingTitle": "Ferrari Red Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "First Blush",
                "colorHexCode": "#B69797",
                "styleCode": "RTIWXFB",
                "marketingTitle": "First Blush Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Forest",
                "colorHexCode": "#2F4437",
                "styleCode": "RTIWXFO",
                "marketingTitle": "Forest Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Gold",
                "colorHexCode": "#8b7256",
                "styleCode": "RTIWXGO",
                "marketingTitle": "Gold Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Gold Metallic",
                "colorHexCode": "#C0B065",
                "styleCode": "RTIWXMG",
                "marketingTitle": "Gold Metallic Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Golden",
                "colorHexCode": "#A7956D",
                "styleCode": "RTIWXGL",
                "marketingTitle": "Golden Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Hunter Green",
                "colorHexCode": "#133B2F",
                "styleCode": "RTIWXHG",
                "marketingTitle": "Hunter Green Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Ivory",
                "colorHexCode": "#EEE9DF",
                "styleCode": "RTIWXIV",
                "marketingTitle": "Ivory Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Lavender",
                "colorHexCode": "#938BA8",
                "styleCode": "RTIWXLA",
                "marketingTitle": "Lavender Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Lite Blue",
                "colorHexCode": "#9FBBD7",
                "styleCode": "RTIWXLB",
                "marketingTitle": "Lite Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Lite Watermelon",
                "colorHexCode": "#D5487D",
                "styleCode": "RTIWXLW",
                "marketingTitle": "Lite Watermelon Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Malibu",
                "colorHexCode": "#369FC4",
                "styleCode": "RTIWXML",
                "marketingTitle": "Malibu Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Mint",
                "colorHexCode": "#AAD6BA",
                "styleCode": "RTIWXMN",
                "marketingTitle": "Mint Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Navy",
                "colorHexCode": "#25334D",
                "styleCode": "RTIWXNA",
                "marketingTitle": "Navy Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Nude",
                "colorHexCode": "#C7B097",
                "styleCode": "RTIWXNU",
                "marketingTitle": "Nude Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Pink Rose Gold",
                "colorHexCode": "#D4B1AA",
                "styleCode": "RTIWXPR",
                "marketingTitle": "Pink Rose Gold Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Platinum",
                "colorHexCode": "#A4A7AD",
                "styleCode": "RTIWXPT",
                "marketingTitle": "Platinum Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Plum",
                "colorHexCode": "#381d38",
                "styleCode": "RTIWXPL",
                "marketingTitle": "Plum Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Quartz",
                "colorHexCode": "#B69797",
                "styleCode": "RTIWXQZ",
                "marketingTitle": "Quartz Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Raspberry",
                "colorHexCode": "#772E59",
                "styleCode": "RTIWXRA",
                "marketingTitle": "Raspberry Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Regency",
                "colorHexCode": "#33215C",
                "styleCode": "RTIWXRG",
                "marketingTitle": "Regency Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Rose Gold",
                "colorHexCode": "#99837F",
                "styleCode": "RTIWXRS",
                "marketingTitle": "Rose Gold Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Rose Petal",
                "colorHexCode": "#E8B4C1",
                "styleCode": "RTIWXRP",
                "marketingTitle": "Rose Petal Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Rosewood",
                "colorHexCode": "#5f3237",
                "styleCode": "RTIWXRW",
                "marketingTitle": "Rosewood Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Royal Blue",
                "colorHexCode": "#21508F",
                "styleCode": "RTIWXRO",
                "marketingTitle": "Royal Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Sage",
                "colorHexCode": "#B3BBA2",
                "styleCode": "RTIWXSE",
                "marketingTitle": "Sage Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Silver",
                "colorHexCode": "#A7AEB8",
                "styleCode": "RTIWXSI",
                "marketingTitle": "Silver Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Silver Metallic",
                "colorHexCode": "#C8C6C6",
                "styleCode": "RTIWXMS",
                "marketingTitle": "Silver Metallic Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Slate Blue",
                "colorHexCode": "#3F5167",
                "styleCode": "RTIWXSL",
                "marketingTitle": "Slate Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Spice",
                "colorHexCode": "#9B4D3E",
                "styleCode": "RTIWXSC",
                "marketingTitle": "Spice Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Taupe",
                "colorHexCode": "#CFC5C3",
                "styleCode": "RTIWXTP",
                "marketingTitle": "Taupe Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Teal",
                "colorHexCode": "#057085",
                "styleCode": "RTIWXTE",
                "marketingTitle": "Teal Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Tiffany Blue",
                "colorHexCode": "#90D2D5",
                "styleCode": "RTIWXTB",
                "marketingTitle": "Tiffany Blue Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Turquoise",
                "colorHexCode": "#5BACB3",
                "styleCode": "RTIWXTU",
                "marketingTitle": "Turquoise Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Viola",
                "colorHexCode": "#5C0A5A",
                "styleCode": "RTIWXVA",
                "marketingTitle": "Viola Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Watermelon",
                "colorHexCode": "#AC1848",
                "styleCode": "RTIWXWA",
                "marketingTitle": "Watermelon Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "White",
                "colorHexCode": "#FFFFFF",
                "styleCode": "RTIWXWH",
                "marketingTitle": "White Striped Expressions Windsor Tie"
              },
              {
                "colorShade": "Wine",
                "colorHexCode": "#54101B",
                "styleCode": "RTIWXWI",
                "marketingTitle": "Wine Striped Expressions Windsor Tie"
              }
            ],
            "colorHexCode": "#5f3237",
            "categories": [
              "Ties"
            ],
            "tieType": "Windsor",
            "companionStylePrice": 39,
            "images": [
              {
                "src": "windsor-tie-rosewood-stripe-WXRW.jpg",
                "alt": "Guy wearing an Expressions Rosewood Windsor Tie and a black vest"
              },
              {
                "src": "windsor-tie-rose-petal-stripe-WXRP.jpg",
                "alt": "this is a test"
              },
              {
                "src": "windsor-tie-black-stripe-WXBL.jpg",
                "alt": "test 2"
              }
            ],
            "sizeScale": "A",
            "styleRank": 157,
            "styleFirestoreDocumentId": "b1EKl2zr3h3ByJqe5Rp4",
            "colorShadeId": "Rosewood",
            "warehouses": [
              "Anderson",
              "Atlanta",
              "Richmond",
              "Salina",
              "Seguin",
              "Trenton",
              "Visalia"
            ],
            "colorFamily": "Pink",
            "relatedStyles": [
              {
                "styleType": "PocketSquare",
                "images": [
                  {
                    "src": "PK_PWRW_1280x1920.jpg",
                    "alt": "Rosewood Expressions Solid Pocket Square"
                  }
                ],
                "styleGroup": "Purchase",
                "styleCode": "PPKPWRW",
                "marketingTitle": "Rosewood Expressions Pocket Square"
              },
              {
                "styleType": "Vest",
                "images": [
                  {
                    "src": "fullback-vest-black-performance-stretch-F921.jpg",
                    "alt": "man wearing black matching wool vest in performance stretch with white shirt and sage green tie"
                  }
                ],
                "styleGroup": "Rental",
                "styleCode": "RVS921",
                "marketingTitle": "Black Performance Vest"
              }
            ],
            "collection": "Premium (PRM)",
            "styleCode": "RTIWXRW",
            "legacyStyleCode": "WXRW",
            "matchingStyles": [
              {
                "docId": "i393vAhanT6qXMCMWJDn",
                "styleCode": "PPKPWRW"
              }
            ],
            "marketingDescription": "### FEATURES:\n- Striped colored windsor tie\n- Pre-tied, expandable neck\n- Coordinates with any Matching Wool Vest\n- Matching solid pocket square is available\n\n### SIZE SCALE:\n - Men's and Boys' Sizes\n\n### FABRIC:\n - Polyester",
            "fabric": "Polyester",
            "mannequinImages": [
              {
                "mannequinImage": "WindsorTie",
                "src": "TI_WXRW_1280x1920.png",
                "alt": "Rosewood Expressions Striped Windsor Tie"
              }
            ],
            "colorShade": "Rosewood",
            "measurementScale1": "A",
            "publishedDate": 1711135542
          }
        ],
        "id": "24cb9fc8-a211-4be6-b2e9-f6529f4942e4",
        "title": "Look 2"
      },
    }
  ],
  "admins": [
    {
      "firstName": "The",
      "lastName": "Organizer",
      "role": "",
      "isOrganizer": true,
      "inviteAccepted": 1719415303,
      "phone": "0000000000",
      "name": "The Organizer",
      "id": "960c03fb-5ff7-4461-acb1-a1f0b74e98da",
      "email": "nickstefanic@jimsfw.com"
    },
    // {
    //   "firstName": "First",
    //   "lastName": "Member",
    //   "role": "",
    //   "isOrganizer": false,
    //   "inviteAccepted": 1719415453,
    //   "phone": "0000000000",
    //   "name": "",
    //   "id": "a508f5ab-2bb2-476c-b982-2be9a9cab6cb",
    //   "email": "nstefanicjfw@gmail.com"
    // }
  ],
  "update_time": {
    "_seconds": 1719497497,
    "_nanoseconds": 192000000
  },
  "eventName": "Nick EXT-2595 (4)a",
  "adminEmails": [
    "nickstefanic@jimsfw.com",
    "nstefanicjfw@gmail.com"
  ],
  "memberEmails": [
    "nstefanicjfw@gmail.com"
  ]
}

export function testEventDif(options?: DifOptions) {
  const defaultTestDifOptions: DifOptions = {
    ignoreMeta: true,
    logChecks: true,
    logDifs: true,
    logNoDif: false,
    logSimpleDifs: false,
  }

  const difOptions = {
    ...defaultTestDifOptions,
    ...options, // override defaults with any passed in options
  }

  const dif = getEventDif(previousEventTest, currentEventTest, difOptions);

  if (dif) {
    console.log("dif previous", dif.previous);
    console.log("dif current", dif.current);

  } else {
    console.log("NO differences found in Event docs");
  }

  // const memberDif = getMembersDif(previousEventTest, currentEventTest, difOptions);

  // if (memberDif) {
  //   console.log("memberDif previous", memberDif.previous);
  //   console.log("memberDif current", memberDif.current);
  // } else {
  //   console.log("NO differences found in Members");
  // }

  return;
}

export function testMembersDif(options?: DifOptions<'members'>) {
  const defaultTestDifOptions: DifOptions<'members'> = {
    ignoreMeta: true,
    logChecks: true,
    logDifs: true,
    logNoDif: false,
    logSimpleDifs: false,
  }

  const difOptions = {
    ...defaultTestDifOptions,
    ...options, // override defaults with any passed in options
  }

  const memberDif = getMembersDifFromEvent(previousEventTest, currentEventTest, difOptions);

  if (memberDif) {
    console.log("memberDif previous", memberDif.previous);
    console.log("memberDif current", memberDif.current);
  } else {
    console.log("NO differences found in Members");
  }

  return;

}
