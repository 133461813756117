import { Cart, MemberCart, ShippingAddress } from 'common-types';

/**
 * If a member has a cached cart, but needs to create a new one. Check to
 * see if we can reconstitute the prior shipping address. If a prior address
 * is found, return the shipping address - otherwise return undefined.
 *
 * @param memberId The member's unique ID.
 * @param priorCart The last cart instance.
 * @returns ShippingAddress | undefined.
 */

export function getPriorShippingAddress(
  memberId: string,
  priorCart: Cart | undefined
): ShippingAddress | undefined {
  if (
    priorCart === undefined ||
    priorCart.members === undefined ||
    priorCart.members.length <= 0
  ) {
    return undefined;
  }

  let priorShippingAddress: ShippingAddress | undefined;

  priorCart.members.forEach((member: MemberCart) => {
    if (member.id === memberId) {
      priorShippingAddress = member.member.shippingAddress;
    }
  });

  return priorShippingAddress;
}
