
import * as z from 'zod';
import { dealerAccountContactValidator } from './dealer-account-contact-validator';
import { elasticLocationValidator } from './elastic-geo-location-validator';

export const DealerAccountElasticSchema = z.object({
  0: z.string().optional(),
  actnum: z.string(),
  aname: z.string(),
  storeDescription: z.string(),
  store_services: z.array(z.string()).optional(),
  isActive: z.boolean(),
  warehouse: z.string(),
  location: elasticLocationValidator,
  distance: z.number().optional(),
  address1: z.string(),
  address2: z.string(),
  offerings: z.array(z.string()).optional(),
  city: z.string(),
  state: z.string(),
  id: z.string().optional(),
  zipCode: z.string(),
  phone: z.string(),
  properName: z.string().optional(),
  email: z.string().optional(),
  website: z.string(),
  landingPageUrl: z.string(),
  strtyp: z.string(),
  sun_from: z.string().optional(),
  sun_to: z.string().optional(),
  sun_type: z.string().optional(),
  mon_from: z.string().optional(),
  mon_to: z.string().optional(),
  mon_type: z.string().optional(),
  tue_from: z.string().optional(),
  tue_to: z.string().optional(),
  tue_type: z.string().optional(),
  wed_from: z.string().optional(),
  wed_to: z.string().optional(),
  wed_type: z.string().optional(),
  thu_from: z.string().optional(),
  thu_to: z.string().optional(),
  thu_type: z.string().optional(),
  fri_from: z.string().optional(),
  fri_to: z.string().optional(),
  fri_type: z.string().optional(),
  sat_from: z.string().optional(),
  sat_to: z.string().optional(),
  sat_type: z.string().optional(),
  contacts: z.array(dealerAccountContactValidator).optional(),
});

export type DealerAccountElastic = z.infer<
  typeof DealerAccountElasticSchema
>;

// export type DealerAccountElastic = {
//   actnum: string;
//   aname: string;
//   storeDescription: string;
//   store_services?: Array<string>;
//   isActive: boolean;
//   warehouse: string;
//   location: {
//     lat: number;
//     lon: number;
//   };
//   distance?: number;
//   address1: string;
//   address2: string;
//   offerings?: string[];
//   city: string;
//   state: string;
//   id?: string;
//   zipCode: string;
//   phone: string;
//   properName?: string;
//   email?: string;
//   website: string;
//   landingPageUrl: string;
//   strtyp: string;
//   sun_from?: string;
//   sun_to?: string;
//   sun_type?: string;
//   mon_from?: string;
//   mon_to?: string;
//   mon_type?: string;
//   tue_from?: string;
//   tue_to?: string;
//   tue_type?: string;
//   wed_from?: string;
//   wed_to?: string;
//   wed_type?: string;
//   thu_from?: string;
//   thu_to?: string;
//   thu_type?: string;
//   fri_from?: string;
//   fri_to?: string;
//   fri_type?: string;
//   sat_from?: string;
//   sat_to?: string;
//   sat_type?: string;
//   contacts?: Array<DealerAccountContact>;
// };
