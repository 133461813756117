import {
  EztOrderDetailResponse,
  EztOrderResponse,
  Order,
  OrderDetail,
} from 'common-types';

export function processEztOrderResponse(
  order: Order,
  response: EztOrderResponse
): Order {
  order.internalOrderNumber = response.header.intnum;
  order.orderNumber = response.header.ordnum;
  order.nationalEventId = response.header.natevid;
  order.orderStatus =
    response.header.ostatus === 'ORD ACCEPT' ? 'placed' : 'invalid';
  //console.log(response)
  if (response.header.etext !== '') {
    order.errors.push(response.header.etext);
  }
  console.log('entering detail assignment.');
  if (response.detail !== undefined && response.detail.length > 0) {
    response.detail.forEach((detail: EztOrderDetailResponse) => {
      let index = order.details.findIndex((orderDetail: OrderDetail) => {
        return orderDetail.line == detail.line;
      });
      if (index >= 0) {
        order.details[index].lineStatus = detail.lstat;
        // EZT was formatting option sizes like shoe 070 to 70 and coat 03B to 3B
        //order.details[index].option1 = detail.opt1;
        //order.details[index].option2 = detail.opt2;
        order.details[index].option3 = detail.opt3;
        order.details[index].warehouse = detail.whse;
        order.details[index].adjustedShipDate = detail.ashpdat;
        order.details[index].replacementCode = detail.repl;
        order.details[index].detailText = detail.dtext;
        order.details[index].detailError = detail.etext;
        order.details[index].carrier = detail.carr;
        order.details[index].carrierService = detail.carsrv;
        //order.details[index]. = detail.;
      }
    });
  }
  return order;
}
