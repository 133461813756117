import { EcomEnsemble, EcomStyle, Image } from 'common-types';

export function getImage(
  object: EcomStyle | EcomEnsemble | Partial<EcomStyle> | undefined,
  index: number = 0,
  baseImageUrlOnly: boolean = true
): Image {
  let image: Image = {
    src: 'assets/images/style-image-placeholder.png',
    alt: 'image placeholder',
  };
  if (object) {
    if (object.images) {
      if (object.images.length > 0) {
        image.alt = object.images[index].alt;
        image.src = object.images[index].src.trim();

        /// If baseImageUrlOnly, exclude any leading URL (i.e. https://www.jimsformalwear.com/etc)
        if (baseImageUrlOnly) {
          image.src = image.src.substring(image.src.lastIndexOf('/') + 1);
        }
      }
    }
  }
  return image;
}
