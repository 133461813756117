// These are the types of mannequin images.
// They are listed in the order they must be added to the mannequin.
// The string value can be assigned to the image's mannequinImage field.

// export enum MannequinImageType {
//   Shirt = 'Shirt',
//   CoatExtra = 'CoatExtra',
//   BowTieExtra = 'BowTieExtra',
//   WesternTieExtra = 'WesternTieExtra',
//   Pant = 'Pant',
//   WesternTie = 'WesternTie',
//   WindsorTie = 'WindsorTie',
//   Vest = 'Vest',
//   ShirtExtra = 'ShirtExtra',
//   Coat = 'Coat',
//   BowTie = 'BowTie',
// }

export const MannequinImageType = {
  Shirt: 'Shirt',
  CoatExtra: 'CoatExtra',
  BowTieExtra: 'BowTieExtra',
  WesternTieExtra: 'WesternTieExtra',
  Pant: 'Pant',
  WesternTie: 'WesternTie',
  WindsorTie: 'WindsorTie',
  Vest: 'Vest',
  ShirtExtra: 'ShirtExtra',
  Coat: 'Coat',
  BowTie: 'BowTie',
} as const;

export type MannequinImageType = typeof MannequinImageType[keyof typeof MannequinImageType];
