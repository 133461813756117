export function getDefaultProductLine(
  legacyProductId: string
): number | undefined {
  switch (legacyProductId) {
    case 'CT':
      return 1;
    case 'PT':
      return 2;
    case 'SR':
      return 3;
    case 'TI':
      return 4;
    case 'VS':
      return 5;
    case 'SO':
      return 6;
    case 'PK':
      return 7;
    case 'JW':
      return 8;
    default:
      return undefined;
  }
}
