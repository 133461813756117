import { EcomCommunicationPrefs } from './ecom-communication-prefs.type';
import { UpdateEcomCommunicationPrefsData } from './update-ecom-communication-prefs-data.type';
import {
  UpdateEcomCommunicationPrefsError,
  UpdateEcomCommunicationPrefsErrorCode,
} from './update-ecom-communication-prefs-error.type';
import { UpdateEcomCommunicationPrefsResponse } from './update-ecom-communication-prefs-response.type';

export {
  EcomCommunicationPrefs,
  UpdateEcomCommunicationPrefsData,
  UpdateEcomCommunicationPrefsError,
  UpdateEcomCommunicationPrefsErrorCode,
  UpdateEcomCommunicationPrefsResponse,
};
