<h3>{{ title }}</h3>
<table *ngIf="values.length > 0">
  <tr *ngFor="let value of values; index as i">
    <td>{{ value }}</td>
    <td>
      <button mat-raised-button type="button" (click)="onDelete(i)">
        Delete
      </button>
    </td>
  </tr>
</table>
<form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="onAdd()">
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>{{ inputLabel }}</mat-label>
    <input matInput formControlName="input" />
  </mat-form-field>
  <button mat-raised-button>Add</button>
</form>
