import { Change } from '../types/change.type';
import { DifOptions } from '../types/dif-options.type';
import { getArrayDif } from './get-array-dif';
import { getLevelSpacers } from './get-level-spacers';
import { getObjectDif } from './get-object-dif';
import { getSimpleDif } from './get-simple-dif';


// const difAlgorithms = {
//   'altColors': getAltColorsDif,
//   'admins': getAdminsDif,
//   'images': getImagesDif,
//   'mannequinImages': getImagesDif,
//   'looks': getLooksDif,
//   'members': getMembersDif,
//   'styles': getStylesDif,
//   'relatedStyles': getStylesDif,
//   'matchingStyles': getStylesDif,
// } as const;

const difAlgorithmItemIdKey = {
  'altColors': 'styleCode',
  'admins': 'id',
  // 'images': 'mannequinImage',
  'mannequinImages': 'mannequinImage',
  'looks': 'id',
  'members': 'id',
  'memberLook': 'id',
  'styles': 'styleCode',
  'relatedStyles': 'styleCode',
  'matchingStyles': 'styleCode',
} as const;

export type SupportedTypes = boolean | string | number | null | object | Array<SupportedTypes>;

/**
 * This function is the main entry point for the dif algorithm.  It will determine the type of the previous and current values and call the appropriate dif algorithm.
 * It is also called recursively by the getObjectDif and getArrayDif functions to handle nested objects and arrays.
 * @param previous the previous value to compare
 * @param current the current value to compare
 * @param recursionDepth the current recursion depth
 * @param recursionKey the current recursion key
 * @param options DifOptions
 * @returns
 */
export function getDif<T extends SupportedTypes>(previous: T, current: T, recursionDepth = 0, recursionKey: string, options: DifOptions) {
  const { ignoreMeta, logDifs, logNoDif, logChecks, logSimpleDifs, keysToInclude, keysToIgnore } = options;

  const dif: Change<T> = {
    previous: {},
    current: {},
  };

  // const newRecursionDepth = recursionDepth === 0 ? 0 : recursionDepth + 1;


  /// Since recursionKey is a period-separated string with each part representing a key in the object (starting from the top of the object),
  /// we need to get the last key in the string find our current key.
  const recursionKeyAtCurrentDepth = recursionKey.split('.').slice(-1)[0];
  const rootKey = recursionKey.split('.')[1];
  // console.log({ recursionDepth, recursionKeyAtCurrentDepth, rootKey });

  if (keysToInclude && recursionDepth === 1 && !keysToInclude.includes(rootKey as keyof DifOptions['keysToInclude'])) {
    if (logChecks) console.log(recursionKeyAtCurrentDepth, '-- skipping key 🙈');
    return null;
  }

  if (keysToIgnore && keysToIgnore.includes(recursionKeyAtCurrentDepth as keyof DifOptions['keysToIgnore'])) {
    // console.log("keysToIgnore: ", keysToIgnore);
    if (logChecks)
      console.log(recursionKeyAtCurrentDepth, '-- skipping key 🙈 ');
    return null;
  }


  if (ignoreMeta && (recursionKeyAtCurrentDepth === 'lastUpdated' || recursionKeyAtCurrentDepth === 'update_time' || recursionKeyAtCurrentDepth === 'lastUpdatedBy')) {
    if (logChecks) console.log(recursionKeyAtCurrentDepth, '-- skipping metadata');
    return null;
  }


  const logKey = recursionDepth === 0 ? recursionKey
    // remove the first key from recursionKey
    : recursionKey.split('.').slice(1).join('.');



  const isBoolean = (typeof current === 'boolean' && typeof previous === 'boolean') || (typeof current === 'boolean' && previous === undefined) || (typeof previous === 'boolean' && current === undefined);
  const isString = typeof current === 'string' && typeof previous === 'string' || (typeof current === 'string' && previous === undefined) || (typeof previous === 'string' && current === undefined);
  const isNumber = typeof current === 'number' && typeof previous === 'number' || (typeof current === 'number' && previous === undefined) || (typeof previous === 'number' && current === undefined);
  const isNull = (current === null && previous === null) || (current === null && previous === undefined) || (previous === null && current === undefined);
  if (isBoolean || isString || isNumber || isNull) {
    if (logSimpleDifs && logChecks) console.log(getLevelSpacers(recursionDepth) + logKey, "checking...");
    return getSimpleDif(previous, current, recursionDepth, recursionKey);
  }

  const isBothUndefined = current === undefined && previous === undefined;
  if (isBothUndefined) {
    if (logNoDif)
      console.log(
        getLevelSpacers(recursionDepth) + logKey,
        'no dif -- both undefined'
      );
    return null;
  }
  const currentIsObject = typeof current === 'object' && current !== null && current !== undefined;
  const previousIsObject = typeof previous === 'object' && previous !== null && previous !== undefined;
  const isObject = (currentIsObject && previousIsObject) || (currentIsObject && previous === undefined) || (previousIsObject && current === undefined);
  const currentIsArray = Array.isArray(current);
  const previousIsArray = Array.isArray(previous);
  const isArray = (currentIsArray && previousIsArray) || (currentIsArray && previous === undefined) || (previousIsArray && current === undefined);

  if (!isObject && !isArray) {
    console.warn(recursionKey, 'not a supported type', { currentType: typeof current, previousType: typeof previous });
    return null;
  }

  const itemIdKey = difAlgorithmItemIdKey[recursionKeyAtCurrentDepth as keyof typeof difAlgorithmItemIdKey] ?? undefined;


  if (logChecks) console.log(getLevelSpacers(recursionDepth) + logKey, "checking...");


  if (isArray) {
    return getArrayDif(previous, current, recursionDepth, recursionKey, itemIdKey, options);
  }

  if (!isObject) {
    console.warn(recursionKey, 'not an object');
    return null;
  }

  return getObjectDif(previous, current, recursionDepth, recursionKey, itemIdKey, options);
}
