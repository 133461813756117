import { Change } from "../types/change.type";
import { DifOptions } from "../types/dif-options.type";
import { getLevelSpacers } from "./get-level-spacers";

export function getSimpleDif<T extends number | string | boolean | null>(previousValue: T, currentValue: T, recursionDepth: number, recursionKey: string, options?: DifOptions) {
  const { logChecks } = options || {};

  if (logChecks) console.log(getLevelSpacers(recursionDepth) + recursionKey, "in getSimpleDif");

  const dif: Change<T> = {
    previous: previousValue,
    current: currentValue,
  };

  if (currentValue !== previousValue) {
    return dif;
  }

  return null;
}
