import { Event, EventMember } from 'common-types';

export function getMembersUserPaidFor(
  event: Event,
  userId: string
): EventMember[] {
  return event.members.filter((member) => {
    return (
      member.memberProgress?.paidBy === userId &&
      member.memberProgress?.paid !== undefined
    );
  });
}
