import { EventMember } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getArrayDif } from './utils/get-array-dif';

export function getMembersDif(previous: EventMember[], current: EventMember[], options?: DifOptions<'members'>) {

  return getArrayDif(previous, current, 0, 'members', 'id', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions);

}

// const members = [] as EventMember[];

// getMembersDif(members, members, { keysToIgnore: ['memberLook'] });

