// These are styleTypes based on the prefix of a styleCode
import { StyleType } from '../platform';
// export enum StyleTypePrefix {
//   RCT = StyleType.Coat,
//   PCT = StyleType.Coat,
//   RPT = StyleType.Pant,
//   PPT = StyleType.Pant,
//   RTI = StyleType.Tie,
//   PTI = StyleType.Tie,
//   RVS = StyleType.Vest,
//   PVS = StyleType.Vest,
//   PSR = StyleType.Shirt,
//   RSR = StyleType.Shirt,
//   PPK = StyleType.PocketSquare,
//   RPK = StyleType.PocketSquare,
//   PHS = StyleType.Socks,
//   RHS = StyleType.Socks,
// }

export const StyleTypePrefix = {
  RCT: StyleType.Coat,
  PCT: StyleType.Coat,
  RPT: StyleType.Pant,
  PPT: StyleType.Pant,
  RTI: StyleType.Tie,
  PTI: StyleType.Tie,
  RVS: StyleType.Vest,
  PVS: StyleType.Vest,
  PSR: StyleType.Shirt,
  RSR: StyleType.Shirt,
  PPK: StyleType.PocketSquare,
  RPK: StyleType.PocketSquare,
  PHS: StyleType.Socks,
  RHS: StyleType.Socks,
} as const;

export type StyleTypePrefix = typeof StyleTypePrefix[keyof typeof StyleTypePrefix];
