// type PreCheck = {
//   badUrl: null;
//   inviteAccepted: null;
//   userEventId: null;
// };
import { Event } from '../event/event.type';

type UserNotLoggedIn = {
  badUrl: false;
  inviteAccepted: false;
  userEventId: null;
  eventData?: Event | undefined;
};

type BadUrlResponse = {
  badUrl: true;
  inviteAccepted: false;
  userEventId: null;
  eventData?: Event | undefined;
};

type InviteAcceptedResponse = {
  badUrl: false;
  inviteAccepted: true;
  userEventId: string;
  dealerAcceptedDate?: number;
  eventData?: Event | undefined;
};

type InviteNotAcceptedResponse = {
  badUrl: false;
  inviteAccepted: false;
  userEventId: string;
  dealerAcceptedDate?: number;
  eventData?: Event | undefined;
};

export type LandingPageBaseResponse = {
  badUrl: boolean;
  inviteAccepted: boolean;
  userEventId: string | undefined;
  eventData?: Event | undefined;
};
export type LandingPageResponse =
  | UserNotLoggedIn
  | BadUrlResponse
  | InviteAcceptedResponse
  | InviteNotAcceptedResponse;

export const badUrlLandingPageResponse: BadUrlResponse = {
  badUrl: true,
  inviteAccepted: false,
  userEventId: null,
};

export const userNotLoggedInLandingPageResponse: UserNotLoggedIn = {
  badUrl: false,
  inviteAccepted: false,
  userEventId: null,
};


// export interface LandingPageResponse {
//   badUrl: boolean;
//   inviteAccepted: boolean;
//   // renderingComplete: boolean;
//   userEventId: string;
//   dealerAcceptedDate?: number;
//   // routeToFirstStep: boolean;
// }
