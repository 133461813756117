import { EventMember } from "common-types";

/**
 * Returns a list of emails from a list of members.
 * Will filter out any members without an email.
 * And will filter out duplicates.
 */
export function getMemberEmails(members: EventMember[]): string[] {

  const emails = members
    .filter((member): member is EventMember & { email: string } => !!member.email) // only include members with an email
    .map(member => member.email);

  // filter duplicates
  return [...new Set(emails)];
}
