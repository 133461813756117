<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid #chipGrid [formControl]="itemsCtrl">
      <mat-chip-row *ngFor="let item of items" (removed)="onRemove(item)">
        {{ item }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input
      #newItemInput
      [placeholder]="placeholder"
      [formControl]="newItemCtrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (focus)="onFocus()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
      (optionSelected)="onSelected($event)"
    >
      <mat-option *ngFor="let item of filteredItems$ | async" [value]="item">
        {{ item }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
