import { DateObjectToStringSchema } from "../date";
import { PhoneToStringSchema } from "../phone";
import { EmailUsFormSchema } from "./email-us-form.type";
import * as z from 'zod';


export const EmailUsFormTransformSchema = EmailUsFormSchema
  .omit({recaptcha: true}) // omit recaptcha from the transform because it is not needed in the email template, and only used before the data is parsed.
  .strip() // since recaptcha is being omitted, but is a property in EmailUsFormSchema, we need to strip the schema to prevent errors.  This just means the recaptcha key will get removed from the data passed in.
  .extend({
    phone: PhoneToStringSchema,
    eventDate: DateObjectToStringSchema
  })

export type EmailUsFormTransform = z.infer<typeof EmailUsFormTransformSchema>;


// const test: EmailUsForm = {} as EmailUsForm;
// const parsed = EmailUsFormTransformSchema.parse(test);
