import { standardTime } from './standard-time';

export function storeHoursConverter(
  fromTime: string | undefined,
  toTime: string | undefined
): string {
  let openTime = fromTime?.replace(':00.000000', '');
  let closeTime = toTime?.replace(':00.000000', '');
  if (openTime) {
    openTime = standardTime(openTime);
  }
  if (closeTime) {
    closeTime = standardTime(closeTime);
  }
  if (
    (openTime === '0:00' && closeTime === '0:00') ||
    openTime === undefined ||
    closeTime === undefined
  ) {
    return 'Closed';
  }
  return openTime + ' - ' + closeTime;
}
