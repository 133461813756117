
export const RecoverEmailErrorCode = {
  noOobCode: 'no-oob-code',
  invalidOobCode: 'invalid-oob-code',
  missingRecoverEmailData: 'missing-recover-email-data', // The RecoverEmail subcollection doc does not have all the necessary properties on it.
  mismatchPrevious: 'mismatch-previous', // The previousEmail in the RecoverEmail subcollection doc does not match the previous email in the userEcomSettings document in Firestore.
  mismatchCurrent: 'mismatch-current', // The newEmail in the RecoverEmail subcollection doc does not match the current email in the userEcomSettings document in Firestore.
  mismatchUserId: 'mismatch-user-id', // The userId in the RecoverEmail subcollection doc does not match the userId in the userEcomSettings document in Firestore.
  missingEmail: 'missing-email',
  userNotFound: 'user-not-found',
  firestore: 'firestore-error',
  outOfSync: 'out-of-sync', // Initial Firestore update was successful, but Auth update failed, and attempt to re-sync failed.
  unknown: 'unknown error',
} as const;

export type RecoverEmailErrorCode = typeof RecoverEmailErrorCode[keyof typeof RecoverEmailErrorCode];

type RecoverEmailErrorResponse = {
  success: false;
  error: RecoverEmailErrorCode
}

type RecoverEmailSuccessResponse = {
  success: true;
  currentEmail: string;
}

export type RecoverEmailResponse = RecoverEmailErrorResponse | RecoverEmailSuccessResponse;
