import { z } from 'zod';

export const EventLastUpdatedBySchema = z.enum([
  'cart api: update members paid and measurements',
  'cart api: update inviteSent',
  'dealerUserEvent api: transfer-event',
  'event api: addAdminToEvent',
  'event api: addLook',
  'event api: addMemberToEvent',
  'event api: assignLooks',
  'event api: copyLook',
  'event api: createEventFromDealerPortal',
  'event api: createEventFromEcom',
  'event api: deleteInStoreInfo',
  'event api: deleteLook',
  'event api: deleteMemberFromEvent',
  'event api: getEventById - add memberEmails/adminEmails',
  'event api: initializePriceSelection',
  'event api: markInviteSent',
  'event api: selectLook',
  'event api: switchToOrderOnline',
  'event api: switchToOrderInStore',
  'event api: switchToGroup',
  'event api: switchToIndividual',
  'event api: updateAdminDetails',
  'event api: updateEventDetails',
  'event api: updateInStoreInfo',
  'event api: updateLookAndEventMemberLooks',
  'event api: updateMemberDetails',
  'event api: updateMemberMeasurements',
  'event api: updateStylesInMemberLooks',
  'invite api: verifyEventInvite',
  'order api',
  'platform-event api: updateEvent',
  'platform-event api: addAdminToEvent',
  'platform-event api: markInviteSent',
  'platform-event api: deleteAdminFromEvent',
  'platform-event api: updateAdminDetails',
  'ecomOrders onCreate trigger',
  'ecomOrders onUpdate trigger',
  'ecomStyles onDelete trigger',
  'ecomStyles onUpdate trigger',

]);

export type EventLastUpdatedBy = z.infer<typeof EventLastUpdatedBySchema>;

export const EventLastUpdatedBy = {
  CartApiUpdateMembersPaidAndMeasurements: EventLastUpdatedBySchema.enum['cart api: update members paid and measurements'],
  CartApiUpdateInviteSent: EventLastUpdatedBySchema.enum['cart api: update inviteSent'],
  DealerUserEventApi_TransferEvent: EventLastUpdatedBySchema.enum['dealerUserEvent api: transfer-event'],
  EventApi_AddAdminToEvent: EventLastUpdatedBySchema.enum['event api: addAdminToEvent'],
  EventApi_AddLook: EventLastUpdatedBySchema.enum['event api: addLook'],
  EventApi_AddMemberToEvent: EventLastUpdatedBySchema.enum['event api: addMemberToEvent'],
  EventApi_AssignLooks: EventLastUpdatedBySchema.enum['event api: assignLooks'],
  EventApi_CopyLook: EventLastUpdatedBySchema.enum['event api: copyLook'],
  EventApi_CreateEventFromDealerPortal: EventLastUpdatedBySchema.enum['event api: createEventFromDealerPortal'],
  EventApi_CreateEventFromEcom: EventLastUpdatedBySchema.enum['event api: createEventFromEcom'],
  EventApi_DeleteInStoreInfo: EventLastUpdatedBySchema.enum['event api: deleteInStoreInfo'],
  EventApi_DeleteLook: EventLastUpdatedBySchema.enum['event api: deleteLook'],
  EventApi_DeleteMemberFromEvent: EventLastUpdatedBySchema.enum['event api: deleteMemberFromEvent'],
  EventApi_GetEventById_AddMemberEmailsAdminEmails: EventLastUpdatedBySchema.enum['event api: getEventById - add memberEmails/adminEmails'],
  EventApi_InitializePriceSelection: EventLastUpdatedBySchema.enum['event api: initializePriceSelection'],
  EventApi_MarkInviteSent: EventLastUpdatedBySchema.enum['event api: markInviteSent'],
  EventApi_SelectLook: EventLastUpdatedBySchema.enum['event api: selectLook'],
  EventApi_SwitchToOrderOnline: EventLastUpdatedBySchema.enum['event api: switchToOrderOnline'],
  EventApi_SwitchToOrderInStore: EventLastUpdatedBySchema.enum['event api: switchToOrderInStore'],
  EventApi_SwitchToGroup: EventLastUpdatedBySchema.enum['event api: switchToGroup'],
  EventApi_SwitchToIndividual: EventLastUpdatedBySchema.enum['event api: switchToIndividual'],
  EventApi_UpdateAdminDetails: EventLastUpdatedBySchema.enum['event api: updateAdminDetails'],
  EventApi_UpdateEventDetails: EventLastUpdatedBySchema.enum['event api: updateEventDetails'],
  EventApi_UpdateInStoreInfo: EventLastUpdatedBySchema.enum['event api: updateInStoreInfo'],
  EventApi_UpdateLookAndEventMemberLooks: EventLastUpdatedBySchema.enum['event api: updateLookAndEventMemberLooks'],
  EventApi_UpdateMemberDetails: EventLastUpdatedBySchema.enum['event api: updateMemberDetails'],
  EventApi_UpdateMemberMeasurements: EventLastUpdatedBySchema.enum['event api: updateMemberMeasurements'],
  EventApi_UpdateStylesInMemberLooks: EventLastUpdatedBySchema.enum['event api: updateStylesInMemberLooks'],
  InviteApi: EventLastUpdatedBySchema.enum['invite api: verifyEventInvite'],
  OrderApi: EventLastUpdatedBySchema.enum['order api'],
  PlatformEventApi_UpdateEvent: EventLastUpdatedBySchema.enum['platform-event api: updateEvent'],
  PlatformEventApi_AddAdminToEvent: EventLastUpdatedBySchema.enum['platform-event api: addAdminToEvent'],
  PlatformEventApi_MarkInviteSent: EventLastUpdatedBySchema.enum['platform-event api: markInviteSent'],
  PlatformEventApi_DeleteAdminFromEvent: EventLastUpdatedBySchema.enum['platform-event api: deleteAdminFromEvent'],
  PlatformEventApi_UpdateAdminDetails: EventLastUpdatedBySchema.enum['platform-event api: updateAdminDetails'],
  EcomOrdersOnCreateTrigger: EventLastUpdatedBySchema.enum['ecomOrders onCreate trigger'],
  EcomOrdersOnUpdateTrigger: EventLastUpdatedBySchema.enum['ecomOrders onUpdate trigger'],
  EcomStylesOnDeleteTrigger: EventLastUpdatedBySchema.enum['ecomStyles onDelete trigger'],
  EcomStylesOnUpdateTrigger: EventLastUpdatedBySchema.enum['ecomStyles onUpdate trigger'],

} as const;

export const EventTypeOptions = EventLastUpdatedBySchema.options;
