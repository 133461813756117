import { EventMember, OrderDetail } from 'common-types';

export function hasEventMemberOrderShipped(member: EventMember): boolean {
  let hasShipped: boolean = false;
  if (member.orderDetails !== undefined && member.orderDetails.length > 0) {
    member.orderDetails.forEach((detail: OrderDetail) => {
      if (detail.trackingNumber !== undefined && detail.trackingNumber !== '') {
        hasShipped = true;
      }
    });
  }
  return hasShipped;
}
