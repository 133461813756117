import { EcomStyle, StyleGroup } from 'common-types';

export function getStyleRentPrice(ecomStyle: EcomStyle): number | undefined {
  let price: number | undefined;

  if (ecomStyle.styleGroup === StyleGroup.Rental) {
    price = ecomStyle.price;
  } else if (ecomStyle.styleGroup === StyleGroup.Purchase) {
    price = ecomStyle.companionStylePrice;
  }

  return price;
}
