import { Event, EventMember, EventMemberDetails } from "common-types";
import { getMemberEmails } from "./get-member-emails";

/**
 * Returns a new event object with three properties:
 *  - updatedEvent: the new event with the member details updated.
 *  - updatedMember: the member that was updated.  If the member was not found, this will be undefined.
 *  - numMembersUpdated: the number of members that were updated.  Should be 0 or 1.  If it is more than 1, something went wrong.
 *
 * Does not mutate the original event.  Updates the members array, memberIds array, and memberEmails array.
 * @param event the event to update the member details for
 * @param memberId the id of the member to update
 * @param newDetails an object containing the new details to update the member with (only the properties that are provided will be updated).
 */
export function updateMemberDetails(event: Event, memberId: string, newDetails: Partial<EventMemberDetails>): { updatedEvent: Event, updatedMember: EventMember | undefined, numMembersUpdated: number } {

  let numMembersUpdated = 0;
  let updatedMember: EventMember | undefined;

  const newMembersArray = event.members.map((member: EventMember) => {

    if (member.id === memberId) { // only update the member with the matching id

      const newMember: EventMember = {
        ...member,
        ...newDetails,
      }

      numMembersUpdated++;
      updatedMember = newMember;

      return newMember;
    }

    return member; // return all other members as is
  });

  // const newMemberIds = getMemberIds(newMembersArray); // updating details should not cause a change to the id property, so we don't need to update the memberIds array
  const newMemberEmails = getMemberEmails(newMembersArray);

  const updatedEvent: Event = {
    ...event,
    members: newMembersArray,
    // memberIds: newMemberIds,
    memberEmails: newMemberEmails,
  }

  return { updatedEvent, updatedMember, numMembersUpdated };

}
