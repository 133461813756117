import { z } from 'zod';
import { IdValidator } from '../../../common';

export const EventAdminValidator = z.object({
  id: IdValidator,
  name: z.string().max(150),
  role: z.string().max(60),
  email: z.string().max(150).email(),
  phone: z.string().max(12),
});
