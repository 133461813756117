import {
  CanBeValidated,
  DealerAccountElastic,
  InStoreInfo as InStoreInfoInterface,
  Serializable,
} from 'common-types';
import { ZodError, ZodSchema } from 'zod';
import { InStoreInfoValidator } from './validation/in-store-info-validator';

export class InStoreInfo
  implements
    Partial<InStoreInfoInterface>,
    CanBeValidated,
    Serializable<InStoreInfo>
{
  isCreatedByDealer?: boolean | undefined;
  dealerId?: string | undefined;
  // store?: Store | undefined;
  store?: DealerAccountElastic | undefined;

  toObject() {
    return {
      isCreatedByDealer: this.isCreatedByDealer,
      dealerId: this.dealerId,
      store: this.store,
    };
  }

  serialize(): string {
    return JSON.stringify(this.toObject());
  }

  deserialize(input: Partial<InStoreInfoInterface>): InStoreInfo {
    this.isCreatedByDealer = input.isCreatedByDealer;
    this.dealerId = input.dealerId;
    this.store = input.store;
    return this;
  }

  isValid(validationSchema?: ZodSchema): boolean {
    if (validationSchema === undefined) {
      validationSchema = InStoreInfoValidator;
    }

    try {
      validationSchema.parse(this);
      return true;
    } catch (err) {
      if (err instanceof ZodError) {
        return false;
      } else {
        throw err;
      }
    }
  }

  getErrors(validationSchema?: ZodSchema): ZodError<any> | undefined {
    if (validationSchema === undefined) {
      validationSchema = InStoreInfoValidator;
    }

    try {
      validationSchema.parse(this);
    } catch (err) {
      if (err instanceof ZodError) {
        return err;
      } else {
        throw err;
      }
    }

    return undefined;
  }
}
