import { Category, Event, StyleGroup, StyleType } from 'common-types';

export const StaticEvents: Event[] = [
  {
    id: '1',
    saved: true,
    eventName: '1092',
    eventType: 'Wedding',
    isSingleUser: false,
    createdByUserId: 'lzCt4HWAPXWjuMP5l4x3qW21iWh2',
    admins: [
      {
        firstName: 'Robert',
        lastName: 'Saylor',
        role: '',
        phone: '2223334444',
        name: 'Robert Saylor',
        id: 'lzCt4HWAPXWjuMP5l4x3qW21iWh2',
        confirmedInfo: false,
        userId: 'lzCt4HWAPXWjuMP5l4x3qW21iWh2',
        email: 'robsaylor@jimsfw.com',
        isOrganizer: true,
      },
    ],
    eventDate: 1689138000,
    createDate: 1681991069,
    members: [
      {
        surrogateUser: false,
        firstName: 'Rob',
        lastName: 'Saylor',
        phone: '2222222222',
        shippingAddress: {
          name: '',
          zip: '',
          phone: '',
          city: '',
          shippingMethod: '',
          streetAddress1: '',
          streetAddress2: '',
          state: '',
        },
        email: 'alink2me@gmail.com',
        memberRole: 'Groom',
        measurements: {
          derivedFrom: '',
        },
      },
      {
        surrogateUser: false,
        lastName: 'Smith',
        firstName: 'John',

        phone: '2222222222',
        shippingAddress: {
          name: '',
          zip: '',
          city: '',
          phone: '',
          shippingMethod: '',
          streetAddress1: '',
          streetAddress2: '',
          state: '',
        },
        memberRole: 'Best Man',
        email: 'alink2me@gmail.com',
        measurements: {
          derivedFrom: '',
        },
      },
    ],
    lastUpdated: '1682007050436',
    looks: [
      {
        title: 'ggfhjghj',
        styles: [
          {
            coatType: 'Suit',
            vent: 'Side',
            keywords: [],
            webReleaseDate: 1681444800,
            styleGroup: StyleGroup.Rental,
            companionStyleCode: 'PCT302',
            marketingTitle: 'Michael Kors Blue Performance Stretch Coat',
            colors: ['Blue'],
            coatLength: 30,
            fit: 'Ultra Slim',
            styleType: StyleType.Coat,
            price: 175,
            categories: [Category.SuitsAndTuxedos],
            lapel: 'Notch',
            companionStylePrice: 300,
            images: [],
            sizeScale: 'B',
            buttons: 2,
            styleFirestoreDocumentId: '0bHpQm0FsqqwJDuuqJei',
            warehouses: [
              'Anderson',
              'Atlanta',
              'Richmond',
              'Salina',
              'Seguin',
              'Trenton',
              'Visalia',
            ],
            designer: 'Michael Kors',
            collection: 'Diamond',
            styleCode: 'RCT302',
            marketingDescription:
              "Tailored in a unique fabric comprised of CoolMax for comfort and Sorona for maximum flexibility, the Michael Kors Blue Performance Wedding Suit looks and feels great during your event and the celebration that follows. It's the suit to wear when having fun. Pair it with a pair of matching ultra slim fit pants and a matching vest for a three-piece suit look. This style is available to rent or to buy.",
            fabric: 'CoolMax/Sorona/Wool',
            mannequinImages: [],
            pocket: 'Besom',
          },
          {
            images: [
              {
                src: 'https://www.jimsformalwear.com/images/1280x1920/microfiber-fitted-formal-shirt-white-96W.jpg',
                alt: 'White Fitted Microfiber Shirt',
              },
            ],
            sizeScale: 'FIT',
            keywords: [],
            styleFirestoreDocumentId: 'cP3bWqMFOifb38CvszqT',
            styleGroup: StyleGroup.Rental,
            warehouses: [
              'Anderson',
              'Atlanta',
              'Richmond',
              'Salina',
              'Seguin',
              'Trenton',
              'Visalia',
            ],
            companionStyleCode: 'RSR76W',
            styleCode: 'RSR96W',
            marketingTitle: 'White Fitted Microfiber Shirt',
            colors: ['White'],
            collarType: 'Laydown',
            fit: 'Fitted',
            styleType: StyleType.Shirt,
            marketingDescription:
              'The ideal choice for an athletic-built gentleman looking for fitted styling! Premium microfiber fabric. Non-pleated front with tapered sides.',
            price: 25,
            mannequinImages: [
              {
                src: 'https://www.jimsformalwear.com/images/1280x1920/SR_96W_1280x1920.png',
                alt: 'Shirt',
              },
              {
                src: 'https://www.jimsformalwear.com/images/1280x1920/SR_96W_C_1280x1920_extra.png',
                alt: 'ShirtExtra',
              },
            ],
            categories: [Category.Shirts],
            companionStylePrice: 50,
            shirtFront: 'Flat Front',
          },
        ],
        id: '646346',
      },
    ],
  },
];
