import { z } from 'zod';
import { IdValidator } from '../../common/validation/id-validator';
import { LookValidator } from '../../ensemble/validation/look-validator';
import { UserIdValidator } from '../../user/validation/user-id-validator';
import { EventAdminValidator } from '../event-admin/validation/event-admin-validator';
import { EventMemberValidator } from '../event-member/validation/event-member-validator';
import { InStoreInfoValidator } from '../in-store-info/validation/in-store-info-validator';

export const EventValidator = z.object({
  id: IdValidator,
  userId: UserIdValidator,
  createDate: z.string().max(40),
  lastUpdated: z.string().max(40),
  isOrganizer: z.boolean(),
  isSingleUser: z.boolean(),
  visible: z.boolean(),
  eventType: z.enum(['Wedding', 'Special Event', 'Prom', 'Quinceañera']),
  eventName: z.string().max(100),
  eventDate: z.string().max(40),
  looks: LookValidator.array(),
  members: EventMemberValidator.array(),
  admins: EventAdminValidator.array().optional(),
  inStoreInfo: InStoreInfoValidator.optional(),
  saved: z.boolean(),
});
