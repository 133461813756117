import * as z from 'zod';

const EMAIL_MAX_LENGTH = 50;

/**
 * Email validation schema for new emails (account creation, adding a member, etc.).  This is not for sign-in (JFWEmailSignInSchema).
 */
export const JFWNewEmailSchema = z
  .string()
  .trim()
  .max(EMAIL_MAX_LENGTH, `Must be less than ${EMAIL_MAX_LENGTH} characters`)
  .min(1, "Email is required")
  .email("Please enter a valid email address")
  .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "Please enter a valid email address")


export type JFWNewEmail = z.infer<typeof JFWNewEmailSchema>;
