import { Site } from './site.type';

/**
 * This is a mapping of Site to the name of the associated Firebase app.  The environment needs to be appended to the app name.
 * For example, the app name for the Ecom site is 'jfw-omni-ecom', so in the Prod environment the full app name is 'jfw-omni-ecom-prod'.
 */
export const SiteToApp = {
  [Site.AccountingPortal]: 'jfw-account-portal',
  [Site.CustomerService]: 'jfw-customer-service-portal',
  [Site.DealerPortal]: 'jfw-omni-dealer',
  [Site.Ecom]: 'jfw-omni-ecom',
  [Site.ITAdmin]: 'jfw-it-admin-portal',
  [Site.ITEcomAdmin]: 'jfw-it-ecom-admin-portal',
  [Site.Merchandising]: 'jfw-merchandising-portal',
  [Site.Sales]: 'jfw-sales-portal',
  [Site.EOO2]: 'jfw-essentials-on-order-v2-portal',
  [Site.CorporateLeadership]: 'jfw-corporate-leadership-portal',
  [Site.Marketing]: 'jfw-marketing-portal',
  [Site.ProductionManagement]: 'jfw-production-management-portal',
  [Site.RegionalManager]: 'jfw-regional-manager-portal',
  [Site.POC]: 'jfw-poc-portal',
} as const;

export type SiteToApp = keyof typeof SiteToApp;
