import { EcomStyle, Event, EventMember } from 'common-types';

export function doesEventQualifyForGroomsFreePromo(event: Event): boolean {
  // Promo only applies to wedding/quince.
  if (!(event.eventType === 'Wedding' || event.eventType === 'Quinceañera')) {
    console.log('not a valid wedding type');
    return false;
  }
  // TODO: include logic to exclude events which already had promo applied.

  let qualifyingMembers: number = 0;
  event.members.forEach((member: EventMember) => {
    // Member has been paid for and shipped
    if (
      member.memberProgress?.paid !== undefined &&
      member.memberProgress.shipped !== undefined
    ) {
      let hasCoat: boolean = false;
      let hasPant: boolean = false;
      member.memberLook?.styles.forEach((style: EcomStyle) => {
        if (style.styleType === 'Coat') {
          hasCoat = true;
        }
        if (style.styleType === 'Pant') {
          hasPant = true;
        }
      });
      if (hasCoat && hasPant) {
        qualifyingMembers += 1;
        console.log(
          'member qualifies. total qualifying members: ',
          qualifyingMembers,
          '. event ID is: ',
          event.id
        );
      } else {
        console.log('member does not qualify: ', member);
      }
    }
  });
  if (qualifyingMembers < 6) {
    // 5 full paid/shipped and groom/honor escort required
    console.log('event does not qualify: ', event.id);
    return false;
  } else {
    console.log('event qualifies for grooms free: ', event.id);
    return true;
  }
}
