import { CommissionTypeEnum, Event } from 'common-types';

export const getCustomLinkSteps = (event: Event): boolean => {
  const { inStoreInfo } = event;
  if (inStoreInfo) {
    // If its a custom link and a store is set from event creation we skip chose a store
    if (
      event.commissionType === CommissionTypeEnum.CustomLink &&
      inStoreInfo.store
    ) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};
