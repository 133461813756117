import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  convertDateToUnixTimestamp,
  convertUnixTimestampToDate,
} from 'business-logic';
import { defer, distinctUntilChanged, map, of, startWith } from 'rxjs';

type DateForm = {
  date: FormControl<Date | null>;
};

type DateFormGroup = FormGroup<DateForm>;

type neededType = FormGroup<{ date: FormControl<Date> }>;

// const minDateValidator = (minDate: Date): ValidatorFn => (control: AbstractControl<Date | null>): ValidationErrors | null => {
//   console.log("in minDateValidator... minDate: ", minDate);
//   const date = control.value;
//   const dif = date ? date.getTime() - minDate.getTime() : null;
//   const isValid = (minDate && dif) ? dif >= 0 : true;
//   return isValid ? null : { minDate: true };
// };

// const maxDateValidator = (maxDate: Date): ValidatorFn => (control: AbstractControl<Date | null>): ValidationErrors | null => {
//   console.log("in maxDateValidator... maxDate: ", maxDate);
//   const date = control.value;
//   const isValid = (maxDate && date) ? date.getTime() <= maxDate.getTime() : true;
//   return isValid ? null : { maxDate: true };
// };

@Component({
  selector: 'app-date-form',
  templateUrl: './date-form.component.html',
  styleUrls: ['./date-form.component.scss'],
})
export class DateFormComponent implements OnInit {
  /* @param {boolean} hideHint - Dynamically hide the label text */
  @Input() hideLabel!: boolean;
  /* @param {boolean} hideHint - Dynamically hide the hint text */
  @Input() hideHint!: boolean;
  /* @param {boolean} hideHintOnValid - Hide the hint text when the form is valid */
  @Input() hideHintOnValid = false;
  @Input() displayError = true;
  @Input() error!: string | undefined;
  @Input() required = true;
  @Input() minDate: Date | undefined;
  @Input() maxDate: Date | undefined;
  @Input() label = '';
  @Input() hideRequiredMarker = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() textAlign!: string;
  @Input() fontWeight!: string;
  @Input() set disabled(disabled: boolean | { disabled: boolean, emitEvent: boolean }) {
    console.log("disabled", disabled);
    if (typeof disabled === 'object') {
      this.form.disable({ emitEvent: disabled.emitEvent });
    } else {
      if (disabled) this.form.disable();
      else this.form.enable();
    }
  }
  @Input() updateOn: FormControl['updateOn'] = 'change';

  @Input()
  set initialValue(value: number) {
    console.log('initialValue', value);
    if (value !== 0) {
      const date = convertUnixTimestampToDate(value);
      this.form.patchValue(
        { date: date },
        { emitEvent: false, onlySelf: true }
      );
    }
  }

  formFieldClasses: string[] = [];

  form = new FormGroup<DateForm>({
    date: new FormControl<Date | null>(null, { updateOn: this.updateOn }),
  }, { updateOn: this.updateOn });


  @Output() formReady = of(this.form);

  @Output()
  valueChange = defer(() => {
    return this.form.valueChanges.pipe(
      startWith(this.form.value),
      distinctUntilChanged((prev, cur) => {
        const isSame = prev.date === cur.date
        console.log('distinctUntilChanged isSame:', isSame);
        return isSame;
      }),
      map((formValue) => {
        console.log('valueChange in DateComponent', formValue);
        if (this.error) {
          this.form.controls.date.setErrors({ invalid: true });
          this.form.controls.date.markAsTouched();
        }
        return formValue.date
          ? convertDateToUnixTimestamp(formValue.date.toDateString())
          : undefined;
      })
    );
  });

  /** Emits on date input field blur and on date picker closed */
  @Output() public onBlur: EventEmitter<void> = new EventEmitter();

  shouldHideHint(hideHint: boolean, hideHintOnValid: boolean): boolean {
    return (
      hideHint ||
      (hideHintOnValid &&
        this.form.controls.date.valid &&
        !!this.form.controls.date.value)
    );
  }

  ngOnInit(): void {
    if (this.textAlign) {
      this.formFieldClasses.push(this.textAlign);
    }
    if (this.fontWeight) {
      this.formFieldClasses.push(this.fontWeight);
    }
    // /*
    //   the minDate property on Mat DatePicker only controls
    //   which dates are displayed and therefore selectable,
    //   but does not handle if a date is manually entered in the form field,
    //   which could be before the minDate. So this adds a validator to the form
    //   to check if the date is before the minDate, and if so, returns the "minDate" error.
    //   Only adds the validator if the minDate property is set.
    // */
    // if(this.minDate) {
    //   console.log("adding minDateValidator to date form");
    //   this.form.controls.date.addValidators(minDateValidator(this.minDate))
    //   this.form.controls.date.updateValueAndValidity();
    // };

    // // same as above, but for optional maxDate property
    // if(this.maxDate) {
    //   this.form.controls.date.addValidators(maxDateValidator(this.maxDate));
    //   this.form.controls.date.updateValueAndValidity();
    // }
  }

  ngOnChanges(change: SimpleChange): void {
    console.log('ngOnChanges', change)
  // const dateValue = this.form.controls.date.value;
  // if (this.maxDate && dateValue && dateValue > this.maxDate) {
  //   setTimeout(() => {
  //     this.form.controls.date.setErrors({ matDatepickerMax: true });
  //   });
  // }
  // if (this.error) {
  //   setTimeout(() => {
  //     this.form.controls.date.setErrors({ invalid: true });
  //   });

    //   this.form.controls.date.markAsTouched();
    // } else {
    //   console.log('no error');
    //   this.form.controls.date.setErrors(null);
    //   this.form.controls.date.markAsPristine();
    // }
  }

  formFieldClass(): string {
    return this.formFieldClasses.join(' ');
  }
}
