import { z } from 'zod';
import { EventMemberSchema } from './event-member.type';

export const EventMemberDetailsSchema = EventMemberSchema.pick({
  firstName: true,
  lastName: true,
  memberRole: true,
  phone: true,
  email: true,
});

export type EventMemberDetails = z.infer<typeof EventMemberDetailsSchema>;
// export interface EventAdminDetails extends z.infer<typeof EventAdminDetailsSchema> { }
