export function getLegacyOrderType(
  eventType: string,
  memberRole: string
): string {
  switch (eventType) {
    case 'Wedding':
      switch (memberRole) {
        case 'Groom':
          return 'G';
        default:
          return 'W';
      }
    case 'Quinceañera':
      switch (memberRole) {
        case 'Honor Escort':
          return 'H';
        default:
          return 'E';
      }
    case 'Prom':
      return 'P';
    default:
      return 'S';
  }
}
