import { Category } from '../platform/style-properties/category';
import { StyleGroup } from '../platform/style-properties/style-group';
import { StyleType } from '../platform/style-properties/style-type';
import { AltColor, AltColorSchema } from './alt-color.type';
import { Image, ImageSchema } from './image.type';
import { MatchingStyle, MatchingStyleSchema } from './matching-style.type';

import * as z from 'zod';

export const BaseEcomStyleSchema = z.object({
  activeDate: z.number().optional(),
  altColors: z.array(AltColorSchema).optional(),
  attachment: z.string().optional(),
  beltLoops: z.boolean().optional(),
  buttons: z.number().optional(),
  categories: z.array(z.nativeEnum(Category)).optional(),
  coatLength: z.number().optional(),
  coatType: z.string().optional(),
  collarType: z.string().optional(),
  collection: z.string().optional(),
  colorFamily: z.string().optional(),
  colorHexCode: z.string().optional(),
  colorShade: z.string().optional(),
  colorShadeId: z.string().optional(),
  colors: z.array(z.string()).optional(),
  companionStyleCode: z.string().optional(),
  companionStylePrice: z.number().optional(),
  designer: z.string().optional(),
  discontinuedDate: z.number().optional(),
  fabric: z.string().optional(),
  finish: z.string().optional(),
  fit: z.string().optional(),
  images: z.array(ImageSchema).optional(),
  jewelryType: z.string().optional(),
  keywords: z.array(z.string()).optional(),
  lapel: z.string().optional(),
  leg: z.string().optional(),
  legacyStyleCode: z.string().optional(),
  mannequinImages: z.array(ImageSchema).optional(),
  marketingDescription: z.string().optional(),
  marketingTitle: z.string().optional(),
  matchingStyles: z.array(MatchingStyleSchema).optional(),
  material: z.string().optional(),
  measurementScale1: z.string().optional(),
  measurementScale2: z.string().optional(),
  miscellaneousType: z.string().optional(),
  pattern: z.string().optional(),
  pocket: z.string().optional(),
  price: z.number().optional(),
  program: z.string().optional(),
  publishedDate: z.number().optional(),
  recommendedStyles: z.array(z.string()).optional(),
  // relatedStyles: z.array(EcomStyleSchema).optional(), // This is a circular reference so has to be done with some Zod and Typescript gymnastics as seen below
  shirtFront: z.string().optional(),
  shoeType: z.string().optional(),
  sizeScale: z.string().optional(),
  sockPattern: z.string().optional(),
  styleCode: z.string(),
  styleFirestoreDocumentId: z.string().optional(),
  styleGroup: z.nativeEnum(StyleGroup),
  styleRank: z.number().optional(),
  styleType: z.nativeEnum(StyleType),
  supplies: z.string().optional(),
  suspenderButtons: z.boolean().optional(),
  tieType: z.string().optional(),
  userCartSelectedStyleGroup: z.string().optional(),
  vent: z.string().optional(),
  vestBack: z.string().optional(),
  vestType: z.string().optional(),
  waist: z.string().optional(),
  warehouses: z.array(z.string()).optional(),
  webDiscontinuedDate: z.number().optional(),
  webReleaseDate: z.number().optional(),
  avalaraLineId: z.string().optional(),
  metaDescription: z.string().optional(),
});

// This is necessary to avoid circular reference errors because the relatedSytles property is an array of EcomStyle
// which is the same type as the parent type.
// see https://github.com/colinhacks/zod#recursive-types for more info
export type ZodBaseEcomStyle = z.infer<typeof BaseEcomStyleSchema>;

export const PartialEcomStyleSchema: z.ZodType<Partial<ZodBaseEcomStyle>> =
  BaseEcomStyleSchema.partial();

export type PartialZodEcomStyle = z.infer<typeof PartialEcomStyleSchema>;

export const EcomStyleSchema = BaseEcomStyleSchema.extend({
  relatedStyles: z.array(z.lazy(() => PartialEcomStyleSchema)).optional(),
});

export type ZodEcomStyle = z.infer<typeof EcomStyleSchema>;

/// SECOND ATTEMPT
// export type ZodEcomStyle = z.infer<typeof BaseEcomStyleSchema>;

// export const PartialEcomStyleSchema: z.ZodType<Partial<ZodEcomStyle>> = BaseEcomStyleSchema.partial();

// export const EcomStyleSchema: z.ZodType<ZodEcomStyle & { relatedStyles: Partial<ZodEcomStyle>[] }> = BaseEcomStyleSchema.extend({
//   relatedStyles: z.array(z.lazy(() => PartialEcomStyleSchema)),
// });

/// FIRST ATTEMPT
// export type ZodEcomStyle = z.infer<typeof BaseEcomStyleSchema> & {
//   relatedStyles: ZodEcomStyle[];
// };

// export const EcomStyleSchema: z.ZodType<ZodEcomStyle> = BaseEcomStyleSchema.extend({
//   relatedStyles: z.lazy(() => EcomStyleSchema.array()),
// });

export type EcomStyle = {
  activeDate?: number;
  altColors?: AltColor[];
  attachment?: string;
  beltLoops?: boolean;
  buttons?: number;
  categories?: Category[];
  coatLength?: number;
  coatType?: string;
  collarType?: string;
  collection?: string;
  colorFamily?: string;
  colorHexCode?: string;
  colorShade?: string;
  colorShadeId?: string;
  colors?: string[];
  companionStyleCode?: string;
  companionStylePrice?: number;
  designer?: string;
  discontinuedDate?: number;
  fabric?: string;
  finish?: string;
  fit?: string;
  images?: Image[];
  jewelryType?: string;
  keywords?: string[];
  lapel?: string;
  leg?: string;
  legacyStyleCode?: string;
  mannequinImages?: Image[];
  marketingDescription?: string;
  marketingTitle?: string;
  matchingStyles?: MatchingStyle[];
  material?: string;
  measurementScale1?: string;
  measurementScale2?: string;
  miscellaneousType?: string;
  pattern?: string;
  pocket?: string;
  price?: number;
  program?: string;
  publishedDate?: number;
  recommendedStyles?: string[];
  relatedStyles?: Partial<EcomStyle>[];
  shirtFront?: string;
  shoeType?: string;
  sizeScale?: string;
  sockPattern?: string;
  styleCode: string;
  styleFirestoreDocumentId?: string;
  styleGroup: StyleGroup;
  styleRank?: number;
  styleType: StyleType;
  supplies?: string;
  suspenderButtons?: boolean;
  tieType?: string;
  userCartSelectedStyleGroup?: string;
  vent?: string;
  vestBack?: string;
  vestType?: string;
  waist?: string;
  warehouses?: string[];
  webDiscontinuedDate?: number;
  webReleaseDate?: number;
  avalaraLineId?: string;
  metaDescription?: string;
};
