import { CommissionTypeEnum, Event, EventStep } from 'common-types';
import { getCustomLinkSteps } from './get-custom-link-steps';
import { getInStoreView } from './get-in-store-view';
import { getIndividualInStoreViewCustomLinkSteps } from './get-individual-in-store-custom-link-steps';
import { getIndividualInStoreView } from './get-individual-in-store-view';
import { getIndividualView } from './get-individual-view';
import { getMemberView } from './get-member-view';

export function getEventSteps(
  event: Event,
  dealerPortal: boolean
): EventStep[] {
  if (dealerPortal) {
    return dealerSteps;
  }
  // CommissionType CUSTOM_LINKS
  if (event.commissionType === CommissionTypeEnum.CustomLink) {
    if (getIndividualInStoreViewCustomLinkSteps(event)) {
      return individualInStoreCustomLinkSteps;
    }
    if (getCustomLinkSteps(event)) {
      // Skips choose a store since it locks the InStoreInfo for commissions
      return hybridOrInStoreStepsByStatus;
    }
  }

  // TODO: (Done after purging of events).
  //  No events should have no commissionType anymore and we should probably have a fallback here.
  // CommissionType ORGANIC/REFERRAL
  if (getIndividualInStoreView(event)) {
    return individualInStoreSteps;
  }
  if (getMemberView(event)) {
    return memberSteps;
  }
  if (getIndividualView(event)) {
    return individualSteps;
  }
  if (getInStoreView(event)) {
    return inStoreSteps;
  }
  return organizerSteps;
}

// Steps by ecomStatus Steps type: 'O', 'I', and 'H'
// If InStoreInfo
const hybridOrInStoreStepsByStatus: EventStep[] = [
  {
    route: 'add-looks',
    label: 'Add Looks',
    hidePrevBtn: true,
  },
  {
    route: 'add-members',
    label: 'Add Members',
  },
  {
    route: 'assign-looks',
    label: 'Assign Looks',
  },
  {
    route: 'share-event',
    label: 'Share Event Details',
    nextBtnName: 'Share With Retailer',
  },
  {
    route: 'confirmation',
    hidePrevBtn: true,
    hideNextBtn: true,
  },
];

const organizerSteps: EventStep[] = [
  {
    route: 'add-looks',
    label: 'Add Looks',
    hidePrevBtn: true,
  },
  {
    route: 'add-members',
    label: 'Add Members',
  },
  {
    route: 'assign-looks',
    label: 'Assign Looks',
  },
  {
    route: 'review-event',
    label: 'Review Event',
  },
  {
    route: '/checkout',
  },
];

const memberSteps: EventStep[] = [
  {
    route: 'review-event',
    label: 'Event Details',
    hidePrevBtn: true,
  },
  {
    route: '/checkout',
  },
];

const individualSteps: EventStep[] = [
  {
    route: 'select-look',
    label: 'Add/Select Look',
    hidePrevBtn: true,
  },
  {
    route: 'wearers-info',
    label: "Wearer's Information",
  },
  {
    route: '/checkout',
  },
];

const individualInStoreSteps: EventStep[] = [
  {
    route: 'select-look',
    label: 'Add/Select Look',
    hidePrevBtn: true,
  },
  {
    route: 'wearers-info',
    label: "Wearer's Information",
  },
  {
    route: 'choose-store',
    label: 'Choose a Retailer',
    hideNextBtn: false,
  },
  {
    route: 'share-event',
    label: 'Share Event Details',
    nextBtnName: 'Share With Retailer',
  },
  {
    route: 'confirmation',
    hidePrevBtn: true,
    hideNextBtn: true,
  }
];

const individualInStoreCustomLinkSteps: EventStep[] = [
  {
    route: 'select-look',
    label: 'Add/Select Look',
    hidePrevBtn: true,
  },
  {
    route: 'wearers-info',
    label: "Wearer's Information",
  },
  {
    route: 'share-event',
    label: 'Share Event Details',
    nextBtnName: 'Share With Retailer',
  },
  {
    route: 'confirmation',
    hidePrevBtn: true,
    hideNextBtn: true,
  }
];

const inStoreSteps: EventStep[] = [
  {
    route: 'add-looks',
    label: 'Add Looks',
    hidePrevBtn: true,
  },
  {
    route: 'add-members',
    label: 'Add Members',
  },
  {
    route: 'assign-looks',
    label: 'Assign Looks',
  },
  {
    route: 'choose-store',
    label: 'Choose a Retailer',
    hideNextBtn: false,
  },
  {
    route: 'share-event',
    label: 'Share Event Details',
    nextBtnName: 'Share With Retailer',
  },
  {
    route: 'confirmation',
    hidePrevBtn: true,
    hideNextBtn: true,
  },
];

const dealerSteps: EventStep[] = [
  {
    route: 'add-looks',
    label: 'Add Looks',
    hidePrevBtn: true,
  },
  {
    route: 'add-members',
    label: 'Add Members',
  },
  {
    route: 'assign-looks',
    label: 'Assign Looks',
  },
  {
    route: 'review-event',
    label: 'Review Event',
    nextBtnName: 'Transfer',
  },
  {
    route: 'transfer-confirmation',
    hidePrevBtn: true,
    hideNextBtn: true,
  },
];
