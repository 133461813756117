import { Event } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getArrayDif } from './utils/get-array-dif';

export function getLooksDifFromEvent<T extends Event>(previous: T, current: T, options?: DifOptions<'looks'>) {

  return getArrayDif(previous.looks, current.looks, 0, 'looks', 'id', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions)

}

// const event: Event = {} as Event;

// getLooksDifFromEvent(event, event, { keysToIgnore: ['altColors'] });

