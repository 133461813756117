import { DateAsObjectSchema } from './date-object.type';
// import { DateAsDate, DateAsDateSchema } from './date-date.type';
import * as z from 'zod';

// This converts an object with date as a property into just a string.
// Currently the date-form component returns an object with date as a nested property.
// This makes it easier on the backend to just have a string value for date instead of an object that needs to be unpacked.
export const DateObjectToStringSchema = DateAsObjectSchema.transform(obj => obj?.date?.toLocaleDateString('en-US') ?? null).pipe(z.string().nullable());

export type DateObjectToString = z.infer<typeof DateObjectToStringSchema>;

  // const parsed = DateObjectToStringSchema.parse({ date: new Date() }); // -> { date: '2021-09-01' }
