import { Event } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getArrayDif } from './utils/get-array-dif';

export function getMembersDifFromEvent<T extends Event>(previous: T, current: T, options?: DifOptions<'members'>) {

  return getArrayDif(previous.members, current.members, 0, 'members', 'id', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions)

}

// const event: Event = {} as Event;

// getMembersDifFromEvent(event, event, { keysToIgnore: ['memberLook'] });

