import * as z from 'zod';

export const ShareEventDetailsSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
  eventSize: z.number().optional(),
});

export type ZodShareEventDetails = z.infer<typeof ShareEventDetailsSchema>;

export type ShareEventDetails = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  eventSize?: number;
};
