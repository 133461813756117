import { Event } from "common-types";

/**
 * Get all style codes in an event.  This includes style codes from looks and member looks.  This will return a unique list of style codes (no duplicates).
 * @param event - The event to get style codes from
 * @returns - An array of unique style codes
 */
export function getAllStyleCodesInEvent(event: Event) {
  const styleCodes = new Set<string>();
  for (const look of event.looks) {
    for (const style of look.styles) {
      styleCodes.add(style.styleCode);
    }
  }

  for (const member of event.members) {
    for (const style of member?.memberLook?.styles ?? []) {
      styleCodes.add(style.styleCode);
    }
  }

  return Array.from(styleCodes);
}
