import { EcomEnsemble, EcomStyle, StyleType } from 'common-types';

export function getEnsembleDesigner(ensemble: EcomEnsemble): string {
  let coat: EcomStyle | undefined;
  let designer = '';

  ensemble?.styles.forEach((style) => {
    if (style.styleType === StyleType.Coat) {
      coat = style;
    }
  });

  if (coat && coat.designer) {
    designer = coat.designer;
  }

  return designer;
}
