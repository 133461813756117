import { z, ZodSchema } from 'zod';

export const MeasurementsValidator: ZodSchema = z.object({
  derivedFrom: z.string().max(20).optional(),
  chest: z.number().max(89).optional(),
  overarm: z.number().max(99).optional(),
  waist: z.number().max(99).optional(),
  hip: z.number().max(99).optional(),
  belly: z.number().max(99).optional(),
  neck: z.number().max(28.0).optional(),
  sleeve: z.number().max(41).optional(),
  insleeve: z.number().max(20).optional(),
  outseam: z.number().max(60).optional(),
  inseam: z.number().max(50).optional(),
  heightFeet: z.number().max(9).optional(),
  heightInches: z.number().max(11).optional(),
  weight: z.number().max(999).optional(),
  age: z.number().max(140).optional(),
  coatSize: z.string().min(2).max(3).optional(),
  vestSize: z.string().length(2).optional(),
  shoeLength: z.number().max(18.0).optional(),
  shoeWidth: z.string().length(1).optional(),
});
