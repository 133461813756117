export enum DealerUserMessage {
  GET_DEALER_USER_BY_ID = 'Failed to fetch dealer user by id',
  GET_PENDING_DEALER_USER_BY_ID = 'Failed to fetch pending dealer users by id',
  POST_DEALER_USER = 'Failed to create a dealer user',
  PUT_DEALER_USER = 'Failed to update dealer user',
  POST_ENABLE_DEALER_USER = 'Failed to enable dealer user',
  PUT_DEALER_INVITE_SENT = 'Failed to update invite sent in dealer user',
  DELETE_DISABLE_DEALER_USER = 'Failed to delete/disable dealer user',
  DELETE_DEALER_USER = 'Failed to delete dealer user',
  GET_ENV_ERROR = 'Failed to get ENV_FILE',
  GET_AUTH_MIDDLEWARE = 'Failed to get authmiddleware',
  GET_AUTH_MIDDLEWARE_PARAMS = 'Failed to get authmiddleware with params',

  GET_DEALER_IDS_USER_ID = 'Failed to get dealer user by userId',
  GET_DEALER_USER_BY_EMAIL_USER_ID = 'Failed to get dealer user by email and user id',
}
