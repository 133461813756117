import { Event, EventMember } from 'common-types';

export function memberInEvent(event: Event, memberEmail: string): boolean {
  if (event.members === undefined) {
    return false;
  }

  return (
    -1 !==
    event.members.findIndex(
      (member: EventMember) => member.email === memberEmail
    )
  );
}
