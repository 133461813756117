import {
  DealerCommunicationPref,
  DealerCommunicationPrefs,
} from './dealer-communication-prefs.type';
import { UpdateDealerCommunicationPrefsData } from './update-dealer-communication-prefs-data.type';
import {
  UpdateDealerCommunicationPrefsError,
  UpdateDealerCommunicationPrefsErrorCode,
} from './update-dealer-communication-prefs-error.type';
import { UpdateDealerCommunicationPrefsResponse } from './update-dealer-communication-prefs-response.type';

export {
  DealerCommunicationPref,
  DealerCommunicationPrefs,
  UpdateDealerCommunicationPrefsData,
  UpdateDealerCommunicationPrefsError,
  UpdateDealerCommunicationPrefsErrorCode,
  UpdateDealerCommunicationPrefsResponse,
};
