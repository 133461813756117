import { LegacyStyle } from 'common-types';

export function getStyleCodeFromLegacy(legacyStyle: LegacyStyle): string {
  let styleGroup = '';
  switch (legacyStyle.styleGroup) {
    case 'Purchase':
      styleGroup = 'P';
      break;
    case 'Display':
      styleGroup = 'D';
      break;
    default:
      styleGroup = 'R';
  }
  let styleType = '';
  switch (legacyStyle.styleType) {
    case 'Coat':
      styleType = 'CT';
      break;
    case 'Pant':
      styleType = 'PT';
      break;
    case 'Shirt':
      styleType = 'SR';
      break;
    case 'Tie':
      styleType = 'TI';
      break;
    case 'Vest':
      styleType = 'VS';
      break;
    case 'PocketSquare':
      styleType = 'PK';
      break;
    case 'Shoes':
      styleType = 'SO';
      break;
    case 'Socks':
      styleType = 'HS';
      break;
    case 'Jewelry':
      styleType = 'JW';
      break;
    case 'Suspenders':
      styleType = 'SU';
      break;
    case 'Miscellaneous':
      styleType = 'MS';
      break;
    case 'ArmBands':
      styleType = 'AB';
      break;
  }
  return styleGroup + styleType + legacyStyle.styleCode;
}
