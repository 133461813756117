import { Event } from "common-types";
import { getAllStyleCodesInEvent } from '../../utilities/get-all-style-codes-in-event';
import { getMemberEmails } from "./get-member-emails";
import { getMemberIds } from "./get-member-ids";

/**
 * Returns a new event object with the member removed.  Does not mutate the original event.  Updates the members array, memberIds array, memberEmails array, and styleCodes array.
 * @param event the event to remove the member from
 * @param memberId the id of the member to remove
 * @returns the event with the member removed (returns a new object; does not mutate the original event)
 */
export function deleteMemberFromEvent(event: Event, memberId: string): Event {
  const newMembers = event.members.filter(member => member.id !== memberId);
  const newMemberIds = getMemberIds(newMembers);
  const newMemberEmails = getMemberEmails(newMembers);
  const newStyleCodes = getAllStyleCodesInEvent({ ...event, members: newMembers });


  const newEvent: Event = {
    ...event,
    members: newMembers,
    memberIds: newMemberIds,
    memberEmails: newMemberEmails,
    styleCodes: newStyleCodes,
  }

  return newEvent;
}
