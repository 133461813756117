import { EventRole } from 'common-types';

export const StaticEventRoles: Array<EventRole> = [
  {
    name: 'Groom',
    eventType: 'Wedding',
    legacyCode: 'GR',
    legacyRole: 'groom',
  },
  {
    name: 'Best Man',
    eventType: 'Wedding',
    legacyCode: 'BM',
    legacyRole: 'best man',
  },
  {
    name: 'Groomsman',
    eventType: 'Wedding',
    legacyCode: 'GM',
    legacyRole: 'groomsman'
  },
  {
    name: 'Person of Honor',
    eventType: 'Wedding',
    legacyCode: 'BM',
    legacyRole: '',
  },
  {
    name: 'Father of the Bride',
    eventType: 'Wedding',
    legacyCode: 'FB',
    legacyRole: 'father of bride',
  },
  {
    name: 'Father of the Groom',
    eventType: 'Wedding',
    legacyCode: 'FG',
    legacyRole: 'father of groom',
  },
  {
    name: 'Grandfather',
    eventType: 'Wedding',
    legacyCode: 'GF',
    legacyRole: 'grandfather',
  },
  {
    name: 'Ring Bearer',
    eventType: 'Wedding',
    legacyCode: 'RB',
    legacyRole: 'ring bearer',
  },
  {
    name: 'Usher',
    eventType: 'Wedding',
    legacyCode: 'US',
    legacyRole: 'usher',
  },
  {
    name: 'Bride',
    eventType: 'Wedding',
    legacyCode: 'BR',
    legacyRole: 'bride',
  },
  {
    name: 'Best Woman',
    eventType: 'Wedding',
    legacyCode: 'BM',
    legacyRole: '',
  },
  {
    name: 'Groomswoman',
    eventType: 'Wedding',
    legacyCode: 'GM',
    legacyRole: '',
  },
  {
    name: 'Officiant',
    eventType: 'Wedding',
    legacyCode: 'OT',
    legacyRole: 'other',
  },
  {
    name: 'Guest',
    eventType: 'Wedding',
    legacyCode: 'GU',
    legacyRole: 'guest',
  },
  {
    name: 'Other',
    eventType: 'Wedding',
    legacyCode: 'OT',
    legacyRole: 'other',
  },
  {
    name: 'Event Goer',
    eventType: 'Prom',
    legacyCode: 'OT',
    legacyRole: 'other',
  },
  {
    name: 'Other',
    eventType: 'Prom',
    legacyCode: 'OT',
    legacyRole: 'other',
  },
  {
    name: 'Honor Escort',
    eventType: 'Quinceañera',
    legacyCode: 'CH',
    legacyRole: 'honor escort',
  },
  {
    name: 'Escort',
    eventType: 'Quinceañera',
    legacyCode: 'ES',
    legacyRole: 'escort',
  },
  {
    name: 'Father of Quince',
    eventType: 'Quinceañera',
    legacyCode: 'FQ',
    legacyRole: 'father of quince',
  },
  {
    name: 'Grandfather',
    eventType: 'Quinceañera',
    legacyCode: 'GQ',
    legacyRole: 'grandfather of quince',
  },
  {
    name: 'Other',
    eventType: 'Quinceañera',
    legacyCode: 'OT',
    legacyRole: 'other',
  },
  {
    name: 'Event Goer',
    eventType: 'Special Event',
    legacyCode: 'OT',
    legacyRole: 'other',
  },
  {
    name: 'Other',
    eventType: 'Special Event',
    legacyCode: 'OT',
    legacyRole: 'other',
  },
];
