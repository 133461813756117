import { z } from 'zod';
import { MeasurementsSchema } from '../../../checkout/measurements.type';
import { DealerUserEvent } from '../../../dealer/dealer-user-event.type';
import { Look, LookSchema } from '../../../ensemble';
import { Event, EventAdminDetailsSchema, EventDetailsSchema, EventMemberDetailsSchema, EventSchema, UserEvent } from '../../../event';
import { EventAdminNoIdSchema, EventAdminSchema } from '../../../event/event-admin.type';
import {
  EventMember,
  EventMemberSchema,
} from '../../../event/event-member.type';
import { HttpMethod } from '../../../logger/http-method.enum';
import { DealerAccountElasticSchema } from '../../../store';
import { InStoreInfoSchema } from '../../../store/in-store-info.type';
import { EcomStyle, EcomStyleSchema } from '../../../style/ecommerce/ecom-style.type';
import { StyleGroup } from '../../../style/platform/style-properties';


// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export const EventNotFoundResponse = 'Event not found' as const;
export const InvalidUserOrEventResponse = 'Invalid user/event' as const;
export const UserValidationErrorResponse =
  'Unable to validate user for event' as const; // Generic error for any error thrown by isValidUserForEventWrites
export const InvalidDataResponse = 'Invalid data' as const;
export const MissingMemberIdsResponse = 'Missing memberIds' as const;
export const LooksIsEmptyResponse = 'No looks to update' as const;
export const LookNotFoundResponse = 'Look not found' as const;
export const UnknownErrorResponse = 'Unknown error' as const;
export const InvalidEventResponse = 'Invalid event' as const;
export const CreateEventMissingOrganizerResponse = "Missing organizer" as const;
export const EventHasPaidMembersResponse = "Event has paid members." as const;
export const EventSharedWithRetailerResponse =
  'Event has been shared with retailer.' as const;

export type UnknownErrorResponseType = typeof UnknownErrorResponse;



// ********************************************************************************************************************
// *                                             CREATE                                                               *
// ********************************************************************************************************************

/// ************ POST /create ************ ///


export const CreateEventFromEcomSchema = z.object({
  /** the event to create */
  event: EventSchema,
});
export type CreateEventFromEcomDataType = z.infer<typeof CreateEventFromEcomSchema>;

/** Data to create an event from Ecom. Organizer is generated (from the decodedUser info) in the api for Ecom events so no organizer info is sent with the request.
 * @param event the event to create
*/
export type CreateEventFromEcomData = {
  /** the event to create */
  event: CreateEventFromEcomDataType['event'],
}
export type CreateEventFromEcomSuccessResponse = {
  newEvent: Event,
  userEvent: UserEvent,
};
export const CreateEventFromEcomFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  InvalidEventResponse,
  // CreateEventFailureResponse,
];
export type CreateEventFromEcomFailureResponsesType =
  (typeof CreateEventFromEcomFailureResponses)[number];
export type CreateEventFromEcomResponse = CreateEventFromEcomSuccessResponse |
  CreateEventFromEcomFailureResponsesType |
  UnknownErrorResponseType;



export const CreateEventFromDealerPortalSchema = z.object({
  /** the event to create */
  event: EventSchema,
  /** the organizer of the event */
  organizer: EventAdminNoIdSchema,
});
type CreateEventFromDealerPortalDataType = z.infer<typeof CreateEventFromDealerPortalSchema>;

/**
 * Data to create a new event from Dealer Portal.
 * @param event the event to create
 * @param organizer the organizer of the event
 */
export type CreateEventFromDealerPortalData = {
  /** the event to create */
  event: CreateEventFromDealerPortalDataType['event'],
  /** the organizer of the event */
  organizer: CreateEventFromDealerPortalDataType['organizer'],
}

export type CreateEventFromDealerPortalSuccessResponse = {
  newEvent: Event,
  dealerUserEvent: DealerUserEvent
};

export const CreateEventFromDealerPortalFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  CreateEventMissingOrganizerResponse,
  InvalidEventResponse,
];
export type CreateEventFromDealerPortalFailureResponsesType =
  (typeof CreateEventFromDealerPortalFailureResponses)[number];

export type CreateEventFromDealerPortalResponse = CreateEventFromDealerPortalSuccessResponse |
  CreateEventFromDealerPortalFailureResponsesType |
  UnknownErrorResponseType;




// ********************************************************************************************************************
// *                                             READ                                                                 *
// ********************************************************************************************************************

/// ************ GET /user/:id ************ ///
// export type GetAllEventsByUserIdRequest = NoBodyRequest;
// export type GetAllEventsByUserIdSuccessResponse = Event[];


/// ************ GET /:id ************ ///
export type GetEventByIdSuccessResponse = { event: Event };
export const GetEventByIdFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
];
export type GetEventByIdFailureResponsesType =
  (typeof GetEventByIdFailureResponses)[number];
export type GetEventByIdResponse = GetEventByIdSuccessResponse |
  GetEventByIdFailureResponsesType |
  UnknownErrorResponseType;


/// ************ GET /grooms-free/:eventId ************ ///
// export type GroomsFreeSuccessResponse = {
//   doesEventQualify: boolean;
//   qualifyingMember: EventMember | undefined;
// };


/// ************ POST /has-discontinued-styles/eventid/:id ************ ///
export const HasDiscontinuedStylesSchema = z.object({
  memberIds: z.array(z.string()),
})
export type HasDiscontinuedStylesData = z.infer<typeof HasDiscontinuedStylesSchema>;
export type MembersWithDiscontinuedStylesMap = Record<string, {
  discontinuedStylesCodes: EcomStyle[]
}>;
export type HasDiscontinuedStylesSuccessResponse = {
  membersHaveDiscontinuedStyles: boolean;
  membersWithDiscontinuedStyles: MembersWithDiscontinuedStylesMap;
};
export const MemberIdsNotFoundResponse = 'One or more memberIds not found' as const;
export const HasDiscontinuedStylesFailureResponses = [
  InvalidDataResponse,
  MissingMemberIdsResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  MemberIdsNotFoundResponse,
];

export type HasDiscontinuedStylesFailureResponsesType =
  (typeof HasDiscontinuedStylesFailureResponses)[number];

export type HasDiscontinuedStylesResponse = HasDiscontinuedStylesSuccessResponse |
  HasDiscontinuedStylesFailureResponsesType |
  UnknownErrorResponseType;





// ********************************************************************************************************************
// *                                             UPDATE                                                               *
// ********************************************************************************************************************



/// ************************ MEMBERS ************************ ///
export const AddMemberSchema = z.object({
  member: EventMemberSchema,
});
export type AddMemberData = z.infer<typeof AddMemberSchema>;
// export type AddMemberData = {
//   member: EventMember;
// };

export type AddMemberSuccessResponse = {
  updatedEvent: Event;
  numMembersAdded: number;
  numMemberIdsAdded: number;
};
export const MemberAlreadyExistsResponse =
  'Member already exists in event' as const;
export const UndefinedMemberIdResponse = 'member.id is undefined' as const;
export const AddMemberMismatchResponse =
  'Number of members to add does not match number of memberIds to add' as const;
export const AddMemberFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  MemberAlreadyExistsResponse,
  UndefinedMemberIdResponse,
  AddMemberMismatchResponse,
];
export type AddMemberFailureResponsesType =
  (typeof AddMemberFailureResponses)[number];
export type AddMemberResponse =
  | AddMemberSuccessResponse
  | AddMemberFailureResponsesType
  | UnknownErrorResponseType;

export const UpdateEventMemberDetailsSchema = EventMemberDetailsSchema
  .partial()
  .transform((data) => {
    return removeUndefined(data); // Remove undefined values because Firestore can't assign undefined values.
  });

export type UpdateEventMemberDetailsData = z.infer<
  typeof UpdateEventMemberDetailsSchema
>;
export type UpdateEventMemberDetailsSuccessResponse = {
  updatedMembersArray: EventMember[];
  updatedEvent: Event;
};
export const NoMembersToUpdateResponse = 'No members to update' as const;
export const MemberNotFoundResponse = 'Member not found' as const;
export const NoMembersUpdatedResponse = 'No members were updated' as const;
export const UpdateEventMemberDetailsFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  NoMembersToUpdateResponse,
  MemberNotFoundResponse,
  NoMembersUpdatedResponse,
];
export type UpdateEventMemberDetailsFailureResponsesType =
  (typeof UpdateEventMemberDetailsFailureResponses)[number];
export type UpdateEventMemberDetailsResponse =
  | UpdateEventMemberDetailsSuccessResponse
  | UpdateEventMemberDetailsFailureResponsesType
  | UnknownErrorResponseType;

export type DeleteMemberSuccessResponse = {
  updatedEvent: Event;
  numMembersRemoved: number;
  numMemberIdsRemoved: number;
  numUserEventDocsDeleted: number;
};

export const NoMembersToDeleteResponse = 'No members to delete' as const;
export const NoMemberIdsToDeleteResponse = 'No memberIds to delete' as const;
export const MemberIdNotFoundResponse =
  'memberId does not exist in memberIds array' as const;
export const MemberIsInACartResponse = 'Member is in a cart' as const;
export const DeleteMemberFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  NoMembersToDeleteResponse,
  NoMemberIdsToDeleteResponse,
  MemberNotFoundResponse,
  MemberIdNotFoundResponse,
  MemberIsInACartResponse,
];
export type DeleteMemberFailureResponsesType =
  (typeof DeleteMemberFailureResponses)[number];

export type DeleteMemberResponse =
  | DeleteMemberSuccessResponse
  | DeleteMemberFailureResponsesType
  | UnknownErrorResponseType;

/// ************************ LOOKS ************************ ///

export const AddLookSchema = z.object({
  look: LookSchema,
});
export type AddLookData = z.infer<typeof AddLookSchema>;
export type AddLookSuccessResponse = {
  updatedEvent: Event;
};
export const LookAlreadyExistsResponse = 'Look already exists' as const;
export const AddLookFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  LookAlreadyExistsResponse,
];
export type AddLookFailureResponsesType =
  (typeof AddLookFailureResponses)[number];
export type AddLookResponse =
  | AddLookSuccessResponse
  | AddLookFailureResponsesType
  | UnknownErrorResponseType;

// export const UpdateStyleInMemberLookSchema = z.object({
//   originalStyleCode: z.string(),
//   newStyleCode: z.string(),
//   isUserCartSelected: z.boolean(), // used to tell api to also update the style.userCartSelectedStyleGroup property.
// });

/** An object whose keys are are memberIds and whose values are an object where each key is the original styleCode (before user made cart selections),
 * and its value is the selected style.
 * */
export const UpdateStylesInMemberLooksSchema = z
  .object({
    members: z.record(z.record(EcomStyleSchema)),
    lastUpdateId: EventSchema.shape.lastUpdateId,
  })
  .required();

/** An object whose keys are are memberIds and whose values are an object where each key is the original styleCode (before user made cart selections),
 * and its value is the selected style.
 * */
export type UpdateStylesInMemberLooksData = z.infer<
  typeof UpdateStylesInMemberLooksSchema
>;
export type UpdateStylesInMemberLooksSuccessResponse = {
  updatedEvent: Event;
  numMembersUpdated: number;
  numStylesUpdated: number;
};
export const NoStylesToUpdateResponse = 'No styles to update' as const;
export const NumMembersToUpdateMismatchResponse =
  'Not all requested members to update were able to be updated, so no members were updated.' as const;
export const NumStylesToUpdateMismatchResponse =
  'Not all requested styles to update were able to be updated, so no styles were updated.' as const;
export const MemberIsPaidResponse =
  'Member is paid. Cannot update memberLook.' as const;
export const MemberLookNotFoundResponse = 'MemberLook not found' as const;
export const StyleNotFoundResponse = 'Style not found' as const;
export const InvalidStyleGroupResponse = 'Invalid StyleGroup' as const;
export const UserCartSelectedStyleGroupMissingResponse =
  'userCartSelectedStyleGroup is undefined' as const;
export const StyleNotInAnyLookResponse = 'Style is not in any look' as const;
export const UpdateStylesInMemberLooksFailureResponses = [
  InvalidDataResponse,
  NoMembersToUpdateResponse,
  NoStylesToUpdateResponse,
  EventNotFoundResponse,
  MemberNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  NumMembersToUpdateMismatchResponse,
  NumStylesToUpdateMismatchResponse,
  MemberIsPaidResponse,
  MemberLookNotFoundResponse,
  StyleNotFoundResponse,
  InvalidStyleGroupResponse,
  UserCartSelectedStyleGroupMissingResponse,
  StyleNotInAnyLookResponse,
];
export type UpdateStylesInMemberLooksFailureResponsesType =
  (typeof UpdateStylesInMemberLooksFailureResponses)[number];
export type UpdateStylesInMemberLooksResponse =
  | UpdateStylesInMemberLooksSuccessResponse
  | UpdateStylesInMemberLooksFailureResponsesType
  | UnknownErrorResponseType;

export type CopyLookSuccessResponse = {
  updatedEvent: Event;
};
export const CopyLookFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  LookNotFoundResponse,
];
export type CopyLookFailureResponsesType =
  (typeof CopyLookFailureResponses)[number];

export type CopyLookResponse =
  | CopyLookSuccessResponse
  | CopyLookFailureResponsesType
  | UnknownErrorResponseType;

export const AssignLooksSchema = z.array(
  z
    .object({
      memberId: z.string(),
      lookId: z.string().optional(), // undefined means remove the look assignment, which will remove the memberLook property from the member object in the event.members array.
    })
    .strict()
);

export type AssignLooksData = z.infer<typeof AssignLooksSchema>;

export type AssignLooksSuccessResponse = {
  updatedEvent: Event;
  numMemberLooksUpdated: number;
};
export const NoLooksToAssignResponse = 'No looks to assign' as const; // The looksToAssign array is empty.
export const NoLooksAssignedResponse = 'No looks assigned' as const; // The looksToAssign array was not empty, but still no looks were assigned.
export const AssignLooksFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  LooksIsEmptyResponse,
  NoLooksToAssignResponse,
  NoLooksAssignedResponse,
  LookNotFoundResponse,
  MemberNotFoundResponse,
];
export type AssignLooksFailureResponsesType =
  (typeof AssignLooksFailureResponses)[number];

export type AssignLooksResponse =
  | AssignLooksSuccessResponse
  | AssignLooksFailureResponsesType
  | UnknownErrorResponseType;

export const UpdateLookAndEventMemberLooksSchema = z.object({
  look: LookSchema,
});
export type UpdateLookAndEventMemberLooksData = z.infer<
  typeof UpdateLookAndEventMemberLooksSchema
>;
export type UpdateLookAndEventMemberLooksSuccessResponse = {
  updatedEvent: Event;
  numMemberLooksUpdated: number;
  numLooksUpdated: number;
};
export const CannotModifyLookResponse = 'Cannot modify look' as const;
export const NoLooksUpdatedResponse = 'No looks updated' as const;
export const LookIdMismatchResponse = 'lookId mismatch' as const;
export const UpdateLookAndEventMemberLooksFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  LooksIsEmptyResponse,
  LookNotFoundResponse,
  CannotModifyLookResponse,
  NoLooksUpdatedResponse,
  LookIdMismatchResponse,
];
export type UpdateLookAndEventMemberLooksFailureResponsesType =
  (typeof UpdateLookAndEventMemberLooksFailureResponses)[number];
export type UpdateLookAndEventMemberLooksResponse =
  | UpdateLookAndEventMemberLooksSuccessResponse
  | UpdateLookAndEventMemberLooksFailureResponsesType
  | UnknownErrorResponseType;

export type DeleteLookSuccessResponse = {
  updatedEvent: Event;
  numLooksRemovedFromLooks: number;
  numMemberLookDeletions: number;
};
export const LookIsUsedByPaidMemberResponse =
  'Look is used by a paid member.  Cannot delete.' as const;
export const DeleteLookFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  LooksIsEmptyResponse,
  LookNotFoundResponse,
  LookIsUsedByPaidMemberResponse,
];
export type DeleteLookFailureResponsesType =
  (typeof DeleteLookFailureResponses)[number];
export type DeleteLookResponse =
  | DeleteLookSuccessResponse
  | DeleteLookFailureResponsesType
  | UnknownErrorResponseType;

export type SelectLookSuccessResponse = {
  updatedEvent: Event;
  selectedLook: Look;
};
export const SelectLookFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  LookNotFoundResponse, // TODO: might change API to use lookId instead of look object, in which case this error will be needed.
];
export type SelectLookFailureResponsesType =
  (typeof SelectLookFailureResponses)[number];

export type SelectLookResponse =
  | SelectLookSuccessResponse
  | SelectLookFailureResponsesType
  | UnknownErrorResponseType;

/// ************************ MEMBER PROGRESS ************************ ///
export type MarkInviteSentSuccessResponse = {
  updatedEvent: Event;
};
export const MarkInviteSentFailureResponses = [
  EventNotFoundResponse,
  MemberNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
];
export type MarkInviteSentFailureResponsesType =
  (typeof MarkInviteSentFailureResponses)[number];
export type MarkInviteSentResponse =
  | MarkInviteSentSuccessResponse
  | MarkInviteSentFailureResponsesType
  | UnknownErrorResponseType;

/// ************************ ADMINS ************************ ///
export const AddAdminSchema = z.object({
  admin: EventAdminSchema,
});

export type AddAdminData = z.infer<typeof AddAdminSchema>;

export type AddAdminSuccessResponse = {
  updatedEvent: Event;
};

export const UndefinedAdminIdResponse = 'Undefined admin id';
export const AdminNotFoundResponse = 'Admin not found';
export const LimitedAdminsResponse = 'Too many Admins';
export const AddAdminFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  UndefinedAdminIdResponse,
  LimitedAdminsResponse,
];

export type AddAdminFailureResponsesType =
  (typeof AddAdminFailureResponses)[number];

export type AddAdminResponse =
  | AddAdminSuccessResponse
  | AddAdminFailureResponsesType;

const eventAdminDetails = [
  'email',
  'firstName',
  'lastName',
  'phone',
  'role',
] as const;

type EventAdminDetails = (typeof eventAdminDetails)[number];

/// ************************ ORGANIZER/ADMIN DETAILS ************************ ///
export const UpdateAdminDetailsSchema =
  EventAdminDetailsSchema.partial().transform((data) => {
    return removeUndefined(data); // Remove undefined values because Firestore can't assign undefined values.
  });

// export const UpdateAdminDetailsSchema = EventAdminSchema.pick({
//   firstName: true,
//   lastName: true,
//   // memberRole: true,
//   phone: true,
//   email: true,
// })
//   .partial()
//   .transform((data) => {
//     return removeUndefined(data); // Remove undefined values because Firestore can't assign undefined values.
//   });

export type UpdateAdminDetailsData = z.infer<
  typeof UpdateAdminDetailsSchema
>;

export type UpdateAdminDetailsSuccessResponse = {
  updatedEvent: Event;
};
export const UpdateAdminMissingFirstOrLastNameResponse =
  'The admin is missing either first or last name.' as const;
export const CannotUpdateMultipleOrganizerDetailsResponse =
  'Multiple organizers found to update.' as const; // If the api finds more than one organizer that matches, it will fail because only one admin should be getting updated.
export const OrganizerHasIdResponse = 'Organizer has an id' as const;
export const OrganizerNotFoundResponse = 'Organizer not found' as const;
export const NoAdminsUpdatedResponse = 'No admins were updated' as const;
export const UpdateAdminDetailsFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  UndefinedAdminIdResponse,
  CannotUpdateMultipleOrganizerDetailsResponse,
  UpdateAdminMissingFirstOrLastNameResponse,
  AdminNotFoundResponse,
  OrganizerNotFoundResponse,
  NoAdminsUpdatedResponse,
];

export type UpdateAdminDetailsFailureResponsesType =
  (typeof UpdateAdminDetailsFailureResponses)[number];

export type UpdateAdminDetailsResponse =
  | UpdateAdminDetailsSuccessResponse
  | UpdateAdminDetailsFailureResponsesType;

/// ************************ EVENT DETAILS ************************ ///

// const eventDetails = ['eventName', 'eventType', 'eventDate'] as const;
// export type EventDetails = (typeof eventDetails)[number];
// export type UpdateEventDetailsData = Partial<Pick<Event, EventDetails>>;

export const UpdateEventDetailsSchema = EventDetailsSchema.pick({
  eventName: true,
  eventType: true,
  eventDate: true,
})
  .partial()
  .transform((data) => {
    return removeUndefined(data); // Remove undefined values because Firestore can't assign undefined values.
  });

export type UpdateEventDetailsData = z.infer<typeof UpdateEventDetailsSchema>;
export type UpdateEventDetailsSuccessResponse = {
  updatedEvent: Event;
  numUserEventsUpdated: number;
  numDealerUserEventsUpdated: number;
};
export const NoEventDetailsToUpdateResponse =
  'No event details to update' as const;
export const NoChangeInEventDetailsResponse =
  'No change in event details' as const; // The event details are the same as the existing event details, so no update is needed.
export const InvalidEventDateResponse =
  'Event date is too soon or too far in the future. Cannot update event details.' as const;
export const UpdateEventDetailsFailureResponses = [
  InvalidDataResponse,
  NoEventDetailsToUpdateResponse,
  NoChangeInEventDetailsResponse,
  EventHasPaidMembersResponse,
  EventNotFoundResponse,
  InvalidEventDateResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
];
export type UpdateEventDetailsFailureResponsesType =
  (typeof UpdateEventDetailsFailureResponses)[number];
export type UpdateEventDetailsResponse =
  | UpdateEventDetailsSuccessResponse
  | UpdateEventDetailsFailureResponsesType
  | UnknownErrorResponseType;

/// ************************ IN STORE INFO ************************ ///
export const InStoreInfoNotFoundResponse =
  'InStore not found on event' as const;
export const UpdateInStoreInfoSchema = InStoreInfoSchema.partial();

export type UpdateInStoreInfoData = z.infer<typeof UpdateInStoreInfoSchema>;

export type UpdateInStoreInfoSuccessResponse = {
  updatedEvent: Event;
};

export const UpdateInStoreInfoFailureResponse = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InStoreInfoNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
];

export type UpdateInStoreInfoFailureResponseType =
  (typeof UpdateInStoreInfoFailureResponse)[number];

export type UpdateInStoreInfoResponse =
  | UpdateInStoreInfoSuccessResponse
  | UpdateInStoreInfoFailureResponseType;

export type DeleteStoreFromInStoreInfoStoreSuccessResponse = {
  updatedEvent: Event;
};

export const DeleteStoreFromInStoreInfoStoreFailureResponse = [
  EventNotFoundResponse,
  InStoreInfoNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
];

export type DeleteStoreFromInStoreInfoFailureResponse =
  (typeof DeleteStoreFromInStoreInfoStoreFailureResponse)[number];

export type DeleteStoreFromInStoreInfoResponse =
  | DeleteStoreFromInStoreInfoStoreSuccessResponse
  | DeleteStoreFromInStoreInfoFailureResponse;



export type SwitchToOrderOnlineSuccessResponse = {
  updatedEvent: Event;
};
export const SwitchToOrderOnlineFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  EventSharedWithRetailerResponse,
];
export type SwitchToOrderOnlineFailureResponsesType =
  (typeof SwitchToOrderOnlineFailureResponses)[number];
export type SwitchToOrderOnlineResponse = SwitchToOrderOnlineSuccessResponse |
  SwitchToOrderOnlineFailureResponsesType |
  UnknownErrorResponseType;

export const SwitchToOrderInStoreSchema = z.object({
  store: DealerAccountElasticSchema.optional()
})

export type SwitchToOrderInStoreData = z.infer<typeof SwitchToOrderInStoreSchema>;

export type SwitchToOrderInStoreSuccessResponse = {
  updatedEvent: Event;
};
export const SwitchToOrderInStoreFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  EventHasPaidMembersResponse,
];
export type SwitchToOrderInStoreFailureResponsesType =
  (typeof SwitchToOrderInStoreFailureResponses)[number];
export type SwitchToOrderInStoreResponse = SwitchToOrderInStoreSuccessResponse |
  SwitchToOrderInStoreFailureResponsesType |
  UnknownErrorResponseType;






/// ************************ IS SINGLE USER ************************ ///

export type SwitchToIndividualSuccessResponse = {
  updatedEvent: Event;
};


export const SwitchToIndividualFailureResponses = [
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  EventHasPaidMembersResponse,
  EventSharedWithRetailerResponse,
];
export type SwitchToIndividualFailureResponsesType =
  (typeof SwitchToIndividualFailureResponses)[number];
export type SwitchToIndividualResponse = SwitchToIndividualSuccessResponse | SwitchToIndividualFailureResponsesType | UnknownErrorResponseType;



export type SwitchToGroupSuccessResponse = {
  updatedEvent: Event;
};
export const SwitchToGroupFailureResponses = [
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  EventHasPaidMembersResponse,
  EventSharedWithRetailerResponse,

];
export type SwitchToGroupFailureResponsesType =
  (typeof SwitchToGroupFailureResponses)[number];
export type SwitchToGroupResponse = SwitchToGroupSuccessResponse | SwitchToGroupFailureResponsesType | UnknownErrorResponseType;




/// ************************ MEASUREMENTS ************************ ///

export const UpdateMeasurementsSchema = z.object({
  measurementsMap: z.record(MeasurementsSchema),
});

export type UpdateMeasurementsData = z.infer<typeof UpdateMeasurementsSchema>;

export type UpdateMeasurementsSuccessResponse = {
  updatedEvent: Event;
};

export const UpdateMeasurementsFailureResponse = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
];

export type UpdateMeasurementsFailureResponseType =
  (typeof UpdateMeasurementsFailureResponse)[number];

export type UpdateMeasurementsResponse =
  | UpdateMeasurementsSuccessResponse
  | UpdateMeasurementsFailureResponseType;

/// Keep this function in the types file
function removeUndefined<T extends object>(obj: T): Partial<T> {
  const result: Partial<T> = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }
  return result;
}

/// ************************ INITIALIZE PRICE SELECTION ************************ ///
export const InitializePriceSelectionSchema = z
  .object({
    lastUpdateId: EventSchema.shape.lastUpdateId,
  })
  .required();
export type InitializePriceSelectionData = z.infer<
  typeof InitializePriceSelectionSchema
>;


export type RentBuyStyleWithMissingCompanion = {
  /** The styleCode that was found, but missing its companion */
  foundStyleCode: string,
  /** The styleCode that was NOT found */
  missingStyleCode: string | null,
  /** The styleGroup this style is treated as (rent only or buy only) */
  styleGroup: StyleGroup
}

export type ReconciledStyle = {
  /** The styleCode that was not found, but did have a companion style */
  missingStyle: string,
  /** The styleCode that the missingStyle was replaced with */
  replacementCompanionStyle: string,
  /** The styleGroup the replacementCompanionStyle is treated as (rent only or buy only) */
  styleGroup: StyleGroup
}

export type UnReconciledStyle = {
  /** The styleCode that was not found. */
  missingStyle: string,
  /** The styleGroup from the style in the memberLook */
  styleGroup: StyleGroup,
};

export type MismatchedCompanionStyle = {
  /** The styleCode that was found, but had a mismatched companion */
  styleCode: string,
  /** The companionStyleCode from the query's style */
  companionStyleCodeInQueryStyle: string | null,
  /** The companionStyleCode from the memberLook's style */
  companionStyleCodeInMemberLookStyle: string | null,
  /** Whether the memberLook was updated to use style from the query */
  memberLookUpdated: boolean,
}



type InitializePriceSelectionNoModifyResponse = {
  updatedEvent: Event;
  modified: false;
  allPossibleStyles: EcomStyle[];
};
type InitializePriceSelectionModifiedResponse = {
  updatedEvent: Event;
  modified: true;
  allPossibleStyles: EcomStyle[];
  rentBuyStylesWithMissingCompanion?: RentBuyStyleWithMissingCompanion[];
  reconciledStyles?: ReconciledStyle[];
  unReconciledStyles?: UnReconciledStyle[];
  mismatchedCompanionStyles?: MismatchedCompanionStyle[];
};

export type InitializePriceSelectionSuccessResponse = InitializePriceSelectionNoModifyResponse | InitializePriceSelectionModifiedResponse;
// export const InvalidStyleGroupResponse = 'Invalid styleGroup.  This happens if the styleGroup or userCartSelectedStyleGroup is undefined after attempting to initializePriceSelection.' as const;
export const InitializePriceSelectionFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
];

export type InitializePriceSelectionFailureResponsesType =
  (typeof InitializePriceSelectionFailureResponses)[number];

export type InitializePriceSelectionResponse =
  | InitializePriceSelectionSuccessResponse
  | InitializePriceSelectionFailureResponsesType
  | UnknownErrorResponseType;




// ********************************************************************************************************************
// *                                             DELETE                                                               *
// ********************************************************************************************************************


/// ************ DELETE /delete/:id ************ ///
export type DeleteEventSuccessResponse = {
  eventDeleted: true;
  numUserEventsDeleted: number;
  /** The document id's of the UserEvent docs that were deleted. */
  userEventsDeleted: string[];
};
// export const DeleteEventFailureResponse = 'Failed to delete event' as const;
export const EventHasCartsResponse = 'Event has carts.  Cannot delete.' as const;
export const EventHasTempCartsResponse = 'Event has temp carts.  Cannot delete.' as const;
export const DeleteEventFailureResponses = [
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  EventHasCartsResponse,
  EventHasTempCartsResponse,
  EventHasPaidMembersResponse,
];
export type DeleteEventFailureResponsesType =
  (typeof DeleteEventFailureResponses)[number];
export type DeleteEventResponse = DeleteEventSuccessResponse |
  DeleteEventFailureResponsesType |
  UnknownErrorResponseType;

















interface EndpointRoutesConfig {
  [key: string]: {
    route: string,
    httpMethod: HttpMethod,
  };
}

const endpointRoutesConfig = {
  createEventFromEcom: {
    route: 'createEventFromEcom',
    httpMethod: HttpMethod.POST,
  },
  createEventFromDealerPortal: {
    route: 'createEventFromDealerPortal',
    httpMethod: HttpMethod.POST,
  },
  insertManyEvents: {
    route: 'insertManyEvents/:numCopies',
    httpMethod: HttpMethod.POST,
  },
  getEventById: {
    route: ':id',
    httpMethod: HttpMethod.GET,
  },
  hasDiscontinuedStyles: {
    route: 'has-discontinued-styles/eventid/:id',
    httpMethod: HttpMethod.POST,
  },
  saveEvent: {
    route: 'save',
    httpMethod: HttpMethod.POST,
  },
} as const satisfies EndpointRoutesConfig;

type ToEndpointRoutes<T> = T extends EndpointRoutesConfig ? T[keyof T]['route'] : never;
type EventV4EndpointRoutes = ToEndpointRoutes<typeof endpointRoutesConfig>;


const routeToParameterizedRouteFunction = (route: string) => {
  const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
  return {
    numArgs: routeArgs.length,
    toRoute: (...args: string[]) => {
      // if (routeArgs.length !== args.length) {
      //   throw new Error(`Expected ${routeArgs.length} arguments, but received ${args.length}`);
      // }
      return route.split('/').map((part) => {
        if (part.startsWith(':')) {
          return args.shift();
        }
        return part;
      }).join('/');
    }
  } as const;

}

const getNumArgs = <T extends EndpointRoutesConfig>(routesConfig: T) => {
  const keys: (keyof T)[] = Object.keys(routesConfig) as (keyof T)[]
  return keys
    .reduce((acc, key) => {
      const route = routesConfig[key].route
      const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
      const numArgs = routeArgs.length
      return {
        ...acc,
        [key]: numArgs,
      };
    }, {} as {
      [key in keyof T]: number
    }
    );
}

const getRoutes = <T extends EndpointRoutesConfig>(routesConfig: T) => {
  const keys: (keyof T)[] = Object.keys(routesConfig) as (keyof T)[]
  return keys
    .reduce((acc, key) => {
      const route = routesConfig[key].route
      const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
      const numArgs = routeArgs.length
      return {
        ...acc,
        [key]: routeToParameterizedRouteFunction(route),
      };
    }, {} as {
      [key in keyof T]: {
        route: typeof routesConfig[key]['route'],
        numArgs: number,
        toRoute: (...args: any[]) => string
      }
    }
    );
};

export const eventV7Routes = getRoutes(endpointRoutesConfig);

const getEventByIdRoute = eventV7Routes.getEventById.route
