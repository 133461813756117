import { Change } from "../types/change.type";
import { DifOptions } from "../types/dif-options.type";
import { getDif } from "./get-dif";
import { getLevelSpacers } from "./get-level-spacers";


export function getObjectDif<T extends Partial<Record<string, any>>>(previous: T, current: T, recursionDepth: number, recursionKey: string, itemIdKey: string | undefined, options: DifOptions) {
  const { logChecks, logDifs } = options;
  if (logChecks) console.log(getLevelSpacers(recursionDepth) + recursionKey, "in getObjectDif");
  // console.log("incoming previous: ", previous);
  // console.log("incoming current: ", current);
  const dif: Change = {
    previous: {},
    current: {},
  }

  const previousKeys = Object.keys(previous);
  const currentKeys = Object.keys(current);

  const keys = new Set([...previousKeys, ...currentKeys]);
  // const isMemberLook = recursionKey.includes('memberLook');
  // if (isMemberLook) {
  //   console.log("keys for getObjectDif in memberLook: ", keys);
  // }

  for (const key of keys) {
    // if (isMemberLook) console.log("memberLook key: ", key);
    const previousValue = previous[key];
    const currentValue = current[key];
    // console.log({ key, previousValue, currentValue });
    /* if (previousValue === undefined && currentValue !== undefined) {
      // if (isMemberLook) console.log("memberLook key added: ", key);
      if (logDifs) console.log(`${getLevelSpacers(recursionDepth) + recursionKey + '.' + key} -- key added`);
      dif.current[key] = currentValue;
      dif.previous[key] = ChangeType.didNotExist;
      continue;
    }

    if (currentValue === undefined && previousValue !== undefined) {
      // if (isMemberLook) console.log("memberLook key removed: ", key);
      if (logDifs) console.log(`${getLevelSpacers(recursionDepth) + recursionKey + '.' + key} -- key removed`);
      dif.previous[key] = previousValue;
      dif.current[key] = ChangeType.removed;
      continue;
    } */

    const valueDif = getDif(previousValue, currentValue, recursionDepth + 1, recursionKey + '.' + key, options);
    if (valueDif !== null) {
      if (typeof dif.current === 'object' && typeof dif.previous === 'object') { // necessary for typescript to know that dif.current and dif.previous are objects
        dif.current[key] = valueDif.current;
        dif.previous[key] = valueDif.previous;
      }
    }
  }


  const hasDif = Object.keys(dif.current).length > 0 || Object.keys(dif.previous).length > 0;

  if (!hasDif) {
    return null;
  }

  return dif;
}
