<mat-form-field
  id="date"
  [appearance]="appearance"
  [hideRequiredMarker]="hideRequiredMarker"
  [ngClass]="formFieldClass()"
>
  <mat-label *ngIf="!hideLabel">{{ label }}</mat-label>
  <input
    matInput
    [formControl]="form.controls.date"
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate"
    [required]="required"
    (blur)="onBlur.emit()"
  />
  <mat-error *ngIf="error && displayError">{{ error }}</mat-error>
  <mat-error *ngIf="form.controls.date.hasError('matDatepickerMin')">
    Date must be on or after {{ minDate | date : "shortDate" }}
  </mat-error>
  <mat-error *ngIf="form.controls.date.hasError('matDatepickerMax')">
    Date must be on or before {{ maxDate | date : "shortDate" }}</mat-error
  >
  <mat-error *ngIf="form.controls.date.errors?.required"
    >Date is required</mat-error
  >
  <mat-error *ngIf="form.controls.date.hasError('matDatepickerParse')">
    <span>Invalid date format.</span>
    <br />
    <span>Please use MM/DD/YYYY</span>
  </mat-error>

  <mat-hint *ngIf="!shouldHideHint(hideHint, hideHintOnValid)"
    >MM/DD/YYYY</mat-hint
  >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker (closed)="onBlur.emit()" #picker ></mat-datepicker>
</mat-form-field>
