import { z } from "zod";
import { EventTypeSchema } from "./event-type.enum";

export const UserEventSchema = z.object({
  createDate: z.number().optional(),
  docId: z.string().optional(),
  id: z.string(),
  userId: z.string().optional(),
  eventName: z.string(),
  eventDate: z.number(),
  eventType: EventTypeSchema.optional(),
  isOrganizer: z.boolean().optional(),
  trackProgress: z.boolean().optional(),
  isSingleUser: z.boolean().optional(),
  isInStore: z.boolean(),
});


export type UserEvent = {
  createDate?: number;
  docId?: string;
  id: string;
  userId?: string;
  eventName: string;
  eventDate: number;
  eventType?: string;
  isOrganizer?: boolean;
  trackProgress?: boolean;
  isSingleUser?: boolean;
  isInStore: boolean;
};
