export const MessageType = {
  co_owner_invite: 'co-owner-invite',
  contact_us: 'contact-us',
  dealer_invite: 'dealer-invite',
  platform_user_invite: 'platform-user-invite',
  email_change: 'email-change',
  email_reverted: 'email-reverted',
  event_not_accepted_dealer: 'event-not-accepted-dealer',
  event_not_accepted_organizer: 'event-not-accepted-organizer',
  event_transfer: 'event-transfer',
  in_store_share_dealer: 'in-store-share-dealer',
  in_store_share_consumer: 'in-store-share-consumer',
  email_verification: 'email-verification',
  email_verification_code: 'email-verification-code',
  feed_back: 'feed-back',
  member_invite: 'member-invite',
  order_shipped: 'order-shipped',
  replacement_shipped: 'replacement-shipped',
  organizer_invite: 'organizer-invite',
  password_reset_request: 'password-reset-request',
  password_reset_success: 'password-reset-success',
  receipt: 'receipt',
  time_to_get_measured: 'time-to-get-measured',
  time_to_order: 'time-to-order',
  time_to_return_order: 'time-to-return-order',
  welcome_to_jfw: 'welcome-to-jfw',
  credit_return: 'credit-return',
  credit_return_grooms_free: 'credit-return-grooms-free',
  addon_charge: 'addon-charge',
  invoice: 'invoice',
} as const;

export type MessageType = (typeof MessageType)[keyof typeof MessageType];
