import { StaticHolidays } from './static-holidays';

export function getHolidays(
  startDate: string | Date,
  endDate: string | Date
): Array<Date> {
  let holidaysBetween: Array<Date> = [];

  startDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
  endDate = typeof endDate === 'string' ? new Date(endDate) : endDate;

  let currentDate = new Date(startDate.getTime());

  while (currentDate.getTime() <= endDate.getTime()) {
    StaticHolidays.forEach((holiday: Date) => {
      if (holiday.toDateString() === currentDate.toDateString()) {
        holidaysBetween.push(holiday);
      }
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return holidaysBetween;
}
