import { z } from 'zod';
import { Event, EventAdminDetailsSchema } from '../../../event';
import { EventAdminSchema } from '../../../event/event-admin.type';
import { HttpMethod } from '../../../logger/http-method.enum';


// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export const EventNotFoundResponse = 'Event not found' as const;
export const InvalidUserOrEventResponse = 'Invalid user/event' as const;
export const UserValidationErrorResponse =
  'Unable to validate user for event' as const; // Generic error for any error thrown by isValidUserForEventWrites
export const InvalidDataResponse = 'Invalid data' as const;
export const MissingMemberIdsResponse = 'Missing memberIds' as const;
export const LooksIsEmptyResponse = 'No looks to update' as const;
export const LookNotFoundResponse = 'Look not found' as const;
export const UnknownErrorResponse = 'Unknown error' as const;
export const InvalidEventResponse = 'Invalid event' as const;
export const CreateEventMissingOrganizerResponse = "Missing organizer" as const;

export type UnknownErrorResponseType = typeof UnknownErrorResponse;



// ********************************************************************************************************************
// *                                             CREATE                                                               *
// ********************************************************************************************************************

/// ************ POST /create ************ ///





// ********************************************************************************************************************
// *                                             READ                                                                 *
// ********************************************************************************************************************





// ********************************************************************************************************************
// *                                             UPDATE                                                               *
// ********************************************************************************************************************



/// ************************ ADMINS ************************ ///
export const AddAdminSchema = z.object({
  admin: EventAdminSchema,
});

export type AddAdminData = z.infer<typeof AddAdminSchema>;

export type AddAdminSuccessResponse = {
  updatedEvent: Event;
};

export const UndefinedAdminIdResponse = 'Undefined admin id';
export const AdminNotFoundResponse = 'Admin not found';
export const LimitedAdminsResponse = 'Too many Admins';
export const AddAdminFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  UndefinedAdminIdResponse,
  LimitedAdminsResponse,
];

export type AddAdminFailureResponsesType =
  (typeof AddAdminFailureResponses)[number];

export type AddAdminResponse =
  | AddAdminSuccessResponse
  | AddAdminFailureResponsesType;

const eventAdminDetails = [
  'email',
  'firstName',
  'lastName',
  'phone',
  'role',
] as const;

type EventAdminDetails = (typeof eventAdminDetails)[number];

/// ************************ ORGANIZER/ADMIN DETAILS ************************ ///
export const UpdateAdminDetailsSchema = EventAdminDetailsSchema
  .partial()
  .transform((data) => {
    return removeUndefined(data); // Remove undefined values because Firestore can't assign undefined values.
  });

export type UpdateAdminDetailsData = z.infer<
  typeof UpdateAdminDetailsSchema
>;

export type UpdateAdminDetailsSuccessResponse = {
  updatedEvent: Event;
};
export const UpdateAdminMissingFirstOrLastNameResponse = 'The admin is missing either first or last name.' as const;
export const CannotUpdateMultipleOrganizerDetailsResponse = 'Multiple organizers found to update.' as const;  // If the api finds more than one organizer that matches, it will fail because only one admin should be getting updated.
export const OrganizerHasIdResponse = 'Organizer has an id' as const;
export const OrganizerNotFoundResponse = 'Organizer not found' as const;
export const NoAdminsUpdatedResponse = 'No admins were updated' as const;
export const UpdateAdminDetailsFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  UndefinedAdminIdResponse,
  CannotUpdateMultipleOrganizerDetailsResponse,
  UpdateAdminMissingFirstOrLastNameResponse,
  AdminNotFoundResponse,
  OrganizerNotFoundResponse,
  NoAdminsUpdatedResponse,
];

export type UpdateAdminDetailsFailureResponsesType =
  (typeof UpdateAdminDetailsFailureResponses)[number];

export type UpdateAdminDetailsResponse =
  | UpdateAdminDetailsSuccessResponse
  | UpdateAdminDetailsFailureResponsesType;



/// ************************ MEMBER PROGRESS ************************ ///
export const MemberNotFoundResponse = 'Member not found' as const;
export type MarkInviteSentSuccessResponse = {
  updatedEvent: Event;
};
export const MarkInviteSentFailureResponses = [
  EventNotFoundResponse,
  MemberNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
];
export type MarkInviteSentFailureResponsesType =
  (typeof MarkInviteSentFailureResponses)[number];
export type MarkInviteSentResponse =
  | MarkInviteSentSuccessResponse
  | MarkInviteSentFailureResponsesType
  | UnknownErrorResponseType;



// ********************************************************************************************************************
// *                                             DELETE                                                               *
// ********************************************************************************************************************
export type DeleteAdminSuccessResponse = {
  updatedEvent: Event;
  numMembersRemoved: number;
  numMemberIdsRemoved: number;
  numUserEventDocsDeleted: number;
};

export const NoAdminsToDeleteResponse = 'No admins to delete' as const;
export const AdminIsOrganizerResponse = 'Admin is the organizer. Cannot delete.' as const;
export const DeleteAdminFailureResponses = [
  InvalidDataResponse,
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  NoAdminsToDeleteResponse,
  AdminNotFoundResponse,
  AdminIsOrganizerResponse,
];
export type DeleteAdminFailureResponsesType =
  (typeof DeleteAdminFailureResponses)[number];

export type DeleteAdminResponse =
  | DeleteAdminSuccessResponse
  | DeleteAdminFailureResponsesType
  | UnknownErrorResponseType;



/// ************ DELETE /delete/:id ************ ///
export type DeleteEventSuccessResponse = {
  eventDeleted: true;
  numUserEventsDeleted: number;
  /** The document id's of the UserEvent docs that were deleted. */
  userEventsDeleted: string[];
};
// export const DeleteEventFailureResponse = 'Failed to delete event' as const;
export const EventHasCartsResponse = 'Event has carts.  Cannot delete.' as const;
export const EventHasTempCartsResponse = 'Event has temp carts.  Cannot delete.' as const;
export const EventHasPaidMembersDeleteResponse = 'Event has paid members.  Cannot delete.' as const;
export const DeleteEventFailureResponses = [
  EventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserValidationErrorResponse,
  EventHasCartsResponse,
  EventHasTempCartsResponse,
  EventHasPaidMembersDeleteResponse,
];
export type DeleteEventFailureResponsesType =
  (typeof DeleteEventFailureResponses)[number];
export type DeleteEventResponse = DeleteEventSuccessResponse |
  DeleteEventFailureResponsesType |
  UnknownErrorResponseType;







/// Keep this function in the types file
function removeUndefined<T extends object>(obj: T): Partial<T> {
  const result: Partial<T> = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }
  return result;
}






interface EndpointRoutesConfig {
  [key: string]: {
    route: string,
    httpMethod: HttpMethod,
  };
}

const endpointRoutesConfig = {
  createEventFromEcom: {
    route: 'createEventFromEcom',
    httpMethod: HttpMethod.POST,
  },
  createEventFromDealerPortal: {
    route: 'createEventFromDealerPortal',
    httpMethod: HttpMethod.POST,
  },
  insertManyEvents: {
    route: 'insertManyEvents/:numCopies',
    httpMethod: HttpMethod.POST,
  },
  getEventById: {
    route: ':id',
    httpMethod: HttpMethod.GET,
  },
  hasDiscontinuedStyles: {
    route: 'has-discontinued-styles/eventid/:id',
    httpMethod: HttpMethod.POST,
  },
  saveEvent: {
    route: 'save',
    httpMethod: HttpMethod.POST,
  },
} as const satisfies EndpointRoutesConfig;

type ToEndpointRoutes<T> = T extends EndpointRoutesConfig ? T[keyof T]['route'] : never;
type EventV4EndpointRoutes = ToEndpointRoutes<typeof endpointRoutesConfig>;


const routeToParameterizedRouteFunction = (route: string) => {
  const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
  return {
    numArgs: routeArgs.length,
    toRoute: (...args: string[]) => {
      // if (routeArgs.length !== args.length) {
      //   throw new Error(`Expected ${routeArgs.length} arguments, but received ${args.length}`);
      // }
      return route.split('/').map((part) => {
        if (part.startsWith(':')) {
          return args.shift();
        }
        return part;
      }).join('/');
    }
  } as const;

}

const getNumArgs = <T extends EndpointRoutesConfig>(routesConfig: T) => {
  const keys: (keyof T)[] = Object.keys(routesConfig) as (keyof T)[]
  return keys
    .reduce((acc, key) => {
      const route = routesConfig[key].route
      const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
      const numArgs = routeArgs.length
      return {
        ...acc,
        [key]: numArgs,
      };
    }, {} as {
      [key in keyof T]: number
    }
    );
}

const getRoutes = <T extends EndpointRoutesConfig>(routesConfig: T) => {
  const keys: (keyof T)[] = Object.keys(routesConfig) as (keyof T)[]
  return keys
    .reduce((acc, key) => {
      const route = routesConfig[key].route
      const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
      const numArgs = routeArgs.length
      return {
        ...acc,
        [key]: routeToParameterizedRouteFunction(route),
      };
    }, {} as {
      [key in keyof T]: {
        route: typeof routesConfig[key]['route'],
        numArgs: number,
        toRoute: (...args: any[]) => string
      }
    }
    );
};

export const eventV6Routes = getRoutes(endpointRoutesConfig);

const getEventByIdRoute = eventV6Routes.getEventById.route
