import { Event, EventAdmin, EventAdminDetails } from "common-types";
import { getNameFromFirstAndLastName } from "../../../user/utilities/get-name-from-first-and-last-name";
import { getAdminEmails } from "./get-admin-emails";

/**
 * Returns a new event object with three properties:
 *  - updatedEvent: the new event with the admin details updated.
 *  - updatedAdmin: the admin that was updated.  If the admin was not found, this will be undefined.
 *  - numAdminsUpdated: the number of admins that were updated.  Should be 0 or 1.  If it is more than 1, something went wrong.
 *
 * Does not mutate the original event.  Updates both the admins array and the adminEmails array.
 * @param event the event to update the admin details for
 * @param adminId the id of the admin to update
 * @param newDetails an object containing the new details to update the admin with (only the properties that are provided will be updated).
 */
export function updateAdminDetails(event: Event, adminId: string, newDetails: Partial<EventAdminDetails>): { updatedEvent: Event, updatedAdmin: EventAdmin | undefined, numAdminsUpdated: number } {
  const { firstName, lastName, phone, email } = newDetails;

  let numAdminsUpdated = 0;
  let updatedAdmin: EventAdmin | undefined;

  const newAdminsArray = event.admins.map((admin: EventAdmin) => {

    // This should only be satisfied for one admin.
    // if (admin.id === adminId || (admin.isOrganizer && admin.id === '' && adminId === 'organizer')) {
    if (admin.id === adminId) {

      // can't update name property if we don't have a first and last name
      if (firstName && !lastName && !admin?.lastName) {
        throw new Error('firstName was provided, but lastName was not provided and this admin does not already have a lastName property.  Both firstName and lastName are required to update the name property.');
      }

      // can't update name property if we don't have a first and last name
      if (lastName && !firstName && !admin?.firstName) {
        throw new Error('lastName was provided, but firstName was not provided and this admin does not already have a firstName property.  Both firstName and lastName are required to update the name property.');
      }

      const newAdmin: EventAdmin = {
        ...admin,
        ...(firstName ? { firstName } : undefined),
        ...(lastName ? { lastName } : undefined),
        ...(firstName || lastName ? { name: getNameFromFirstAndLastName(firstName ?? admin?.firstName ?? '', lastName ?? admin?.lastName ?? '') } : undefined), // empty strings are just to satisfy typescript.  There is a check above to make sure the values are there.
        ...(phone ? { phone } : undefined),
        ...(email ? { email } : undefined),
      }

      numAdminsUpdated++;
      updatedAdmin = newAdmin;

      return newAdmin;
    }

    return admin;
  });

  const newAdminEmailsArray = getAdminEmails(newAdminsArray);

  const updatedEvent: Event = {
    ...event,
    admins: newAdminsArray,
    adminEmails: newAdminEmailsArray,
  }

  return { updatedEvent, updatedAdmin, numAdminsUpdated };

}
