import { Look } from 'common-types';
import { DEFAULT_DIF_OPTIONS, DifOptions } from './types/dif-options.type';
import { getArrayDif } from './utils/get-array-dif';

export function getLooksDif<T extends Look[]>(previous: T, current: T, options?: DifOptions<'looks'>) {

  return getArrayDif(previous, current, 0, 'looks', 'id', (options ?? DEFAULT_DIF_OPTIONS) as DifOptions)

}

// const looks = [] as Look[];

// getLooksDif(looks, looks, { keysToIgnore: ['altColors'] });
