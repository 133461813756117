import { z, ZodSchema } from 'zod';
import { IdValidator } from '../../common/validation/id-validator';
import { ProductValidator } from '../../product/validation/product-validator';

export const LookValidator: ZodSchema = z.object({
  id: IdValidator,
  lookName: z.string().max(100),
  products: ProductValidator.array(),
  categories: z.string().array().optional(),
  customized: z.boolean().optional(),
  priceRent: z.number().positive().max(999.99).optional(),
  priceBuy: z.number().positive().max(9999.99).optional(),
});
