import { ComType, MessageType } from 'common-types';

export function isUrgentNotification(
  messageType: MessageType,
  comType: ComType
): boolean {
  let isUrgent: boolean;
  switch (messageType) {
    case MessageType.dealer_invite:
    case MessageType.email_verification:
    case MessageType.email_verification_code:
    case MessageType.event_transfer:
    case MessageType.email_change:
    case MessageType.password_reset_request:
    case MessageType.co_owner_invite:
    case MessageType.member_invite:
      isUrgent = comType == ComType.email;
      break;

    default:
      isUrgent = false;
      break;
  }
  return isUrgent;
}
