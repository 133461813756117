import { AccountMaster } from 'common-types';

/**
 *  Determines based on business rules should we display a store in our store locator.
 */
/**
 * @param {AccountMaster} platformAccount - Platform Accounts document
 * @returns {boolean}
 */
export const shouldProcessAccountToElastic = (
  platformAccountToUpdate: AccountMaster
): boolean => {
  /**
   * Store Criteria to show:
   *  accountStatus = A
   *  ecomStatus = H or I (exclude O)
   *  storeType = B, C, D, F, H, J, M, N, O, P, R, T, Y
   *  searchable = Y
   * ELSE
   *  Remove from elastic or do nothing
   */

  const validAccountStatus = (account: AccountMaster): boolean => {
    return account.accountStatus === 'A';
  };

  const validEcomStatus = (account: AccountMaster): boolean => {
    const { ecomStatus } = account;
    if (
      ecomStatus?.toUpperCase() === 'H' ||
      ecomStatus?.toUpperCase() === 'I'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validStoreType = (account: AccountMaster): boolean => {
    const validStoreTypes = [
      'B',
      'C',
      'D',
      'F',
      'H',
      'J',
      'M',
      'N',
      'O',
      'P',
      'R',
      'T',
      'Y',
    ];
    const { storeType } = account;
    if (!storeType) {
      return false;
    } else {
      return validStoreTypes.includes(storeType?.toUpperCase());
    }
  };

  const validStoreSearch = (account: AccountMaster): boolean => {
    const { searchable } = account;
    return searchable === 'Y';
  };

  if (platformAccountToUpdate) {
    const account = platformAccountToUpdate;
    if (
      validAccountStatus(account) &&
      validEcomStatus(account) &&
      validStoreType(account) &&
      validStoreSearch(account)
    ) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};
